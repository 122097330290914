import { createContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const BackLocationsContext = createContext();

// It is possible we don't need to maintain this ourselves and react router can do it.
function BackLocationsProvider({ children }) {
  const [backLocations, setBackLocations] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(backLocations[backLocations.length - 1]);
    const newLocations = backLocations;
    newLocations.pop();
    setBackLocations(newLocations);
  };

  const addBackLocation = (path = `${location.pathname}${location.search}`) => {
    const newLocations = backLocations;
    newLocations.push(path);
    setBackLocations(newLocations);
  };

  const clearBackLocations = () => {
    setBackLocations([]);
  };

  return (
    <BackLocationsContext.Provider
      value={{
        backLocations,
        addBackLocation,
        clearBackLocations,
        goBack,
      }}
    >
      {children}
    </BackLocationsContext.Provider>
  );
}

export default BackLocationsProvider;
