import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { css, styled } from "styled-components";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { comicUrl } from "../lib/routes";
import Button from "../components/Button";
import { ReactSVG } from "react-svg";
import {
  DIALOG_NAME,
  useDialogSearchParam,
  useOpenParamDialog,
} from "../helpers/useDialogSearchParam";
import CommentDrawer from "../components/social/CommentDrawer";
import EpisodeDrawer from "../components/EpisodeDrawer";

const headerHeight = "60";

export default function ComicReaderHeader({
  episodes,
  currentEpisodeNumber,
  commentTotal,
}) {
  const [isCommentsParamSet, removeCommentsParam] = useDialogSearchParam(
    DIALOG_NAME.comments
  );
  const [isEpisodesParamSet, removeEpisodesParam] = useDialogSearchParam(
    DIALOG_NAME.episodes
  );
  const setCommentsParam = useOpenParamDialog(DIALOG_NAME.comments);
  const setEpisodesParam = useOpenParamDialog(DIALOG_NAME.episodes);

  const navigate = useNavigate();

  const { selectedStoryworld } = useContext(SelectedStoryworldContext);

  const toggleCommentParam = () => {
    if (isCommentsParamSet) {
      removeCommentsParam();
    } else {
      if (isEpisodesParamSet) {
        removeEpisodesParam();
      }

      setCommentsParam();
    }
  };

  const toggleEpisodeDrawer = () => {
    if (isEpisodesParamSet) {
      removeEpisodesParam();
    } else {
      if (isCommentsParamSet) {
        removeCommentsParam();
      }

      setEpisodesParam();
    }
  };

  const episodeTitle =
    episodes.find((ep) => ep.episodeNumber === currentEpisodeNumber)?.title ||
    "";

  return (
    <>
      <Container>
        <EpisodeInfo>
          <Link to={`/`}>
            <img src="/images/logos/logo-tablet-white.svg" />
          </Link>
          <div>
            <Link
              to={`/stories/${selectedStoryworld?.id}`}
              style={{
                color: "var(--color-cream-base)",
              }}
            >
              {selectedStoryworld?.title}
            </Link>
            <span
              style={{ color: "var(--color-gray-200)", marginInline: "6px" }}
            >
              /
            </span>
            {episodeTitle}
          </div>
        </EpisodeInfo>
        <SwitchEpisode>
          <ArrowButton
            $isFlipped={true}
            disabled={currentEpisodeNumber === 1}
            onClick={() => {
              navigate(
                comicUrl(selectedStoryworld.id, currentEpisodeNumber - 1)
              );
            }}
          >
            <img src="/images/about/arrow.svg" />
          </ArrowButton>
          # {currentEpisodeNumber}
          <ArrowButton
            disabled={currentEpisodeNumber === episodes.length}
            onClick={() => {
              navigate(
                comicUrl(selectedStoryworld.id, currentEpisodeNumber + 1)
              );
            }}
          >
            <img src="/images/about/arrow.svg" />
          </ArrowButton>
        </SwitchEpisode>
        <Actions>
          <Button
            variant="ghost"
            width="unset"
            onClick={toggleCommentParam}
            isActive={isCommentsParamSet}
          >
            <div>
              <CommentIcon src="/icons/social/comment-icon.svg" />
              {commentTotal ? commentTotal : null}
            </div>
          </Button>
          <Button
            variant="ghost"
            width="unset"
            onClick={toggleEpisodeDrawer}
            isActive={isEpisodesParamSet}
          >
            <div>
              <ReactSVG src="/icons/list.svg" />
              Episodes List
            </div>
          </Button>
        </Actions>
      </Container>
      <CommentDrawer
        isOpen={isCommentsParamSet}
        onClose={removeCommentsParam}
        offsetTop={headerHeight}
      />
      <EpisodeDrawer
        isOpen={isEpisodesParamSet}
        onClose={toggleEpisodeDrawer}
        offsetTop={headerHeight}
      />
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: ${headerHeight}px;
  background: var(--color-gray-700);
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 0px 1.5rem;

  display: grid;
  align-items: center;
  grid-template-columns: 3fr 2fr 3fr;
`;

const EpisodeInfo = styled.div`
  justify-self: left;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  color: var(--color-cream-base);
  font-size: 0.875rem;
  font-weight: 500;
`;

const SwitchEpisode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  color: var(--color-cream-base);
  font-size: 0.875rem;
  font-weight: 500;

  /* :first-child {
    transform: rotate(180deg);
  } */
`;

const Actions = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 600;

  button {
    > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

const ArrowButton = styled.button`
  /* Button reset */
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  /* end of button reset */

  width: 2rem;
  height: 2rem;

  border-radius: 4px;
  border: 2px solid #777e91;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  img {
    margin-top: -2px;
    ${(props) =>
      props.$isFlipped &&
      css`
        transform: rotate(180deg);
        transform-origin: center;
      `}
  }
`;

const CommentIcon = styled(ReactSVG)`
  display: block;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
