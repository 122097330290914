import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  firebaseAuth,
  registerFirebaseAuth,
  validateEmail,
} from "../../helpers/authentication";
import { Col, Row, message } from "antd";
import { SESSION_STORAGE, APP_NAME, APP_LOGO } from "../../helpers/constants";
import {
  FieldContainer,
  Highlight,
  Spacer,
  StyledCheckbox,
  StyledInput,
} from "../../styles/CommonElements";
import TosSheet from "../TosSheet";
import Button from "../Button";
import { AuthContext } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import ThirdPartyAuth from "./ThirdPartyAuth";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import Backlink from "../Backlink";

const passwordLowerThreshold = 6;
const passwordUpperThreshold = 15;

export default function CreateAccount({
  emailAddress,
  setEmailAddress,
  setGoToProfileCreation,
}) {
  const { user, userInfo, isUserInfoLoading, isThirdPartyAuthUser } =
    useContext(AuthContext);
  const { backLocations, goBack } = useContext(BackLocationsContext);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [isPasswordValidated, setPasswordValidated] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [revealPassword, setRevealPassword] = useState(false);
  const [reavealConfirmedPassword, setReavealConfirmedPassword] =
    useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [tosChecked, setTosChecked] = useState(true);
  const [tosSheetOpen, setTosSheetOpen] = useState(false);

  let password1Ref = React.useRef();
  let password2Ref = React.useRef();

  useEffect(() => {
    if (user && !isUserInfoLoading) {
      if (userInfo) {
        navigate("/stories");
      } else if (!isThirdPartyAuthUser) {
        setGoToProfileCreation(true);
      }
    }
  }, [
    user,
    userInfo,
    navigate,
    setGoToProfileCreation,
    isUserInfoLoading,
    isThirdPartyAuthUser,
  ]);

  useEffect(() => {
    const passwordsMatch = password === confirmedPassword;
    const passwordLengthValid =
      password.length >= passwordLowerThreshold &&
      password.length <= passwordUpperThreshold;
    setPasswordValidated(passwordLengthValid && passwordsMatch);
    if (
      !passwordsMatch &&
      password.length >= passwordLowerThreshold &&
      confirmedPassword.length >= passwordLowerThreshold
    )
      setPasswordError("Passwords do not match");
    else if (!passwordLengthValid && password !== "") {
      setPasswordError(
        `Must be between ${passwordLowerThreshold} and ${passwordUpperThreshold} characters.`
      );
    } else setPasswordError("");
  }, [password, confirmedPassword]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsRegistering(true);

    if (isThirdPartyAuthUser) {
      setGoToProfileCreation(true);
    } else {
      const registered = await registerFirebaseAuth({
        email: emailAddress,
        password,
      });
      setGoToProfileCreation(registered);
      if (!registered)
        message.error("An account with this email already exists");
    }

    setIsRegistering(false);
  };

  return (
    <>
      <Container>
        {backLocations.length > 0 && <Backlink onClick={goBack} />}
        <WelcomeImage src={APP_LOGO} alt="" />
        <Subheader>
          {isThirdPartyAuthUser &&
            "Almost there! Please review the following information."}{" "}
          {!user &&
            `Enter your email and password to create a ${APP_NAME} account`}
        </Subheader>
        <form onSubmit={onSubmit}>
          <InputHeader>Email</InputHeader>
          <InputContainer>
            <StyledInput
              placeholder="email@email.com"
              value={user && isThirdPartyAuthUser ? user.email : emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              disabled={isThirdPartyAuthUser}
              className={isThirdPartyAuthUser ? "locked" : ""}
            />
            {isThirdPartyAuthUser && <Checkmark src="/icons/checkmark.svg" />}
          </InputContainer>

          {!isThirdPartyAuthUser && (
            <>
              <InputHeader>Password</InputHeader>
              <InputContainer>
                <StyledInput
                  className="transition-colors"
                  placeholder={`Must be at least ${passwordLowerThreshold} characters`}
                  value={password}
                  maxLength={40}
                  onChange={(e) => setPassword(e.target.value)}
                  type={revealPassword ? "text" : "password"}
                  ref={password1Ref}
                />
                <span
                  className="flex justify-around items-center"
                  onClick={() => {
                    setRevealPassword(!revealPassword);
                    password1Ref.current.focus();
                  }}
                >
                  {revealPassword ? (
                    <HidePasswordIcon src="/images/general/password-hide.svg" />
                  ) : (
                    <RevealPasswordIcon src="/images/general/password-reveal.svg" />
                  )}
                </span>
              </InputContainer>

              <InputHeader>Confirm password</InputHeader>
              <InputContainer>
                <StyledInput
                  className="transition-colors"
                  placeholder="Type your password again"
                  value={confirmedPassword}
                  maxLength={40}
                  onChange={(e) => setConfirmedPassword(e.target.value)}
                  type={reavealConfirmedPassword ? "text" : "password"}
                  ref={password2Ref}
                />
                <span
                  className="flex justify-around items-center"
                  onClick={() => {
                    setReavealConfirmedPassword(!reavealConfirmedPassword);
                    password2Ref.current.focus();
                  }}
                >
                  {reavealConfirmedPassword ? (
                    <HidePasswordIcon src="/images/general/password-hide.svg" />
                  ) : (
                    <RevealPasswordIcon src="/images/general/password-reveal.svg" />
                  )}
                </span>
              </InputContainer>
              {passwordError !== "" && (
                <PasswordError>{passwordError}</PasswordError>
              )}
            </>
          )}

          <Spacer $marginTop="2rem" />

          <FieldContainer style={{ marginTop: 6 }}>
            <Row>
              <Col span={2}>
                <StyledCheckbox
                  defaultChecked={tosChecked}
                  onClick={(e) => setTosChecked(e.target.checked)}
                />
              </Col>
              <Col span={1} />
              <StyledCol span={21}>
                I have read and agreed to the{" "}
                <Highlight
                  onClick={() => {
                    setTosSheetOpen(true);
                  }}
                >
                  Terms of Service
                </Highlight>
              </StyledCol>
            </Row>
          </FieldContainer>

          <Spacer $marginTop="2.25rem" />

          {isThirdPartyAuthUser ? (
            <>
              <Button type="submit" size="large" disabled={!tosChecked}>
                {isRegistering ? (
                  <Spinner alt="" src="/icons/loading-spheres.svg" />
                ) : (
                  "Create Account"
                )}
              </Button>
              <Spacer $marginTop="1.5rem" />
              <Logout
                onClick={() => {
                  firebaseAuth
                    .signOut()
                    .then(() => {
                      navigate(
                        sessionStorage.getItem(SESSION_STORAGE.redirectUrl) ||
                          "/"
                      );
                    })
                    .catch((error) => {
                      console.error("Sign out Error:", error);
                    });
                }}
              >
                Go Back
              </Logout>
            </>
          ) : (
            <>
              <Button
                type="submit"
                size="large"
                disabled={
                  !(isPasswordValidated && tosChecked) ||
                  isRegistering ||
                  !validateEmail(emailAddress)
                }
              >
                {isRegistering ? (
                  <Spinner alt="" src="/icons/loading-spheres.svg" />
                ) : (
                  "Create Account"
                )}
              </Button>
              <Spacer $marginTop="1rem" />
              {/*
              <ThirdPartyAuth cta="sign up" />
              */}
              <Spacer $marginTop="2rem" />
            </>
          )}
        </form>
      </Container>

      <TosSheet isOpen={tosSheetOpen} setOpen={setTosSheetOpen} />
    </>
  );
}

const Container = styled.div`
  margin: 1.5rem auto auto auto;
  width: 90%;
  text-align: left;
`;

const WelcomeImage = styled.img`
  width: 80px;
  height: auto;
  margin: auto;
  display: block;
`;

const Checkmark = styled.img`
  right: 14px !important;
  top: 35%;
  height: 12px;
  position: absolute;
`;

const InputContainer = styled.div`
  position: relative;

  .locked {
    color: var(--seafoam);
  }
`;

const Subheader = styled.div`
  margin: auto;
  margin-bottom: 0;
  margin-top: 16px;
  color: var(--color-cream-base);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  max-width: 250px;
`;

const InputHeader = styled.div`
  margin: 24px 0px 10px 0px;
  color: var(--color-gray-50);
  font-family: var(--font-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.2px;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const PasswordError = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 12px;
  color: var(--color-red);
  letter-spacing: 0.2px;
`;

const RevealPasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

const HidePasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

const StyledCol = styled(Col)`
  color: var(--color-gray-50);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  vertical-align: middle;
`;

const Logout = styled.div`
  color: var(--color-red-rejected);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;
