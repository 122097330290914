import { Menu, Tooltip, Layout } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { ReactSVG } from "react-svg";
import { useQuery } from "@tanstack/react-query";
import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";
import { getUserStoryworlds } from "../helpers/firebase";
import { showHeaderUi } from "../helpers/general";
import { getItem, striderAdminList } from "../helpers/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { promoKeys, storyworldKeys } from "../lib/queryKeys";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { AuthContext } from "../context/AuthProvider";
import promoModel from "../lib/firebase/promoModel";
import { VisionHolderPanelHeader } from "../components/vh-panel";

const { Sider } = Layout;

export const siderWidth = 260;

export default function VisionHolderPanel() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState(null);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [selectedVote, setSelectedVote] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [selectedCreatorPoll, setSelectedCreatorPoll] = useState(null);
  const [navItems, setNavItems] = useState([]);
  const [, setStoryworldQuests] = useState([]);
  const [current, setCurrent] = useState("0");
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const { selectedStoryworld, setSelectedStoryworld } = useContext(
    SelectedStoryworldContext
  );

  const { user: currentUser } = useContext(AuthContext);

  const { data: storyworlds } = useQuery({
    queryKey: storyworldKeys.user_storyworlds(),
    queryFn: () => {
      return getUserStoryworlds();
    },
    enabled: isAdmin,
    placeholderData: [],
  });

  const { data: promos } = useQuery({
    queryKey: promoKeys.created(),
    queryFn: () => {
      return promoModel.getMany(["sort", "order", "asc"]);
    },
    enabled: isAdmin,
    placeholderData: [],
  });

  useEffect(() => {
    if (location.pathname === "/profile") {
      setSelectedQuest(null);
    } else if (location.pathname === "/edit") {
      setSelectedQuest(null);
    }

    setSelectedVote(null);
  }, [location.pathname, setSelectedQuest, setSelectedVote]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [selectedVote, location.pathname]);

  useEffect(() => {
    if (selectedEpisode === null) {
      setSelectedQuest(null);
    }
  }, [selectedEpisode, setSelectedQuest]);

  useEffect(() => {
    if (currentUser && striderAdminList.includes(currentUser.email))
      setIsAdmin(true);
    else if (currentUser) setIsAdmin(false);
    let newNavItems = [];
    if (isAdmin && storyworlds.length > 0) {
      const sortedStoryworlds = storyworlds.slice().sort(function (a, b) {
        return b.lastUpdated.toDate() - a.lastUpdated.toDate();
      });

      sortedStoryworlds.forEach((storyworld) => {
        newNavItems.push({
          label: (
            <SubNavItem>
              <Tooltip placement="right" title={storyworld.title}>
                <Link
                  to={`/edit/${storyworld.id}/episodes`}
                  onClick={() => setCurrent("0")}
                >
                  {storyworld.title}
                </Link>
              </Tooltip>
            </SubNavItem>
          ),
          key: storyworld.id,
        });
      });
      setNavItems([
        getItem(
          <MenuHeaderOption>
            <Link to="/edit">Storyworlds</Link>
          </MenuHeaderOption>,
          "1",
          null,
          newNavItems
        ),
      ]);
    } else {
      setNavItems([getItem(<Link to="/quests">Quests</Link>, "/quests")]);
    }
  }, [currentUser, isAdmin, setCurrent, setNavItems, storyworlds]);

  const onClick = (e) => {
    const storyworld = storyworlds.filter(function (el) {
      return e.key === el.id;
    });
    setSelectedStoryworld(storyworld[0]);
  };

  const onClickCurrent = (e) => {
    setCurrent(e.key);
  };

  return (
    <>
      {showHeaderUi(location) && (
        <VisionHolderPanelHeader
          selectedQuest={selectedQuest}
          setSelectedQuest={setSelectedQuest}
          selectedPoll={selectedPoll}
          setSelectedPoll={setSelectedPoll}
          selectedEpisode={selectedEpisode}
          setSelectedEpisode={setSelectedEpisode}
          selectedCreatorPoll={selectedCreatorPoll}
          setSelectedCreatorPoll={setSelectedCreatorPoll}
        />
      )}

      {selectedQuest === null &&
        selectedPoll === null &&
        selectedEpisode === null &&
        selectedCreatorPoll === null &&
        showHeaderUi(location) &&
        currentUser &&
        striderAdminList.includes(currentUser.email) && (
          <>
            <StyledSider
              collapsedWidth="0"
              collapsed={sidebarCollapsed}
              onCollapse={(collapsed) => setSidebarCollapsed(collapsed)}
              // setting trigger to null is the way to hide the provided icon from antd
              {...(sidebarCollapsed ? {} : { trigger: null })}
              collapsible
              $absolute={true}
            >
              <MenuSidebar>
                <Logo
                  onClick={() => navigate("/edit")}
                  src="/images/footer-hero.svg"
                  alt=""
                />

                <Collapse
                  onClick={() => {
                    setSidebarCollapsed(true);
                  }}
                  src="/icons/shrink.svg"
                />

                {isAdmin && (
                  <>
                    {!selectedStoryworld && (
                      <>
                        <Menu
                          mode="inline"
                          defaultSelectedKeys={["1"]}
                          defaultOpenKeys={["1"]}
                          items={navItems}
                          onClick={onClick}
                        />

                        {/* Promo Carousel Options */}
                        <Menu
                          mode="inline"
                          items={[
                            getItem(
                              <MenuHeaderOption>
                                <Link to="/edit/promos">
                                  Promotional Carousel
                                </Link>
                              </MenuHeaderOption>,
                              "promo-toplevel",
                              null,
                              promos.map((promo, index) =>
                                getItem(
                                  <SubNavItem>
                                    <Link
                                      to={`/edit/promos/create?id=${promo.id}`}
                                    >
                                      {promo.title ?? "Promo"}
                                    </Link>
                                  </SubNavItem>,
                                  index,
                                  null,
                                  null
                                )
                              )
                            ),
                          ]}
                          onClick={onClickCurrent}
                        />
                        {/* Mod Dashboard */}
                        <Menu
                          mode="inline"
                          items={[
                            {
                              key: "mod",
                              label: (
                                <MenuHeaderOption>Moderation</MenuHeaderOption>
                              ),
                              children: [
                                {
                                  key: "comments",
                                  label: (
                                    <SubNavItem>
                                      <Link to="/edit/mod/comments">
                                        Comment Review
                                      </Link>
                                    </SubNavItem>
                                  ),
                                },
                              ],
                            },
                          ]}
                          onClick={onClickCurrent}
                        />
                      </>
                    )}

                    {selectedStoryworld && (
                      <Menu
                        mode="inline"
                        defaultOpenKeys={["0"]}
                        selectedKeys={[current]}
                        items={["Episodes", "Quests", "Polls"].map(
                          (item, index) =>
                            getItem(
                              <MenuHeaderOption>
                                <Link
                                  to={`/edit/${
                                    selectedStoryworld.id
                                  }/${item.toLowerCase()}`}
                                >
                                  {item}
                                </Link>
                              </MenuHeaderOption>,
                              index,
                              null,
                              null
                            )
                        )}
                        onClick={onClickCurrent}
                      />
                    )}
                  </>
                )}
              </MenuSidebar>
            </StyledSider>

            <StyledSider
              collapsedWidth="0"
              collapsed={sidebarCollapsed}
              // setting trigger to null is the way to hide the provided icon from antd
              {...(sidebarCollapsed ? {} : { trigger: null })}
              collapsible
              $hideExpandButton={true}
            />
          </>
        )}

      <Layout>
        <AdminContainer>
          <Outlet
            context={{
              isAdmin,
              setCurrent,
              selectedQuest,
              setSelectedQuest,
              selectedPoll,
              setSelectedPoll,
              setStoryworldQuests,
              setSelectedEpisode,
              selectedEpisode,
              selectedCreatorPoll,
              setSelectedCreatorPoll,
            }}
          />
        </AdminContainer>
      </Layout>
    </>
  );
}

const AdminContainer = styled.div`
  text-align: left;
  padding: 76px 70px 80px 120px;
  width: 100%;
  height: 100%;
  background: var(--color-black);
`;

const Logo = styled.img`
  margin: 10px 0px 30px 10px;
  width: 150px;
  height: auto;
`;

const Collapse = styled(ReactSVG)`
  transform: rotate(180deg);
  margin-left: 16px;
  margin-top: -10px;
  display: inline-block;
  cursor: pointer;
`;

const MenuHeaderOption = styled.div`
  margin: auto;
  font-weight: bold;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  a {
    color: white !important;
    display: flow;
  }
`;

const MenuSidebar = styled.div`
  background: var(--color-gray-500);
  padding: 15px 20px !important;
  height: 100%;
  min-width: ${siderWidth}px !important;
  border-right: none !important;
  border-right: 2px solid var(--color-gray-800) !important;
  text-align: left;
`;

const StyledSider = styled(Sider)`
  // !important is needed to override antd's inline styles
  position: ${(props) => props.$absolute && "absolute"} !important;
  top: 0;
  // Current height of the NavFooter / hacky way to make this work for now
  height: calc(100% - 156px);
  z-index: 2;
  width: 260px;

  .ant-layout-sider-zero-width-trigger-left {
    display: ${(props) => props.$hideExpandButton && "none"};
  }
`;

const SubNavItem = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  font-size: 12px;
  font-family: sans-serif !important;
  font-weight: 600;
  color: var(--color-cream-dark);
`;
