export const profaneWords = [
  "@rsehole",
  "@sshole",
  "coochy",
  "l1ckmyp0ssy",
  "@ss",
  "5ex",
  "5exy",
  "letshave5ex",
  "f@ck",
  "b00b",
  "fuc",
];
