import React from "react";
import styled from "styled-components";
import pluralize from "pluralize";
import { useQuery } from "@tanstack/react-query";
import creatorPollVoteModel from "../../lib/firebase/creatorPollVoteModel";

export default function CreatorPollPreviewIndividualResponse({
  creatorPoll,
  response,
}) {
  const { data: responseCount = 0 } = useQuery({
    queryKey: ["creator-polls", "vote-counts", response],
    queryFn: async () => {
      return await creatorPollVoteModel.count(
        ["creatorPollId", "==", creatorPoll.id],
        ["response", "array-contains", response]
      );
    },
  });

  return (
    <Response className="response">
      <ResponseContainer>
        <ResponseOption>{response}</ResponseOption>
      </ResponseContainer>
      <VoteAmount>{pluralize("vote", responseCount, true)}</VoteAmount>
    </Response>
  );
}

const Response = styled.div`
  width: 100%;
  display: inline-flex;
`;

const ResponseContainer = styled.div`
  display: flex;
  width: calc(100% - 100px);
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  height: 46px;
  border: 2px solid var(--color-blurple);
`;

const ResponseOption = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
`;

const VoteAmount = styled.div`
  color: var(--inputFieldText);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  height: 100%;
  margin: auto;
  margin-left: 0.75rem;
`;
