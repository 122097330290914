import React, { useState } from "react";
import styled from "styled-components";
import { useContext } from "react";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import promoModel from "../lib/firebase/promoModel";
import { Spacer, Spinner } from "../styles/CommonElements";
import { promoKeys } from "../lib/queryKeys";
import Slider from "react-slick";
import { device } from "../lib/breakpoints";
import Hidden from "./Hidden";

const carouselDelayInSeconds = 5;
const carouselAnimationSpeedInSeconds = 0.5;

const AD_TOP = "/images/stories/ad-creators.svg";
const AD_BOTTOM = "/images/stories/ad-quests.svg";

const AD_TOP_LINK = "https://google.com/";
const AD_BOTTOM_LINK = "/quests";

const PromoCarousel = () => {
  const { addBackLocation } = useContext(BackLocationsContext);
  let carouselRef = React.createRef();

  const [currentSlide, setCurrentSlide] = useState(0);
  const {
    data: promos,
    isFetching,
    isPlaceholderData,
  } = useQuery({
    queryKey: promoKeys.published(),
    queryFn: () => {
      return promoModel.getMany(
        ["startTimestamp", "<", new Date()],
        ["endTimestamp", ">", new Date()],
        ["isDraft", "==", false],
        ["sort", "order", "asc"]
      );
    },
    placeholderData: [],
  });

  const afterChange = (newSlide) => {
    setCurrentSlide(newSlide);
  };

  const onMouseEnter = () => {
    carouselRef.current.slickPause();
  };

  const onMouseLeave = () => {
    carouselRef.current.slickPlay();
  };

  if (isFetching && isPlaceholderData) {
    return (
      <SpinnerContainer>
        <StyledSpinner />
      </SpinnerContainer>
    );
  }

  if (promos.length === 0) {
    return null;
  }

  return (
    <>
      {isFetching && isPlaceholderData && (
        <SpinnerContainer>
          <StyledSpinner />
        </SpinnerContainer>
      )}

      <Container>
        {promos?.length > 1 && (
          <CarouselContainer>
            <StyledCarousel
              afterChange={afterChange}
              autoplay={true}
              autoplaySpeed={carouselDelayInSeconds * 1000}
              dots={false}
              arrows={false}
              infinite={true}
              pauseOnHover={true}
              ref={carouselRef}
              speed={carouselAnimationSpeedInSeconds * 1000}
              onTouchStart={() => onMouseEnter()}
              onTouchEnd={() => onMouseLeave()}
            >
              {promos.map((promo) => (
                <CarouselSection
                  key={promo.id}
                  onClick={async () => {
                    addBackLocation();
                    await promoModel.incrementClicks({ id: promo.id });
                  }}
                  to={promo.link}
                >
                  <Promo src={promo.imageUrl} alt="Promo Image" />
                </CarouselSection>
              ))}
            </StyledCarousel>

            <Spacer $marginTop="0.6rem" />

            <Pagination>
              {Array.from({ length: promos.length }).map((_, index) => (
                <Dot
                  key={index}
                  onClick={() => {
                    carouselRef.current.slickGoTo(index);
                    setCurrentSlide(index);
                  }}
                  $activeSlide={index === currentSlide}
                />
              ))}
            </Pagination>
          </CarouselContainer>
        )}

        {promos?.length === 1 && (
          <CarouselContainer>
            <CarouselSection
              key={promos[0].id}
              onClick={async () => {
                addBackLocation();
                await promoModel.incrementClicks({ id: promos[0].id });
              }}
              to={promos[0].link}
            >
              <Promo src={promos[0].imageUrl} alt="Promo Image" />
            </CarouselSection>
          </CarouselContainer>
        )}

        <Hidden mobile tablet>
          <AdvertisementContainer>
            <Link to={AD_TOP_LINK} target="_blank">
              <Advertisement src={AD_TOP} alt="" />
            </Link>
            <Spacer $marginTop="16px" />
            <Link to={AD_BOTTOM_LINK}>
              <Advertisement src={AD_BOTTOM} alt="" />
            </Link>
          </AdvertisementContainer>
        </Hidden>
      </Container>

      {promos?.length === 1 && <Spacer $marginTop="3rem" />}

      <Hidden mobile>
        <Spacer $marginTop="1.25rem" />
      </Hidden>
    </>
  );
};

const Container = styled.div`
  display: block;
  margin: auto;

  @media (${device.tablet}) {
    max-width: 596px;
  }

  @media (${device.desktop}) {
    display: inline-flex;
  }
`;

const AdvertisementContainer = styled.div`
  display: block;
  margin-top: 1.5rem;
  width: 512px;
`;

const Advertisement = styled.img`
  width: 100%;
  display: block;
`;

const CarouselContainer = styled.div`
  height: fit-content;

  @media (${device.mobile}) {
    width: 100%;
  }

  @media (${device.tablet}) {
    width: 596px;
    margin: auto;
    margin-top: 1rem;
  }

  @media (${device.desktop}) {
    margin-top: 1.5rem;
    margin-right: 16px;
  }
`;

const CarouselSection = styled(Link)`
  height: fit-content;
`;

const Dot = styled.div`
  border-radius: 100px;
  cursor: pointer;
  height: 6px;
  transition: width 150ms ease-out;
  width: ${(props) => (props.$activeSlide ? "32px" : "6px")};
  background: ${(props) =>
    props.$activeSlide ? "var(--color-pink)" : "#949BA4"};
  z-index: 0;

  @media (${device.tablet}) {
    margin-top: -1rem;
    height: 8px;
    width: ${(props) => (props.$activeSlide ? "32px" : "8px")};
  }

  @media (${device.desktop}) {
    margin-top: -1rem;
    height: 8px;
    width: ${(props) => (props.$activeSlide ? "32px" : "8px")};
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
`;

const Promo = styled.img`
  width: 100%;
  height: auto;
  display: inline-block;
  object-fit: cover;
  object-position: center;
  cursor: pointer;

  @media (${device.tablet}) {
    height: 330px;
    border-radius: 16px;
  }

  @media (${device.desktop}) {
    height: 380px;
    margin: auto;
    border-radius: 16px;
  }
`;

const SpinnerContainer = styled.div`
  margin: auto;
  height: 212px;
  align-items: center;
  display: flex;
  width: 100%;
  background: var(--color-gray-900);

  @media (${device.tablet}) {
    background: transparent;
  }

  @media (${device.desktop}) {
    background: transparent;
  }
`;

const StyledCarousel = styled(Slider)`
  height: fit-content;
  width: 100%;
  background: transparent;

  @media (${device.tablet}) {
    height: 354px;
    margin: auto;
  }

  @media (${device.desktop}) {
    width: 688px;
    height: 412px;
    margin: auto;
  }
`;

const StyledSpinner = styled(Spinner)`
  width: 50px;
  height: auto;
  margin: auto;
`;

export default PromoCarousel;
