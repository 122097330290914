import React from "react";
import styled from "styled-components";
import pluralize from "pluralize";
import { Spacer } from "../../styles/CommonElements";
import { useQuery } from "@tanstack/react-query";
import creatorPollVoteModel from "../../lib/firebase/creatorPollVoteModel";
import CreatorPollPreviewIndividualResponse from "./CreatorPollPreviewIndividualResponse";

export default function CreatorPollPreviewResponses({
  creatorPoll,
  responseOptions,
}) {
  const { data: totalResponseCount = 0 } = useQuery({
    queryKey: ["creator-polls", "vote-counts", creatorPoll.id],
    queryFn: async () => {
      return await creatorPollVoteModel.count([
        "creatorPollId",
        "==",
        creatorPoll.id,
      ]);
    },
  });

  return (
    responseOptions.length > 0 && (
      <ResponseSection>
        <CoheaderSection>
          <FieldHeader>Responses</FieldHeader>
          <TotalResponses>
            {pluralize("Total Response", totalResponseCount, true)}
          </TotalResponses>
        </CoheaderSection>
        <Spacer $marginTop="20px" />
        <PaddedLeftContainer>
          {responseOptions.map((response, index) => (
            <div key={index}>
              <CreatorPollPreviewIndividualResponse
                creatorPoll={creatorPoll}
                response={response}
              />
              <Spacer $marginTop="8px" />
            </div>
          ))}
        </PaddedLeftContainer>
      </ResponseSection>
    )
  );
}

const CoheaderSection = styled.div`
  display: inline-flex;
  width: calc(100% - 100px);
`;

const FieldHeader = styled.div`
  color: var(--darkTextColor);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  text-align: ${(props) => props.position === "right" && "right"};
  width: auto;
`;

const TotalResponses = styled.div`
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 32px; /* 213.333% */
  margin: auto 0px auto auto;
`;

const PaddedLeftContainer = styled.div`
  width: 100%;
  padding-left: 30px;
`;

const ResponseSection = styled.div`
  margin: 24px 0px;
  margin-top: 50px;
  width: 100%;
  max-width: 500px;
`;
