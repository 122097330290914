import { Download as DownloadIcon } from "lucide-react";
import { Link } from "react-router-dom";

export default function Download(props) {
  if (!props.predictions.length) return null;

  const lastPrediction = props.predictions[props.predictions.length - 1];
  if (!lastPrediction.output) return null;

  const lastImage = lastPrediction.output;

  return (
    <Link
      to={lastImage}
      className="lil-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <DownloadIcon className="icon" />
      Download
    </Link>
  );
}
