import React from "react";
import { Button, Col, Divider, Row } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { AdminCreatorPollDisplay, CreateCreatorPoll } from "../views";
import styled from "styled-components";
import creatorPollModel from "../lib/firebase/creatorPollModel";
import { Spacer } from "../styles/CommonElements";
import { useQuery } from "@tanstack/react-query";
import { useOutletContext, useParams } from "react-router-dom";

export default function CreatorPollAdmin() {
  const [isNewCreatorPoll, setIsNewCreatorPoll] = useState(false);
  const [startArrowDirection, setStartArrowDirection] = useState("ascending");
  const [endArrowDirection, setEndArrowDirection] = useState("ascending");
  const [creatorPolls, setCreatorPolls] = useState([]);

  const { selectedCreatorPoll, setSelectedCreatorPoll } = useOutletContext();
  const { storyId: storyworldId } = useParams();

  const { data: creatorPollData, isSuccess } = useQuery({
    queryKey: ["creator-polls", storyworldId],
    queryFn: () => {
      return creatorPollModel.getManyByStoryworldId(storyworldId);
    },
  });

  useEffect(() => {
    if (isSuccess && creatorPollData) setCreatorPolls(creatorPollData);
  }, [isSuccess, creatorPollData]);

  function switchDirection(val, setVal) {
    if (val === "descending") {
      val = "ascending";
    } else if (val === "ascending") {
      val = "descending";
    }
    setVal(val);
  }

  const handleStartSort = () => {
    setEndArrowDirection("ascending");
    switchDirection(startArrowDirection, setStartArrowDirection);
    const direction = startArrowDirection;
    let arr;
    if (direction === "ascending") {
      arr = [...creatorPolls].sort(function compare(a, b) {
        if (a.startTimestamp < b.startTimestamp) return 1;
        if (a.startTimestamp > b.startTimestamp) return -1;
        return 0;
      });
    } else if (direction === "descending") {
      arr = [...creatorPolls].sort(function compare(a, b) {
        if (a.startTimestamp > b.startTimestamp) return 1;
        if (a.startTimestamp < b.startTimestamp) return -1;
        return 0;
      });
    }
    setCreatorPolls(arr);
  };

  const handleEndSort = () => {
    setStartArrowDirection("ascending");
    switchDirection(endArrowDirection, setEndArrowDirection);
    const direction = endArrowDirection;
    let arr;
    if (direction === "ascending") {
      arr = [...creatorPolls].sort(function compare(a, b) {
        if (a.endTimestamp < b.endTimestamp) return 1;
        if (a.endTimestamp > b.endTimestamp) return -1;
        return 0;
      });
    } else if (direction === "descending") {
      arr = [...creatorPolls].sort(function compare(a, b) {
        if (a.endTimestamp > b.endTimestamp) return 1;
        if (a.endTimestamp < b.endTimestamp) return -1;
        return 0;
      });
    }
    setCreatorPolls(arr);
  };

  return (
    <>
      {selectedCreatorPoll === null && (
        <>
          <div>
            <Row>
              <Col span={12}>
                <h2>Polls</h2>
              </Col>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    className="new-quest-button"
                    onClick={async () => {
                      const cp = { title: "" };
                      setIsNewCreatorPoll(true);
                      setSelectedCreatorPoll(cp);
                    }}
                    size="medium"
                  >
                    + New Poll
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Spacer $marginTop="40px" />
          <Row gutter={48}>
            <MarginCol span={11}>Poll Title</MarginCol>
            <Col span={3}>
              <Row>
                <MarginCol span={18}>Start</MarginCol>
                <Col span={6}>
                  <Button
                    className="chevron-button"
                    onClick={() => {
                      handleStartSort();
                    }}
                  >
                    {startArrowDirection === "ascending" ? (
                      <DownOutlined />
                    ) : (
                      <UpOutlined />
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              <Row>
                <MarginCol span={18}>End</MarginCol>
                <Col span={6}>
                  <Button
                    className="chevron-button"
                    onClick={() => {
                      handleEndSort();
                    }}
                  >
                    {endArrowDirection === "ascending" ? (
                      <DownOutlined />
                    ) : (
                      <UpOutlined />
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
            <MarginCol span={7}>
              <Row>
                <Col span={18}>Poll Status</Col>
                <Col span={6}></Col>
              </Row>
            </MarginCol>
          </Row>
          <Divider />
        </>
      )}
      {selectedCreatorPoll !== null && (
        <CreateCreatorPoll
          selectedCreatorPoll={selectedCreatorPoll}
          setSelectedCreatorPoll={setSelectedCreatorPoll}
          creatorPolls={creatorPolls}
          isNewCreatorPoll={isNewCreatorPoll}
          setCreatorPolls={setCreatorPolls}
        />
      )}
      {selectedCreatorPoll === null && (
        <div>
          {creatorPolls.map((cp, index) => (
            <InnerContainer key={index}>
              <AdminCreatorPollDisplay
                creatorPoll={cp}
                setSelectedCreatorPoll={setSelectedCreatorPoll}
                setIsNewCreatorPoll={setIsNewCreatorPoll}
              />
            </InnerContainer>
          ))}
        </div>
      )}
    </>
  );
}

const InnerContainer = styled.div`
  margin: 14px 0px;
`;

const MarginCol = styled(Col)`
  margin: auto !important;
`;
