import { useState } from "react";
import styled from "styled-components";
import IdeaBlock from "../IdeaBlock";
import useSingleAndDoubleClick from "../../helpers/useSingleAndDoubleClick";
import IdeaBlockEnlargedSheet from "../IdeaBlockEnlargedSheet";

const VoteIdeaSubmission = ({ submission, handleVote, isSelected = false }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const triggerSheet = () => {
    setDrawerOpen(true);
  };

  const toggleVote = () => {
    handleVote(submission);
  };

  const onClick = useSingleAndDoubleClick(triggerSheet, toggleVote);

  return (
    <>
      <Container>
        <VoteIdeaBlockWrapper $isSelected={isSelected} onClick={onClick}>
          <IdeaBlock idea={submission} hideTitle />
        </VoteIdeaBlockWrapper>
        {/* The design highlights the idea block images only, so we need to hide them there and reproduce the title here */}
        <Title>{submission.title}</Title>
        <ArtCheckbox $isSelected={isSelected} onClick={toggleVote}>
          {isSelected && <Checkmark src="/icons/checkmark-white.svg" alt="" />}
        </ArtCheckbox>
      </Container>
      <IdeaBlockEnlargedSheet
        isOpen={drawerOpen}
        isSelected={isSelected}
        handleVote={toggleVote}
        handleClose={() => setDrawerOpen(false)}
        submission={submission}
      />
    </>
  );
};

const Container = styled.div`
  grid-column: auto;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
  position: relative;
`;

const ArtCheckbox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
  width: 38px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 12px;
  border: 0;
  background: ${(props) =>
    props.$isSelected ? `var(--color-blurple)` : `transparent`};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const VoteIdeaBlockWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  background: ${(props) =>
    props.$isSelected ? `var(--color-blurple)` : `transparent`};
  border: 3px solid transparent;
`;

const Checkmark = styled.img`
  height: 20px;
  width: 20px;
`;

const Title = styled.div`
  color: var(--color-cream-base);
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 12px;
`;

export default VoteIdeaSubmission;
