import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import { ActiveStoryworldMark, Spacer } from "../styles/CommonElements";
import { storyworldTypes } from "../helpers/constants";
import { device } from "../lib/breakpoints";

export default function StoryworldCollection({
  stories,
  title,
  subtitle = "",
  showActiveMark = true,
  showBigIcon = false,
}) {
  const navigate = useNavigate();
  const { addBackLocation } = useContext(BackLocationsContext);

  return (
    <Container>
      <Header>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Header>
      <Spacer $marginTop="16px" />
      <Slider>
        {stories.map((storyworld) => (
          <Story $bigIcon={showBigIcon} key={storyworld.id}>
            <ImageWrapper $bigIcon={showBigIcon}>
              <StoryIcon
                src={storyworld.imageUrl}
                alt={`Link to ${storyworld.title}`}
                onClick={() => {
                  addBackLocation();
                  navigate(`/stories/${storyworld.id}`);
                }}
              />
            </ImageWrapper>
            <Label>{storyworld.genre}</Label>
            <Link to={`/stories/${storyworld.id}`}>
              {showActiveMark &&
                storyworld.type === storyworldTypes.STORYWORLD && (
                  <ActiveStoryworldMark $marginRight="4px" />
                )}
              {storyworld.title}
            </Link>
          </Story>
        ))}
      </Slider>
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
`;

const Header = styled.div`
  width: 100%;
  margin-left: 18px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.$bigIcon ? "240" : "208")}px;
  margin-bottom: 14px;
`;

const Label = styled.div`
  color: #949ba4;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: capitalize;
`;

const Slider = styled.div`
  width: 100%;
  margin: auto;
  height: fit-content;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Story = styled.div`
  flex: none;
  width: ${(props) => (props.$bigIcon ? "135" : "117")}px;
  cursor: pointer;

  a {
    display: inline-block;
    color: var(--color-cream);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: normal;
  }
`;

const StoryIcon = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Subtitle = styled.div`
  color: #949ba4;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;

  @media ${device.mobile} {
    font-size: 12px;
  }

  @media ${device.tablet}, ${device.desktop} {
    font-size: 14px;
  }
`;

const Title = styled.div`
  color: var(--color-cream);
  text-align: left;
  text-transform: capitalize;
  font-family: var(--font-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;
