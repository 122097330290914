import styled from "styled-components";

const RevealPassword = ({ isRevealed, toggleRevealed, passwordRef }) => {
  return (
    <span
      className="flex justify-around items-center"
      onClick={() => {
        toggleRevealed();
        passwordRef.current.focus();
      }}
    >
      {isRevealed ? (
        <HidePasswordIcon src="/images/general/password-hide.svg" />
      ) : (
        <RevealPasswordIcon src="/images/general/password-reveal.svg" />
      )}
    </span>
  );
};

const HidePasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

const RevealPasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
`;

export default RevealPassword;
