import React from "react";
import { useEffect } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import {
  vhPanelLeftPanelWidth,
  vhPanelrightPanelLeftMargin,
} from "../helpers/constants";
import { InputSection, Section, Spacer } from "../styles/CommonElements";
import { Col, Row } from "antd";
import { CreatorPollPreviewResponses } from "../components/vh-panel";

const dayOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const timeOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

export default function CreatorPollPreview({
  startTimestamp,
  endTimestamp,
  question,
  responseOptions,
  creatorPoll,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <LeftPanel>
        <Section>
          <InnerSection>
            <FieldHeader>Duration</FieldHeader>
            <Spacer $marginTop="34px" />
            <Row>
              <Col span={8}>
                <InputHeader>Open</InputHeader>
              </Col>
              <Col span={8}>
                <DateTimeText>
                  {startTimestamp.toLocaleDateString("en-US", dayOptions)}
                </DateTimeText>
              </Col>
              <Col span={8}>
                <DateTimeText>
                  {startTimestamp.toLocaleTimeString("en-US", timeOptions)}
                </DateTimeText>
              </Col>
            </Row>
            <Spacer $marginTop="46px" />
            <Row>
              <Col span={8}>
                <InputHeader>Close</InputHeader>
              </Col>
              <Col span={8}>
                <DateTimeText>
                  {endTimestamp.toLocaleDateString("en-US", dayOptions)}
                </DateTimeText>
              </Col>
              <Col span={8}>
                <DateTimeText>
                  {endTimestamp.toLocaleTimeString("en-US", timeOptions)}
                </DateTimeText>
              </Col>
            </Row>
            <Spacer $marginTop="48px" />
          </InnerSection>
        </Section>
      </LeftPanel>

      <RightPanel>
        <div className="create-quest-content-section">
          <InnerSection>
            <FieldHeader>Poll information</FieldHeader>
            <InputSection>
              <div className="poll-form-element">
                <Question>{parse(question)}</Question>
              </div>
            </InputSection>
            <CreatorPollPreviewResponses
              creatorPoll={creatorPoll}
              responseOptions={responseOptions}
            />
          </InnerSection>
        </div>
      </RightPanel>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: inline-flex;

  img {
    max-width: 100%;
  }
`;

const LeftPanel = styled.div`
  width: ${vhPanelLeftPanelWidth}px;
`;

const RightPanel = styled.div`
  width: calc(
    100% - ${vhPanelLeftPanelWidth}px - ${vhPanelrightPanelLeftMargin}px
  );
  margin-left: ${vhPanelrightPanelLeftMargin}px;
`;

const FieldHeader = styled.div`
  color: var(--darkTextColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.32px;
  text-align: ${(props) => props.position === "right" && "right"};
  width: auto;
`;

const InnerSection = styled.div`
  padding: 12px 16px 20px 16px;
  text-align: left;
`;

const InputHeader = styled.div`
  color: var(--darkTextColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
`;

const DateTimeText = styled.div`
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.15px;
`;

const Question = styled.div`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
`;
