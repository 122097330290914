import React from "react";
import { styled } from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { getAuth } from "firebase/auth";
import { getStoryworldById } from "../helpers/firebase";
import { storyworldKeys } from "../lib/queryKeys";
import { trackEvent } from "../helpers/mixpanel";
import { APP_NAME } from "../helpers/constants";

export default function ShareButton({
  title = `${APP_NAME} Share`,
  message = "Check this out!",
  imageUrl = "",
  inCircle = false,
  location,
  storyworldId,
  episode,
}) {
  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(storyworldId),
    queryFn: () => {
      const currentUser = getAuth().currentUser;
      if (!currentUser) return null;
      return getStoryworldById(storyworldId);
    },
  });

  const shareMessage = async () => {
    trackEvent("Clicked Share Button", {
      location,
      storyworld_id: storyworldId,
      storyworld_name: storyworldQuery.data.title,
      episode_id: episode ? episode.id : null,
      episode_name: episode ? episode.title : null,
      episode_number: episode ? episode.number : null,
      title: `${storyworldQuery.data.title}${
        episode ? ` #${episode.number}:  ${episode.title}` : ""
      }`,
    });

    const image = await fetch(imageUrl);
    const blob = await image.blob();
    const file = new File([blob], "image.jpg", { type: "image/jpeg" });
    const filesArray = [file];
    const shareData = {
      title,
      text: message,
      url: window.location,
      files: filesArray,
    };
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => {
          trackEvent("Completed Share", {
            location,
            storyworld_id: storyworldId,
            storyworld_name: storyworldQuery.data.title,
            episode_id: episode ? episode.id : null,
            episode_name: episode ? episode.title : null,
            episode_number: episode ? episode.number : null,
            title: `${storyworldQuery.data.title}${
              episode ? ` #${episode.number}:  ${episode.title}` : ""
            }`,
          });
        })
        .catch((error) => console.log("Error sharing", error));
    }
  };

  return (
    <Container>
      {!inCircle ? (
        <Button onClick={shareMessage}>
          <PinkShare src={"/icons/share.svg"} alt="" />
        </Button>
      ) : (
        <Button onClick={shareMessage} className="share-button-full">
          <Icon src={"/icons/share-white.svg"} alt="" />
        </Button>
      )}
    </Container>
  );
}

const Container = styled.div`
  .share-button-full {
    background: none;
    margin: auto;
    padding: 0px 10px !important;
  }
`;

const Icon = styled.img`
  height: 20px;
  width: 100%;
`;

const PinkShare = styled.img`
  height: 12px;
  width: auto;
  margin-top: 5px;
`;

const Button = styled.div`
  width: auto;
  height: 26px;
  border-radius: 65px;
  font-size: 8pt;
  padding: 0px 12px;
  border: 0;
  font-weight: bold;
  color: var(--hoverColor);
  background: var(--color-gray-400);

  &:disabled {
    color: var(--hoverColor) !important;
    background: var(--color-gray-400) !important;
  }

  &:active {
    color: var(--hoverColor) !important;
    background: var(--color-gray-400) !important;
  }

  &:hover {
    color: var(--hoverColor) !important;
    background: var(--color-gray-400) !important;
  }

  &:focus {
    color: var(--hoverColor) !important;
    background: var(--color-gray-400) !important;
  }
`;
