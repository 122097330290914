import React, { useRef, useState } from "react";
import { XCircle as StartOverIcon } from "lucide-react";
import styled from "styled-components";
import Canvas from "../components/Canvas";
import Dropzone from "../components/Dropzone";
import Download from "../components/Download";
import Button from "../components/Button";
import { HeaderContainer, MainHeader, Spacer } from "../styles/CommonElements";
import predictionModel from "../lib/firebase/predictionModel";
import { APP_NAME } from "../helpers/constants";
import { Col, message, Row } from "antd";
import Arrow from "../components/Arrow";
import { uploadImage } from "../helpers/firebase";
import { addBackgroundToPNG } from "../lib/add-background-to-png";
import { retrieveGraydientImagesFromFirebase } from "../helpers/genAI";
import { readAsDataURL, sleep } from "../helpers/general";
import Spinner from "../components/Spinner";
import Hidden from "./../components/Hidden";
import RecentPredictions from "./RecentPredictions";
import { useQueryClient } from "@tanstack/react-query";
import { predictionKeys } from "../lib/queryKeys";

const messageDuration = 5;

export default function Paint() {
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const [predictions, setPredictions] = useState([]);
  const [error, setError] = useState(null);
  const [maskImage, setMaskImage] = useState(null);
  const [userUploadedImage, setUserUploadedImage] = useState(null);
  const [predicting, setPredicting] = useState(false);

  const canvas = useRef();

  const startOver = async (e) => {
    if (e) e.preventDefault();
    setPredictions([]);
    setError(null);
    setMaskImage(null);
    setUserUploadedImage(null);
    setPredicting(false);
  };

  /*
  const removeMask = async (e) => {
    if (e) e.preventDefault();
    setPredictions([]);
    setError(null);
    setMaskImage(null);
    setPredicting(false);
  };
  */

  // Handle Submit -- Replicate
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPredicting(true);
    setPredictions([]);

    messageApi.open({
      type: "success",
      content: `Getting ${APP_NAME} Prediction...`,
      duration: messageDuration * 3,
    });

    const prevPrediction = predictions[predictions.length - 1];
    const prevPredictionOutput = prevPrediction?.output
      ? Array.isArray(prevPrediction.output)
        ? prevPrediction.output[prevPrediction.output.length - 1]
        : prevPrediction.output
      : null;

    const prediction = await predictionModel.createReplicate({
      image: userUploadedImage
        ? await readAsDataURL(userUploadedImage)
        : // only use previous prediction as init image if there's a mask
        maskImage
        ? prevPredictionOutput
        : null,
      maskImage,
    });

    if (prediction.status === "succeeded") {
      setPredictions([prediction]);
      messageApi.open({
        type: "success",
        content: `${APP_NAME} prediction successful, take a look!`,
        duration: messageDuration,
      });
      queryClient.invalidateQueries({
        queryKey: predictionKeys.all,
      });
    }

    setPredicting(false);
  };

  /*
  // Handle Submit -- Graydient
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("-----------");
    console.log("Started Generation");

    // Upload user supplied image
    const image = await uploadImage("before", userUploadedImage);

    // Upload mask image
    const maskPng = addBackgroundToPNG(maskImage, true);
    const mask = await uploadImage("masks", maskPng);

    const predictionId = await predictionModel.createGraydient({
      image,
      maskImage: mask,
    });

    console.log("Returned a prediction ID");
    console.log(predictionId);

    /*
    const prediction = await retrieveGraydientImagesFromFirebase(predictionId);
    console.log(prediction);
    setPredictions(prediction);
    */
  /*
  };
  */

  return (
    <>
      {contextHolder}

      <Container>
        <HeaderContainer>
          <MainHeader>Get {APP_NAME} Prediction</MainHeader>
        </HeaderContainer>

        <Spacer $marginTop="2rem" />
        <Hidden desktop tablet>
          <Subheader>
            To get a {APP_NAME} prediction, please access this page on desktop
            or tablet devices
          </Subheader>
        </Hidden>
        <Hidden mobile>
          <Subheader>
            1) <b>Upload image</b> of patient
          </Subheader>
          <Subheader>
            2) Use your mouse to <b>fill the area</b>
          </Subheader>
          <Subheader>
            3) <b>Click {APP_NAME}</b> to get a prediction
          </Subheader>
          <Spacer $marginTop="1rem" />
          <Subheader>Normal wait time: ⏲️ 20-30 seconds</Subheader>
          <Spacer $marginTop="1rem" />
          <main className="container mx-auto p-5">
            <Row>
              <Col span={11}>
                <ImageContainer className="mx-auto relative">
                  <Dropzone
                    onImageDropped={setUserUploadedImage}
                    predictions={predictions}
                    userUploadedImage={userUploadedImage}
                    className="border-hairline max-w-[min(1024px,100vw-40px)]"
                  />
                  <ImageHolder
                    className="bg-gray-50 relative w-full flex items-stretch"
                    style={{
                      maxHeight: "min(768px, 100vw - 40px)",
                      aspectRatio: "4 / 3",
                    }}
                  >
                    <Canvas
                      predictions={predictions}
                      userUploadedImage={userUploadedImage}
                      onDraw={setMaskImage}
                      canvas={canvas}
                    />
                  </ImageHolder>

                  <Spacer $marginTop="1rem" />

                  <StyledButton
                    variant="primary"
                    rounded="slight"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    disabled={predicting || !maskImage}
                  >
                    {predicting ? <Spinner /> : APP_NAME}
                  </StyledButton>
                </ImageContainer>

                <div className="max-w-[min(1024px,100vw-40px)] mx-auto">
                  {error && (
                    <div className="text-red-700 bg-red-50 p-3 rounded-md mb-5">
                      {error}
                    </div>
                  )}

                  <StartOverContainer>
                    {((predictions.length > 0 &&
                      predictions[predictions.length - 1].output) ||
                      maskImage ||
                      userUploadedImage) &&
                      !predicting && (
                        <>
                          <button className="lil-button" onClick={startOver}>
                            <StartOverIcon className="icon" />
                            Start over
                          </button>
                          {/*
                          <button className="lil-button" onClick={removeMask}>
                            <StartOverIcon className="icon" />
                            Remove mask
                          </button>
                          */}
                        </>
                      )}
                  </StartOverContainer>
                </div>
              </Col>

              <StyledCol span={2}>
                {userUploadedImage && !predicting && (
                  <Spacer $marginTop="-1.95rem" />
                )}
                <Arrow direction="right" width="4" />
              </StyledCol>

              <Col span={11}>
                <ImageContainer className="mx-auto relative">
                  <ImageHolder
                    className="bg-gray-50 relative w-full flex items-stretch"
                    style={{
                      maxHeight: "min(768px, 100vw - 40px)",
                      aspectRatio: "4 / 3",
                    }}
                  >
                    {predictions.length === 0 ? (
                      <div className="m-auto">
                        <Spacer $marginTop="3rem" />
                        <ImageSubtitle>
                          {APP_NAME} prediction will appear here{" "}
                        </ImageSubtitle>
                      </div>
                    ) : (
                      <img src={predictions[0]?.output ?? null} alt="" />
                    )}
                  </ImageHolder>
                </ImageContainer>
                <StartOverContainer>
                  <Download predictions={predictions} />
                </StartOverContainer>
              </Col>
            </Row>
          </main>
          <Spacer $marginTop="5rem" />
          <RecentPredictions />
          <Spacer $marginTop="5rem" />
        </Hidden>
      </Container>
    </>
  );
}

const Container = styled.div``;

/*
const ErrorMessage = styled.div`
  color: #b91c1c;
  background-color: #fef2f2;
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
`;
*/

const ImageContainer = styled.div`
  max-width: 500px;
  margin: auto;
`;

const ImageHolder = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.25);
  justify-content: center;
  object-fit: contain;
`;

const ImageSubtitle = styled.p`
  color: rgba(0, 0, 0, 0.5);
`;

const StartOverContainer = styled.div`
  text-align: center;
`;

const StyledCol = styled(Col)`
  align-content: center;
  justify-items: center;
`;

const StyledButton = styled(Button)`
  svg {
    width: 24px !important;
    height: auto !important;
  }
`;

const Subheader = styled.div`
  width: 70%;
  max-width: 900px;
  font-size: 16px;
  margin: auto;
`;
