import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { createQuest, updateQuest, deleteUserQuest } from "../helpers/firebase";
import { getQuestsWithPolls } from "../helpers/general";
import styled from "styled-components";
import { getQuestType } from "../components/quests/QuestFilter";
import { useParams } from "react-router-dom";

export default function QuestButtons({
  setUserQuests,
  selectedQuest,
  userQuests,
  isNewQuest,
  setSelectedQuest,
  title,
  description,
  objectiveDescription,
  startTimestamp,
  endTimestamp,
  textSubmissionLimit,
  showDelete,
  submissionRequirements,
  suggestedSearchTerms,
  isPreviewing,
  setIsPreviewing,
  videoUrl,
  thumbnailUrl,
  isCompleted,
  moreInfo,
  ongoing,
}) {
  const { storyId: selectedStoryworld } = useParams();
  const [draftEditButton, setDraftEditButton] = useState(null);
  const [nextPublishButton, setNextPublishButton] = useState(null);

  // Definitely didn't want to introduce another piece of state, so for now we set this based on the submission requirements
  const questType = getQuestType({
    submissionRequirements,
  });

  useEffect(() => {
    if (isCompleted === true) {
      setDraftEditButton(null);
    } else if (isPreviewing && isNewQuest) {
      setDraftEditButton(
        <Button
          style={{ marginLeft: 12 }}
          className="close-button"
          type="default"
          onClick={async () => {
            setIsPreviewing(false);
          }}
        >
          Edit Quest
        </Button>
      );
    } else if (!isPreviewing && isNewQuest) {
      setDraftEditButton(
        <Button
          style={{ marginLeft: 12 }}
          className="close-button"
          type="default"
          onClick={async () => {
            const promise = await createQuest({
              type: questType,
              title,
              startTimestamp,
              endTimestamp,
              textSubmissionLimit,
              description,
              objectiveDescription,
              isDraft: true,
              submissionRequirements,
              suggestedSearchTerms,
              videoUrl,
              thumbnailUrl,
              storyworld: selectedStoryworld,
              moreInfo,
              ongoing,
            });
            const questsWithPollsPromise =
              await getQuestsWithPolls(selectedStoryworld);
            setUserQuests(questsWithPollsPromise);
            if (promise === true) {
              setSelectedQuest(null);
            }
          }}
        >
          Save draft
        </Button>
      );
    } else if (isPreviewing && !isNewQuest) {
      setDraftEditButton(
        <Button
          style={{ marginLeft: 12 }}
          className="close-button"
          type="default"
          onClick={async () => {
            setIsPreviewing(false);
          }}
        >
          Edit Quest
        </Button>
      );
    } else if (!isPreviewing && !isNewQuest) {
      setDraftEditButton(
        <Button
          style={{ marginLeft: 12 }}
          className="close-button"
          type="default"
          onClick={async () => {
            const promise = await updateQuest({
              type: questType,
              questId: selectedQuest.id,
              title,
              startTimestamp,
              endTimestamp,
              description,
              objectiveDescription,
              isDraft: true,
              createdAt: selectedQuest.createdAt
                ? selectedQuest.createdAt
                : new Date(),
              submissionRequirements,
              suggestedSearchTerms,
              videoUrl,
              thumbnailUrl,
              textSubmissionLimit,
              storyworld: selectedStoryworld,
              moreInfo,
              ongoing,
            });
            const questsWithPollsPromise =
              await getQuestsWithPolls(selectedStoryworld);
            setUserQuests(questsWithPollsPromise);
            if (promise === true) {
              setSelectedQuest(null);
            }
          }}
        >
          Save draft
        </Button>
      );
    }
  }, [
    isPreviewing,
    isNewQuest,
    endTimestamp,
    setIsPreviewing,
    objectiveDescription,
    description,
    selectedQuest.createdAt,
    selectedQuest.id,
    setSelectedQuest,
    setUserQuests,
    startTimestamp,
    submissionRequirements,
    textSubmissionLimit,
    title,
    videoUrl,
    thumbnailUrl,
    moreInfo,
    selectedStoryworld,
    isCompleted,
    questType,
    suggestedSearchTerms,
    ongoing,
  ]);

  useEffect(() => {
    if (isCompleted === true) {
      setNextPublishButton(null);
    } else if (isPreviewing && isNewQuest) {
      setNextPublishButton(
        <Button
          style={{ marginLeft: 12 }}
          className="next-preview-button publish-quest-button"
          type="primary"
          onClick={async () => {
            const promise = await createQuest({
              type: questType,
              title,
              startTimestamp,
              endTimestamp,
              textSubmissionLimit,
              description,
              objectiveDescription,
              isDraft: false,
              submissionRequirements,
              suggestedSearchTerms,
              videoUrl,
              thumbnailUrl,
              storyworld: selectedStoryworld,
              moreInfo,
              ongoing,
            });
            const questsWithPollsPromise =
              await getQuestsWithPolls(selectedStoryworld);
            setUserQuests(questsWithPollsPromise);
            if (promise === true) {
              setSelectedQuest(null);
            }
          }}
        >
          Publish Quest
        </Button>
      );
    } else if (isPreviewing && !isNewQuest) {
      setNextPublishButton(
        <Button
          style={{ marginLeft: 12 }}
          className="next-preview-button publish-quest-button"
          type="primary"
          onClick={async () => {
            const promise = await updateQuest({
              type: questType,
              questId: selectedQuest.id,
              title,
              startTimestamp,
              endTimestamp,
              description,
              objectiveDescription,
              isDraft: false,
              createdAt: selectedQuest.createdAt
                ? selectedQuest.createdAt
                : new Date(),
              submissionRequirements,
              suggestedSearchTerms,
              videoUrl,
              thumbnailUrl,
              textSubmissionLimit,
              storyworld: selectedStoryworld,
              moreInfo,
              ongoing,
            });
            const questsWithPollsPromise =
              await getQuestsWithPolls(selectedStoryworld);
            setUserQuests(questsWithPollsPromise);
            if (promise === true) {
              setSelectedQuest(null);
            }
          }}
        >
          Publish Quest
        </Button>
      );
    } else if (!isPreviewing) {
      setNextPublishButton(
        <Button
          style={{ marginLeft: 12 }}
          className="next-preview-button"
          type="primary"
          onClick={async () => {
            setIsPreviewing(true);
          }}
        >
          Next: Preview
        </Button>
      );
    }
  }, [
    isPreviewing,
    description,
    endTimestamp,
    startTimestamp,
    isNewQuest,
    objectiveDescription,
    selectedQuest.createdAt,
    setIsPreviewing,
    selectedQuest.id,
    setSelectedQuest,
    setUserQuests,
    submissionRequirements,
    textSubmissionLimit,
    title,
    videoUrl,
    thumbnailUrl,
    selectedStoryworld,
    isCompleted,
    moreInfo,
    questType,
    suggestedSearchTerms,
    ongoing,
  ]);

  return (
    <Container>
      {!isPreviewing && (
        <Button
          className="close-button"
          onClick={() => {
            setSelectedQuest(null);
          }}
        >
          Back
        </Button>
      )}

      {draftEditButton}

      {nextPublishButton}

      {!isNewQuest && showDelete && !isCompleted && (
        <DeleteButton
          onClick={async () => {
            await deleteUserQuest(selectedQuest.id);
            const filteredArray = userQuests.filter(function (e) {
              return e.id !== selectedQuest.id;
            });
            setUserQuests(filteredArray);
            setSelectedQuest(null);
          }}
        >
          Delete Quest
        </DeleteButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: inline-flex;
`;

const DeleteButton = styled.button`
  align-items: center;
  padding: 8px 16px;
  width: fit-content;
  height: 40px;
  background: red;
  border-radius: 8px;
  border: 0;
  margin-left: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
`;
