import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { FaqSection } from "../components/general";
import { useEffect } from "react";
import { device } from "../lib/breakpoints";
import { HeaderContainer, MainHeader, Spacer } from "../styles/CommonElements";

const bannerImage = "/images/faq/faq-banner-000.svg";

export default function FAQ() {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return (
    <Container>
      <HeaderContainer>
        <MainHeader>Frequently Asked Questions</MainHeader>
      </HeaderContainer>
      <Image src={bannerImage} alt="" />
      <FaqContainer>
        <Spacer $marginTop="2.5rem" />
        <FaqSection />
      </FaqContainer>
      <Spacer $marginTop="2rem" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const FaqContainer = styled.div`
  @media ${device.tablet} {
    width: 600px;
    margin: auto;
  }

  @media ${device.desktop} {
    width: 800px;
    margin: auto;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;

  @media ${device.mobile} {
    height: 100px;
  }
`;
