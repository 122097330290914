import React from "react";
import { Col, Row } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { updateQuest } from "../helpers/firebase";
import { isQuestCompleted, isTimePassed } from "../helpers/general";
import CreateCreatorPollButtons from "./CreateCreatorPollButtons";
import styled from "styled-components";
import CreatorPollEditor from "./CreatorPollEditor";
import CreatorPollPreview from "./CreatorPollPreview";
import { useParams } from "react-router-dom";

export default function CreateCreatorPoll({
  userQuests,
  isNewCreatorPoll,
  selectedCreatorPoll,
  setSelectedCreatorPoll,
  setCreatorPolls,
}) {
  const { storyId } = useParams();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [startTimestamp, onStartTimestampChange] = useState(
    isNewCreatorPoll
      ? new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
      : selectedCreatorPoll.startTimestamp.toDate()
  );
  const [endTimestamp, onEndTimestampChange] = useState(
    isNewCreatorPoll
      ? new Date(new Date().getTime() + 60 * 60 * 48 * 1000)
      : selectedCreatorPoll.endTimestamp.toDate()
  );
  const [question, setQuestion] = useState(
    selectedCreatorPoll.question ? selectedCreatorPoll.question : ""
  );
  const [responseOptions, setResponseOptions] = useState(
    selectedCreatorPoll.responseOptions
      ? selectedCreatorPoll.responseOptions
      : ["", ""]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <Row>
          <Col span={12}>
            <Header>
              {isNewCreatorPoll ? "Create new poll" : "Update poll"}
            </Header>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <CreateCreatorPollButtons
              selectedCreatorPoll={selectedCreatorPoll}
              userQuests={userQuests}
              isNewCreatorPoll={isNewCreatorPoll}
              setSelectedCreatorPoll={setSelectedCreatorPoll}
              updateQuest={updateQuest}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              showDelete={false}
              isPreviewing={isPreviewing}
              setIsPreviewing={setIsPreviewing}
              isCompleted={isQuestCompleted(selectedCreatorPoll)}
              responseOptions={responseOptions}
              question={question}
              setCreatorPolls={setCreatorPolls}
              storyworldId={storyId}
            />
          </Col>
        </Row>
      </HeaderContainer>

      {!isPreviewing && !isQuestCompleted(selectedCreatorPoll) && (
        <CreatorPollEditor
          responseOptions={responseOptions}
          setResponseOptions={setResponseOptions}
          question={question}
          setQuestion={setQuestion}
          endTimestamp={endTimestamp}
          onEndTimestampChange={onEndTimestampChange}
          startTimestamp={startTimestamp}
          onStartTimestampChange={onStartTimestampChange}
          creatorPollLive={
            !isNewCreatorPoll
              ? isTimePassed(selectedCreatorPoll.startTimestamp.toDate()) &&
                selectedCreatorPoll.isDraft === false
              : false
          }
        />
      )}

      {(isPreviewing || isQuestCompleted(selectedCreatorPoll)) && (
        <CreatorPollPreview
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          question={question}
          responseOptions={responseOptions}
          creatorPoll={selectedCreatorPoll}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  margin: auto;
  margin-top: 0px;
  display: inline-block;
  width: 100%;
  min-width: 350px;
  margin-bottom: 80px;
`;

const Header = styled.div`
  color: var(--Neutral-01, #fcfcfc);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 106.667% */
  letter-spacing: -0.6px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 40px !important;
`;
