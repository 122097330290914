import React, { useEffect, useState, useMemo, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import SubmissionPreview from "./preview/SubmissionPreview";
import SubmissionCard from "./SubmissionCard";
import voteModel from "../../lib/firebase/voteModel";
import { APP_NAME, discordLink } from "../../helpers/constants";
import { voteKeys } from "../../lib/queryKeys";
import { AuthContext } from "../../context/AuthProvider";
import { valueBySubmissionType } from "./SubmissionFilter";

export default function SubmissionsDisplay({
  quest,
  state,
  hasVoted,
  confirmedSubmissions,
  currentUserSubmission,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [displayedSubmission, setDisplayedSubmission] = useState(null);
  const [votedSubmissions, setVotedSubmissions] = useState([]);

  const { user } = useContext(AuthContext);

  // Comes from getQuestAndPollStates
  const { quest: questState, poll: pollState } = state;

  const { data: votes } = useQuery({
    queryKey: voteKeys.quest_user_vote(quest.id, user?.uid),
    queryFn: () => {
      return voteModel.getMany(
        ["questId", "==", quest.id],
        ["creator", "==", user?.uid],
        ["limit", 1]
      );
    },
    enabled: !!hasVoted && !!user?.uid,
    placeholderData: [],
  });

  useEffect(() => {
    if (votes.length === 1) {
      setVotedSubmissions(votes[0].voteIds);
    }
  }, [votes]);

  const handleSubmissionClick = (submission) => {
    if (hasVoted) {
      setDrawerOpen(true);
      setDisplayedSubmission(submission);
    }
  };

  const submissionsSortedByUserVote = useMemo(() => {
    const userVotes = confirmedSubmissions.filter((subm) =>
      votedSubmissions.includes(subm.id)
    );
    const remaining = confirmedSubmissions.filter(
      (subm) => !votedSubmissions.includes(subm.id)
    );

    return [...userVotes, ...remaining];
  }, [confirmedSubmissions, votedSubmissions]);

  const isCardFullWidth = (submission) => {
    return valueBySubmissionType(
      submission,
      {
        text: true,
      },
      false
    );
  };

  // TODO: remove after ai is gone
  const isGenAi = (submission) => {
    return valueBySubmissionType(
      submission,
      {
        image: true,
      },
      false
    );
  };

  return (
    <>
      <Container>
        {currentUserSubmission && questState.isOpen && (
          <PreSubmissionsSection>
            <PreSubmissionsTitle>
              <Stars src="/images/quests/stars.svg" alt="" />
              Successfully submitted
            </PreSubmissionsTitle>
            <PreSubmissionsSubheader>
              In the meantime, come hang out with the quest community in the{" "}
              <Link href={discordLink} target="_blank">
                {APP_NAME} Discord
              </Link>
            </PreSubmissionsSubheader>
          </PreSubmissionsSection>
        )}

        {hasVoted && pollState?.isOpen && (
          <PreSubmissionsSection>
            <PreSubmissionsTitle>
              <Stars src="/images/quests/stars.svg" alt="" />
              Stay tuned for the results
            </PreSubmissionsTitle>
            <PreSubmissionsSubheader>
              In the meantime, come hang out with the quest community in the{" "}
              <Link href={discordLink} target="_blank">
                {APP_NAME} Discord
              </Link>
            </PreSubmissionsSubheader>
          </PreSubmissionsSection>
        )}

        <SubmissionGrid>
          {/* Highlight the user's submission at the top if they haven't voted yet */}
          {currentUserSubmission && !hasVoted && (
            <SubmissionCardWrapper
              $isFullWidth={isCardFullWidth(currentUserSubmission)}
              $isGenAi={isGenAi(currentUserSubmission)}
            >
              <CurrentUserSubmittedArtOverlay />
              <SubmissionCard
                submission={currentUserSubmission}
                hasVoted={hasVoted}
                onClick={() => handleSubmissionClick(currentUserSubmission)}
              />
            </SubmissionCardWrapper>
          )}

          {/* If the user voted, and that vote inclues their submission, show that at the top. 
          Otherwise it will be rendered at the end of the list */}
          {hasVoted &&
            currentUserSubmission &&
            votedSubmissions.includes(currentUserSubmission.id) && (
              <SubmissionCardWrapper
                $isFullWidth={isCardFullWidth(currentUserSubmission)}
                $isGenAi={isGenAi(currentUserSubmission)}
                key={0}
              >
                <SubmissionCard
                  submission={currentUserSubmission}
                  hasVoted={hasVoted}
                  isVotedByUser={true}
                  onClick={() => handleSubmissionClick(currentUserSubmission)}
                />
              </SubmissionCardWrapper>
            )}

          {/* Iterate over the submissions, with those from fellow users receiving votes at the top */}
          {submissionsSortedByUserVote.map((submission, index) => (
            <SubmissionCardWrapper
              $isFullWidth={isCardFullWidth(submission)}
              $isGenAi={isGenAi(submission)}
              key={index}
            >
              <SubmissionCard
                submission={submission}
                hasVoted={hasVoted}
                isVotedByUser={votedSubmissions.includes(submission.id)}
                onClick={() => handleSubmissionClick(submission)}
              />
            </SubmissionCardWrapper>
          ))}

          {/* Finally, if you voted but didn't vote for your own submission  */}
          {currentUserSubmission &&
            hasVoted &&
            !votedSubmissions.includes(currentUserSubmission.id) && (
              <SubmissionCardWrapper
                $isFullWidth={isCardFullWidth(currentUserSubmission)}
                $isGenAi={isGenAi(currentUserSubmission)}
                key={0}
              >
                <SubmissionCard
                  submission={currentUserSubmission}
                  hasVoted={hasVoted}
                  onClick={() => handleSubmissionClick(currentUserSubmission)}
                />
              </SubmissionCardWrapper>
            )}
        </SubmissionGrid>
      </Container>

      <SubmissionPreview
        submission={displayedSubmission}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
}

const SubmissionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
  row-gap: 32px;
`;

const SubmissionCardWrapper = styled.div`
  grid-column: ${(props) => (props.$isFullWidth ? "1 / -1" : "auto")};
  /* TODO: remove after ai is gone */
  aspect-ratio: ${(props) => (props.$isGenAi ? "1/1" : "unset")};

  height: 100%;
  display: inline-block;
  position: relative;
  mask-image: radial-gradient(white, black);
`;

const Container = styled.div`
  display: contents;
  margin: auto;
  margin-top: 20px;
  text-align: center;
`;

const CurrentUserSubmittedArtOverlay = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  z-index: 100 !important;
  position: absolute;

  &::before {
    z-index: 100 !important;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px !important;
    border: 3px solid transparent;
    background: linear-gradient(
        30deg,
        var(--color-pink),
        var(--nextPreviewButton)
      )
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }
`;

const PreSubmissionsSection = styled.div`
  width: 100%;
  margin-bottom: 1.4rem;
`;

const Link = styled.a`
  color: var(--color-pink);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
`;

const PreSubmissionsTitle = styled.div`
  display: inline-block;
  color: var(--cream-base, #fff6f2);
  text-align: center;
  font-family: "Hoss Sharp";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.5px;
  margin: auto;
  margin-bottom: 0.15rem;
  position: relative;
`;

const Stars = styled.img`
  position: absolute;
  height: 24px;
  width: 24px;
  left: -1.75rem;
  top: -0.5rem;
  width: auto;
  height: 26.316px;
  flex-shrink: 0;
`;

const PreSubmissionsSubheader = styled.div`
  color: #949ba4;
  text-align: center;
  /* Medium Body Text */
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;

  a {
    color: var(--color-pink);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
  }
`;
