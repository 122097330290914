import React from "react";
import styled from "styled-components";
import IdeaBlock from "../../IdeaBlock";

export const IdeaResult = ({ entry, isWinner = false, children }) => {
  const WinnerCheck = isWinner ? WinningIdea : React.Fragment;

  return (
    <Container $isWinner={isWinner}>
      <WinnerCheck>
        <div>
          <IdeaBlock idea={entry} hideTitle={isWinner} />
          {isWinner && entry.title && <Title>{entry.title}</Title>}
        </div>
      </WinnerCheck>
      {children}
    </Container>
  );
};

export const IdeaWinner = styled.div`
  margin-bottom: 34px;
  padding-top: 70px;
`;

const Container = styled.div`
  position: relative;
`;

const WinningIdea = styled.div`
  left: 0;
  text-align: center;
  width: 100%;
  margin: auto;
  display: block;
  border-radius: 8px;
  position: relative;
`;

const Title = styled.p`
  text-align: center;
  color: var(--color-gray-50);
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
`;
