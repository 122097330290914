import { useState } from "react";
import styled from "styled-components";
import { Typography } from "antd";

const { Text, Paragraph } = Typography;

const WritingContent = ({
  title,
  text,
  isExpandable,
  omitBackground = false,
}) => {
  // Apparently we have to make the expanding controlled in order to customize the text for both states.
  const [expanded, setExpanded] = useState(false);

  return (
    <Container $omitBackground={omitBackground}>
      {title && (
        <Text style={{ color: "var(--color-cream)" }} strong>
          {title}
        </Text>
      )}
      <Paragraph
        style={{ color: "var(--secondaryText)", marginBottom: "0px" }}
        ellipsis={
          isExpandable
            ? {
                rows: 4,
                expandable: "collapsible",
                expanded,
                onExpand: (e, info) => {
                  e.stopPropagation();
                  setExpanded(info.expanded);
                },
                symbol: expanded ? "- Less" : "+ More",
              }
            : false
        }
      >
        {text}
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px;
  white-space: pre-wrap;

  border-radius: ${(props) => !props.$omitBackground && "12px"};
  border: ${(props) =>
    !props.$omitBackground && "1.5px solid var(--border-light)"};
  background: ${(props) => !props.$omitBackground && "var(--color-gray-500)"};

  .ant-typography-expand,
  .ant-typography-collapse {
    &,
    &:hover,
    &:active {
      color: var(--color-pink);
      font-weight: 600;
    }
  }
`;

export default WritingContent;
