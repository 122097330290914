import React from "react";
import { styled } from "styled-components";
import Collapse from "../Collapse";
import questFaqData from "../../helpers/questFaq";
import questFaqDataWriting from "../../helpers/questFaqWriting";
import { valueByQuestType } from "../quests/QuestFilter";

export default function QuestFaq({ quest }) {
  const questions = valueByQuestType(
    quest,
    {
      writing: questFaqDataWriting,
      visual: questFaqData,
    },
    // TODO: remove after ai is gone
    questFaqData
  );

  return (
    <Container>
      {questions.map((faq, index) => (
        <Collapse
          key={index}
          title={faq.title}
          description={faq.description}
          image={faq.image}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;

  h1 {
    color: var(--color-cream-base);
    font-family: var(--font-primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  p {
    color: var(--color-cream-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 200% */
  }
`;
