import { useRef } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { removeTags } from "../../helpers/general";
import { generalQuillModules } from "../../helpers/constants";
import Quill from "quill";
import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";
// eslint-disable-next-line
import quillEmoji from "react-quill-emoji"; // used in some modules
import "react-quill-emoji/dist/quill-emoji.css"; // used in some modules

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

const StyledQuill = ({
  placeholder,
  value,
  setValue,
  limit,
  styles = "",
  classes = "",
  theme = "snow",
  modules = generalQuillModules,
}) => {
  const questionRef = useRef();

  return (
    <Editor
      className={classes}
      ref={questionRef}
      value={value}
      onKeyDown={(e) => {
        const exceededLimit =
          removeTags(questionRef.current.value).length >= limit;
        if (exceededLimit && e.key !== "Backspace") e.preventDefault();
      }}
      onChange={(e) => {
        if (removeTags(e).length <= limit) setValue(e);
      }}
      placeholder={placeholder}
      modules={modules}
      style={styles}
      theme={theme}
    />
  );
};

const Editor = styled(ReactQuill)`
  background: var(--color-gray-300);
  border: 0px;
  border-radius: 8px;
  vertical-align: top;
  font-size: 12px;
  font-family: inherit;
  font-weight: bold;
  color: var(--color-cream);
  padding: 14px;
  line-height: 24px;

  &::placeholder {
    font-weight: unset !important;
  }

  .ql-container {
    border: 0px !important;
  }

  .ql-toolbar {
    border: 0px !important;
  }

  &::placeholder {
    font-weight: normal !important;
    color: var(--inputFieldText);
  }
`;

export default StyledQuill;
