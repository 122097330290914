import { QueryClient, QueryCache, MutationCache } from "@tanstack/react-query";

export const _queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3, // 3 minutes, should be raised as we evaluate
      refetchOnMount: true,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      // if (process.env.NODE_ENV === "development") {
      console.error(error);
      // }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      // if (process.env.NODE_ENV === "development") {
      console.error(error);
      // }
    },
  }),
});

// This is pretty much the boilerplate for tanstack optimistic updates, collected here for convienence
// https://tanstack.com/query/v4/docs/framework/react/guides/optimistic-updates
export const optimisticUpdate = async (queryClient, queryKey, mutationFn) => {
  await queryClient.cancelQueries(queryKey);
  const previousData = queryClient.getQueryData(queryKey);
  queryClient.setQueryData(queryKey, (old) => {
    return mutationFn(old);
  });
  return previousData;
};
