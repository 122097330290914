import CustomSheet from "./CustomSheet";
import TermsOfService from "../pages/TermsOfService";
import { Sheet } from "react-modal-sheet";
import Button from "./Button";
import styled from "styled-components";

const TosSheet = ({ isOpen, setOpen }) => {
  return (
    <CustomSheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      snapPoints={[0.9]}
      tweenConfig={{ ease: "easeOut", duration: 0.3 }}
      hasCloseIcon
    >
      <Sheet.Scroller>
        <TermsOfService />
      </Sheet.Scroller>
      <Footer>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Footer>
    </CustomSheet>
  );
};

export default TosSheet;

const Footer = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;
