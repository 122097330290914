import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Disclaimer } from "../components/general";
import CharacterCounter from "../components/CharacterCounter";
import { questDetailUrl } from "../lib/routes";

export default function FinalizeGeneratedImage({
  quest,
  text,
  setText,
  selectedImage,
  submit,
}) {
  const navigate = useNavigate();

  const cancelQuestSubmission = () => {
    navigate(questDetailUrl(quest.storyworld, quest.id));
  };

  return (
    <>
      <FinalizedImage src={selectedImage} alt="" />
      {quest && quest.submissionRequirements.includes("text") && (
        <TextEntryContainer>
          <TextEntry
            placeholder="Write something..."
            defaultValue={text}
            maxLength={quest.textSubmissionLimit}
            onChange={(event) => setText(event.target.value)}
          />
          <CharacterCounter>
            {text.length} / {quest.textSubmissionLimit}
          </CharacterCounter>
        </TextEntryContainer>
      )}
      <ButtonSection>
        <CompleteQuestButton onClick={submit}>
          Complete quest
        </CompleteQuestButton>
        <CancelButton onClick={() => cancelQuestSubmission()}>
          Cancel
        </CancelButton>
        <Disclaimer text="Your quest won't be saved" />
      </ButtonSection>
    </>
  );
}

const FinalizedImage = styled.img`
  object-fit: contain !important;
  width: 100% !important;
  height: 100%;
  border-radius: 12px;
`;

const ButtonSection = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 36px;
`;

const TextEntryContainer = styled.div`
  margin-top: 22px;
  margin-bottom: -20px;
`;

const TextEntry = styled.textarea`
  background: var(--color-gray-300);
  border: 0px;
  border-radius: 8px;
  vertical-align: top;
  padding: 10px 16px;
  width: 100%;
  color: white;
  resize: none;
  letter-spacing: -0.14px;
  min-height: 100px;
  height: fit-content;
  overflow: hidden;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: white !important;
  }
`;

const CompleteQuestButton = styled.button`
  margin: auto;
  border: 0;
  border-radius: 100px;
  background: var(--blurple);
  color: var(--color-cream);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;

  &:focus {
    background: var(--blurple);
    color: var(--color-cream);
  }

  &:hover {
    background: var(--blurple);
    color: var(--color-cream);
  }
`;

const CancelButton = styled.button`
  margin: auto;
  border: 2px solid #777e91;
  border-radius: 100px;
  background: transparent;
  color: var(--color-cream);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &:focus {
    background: transparent;
    color: var(--color-cream);
  }

  &:hover {
    background: transparent;
    color: var(--color-cream);
  }
`;
