import React, { useState } from "react";
import { styled } from "styled-components";
import { CommentInputField, CommentSection, EmptyComments } from "../social";
import { useQuery } from "@tanstack/react-query";
import { commentKeys, storyworldKeys } from "../../lib/queryKeys";
import storyworldModel from "../../lib/firebase/storyworldModel";
import commentsWithLikeCounts from "../../lib/advanced/commentsWithLikeCounts";
import CommentInputProvider, {
  CommentInputContext,
} from "../../context/CommentInputProvider";
import EditCommentSection from "../EditCommentSection";

export default function QuestComments({ quest }) {
  const [sortType, setSortType] = useState("top");

  const { data: storyworld } = useQuery({
    queryKey: storyworldKeys.storyworld(quest.storyworld),
    queryFn: () => {
      return storyworldModel.getOneById(quest.storyworld);
    },
    enabled: !!quest.storyworld,
  });

  const { data: comments } = useQuery({
    queryKey: commentKeys.subject(quest.id),
    queryFn: () => {
      return commentsWithLikeCounts(quest.id);
    },
  });

  const trackingData = {
    location: "quest",
    quest_id: quest.id,
    quest_name: quest.title,
    storyworld_id: storyworld.id,
    storyworld_name: storyworld.title,
    title: `${storyworld.title}: ${quest.title}`,
  };

  return (
    <Container>
      <CommentInputProvider
        value={{
          trackingData,
        }}
      >
        <CommentInputContext.Consumer>
          {({
            startCommentSubmit,
            commentState: { commentOk, editComment },
            isSending,
            dispatch,
          }) => (
            <>
              {editComment && (
                <EditCommentSection
                  handleCancelEdit={() => {
                    dispatch({ type: "CANCEL_EDIT" });
                  }}
                />
              )}
              <InnerContainer>
                <AddCommentSection>
                  <CommentInputField />
                  <PostButton
                    disabled={!commentOk || isSending}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await startCommentSubmit(e);
                      setSortType("newest");
                    }}
                  >
                    Post
                  </PostButton>
                </AddCommentSection>

                {comments && comments.length > 0 && (
                  <CommentSection
                    comments={comments}
                    sortType={sortType}
                    setSortType={setSortType}
                    mixpanelParams={{
                      episode_id: quest.id,
                      episode_name: quest.title,
                      location: "quest",
                      storyworld_id: storyworld.id,
                      storyworld_name: storyworld.title,
                      title: `${storyworld.title}: ${quest.title}`,
                    }}
                  />
                )}

                {comments && comments.length === 0 && <EmptyComments />}
              </InnerContainer>
            </>
          )}
        </CommentInputContext.Consumer>
      </CommentInputProvider>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 0px;
  overflow: auto;
`;

const InnerContainer = styled.div`
  padding: 0 12px;
`;

const AddCommentSection = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  gap: 10px;
  position: relative;
`;

const PostButton = styled.button`
  width: 70px;
  flex-shrink: 0;
  height: 36px;
  border-radius: 20px;
  border: 0px !important;
  outline: 0px;
  border-radius: 100px;
  background: ${(props) =>
    props.disabled ? "var(--color-gray-300)" : "var(--blurple)"};
  color: ${(props) =>
    props.disabled ? "var(--disabled-button-text)" : "var(--color-cream-base)"};
  text-align: center;
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */

  &::focus-visible {
    outline: 0px;
  }
`;
