import { useId } from "react";
import styled from "styled-components";

const RadioGroup = ({ options, selected, setSelected, showResults }) => {
  const groupName = useId();

  return (
    <Container>
      {options.map((option) => (
        <CustomRadio
          value={option.value}
          label={option.value}
          percentVoted={option.percentVoted}
          groupName={groupName}
          selected={selected}
          setSelected={setSelected}
          showResults={showResults}
          key={option.value}
        />
      ))}
    </Container>
  );
};

const CustomRadio = ({
  value,
  label,
  percentVoted,
  groupName,
  selected,
  setSelected,
  showResults,
}) => {
  return (
    <RadioLabel $showResults={showResults} $percentVoted={percentVoted}>
      <RadioInput
        type="radio"
        name={groupName}
        value={value}
        checked={value === selected}
        disabled={showResults}
        onChange={(e) => setSelected(e.target.value)}
      />
      <TextWrapper>
        <Text $showResults={showResults}>{label}</Text>
        {showResults && value === selected && (
          <img src="/icons/circle-check.svg" alt="" />
        )}
      </TextWrapper>
      <Result style={{ visibility: showResults ? "visible" : "hidden" }}>
        {percentVoted}%
      </Result>
    </RadioLabel>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* overflow: hidden; */

  --radio-color: var(--color-gray);
  --radio-size: 1.25rem;
  --checked-size: calc(var(--radio-size) / 2.5);
`;

const RadioLabel = styled.label`
  position: relative;
  display: grid;
  grid-template-columns: 1em auto;
  align-items: center;
  gap: 1.25rem;
  line-height: 1.625rem;
  overflow: hidden;

  border-radius: 8px;
  border: 1.5px solid;
  padding: 0.5rem 1rem;
  border-color: var(--radio-color);

  padding-right: 2rem;

  &:has(input:checked) {
    border-color: var(--color-blurple);
  }

  /* Slide radio, text, and offscreen percentages to the left */
  & > * {
    transform: ${(props) =>
      props.$showResults ? "translateX(-40px)" : "none"};
    transition: transform 200ms;
  }

  /* Background blurple representing vote percentages */
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    background-color: rgba(80, 93, 255, 0.35);

    width: ${(props) => (props.$percentVoted ? `${props.$percentVoted}%` : 0)};
    transform: ${(props) =>
      props.$showResults ? "none" : "translateX(-100%)"};
    transition: ${(props) =>
      props.$showResults ? "transform 500ms ease-in-out" : "none"};
    transition-delay: 200ms;
  }
`;

const Text = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${(props) => (props.$showResults ? "nowrap" : "normal")};
`;

const Result = styled.span`
  color: var(--color-cream-base);
  font-weight: 600;
  font-size: 0.875rem;
  position: absolute;
  right: -2rem;
`;

const RadioInput = styled.input`
  appearance: none;
  margin: 0;

  display: grid;
  place-content: center;

  color: currentColor;
  width: var(--radio-size);
  height: var(--radio-size);
  border: 0.15rem solid var(--radio-color);
  border-radius: 50%;

  /* Check circle */
  &::before {
    content: "";
    width: var(--checked-size);
    height: var(--checked-size);

    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: var(--poll-background);
  }

  &:checked {
    background-color: var(--color-blurple);
    border-color: var(--color-blurple);
  }

  &:checked::before {
    transform: scale(1);
  }

  /* Resetting focus color to ours to reset browser styles */
  &:focus {
    outline: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export default RadioGroup;
