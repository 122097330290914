import React, { useContext, useMemo } from "react";
import { styled } from "styled-components";
import { Helmet } from "react-helmet";
import { useQuery } from "@tanstack/react-query";
import Hidden from "../components/Hidden";
import { device } from "../lib/breakpoints";
import { Spacer } from "../styles/CommonElements";
import { AuthContext } from "../context/AuthProvider";
import StoryworldGroup from "../components/StoryworldGroup";
import StoryHoverCard from "../components/stories/StoryHoverCard";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { storyworldKeys } from "../lib/queryKeys";
import storyworldsWithQuestsNoCatalog from "../lib/advanced/storyworldsWithQuestsNoCatalog";
import { APP_NAME } from "../helpers/constants";

const TOP_BANNER_MOBILE = "/images/quests/create-and-vote-banner-mobile.svg";
const TOP_BANNER_TABLET_OR_ABOVE =
  "/images/quests/create-and-vote-banner-tablet-or-above.svg";

export default function Quests() {
  const { user, userInfo } = useContext(AuthContext);
  const { selectedStoryworld, storyworldElement } = useContext(
    SelectedStoryworldContext
  );
  const stories = useQuery({
    queryKey: storyworldKeys.with_quests_no_catalog(),
    queryFn: async () => {
      return storyworldsWithQuestsNoCatalog();
    },
    placeholderData: [],
  });

  const mySeries = useMemo(() => {
    const readStories = userInfo?.readStories;
    const publishedStories = stories?.data;

    if (!readStories || !publishedStories) return [];

    return (
      publishedStories
        // only include stories user has read
        .filter((story) =>
          readStories.some((readStoryId) => {
            return story.id === readStoryId;
          })
        )
        // sort by the order present in the readStories
        .sort((storyA, storyB) => {
          const storyAIndex = readStories.findIndex(
            (readStoryId) => readStoryId === storyA.id
          );
          const storyBIndex = readStories.findIndex(
            (readStoryId) => readStoryId === storyB.id
          );

          return storyAIndex - storyBIndex;
        })
    );
  }, [stories?.data, userInfo?.readStories]);

  if (!stories?.data) return;
  if (!mySeries) return;

  const remainingStoryworlds = stories?.data.filter((story) =>
    mySeries.every((my) => my.id !== story.id)
  );

  return (
    <>
      <Helmet>
        <title>{APP_NAME} - Quests</title>
      </Helmet>
      <Container>
        <HeaderContainer>
          <Hidden tablet desktop>
            <BannerImage src={TOP_BANNER_MOBILE} alt="" />
          </Hidden>
          <Hidden mobile>
            <BannerImage src={TOP_BANNER_TABLET_OR_ABOVE} alt="" />
          </Hidden>
        </HeaderContainer>

        <InnerContainer>
          {user && mySeries.length > 0 && (
            <>
              <StoryworldGroup
                title="My Series"
                subtitle="Quests from the webcomics you're reading"
                stories={mySeries}
              />
              <Spacer $marginTop="3.5rem" />
            </>
          )}

          {remainingStoryworlds.length > 0 && (
            <StoryworldGroup
              title="Storyworld Quests"
              subtitle="Shape these Storyworlds with your ideas"
              stories={remainingStoryworlds}
            />
          )}
        </InnerContainer>
        <Spacer $marginTop="5rem" />
      </Container>

      <StoryHoverCard
        isVisible={selectedStoryworld !== null}
        storyworld={selectedStoryworld}
        target={storyworldElement}
      />
    </>
  );
}

const Container = styled.div`
  text-align: left;
  width: 100%;
  margin: auto;
  margin-top: 0px;

  .ant-image {
    width: 100%;
  }
`;

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;

  @media ${device.tablet}, ${device.desktop} {
    min-height: 200px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 36px !important;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 614px;
    margin: auto;
  }

  @media ${device.desktop} {
    width: 908px;
    margin: auto;
  }
`;
