import { useMutation, useQueryClient } from "@tanstack/react-query";
import userModel from "./firebase/userModel";
import { userKeys } from "./queryKeys";
import { message } from "antd";
import finishAccountSetup from "./finishAccountSetup";
import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import { trackEvent } from "../helpers/mixpanel";

const useCreateProfileMutation = () => {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutationResult = useMutation({
    mutationFn: async (profileData) => {
      return userModel.create({
        bio: profileData?.bio ?? "",
        email: user?.email.toLocaleLowerCase(),
        id: user?.uid,
        pfp: profileData.profilePic,
        username: profileData.username,
      });
    },
    onSuccess: ({ username }) => {
      // Up until this point, userInfo will have been null (namely in the AuthProvider). This resets it.
      queryClient.invalidateQueries({
        queryKey: userKeys.user(user.uid),
      });

      finishAccountSetup(user, username);

      trackEvent(
        "Completed Profile Creation",
        {
          email: user?.email.toLocaleLowerCase(),
        },
        true
      );
    },
    onError: () => {
      message.error("Account could not be created");
    },
  });

  return mutationResult;
};

export default useCreateProfileMutation;
