import React from "react";
import { styled } from "styled-components";
import parse from "html-react-parser";

export default function QuestInfo({ quest }) {
  return <Container>{parse(quest.moreInfo ? quest.moreInfo : "")}</Container>;
}

const Container = styled.div`
  display: block;
  width: 90%;
  margin: auto;
  color: var(--color-cream-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  img {
    max-width: 100%;
  }
`;
