import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getUserInfoCountByEmail } from "./firebase";
import { defaultProfileImages } from "./constants";
import userModel from "../lib/firebase/userModel";
import { prodFirebaseConfig, sandboxFirebaseConfig } from "./firebaseConfigs";
import { initializeApp } from "firebase/app";
import { isUserBanned, isUserSuspended } from "./report";

let config;
switch (process.env.REACT_APP_DEPLOYMENT) {
  case "prod":
    config = prodFirebaseConfig;
    break;
  default:
    config = sandboxFirebaseConfig;
}

const app = initializeApp(config);
export const firebaseAuth = getAuth(app);

export const GOOGLE_OAUTH_CLIENT_ID =
  "234934930439-mfjt2tsbcba7tkhgom4hd33vr0vna35i.apps.googleusercontent.com";

export const authRoutes = ["/login", "/signup", "/forgot-password"];

export async function registerFirebaseAuth({ email, password }) {
  try {
    const profileCount = await getUserInfoCountByEmail(email);
    if (profileCount >= 1) return false;
    const result = await createUserWithEmailAndPassword(
      firebaseAuth,
      email.toLowerCase(),
      password
    );
    return result;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function loginUser(email, password) {
  const credentials = await signInWithEmailAndPassword(
    firebaseAuth,
    email,
    password
  );

  // Unclear if this would ever occur, signInWithEmailAndPassword should throw an error on incorrect auth
  if (!credentials) {
    throw new Error("Something went wrong logging in");
  }

  const isBanned = await isUserBanned(credentials.user.uid);
  if (isBanned) {
    firebaseAuth.signOut();
    throw { code: "banned" };
  }

  const isSuspended = await isUserSuspended(credentials.user.uid);
  if (isSuspended.suspended) {
    firebaseAuth.signOut();
    throw { code: "suspended", expiresAt: isSuspended.expiresAt };
  }

  return userModel.getOneById(credentials.user.uid);
}

export function logoutUser(to = "/") {
  firebaseAuth
    .signOut()
    .then(() => {
      window.location.href = to;
    })
    .catch((error) => {
      console.error("Sign out error:", error);
    });
}

export function validateEmail(email) {
  const res =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

export function getRandomProfilePic() {
  return defaultProfileImages[
    Math.floor(Math.random() * defaultProfileImages.length)
  ];
}

export function getDefaultProfilePicByUserId(userId) {
  return defaultProfileImages[
    userId.charCodeAt(0) % defaultProfileImages.length
  ];
}

export async function doesUserAuthExistByEmail(email) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/doesUserExist`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({ email }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch user");
  }

  return response.json();
}
