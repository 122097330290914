import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

export function TabItem({ isActive, layoutId, children, ...props }) {
  return (
    <TabItemStyled $isActive={isActive} {...props}>
      <div className="underline" />
      <AnimatePresence>
        {isActive && (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.2 } }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
            transition={{ type: "spring", duration: 0.25 }}
            layoutId={"selectedUnderline" + (layoutId || "")}
            className="activeUnderline"
          />
        )}
      </AnimatePresence>
      {children}
    </TabItemStyled>
  );
}

const TabItemStyled = styled.div`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  line-height: 18px;
  padding-bottom: 22px;
  color: ${(props) =>
    props.$isActive ? "var(--color-pink)" : "var(--color-gray)"};
  font-weight: ${(props) => (props.$isActive ? "600" : "500")};
  font-family: var(--font-primary);

  .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #35383f;
  }

  .activeUnderline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--color-pink);
    border-radius: 9999px;
  }
`;
