import { ReactSVG } from "react-svg";
import styled from "styled-components";

const CARAT_ICON = "/icons/general/carat-right.svg";

const SettingsItem = ({ item }) => {
  return (
    <Container onClick={() => item?.onClick()}>
      <Icon src={item?.icon} style={item?.style} alt="" />
      <Title>{item?.title}</Title>
      <Carat src={CARAT_ICON} alt="" />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 10px;
`;

const Carat = styled(ReactSVG)`
  position: absolute;
  right: 2px;
  top: -6px;
  width: auto;
  height: auto;
  transform: scale(0.55);
`;

const Icon = styled(ReactSVG)`
  width: 22px;
  height: 22px;
  padding-top: 1px;
  filter: brightness(0);
`;

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
`;

export default SettingsItem;
