import React from "react";
import styled from "styled-components";
import { APP_NAME, APP_URL, FULL_COMPANY_NAME } from "../helpers/constants";
import { Spacer } from "../styles/CommonElements";

export default function PrivacyPolicy() {
  return (
    <Container>
      <MainHeader>Privacy Policy</MainHeader>
      <Spacer $marginTop="2rem" />
      <TextContainer>
        <TextSection>
          {APP_NAME} Services (“{APP_NAME}” or “Services”) are provided by{" "}
          {FULL_COMPANY_NAME} (“We”, “Our”, “{APP_NAME}”) to you (“User” or
          “Users”).
        </TextSection>
        <TextSection>
          We believe in transparency and care about your privacy. We want you to
          be clear how we're using your information as you use our Services, so
          please take the time to read this policy carefully.
        </TextSection>

        <Header>Information We Collect or Receive About You</Header>
        <TextSection>
          Similar to many other platforms, we collect and process basic
          information to run our business and provide our Services to you.
        </TextSection>
        <TextSection>
          We collect or receive information in a few different ways, depending
          on the Services you use and how you use them:
        </TextSection>

        <ItalicsHeader>Information you provide</ItalicsHeader>
        <TextSection>
          We collect information you provide when you create a {APP_NAME}{" "}
          account, upload content, or otherwise use the Service, including:
        </TextSection>
        {/*
        <List>
          <ListItem>
            Identification information: for example, your email address,
            username, and authentication credentials used to sign up or log in.
            If you choose to add a photo, location, or other optional details to
            your profile, you should be aware that such information is public,
            and therefore visible to anyone who visits your profile page.
          </ListItem>
        </List>
        */}

        <ItalicsHeader>
          Information we collect from your use of the Service
        </ItalicsHeader>
        <TextSection>
          We collect information about you and the devices you use to access the
          Service, such as your mobile phone, or tablet.
        </TextSection>

        <TextSection>The information that we collect includes:</TextSection>
        <List>
          <ListItem>
            Device information: we may collect device-specific information (such
            as your hardware model, operating system version, and unique device
            identifiers). {APP_NAME} may associate your device identifiers with
            your {APP_NAME} user account.
          </ListItem>
          <ListItem>
            Log information. When you use our services or view content provided
            on the service, we may automatically collect and store certain
            information in server logs. This may include:
          </ListItem>
          <List>
            <ListItem>Details of how you used our service;</ListItem>
            <ListItem>Internet protocol (IP) address;</ListItem>
            <ListItem>
              Device event information such as crashes, system activity,
              hardware settings, browser type, browser language, and the date
              and time of your request;
            </ListItem>
            <ListItem>
              Cookies that may uniquely identify your browser or your
              {APP_NAME} user account.
            </ListItem>
          </List>
          <ListItem>
            Location information. When you use a location-enabled device, we may
            collect and process information about your approximate location,
            like the GPS signals sent by a mobile device.
          </ListItem>
          <ListItem>
            Local storage. We may collect and store information (including
            personal information) locally on your device using mechanisms such
            as browser web storage (including HTML 5) and application data
            caches.
          </ListItem>
          <ListItem>
            Cookies and anonymous identifiers. We use various technologies to
            collect and store information when you visit our services, and this
            may include sending one or more cookies or anonymous identifiers to
            your device. We also use cookies and anonymous identifiers when you
            interact with services we offer to our partners, such as advertising
            services or {APP_NAME} features that may appear on other sites.
          </ListItem>
        </List>

        <Header>How We Use Your Information</Header>
        <TextSection>
          We rely on a number of legal bases to collect, use, or process your
          information in the ways described in this policy. These bases include
          where:
        </TextSection>
        <List>
          <ListItem>
            Necessary to provide the Services to you or perform the contractual
            obligations in our Terms of Service;
          </ListItem>
          <ListItem>
            Necessary to satisfy legal obligation, to exercise or defend legal
            claims, or to comply with a court order;
          </ListItem>
          <ListItem>
            You have consented to the processing, which you can revoke at any
            time; or
          </ListItem>
          <ListItem>You have expressly made the information public.</ListItem>
        </List>

        <TextSection>
          We may use information about you for a number of purposes, including:
        </TextSection>

        <ItalicsHeader>Providing and improving our services</ItalicsHeader>
        <List>
          <ListItem>
            Providing, maintaining, and improving our Services, including the
            specific {APP_NAME} features you choose to use;
          </ListItem>
          <ListItem>Personalizing your use of the Services;</ListItem>
          <ListItem>Developing new products, services, and features;</ListItem>
          <ListItem>
            Determining whether the Services or specific content are available
            in your country;
          </ListItem>
          <ListItem>
            Measuring, tracking, and analyzing trends in usage in connection
            with your use or the performance of our Services.
          </ListItem>
        </List>

        <ItalicsHeader>Communicating with you about our services</ItalicsHeader>
        <List>
          <ListItem>
            Sending you information you have requested from us or that we think
            you may find useful, such as updates or changes to our Services;
          </ListItem>
          <ListItem>Collecting feedback about our Services;</ListItem>
          <ListItem>
            Contacting you to resolve disputes, provide assistance, or notify
            you of changes to our policies or terms.
          </ListItem>
        </List>

        <ItalicsHeader>
          Keeping our services and community safe and secure
        </ItalicsHeader>
        <List>
          <ListItem>
            Enforcing our Terms of Service or other applicable contracts,
            agreements, or policies;
          </ListItem>
          <ListItem>
            Complying with any applicable laws or regulations, or in response to
            lawful requests for information from the government or other third
            party.
          </ListItem>
        </List>

        <ItalicsHeader>Service providers</ItalicsHeader>
        <List>
          <ListItem>
            {APP_NAME} engages with third-party companies and service providers
            (such as analytics providers) to help us provide or improve our
            Services. These third parties have limited access to your
            information, and may only use the information provided to perform
            specific tasks on our behalf.
          </ListItem>
        </List>

        <ItalicsHeader>Aggregated information</ItalicsHeader>
        <List>
          <ListItem>
            Aggregated information may be used to analyze performance or
            otherwise improve our Services, but it will be aggregated and
            anonymized so that personal information is not revealed.
          </ListItem>
        </List>

        <Header>When and With Whom We Share Information</Header>
        <TextSection>
          We may share information about you as follows:
        </TextSection>

        <ItalicsHeader>Safety and compliance with the law</ItalicsHeader>
        <List>
          <ListItem>
            If we have a good-faith belief that access, use, preservation or
            disclosure of the information is reasonably necessary to:
          </ListItem>
          <ListItem>
            Meet any applicable law, regulation, legal process or enforceable
            governmental request.
          </ListItem>
          <ListItem>
            Enforce applicable Terms of Service, including investigation of
            potential violations.
          </ListItem>
          <ListItem>
            Detect, prevent, or otherwise address fraud, security or technical
            issues.
          </ListItem>
          <ListItem>
            Protect against harm to the rights, property or safety of
            {APP_NAME}, our users, or the public as required or permitted by
            law.
          </ListItem>
        </List>

        <ItalicsHeader>Business transfers and corporate changes</ItalicsHeader>
        <TextSection>
          If {APP_NAME} is involved in a merger, acquisition or asset sale, we
          will continue to ensure the confidentiality of any personal
          information and give affected users notice before personal information
          is transferred or becomes subject to a different privacy policy.
        </TextSection>

        <Header>How Long We Retain Your Information</Header>
        <TextSection>
          We retain data until it is no longer necessary to provide our services
          to you, or until your account is deleted - whichever comes first. If
          you no longer want {APP_NAME} to use your information to provide the
          Services to you, you may delete your account.
        </TextSection>
        <TextSection>
          We also retain log files for internal analysis purposes. These log
          files are generally stored for a brief period of time, except in cases
          where they are used to improve service functionality or security, or
          where we are legally obligated to retain them for longer periods. We
          also retain information from accounts disabled for terms violations
          for at least a year to prevent repeat abuse or violations.
        </TextSection>

        <Header>Third party service providers</Header>
        <TextSection>
          Our Privacy Policy applies to all of the services offered by{" "}
          {APP_NAME}, but excludes services that have separate privacy policies
          that do not incorporate this Privacy Policy. We use third-party
          service providers primarily for email and analytics services.
        </TextSection>

        <ItalicsHeader>
          Examples of our third-party service providers include:
        </ItalicsHeader>
        <List>
          <ListItem>
            Mixpanel: we use Mixpanel to understand how our Services perform,
            and how you use them.
          </ListItem>
          <ListItem>
            Mailchimp: we use Mailchimp for email monitoring and automation.
          </ListItem>
        </List>

        <TextSection>
          This Privacy Policy does not apply to, nor are we responsible for,
          third-party cookies, pixel tags, web beacons, or other tracking
          technologies, which are covered by such third parties' privacy
          policies. For more information, we encourage you to check the privacy
          policies of these third parties to learn about their privacy
          practices.
        </TextSection>

        <Header>Your Choices</Header>
        <TextSection>
          People have different personal privacy concerns. Our goal is to be
          clear about what information we collect, so that you can make
          meaningful choices about how it is used.
        </TextSection>

        <TextSection>
          Here are some of your choices related to personal privacy:
        </TextSection>
        <List>
          <ListItem>
            You may set your browser to block all cookies, including cookies
            associated with our services, or to indicate when a cookie is being
            set by us. However, it is important to remember that many of our
            services may not function properly if your cookies are disabled.
          </ListItem>
          <ListItem>
            If you no longer wish to use the Services or receive service-related
            communications (except for legally required notices), then you may
            delete your account.
          </ListItem>
        </List>

        <TextSection>
          If your personal information is wrong, we strive to give you ways to
          update it quickly or to remove it — unless we are required to retain
          that information for legitimate business or legal purposes.
        </TextSection>

        <TextSection>
          Where we can provide information access and correction, we will do so
          for free, except where it would require a disproportionate effort. We
          aim to maintain our services in a manner that protects information
          from accidental or malicious destruction. Because of this, after you
          delete information from our services, we may not immediately delete
          residual copies from our active servers and may not remove information
          from our backup systems. Please note that we may reject requests that
          are unreasonably repetitive, require disproportionate technical effort
          (for example, developing a new system or fundamentally changing an
          existing practice), risk the privacy of others, or would be extremely
          impractical (for instance, requests concerning information residing on
          backup tapes).
        </TextSection>

        <ItalicsHeader>Withdrawing consent</ItalicsHeader>
        <TextSection>
          You have the right to withdraw your consent to our processing of your
          information and your use of our Services. For example:
        </TextSection>
        <List>
          <ListItem>
            You can withdraw consent from email marketing or notifications by
            using the unsubscribe link in such communications.
          </ListItem>
          <ListItem>
            You can choose to withdraw your consent to our processing of your
            data and your use of the Services by closing your account, by using
            the "Delete account" option in your {APP_NAME} account settings.
          </ListItem>
        </List>

        <TextSection>
          Depending on your location, you can also email{" "}
          <a href={`mailto:support@${APP_URL}`}>support@{APP_URL}</a> to request
          that your personal information be deleted, excluding information we
          are legally required to retain.
        </TextSection>

        <Header>Security</Header>
        <TextSection>
          Your personal information is important to you, and protecting that
          information is important to us. We take reasonable measures to protect{" "}
          {APP_NAME} and our users from unauthorized access, alteration,
          disclosure, or loss of information we hold, including administrative,
          technical, and physical safeguards.
        </TextSection>

        <TextSection>
          Your account information is protected by a password. It is important
          that you protect against unauthorized access to your account and
          information by choosing your password carefully and by keeping your
          password and device secure, such as by logging out after using the
          Services.
        </TextSection>

        <TextSection>
          We follow generally accepted standards to protect the personal
          information submitted to us, both during transmission and after it is
          received. Here are some of the security measures we take:
        </TextSection>
        <List>
          <ListItem>
            We encrypt many of our services and certain information using secure
            socket layer technology (SSL).
          </ListItem>
          <ListItem>
            We review our information collection, storage and processing
            practices, including physical security measures, to guard against
            unauthorized access to systems.
          </ListItem>
          <ListItem>
            We restrict access to personal information to {APP_NAME} employees,
            contractors and agents who need to know that information in order to
            process it for us, and who are subject to strict contractual
            confidentiality obligations and may be disciplined or terminated if
            they fail to meet these obligations.
          </ListItem>
        </List>

        <TextSection>
          Unfortunately, no method of transmission over the internet or method
          of electronic storage is 100% secure, and we cannot guarantee absolute
          security of the transmission or storage of your information.
        </TextSection>

        <Header>Changes to This Policy</Header>
        <TextSection>
          Our Privacy Policy may change from time to time, at any time. We will
          not reduce your rights under this Privacy Policy without your explicit
          consent. We will post any privacy policy changes on this page and, if
          the changes are significant, we will provide a more prominent notice
          (including, for certain services, email notification of privacy policy
          changes).
        </TextSection>

        <Header>Contact</Header>
        <TextSection>
          Please contact us at{" "}
          <a href={`mailto:support@${APP_URL}`}>support@{APP_URL}</a> with any
          questions or concerns regarding this Privacy Policy.
        </TextSection>

        <TextSection>
          We regularly review our compliance with our Privacy Policy. When we
          receive formal written complaints, we will contact the person who made
          the complaint to follow up. We work with the appropriate regulatory
          authorities, including local data protection authorities, to resolve
          any complaints regarding the transfer of personal data that we cannot
          resolve with our users directly.
        </TextSection>
      </TextContainer>
    </Container>
  );
}

const Container = styled.div`
  color: black;
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  width: 80%;
  margin: auto;
  overflow: hidden;
  margin-bottom: 40px;
  margin-top: 3rem;
`;

const TextContainer = styled.div`
  height: 100%;
  overflow: unset !important;
  padding-right: 5%;
  padding-bottom: 32px;
`;

const Header = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 20.8px;
  margin-top: 24px;
`;

const MainHeader = styled.div`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 18px;
`;

const List = styled.ul`
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  margin-top: 18px;
`;

const ListItem = styled.li`
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  margin-top: 10px;
`;

const TextSection = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  margin-top: 18px;
`;

const ItalicsHeader = styled.div`
  font-size: 13px;
  font-style: italic;
  line-height: 20.8px;
  margin-top: 24px;
`;
