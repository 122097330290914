import React from "react";
import styled from "styled-components";

export default function Disclaimer({ text }) {
  return (
    <Container>
      <Icon src="/icons/warning.svg" alt="" />
      {text}
    </Container>
  );
}
const Container = styled.div`
  margin-top: 14px;
  color: rgba(194, 201, 221, 0.5);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.12px;
`;

const Icon = styled.img`
  margin-right: 4px;
  width: 17px;
  height: 17px;
  margin-bottom: 2px;
`;
