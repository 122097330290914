import React from "react";
import styled from "styled-components";
import CustomSheet from "../CustomSheet";

export default function IdeaSheet({ isOpen, setOpen, setPrompt, text }) {
  return (
    <StyledSheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      tweenConfig={{ ease: "easeOut", duration: 0.2 }}
    >
      <InnerContainer>
        <SheetText>{text}</SheetText>
        <CopyPromptButton
          onClick={async () => {
            setOpen(false);
            setPrompt(text);
          }}
        >
          Copy Prompt
        </CopyPromptButton>
      </InnerContainer>
    </StyledSheet>
  );
}

const StyledSheet = styled(CustomSheet)`
  & .react-modal-sheet-header {
    height: 24px;
    margin-top: 4px;
  }
  & .react-modal-sheet-drag-indicator {
    width: 30px;
    height: 7px;
    background: var(--color-gray);
  }
  & .react-modal-sheet-container {
    background-color: var(--color-gray-600);
    background-position: center;
    background-size: cover;
    padding: 0px 20px 20px 20px;
    border-radius: 20px 20px 0px 0px;
    position: relative;
    overflow-x: hidden;
    height: fit-content;
  }
`;

const InnerContainer = styled.div`
  margin-top: 8px;
`;

const SheetText = styled.div`
  color: var(--color-cream-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.5px;
`;

const CopyPromptButton = styled.button`
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  border: 0;
  border-radius: 100px;
  background: var(--blurple);
  color: var(--cream);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-top: 22px;
`;
