import React from "react";
import styled from "styled-components";

export default function GeneratedImage({
  genImage,
  isGenerating,
  hasResults,
  setSelectedImage,
  selectedImage,
}) {
  return (
    <Container>
      {hasResults ? (
        <div
          className={`generated-image-section ${
            selectedImage === genImage
              ? "generated-image-section-selected"
              : selectedImage === null
              ? ""
              : "generated-image-section-gray"
          }`}
          onClick={() => {
            setSelectedImage(selectedImage !== genImage ? genImage : null);
          }}
          style={{ background: `url(${genImage}) !important` }}
        >
          <ImageSectionGenerated>
            <GenImage alt="" src={genImage} />
          </ImageSectionGenerated>
        </div>
      ) : isGenerating ? (
        <GenerationLoadingContainer className="generated-image-section">
          <ImageSectionGenerating>
            <Spinner src={"/icons/loading-spheres-lavender.svg"} />
            <GeneratingText>Generating...</GeneratingText>
          </ImageSectionGenerating>
        </GenerationLoadingContainer>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  .generated-image-section:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .generated-image-section {
    border-radius: 12px !important;
    display: block;
    position: relative !important;
    width: 100%;
  }

  .generated-image-section-selected {
    border-radius: 8px;
    background:
      linear-gradient(white, white) padding-box,
      linear-gradient(20deg, var(--color-pink), var(--nextPreviewButton))
        border-box;
    border: 4px solid transparent;
  }

  .generated-image-section-gray {
    opacity: 0.4 !important;
  }
`;

const GenImage = styled.img`
  object-fit: contain !important;
  width: 100% !important;
  height: 100%;
  border-radius: 8px;
`;

/*
const GenImage = styled.img`
  object-fit: contain !important;
  width: 100% !important;
  height: 100%;
  border-radius: 8px;
  filter: grayscale(100%);
`;
*/

const ImageSectionGenerated = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
`;

const GenerationLoadingContainer = styled.div`
  background: var(--color-gray-400);
`;

const GeneratingText = styled.h5`
  text-align: center;
  color: var(--lavender);
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 22.517px */
  letter-spacing: 0.176px;
`;

const ImageSectionGenerating = styled.div`
  position: absolute;
  left: 25%;
  right: 25%;
  top: 30%;
  text-align: center;
`;

const Spinner = styled.img`
  width: 40px;
  height: 40px;
  animation: rotation 2s infinite linear;
  margin: auto;
  margin-top: -6px;
  text-align: center;
`;
