import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Collapse, Tooltip } from "antd";
import moment from "moment";
import { Comment } from "@ant-design/compatible";
import UserDisplay from "../UserDisplay";
import LikeButton from "./../LikeButton";
import CommentDropdownButton from "./CommentDropdownButton";
import pluralize from "pluralize";
import { ReadMore } from "../general";
import { AuthContext } from "../../context/AuthProvider";
import { SelectedStoryworldContext } from "../../context/SelectedStoryworldProvider";
import { Username } from "../user";
import { device, useBreakpoints } from "../../lib/breakpoints";
export default function PostedComment({
  comment,
  showReply = true,
  setEditComment,
  setReplyTo,
  children,
  replyToSubject = null,
  setDeletedComments,
  mixpanelParams,
}) {
  const { user, requireLogin } = useContext(AuthContext);
  const storyworldContext = useContext(SelectedStoryworldContext);
  const { isMobile } = useBreakpoints();

  // The context may be undefined if this component isn't in the Episode Comments section
  const storyworldCreators =
    storyworldContext?.selectedStoryworld?.creators ?? [];

  const [sortedChildren, setSortedChildren] = useState(false);
  const [repliesExpanded, setRepliesExpanded] = useState(false);

  useEffect(() => {
    if (children && children.length > 0) {
      const sorted = children.sort(function (a, b) {
        return a.createdAt - b.createdAt;
      });
      setSortedChildren(sorted);
    }
  }, [children]);

  const panelKey = "1";
  const collapsePanel = [
    {
      key: panelKey,
      label: `${pluralize("Reply", sortedChildren.length, true)}`,
      children:
        sortedChildren &&
        sortedChildren.map((c, index) => (
          <PostedComment
            comment={c}
            key={index}
            setEditComment={setEditComment}
            setReplyTo={setReplyTo}
            replyToSubject={comment}
            setDeletedComments={setDeletedComments}
            mixpanelParams={mixpanelParams}
          />
        )),
    },
  ];

  return (
    <Container>
      <Comment
        style={{ marginBottom: children && children.length > 0 && "-6px" }}
        actions={[
          <CommentActions key="comment-actions">
            <ReplyWrapper>
              {showReply && (
                <ReplySpan
                  onClick={() => {
                    if (!user) {
                      requireLogin();
                      return;
                    }

                    setEditComment(null);
                    setReplyTo(replyToSubject ? replyToSubject : comment);
                  }}
                >
                  Reply
                </ReplySpan>
              )}
            </ReplyWrapper>
            <div>
              {showReply && isMobile && (
                <CommentDropdownButton
                  comment={comment}
                  setEditComment={setEditComment}
                  isCurrentUser={comment.creator === user?.uid}
                  setDeletedComments={setDeletedComments}
                  mixpanelParams={mixpanelParams}
                >
                  {children}
                </CommentDropdownButton>
              )}
              <LikeButton
                ghost
                itemType="comment"
                itemId={comment.id}
                mixpanelParams={{
                  ...mixpanelParams,
                  type: comment.replyTo ? "reply" : "comment",
                }}
              />
            </div>
          </CommentActions>,
        ]}
        author={
          !isMobile ? (
            <LargeScreenUsernameWrapper>
              <UserDisplay
                userId={comment.creator}
                showName={false}
                size={32}
                isWinner={true}
                backLocation={`${location.pathname}${location.search}`}
              />
              <Username
                userId={comment.creator}
                isCreator={storyworldCreators.includes(comment.creator)}
                isLinkToProfile
              />
            </LargeScreenUsernameWrapper>
          ) : (
            <Username
              userId={comment.creator}
              isCreator={storyworldCreators.includes(comment.creator)}
              isLinkToProfile
            />
          )
        }
        avatar={
          isMobile && (
            <UserDisplay
              userId={comment.creator}
              showName={false}
              size={comment.replyTo === null ? 38 : 22}
              isWinner={true}
            />
          )
        }
        content={
          <CommentText>
            <ReadMore
              text={comment.comment}
              threshold={100}
              showEdited={comment.edited}
              showLess={false}
            />
          </CommentText>
        }
        datetime={
          <DatetimeWrapper>
            <Tooltip
              title={moment(comment.createdAt).format("MMMM D YYYY, h:mm:ss a")}
            >
              <MomentSpan>
                • {moment(comment.createdAt).fromNow(true)}
              </MomentSpan>
            </Tooltip>
            {showReply && !isMobile && (
              <CommentDropdownButton
                comment={comment}
                setEditComment={setEditComment}
                isCurrentUser={comment.creator === user?.uid}
                setDeletedComments={setDeletedComments}
                mixpanelParams={mixpanelParams}
              >
                {children}
              </CommentDropdownButton>
            )}
          </DatetimeWrapper>
        }
      />
      {children && children.length > 0 && (
        <CollapseContainer $expanded={repliesExpanded}>
          <Collapse
            items={collapsePanel}
            // We add our own with psuedo elements
            expandIcon={() => null}
            onChange={(status) => {
              // How antd indicates the state of the collapse
              setRepliesExpanded(status.includes(panelKey));
            }}
          />
        </CollapseContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding-block: 10px;

  &:first-of-type {
    .ant-comment-inner {
      margin-top: 0px;
    }
  }

  &:last-of-type {
    .ant-comment-inner {
      margin-bottom: 0px;
    }
  }

  .ant-comment {
    background: transparent;
  }

  .ant-comment-content-author-name {
    font-feature-settings:
      "clig" off,
      "liga" off;
    line-height: 22px; /* 157.143% */
  }

  .ant-comment-content-author-name * {
    color: var(--color-cream-base);
  }

  .ant-avatar-circle img {
    width: 100%;
    height: 100%;
  }

  .ant-comment-content-author-time {
    color: var(--disabled-button-text);
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
    flex: 2;
  }

  .ant-comment-actions {
    /* Reset to be handled by CommentActions below  */
    margin-top: 0px;
  }

  .ant-comment-actions li {
    width: 100%;
  }

  .ant-comment-inner {
    padding: 0px;
  }

  @media ${device.tablet}, ${device.desktop} {
    border-top: solid 1px var(--border-light);
    padding-block: 16px;

    &:first-of-type {
      border-top: none;
      padding-top: 0px;
    }

    .ant-comment-content-author {
      margin-bottom: 12px;
    }
    .ant-comment-content-author-time {
      display: flex;
      align-items: center;
      padding-top: 2px;
    }
  }
`;

const CollapseContainer = styled.div`
  margin-left: 48px;
  margin-bottom: 10px;
  margin-top: 10px;

  .ant-collapse {
    background: transparent;
    border: 0;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content {
    border: 0;
    background: transparent;
    outline: 0;
    margin-bottom: -30px;
  }

  .ant-comment-inner {
    padding: 0;
  }

  .ant-collapse-header-text {
    color: var(--disabled-button-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.5px; /* 189.286% */

    flex: none !important;
    position: relative;
    padding-left: 28px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: calc(50% - 2px);
      width: 20px;
      height: 1px;
      border: 0.5px solid var(--border-light);
    }

    &::after {
      content: url(/icons/collapse-caret.svg);
      position: absolute;
      padding-left: 4px;
      height: 100%;

      transform-origin: right center;
      transition: transform 0.5s ease-out;
      transform: ${(props) =>
        props.$expanded
          ? "rotate(-90deg) translateX(40%)"
          : "rotate(90deg) translateX(40%) translateY(10%)"};
    }
  }

  .ant-collapse-item {
    border: 0;
  }

  .ant-collapse-content-box {
    padding: 10px 0px;
  }

  .ant-collapse-content-box {
    padding: 16px 0px !important;
  }

  .ant-comment {
    margin-bottom: 20px;
  }
`;

const MomentSpan = styled.span`
  margin-left: -3px;
`;

const ReplyWrapper = styled.div`
  align-self: center;
  /* I don't know why but this centers reply in relation to the LikeButton. */
  /* If it gets out of alignment, let this style be the first to go. */
  line-height: 110%;
`;

const ReplySpan = styled.span`
  color: var(--disabled-button-text) !important;
  /* Small Body Text (Default) */
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
`;

const CommentText = styled.div`
  p {
    color: var(--color-cream-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media ${device.tablet}, ${device.desktop} {
    /* The avatar sets it a bit off the container, so we match it */
    padding-left: 2px;
  }
`;

const CommentActions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  margin-top: 10px;

  @media ${device.tablet}, ${device.desktop} {
    flex-direction: row-reverse;
    justify-content: start;
    padding-left: 4px;
    margin-top: 12px;
  }
`;

const LargeScreenUsernameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const DatetimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
`;
