import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row, notification } from "antd";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { suspendUser } from "../../helpers/report";
import CustomSheet from "../CustomSheet";
import userModel from "../../lib/firebase/userModel";

export default function SuspendUserSheet({ isOpen, setOpen, user }) {
  const [api, contextHolder] = notification.useNotification();
  const [isSending, setIsSending] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [expiresAtTimestamp, setExpiresAtTimestamp] = useState(null);

  const openNotification = () => {
    const key = `open${Date.now()}`;
    api.open({
      description: (
        <Row>
          <Col span={22}>
            <Message>
              You have suspended @{userInfo && userInfo.username}
            </Message>
          </Col>
          <Col span={2}></Col>
        </Row>
      ),
      key,
      duration: 10,
      className: "admin-notification",
      closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
    });
  };

  useEffect(() => {
    async function fetchData() {
      const userInfo = await userModel.getOneById(user);
      setUserInfo(userInfo);
    }
    if (user) fetchData();
  }, [user]);

  const startSendingReport = async () => {
    setIsSending(true);
    const result = await suspendUser({
      userId: user,
      suspendUntilDate: expiresAtTimestamp,
    });
    if (result) {
      openNotification();
      setOpen(false);
    }
    setIsSending(false);
  };

  useEffect(() => {
    if (isOpen) {
      setIsSending(false);
      const a = new Date();
      a.setDate(a.getDate() + 1);
      setExpiresAtTimestamp(a);
    }
  }, [isOpen]);

  return (
    <>
      {contextHolder}
      <CustomSheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[425]}
        tweenConfig={{ ease: "easeOut", duration: 0.3 }}
      >
        <InnerContainer>
          <ProfileImage src={userInfo && userInfo.pfp} alt="" />
          <Header>Suspend {userInfo && userInfo.username}?</Header>
          <DateTimeHeader>Suspension expires at:</DateTimeHeader>
          <DateTimePicker
            onChange={setExpiresAtTimestamp}
            value={expiresAtTimestamp}
            className="datetime-picker"
            clearIcon={null}
            calendarIcon={null}
            disableClock={true}
            disableCalendar={true}
          />
          <Content>
            Suspending {userInfo && userInfo.username} will prevent them from
            logging in until the expiration date.
          </Content>
          {!isSending ? (
            <SendReportButton onClick={async () => await startSendingReport()}>
              Suspend
            </SendReportButton>
          ) : (
            <SendReportButton disabled>
              <Spinner alt="" src="/icons/loading-spheres.svg" />
            </SendReportButton>
          )}
        </InnerContainer>
      </CustomSheet>
    </>
  );
}

const Header = styled.div`
  color: var(--color-cream-base);
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 14px;
`;

const Content = styled.div`
  color: var(--color-gray-50);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  margin-top: 12px;
`;

const InnerContainer = styled.div`
  width: 85%;
  margin: 0px auto;
  text-align: center;
`;

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
  top: 10px;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const SendReportButton = styled.button`
  width: 100%;
  height: 50px;
  background: var(--blurple);
  border: 0;
  border-radius: 90px;
  margin-top: 20px;
  cursor: pointer;
  line-height: 16px;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: stretch;
  color: var(--color-cream-base);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;

const DateTimeHeader = styled.div`
  color: var(--color-cream-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 14px;
  margin-bottom: 6px;
`;

const ProfileImage = styled.img`
  height: 82px;
  width: 82px;
  text-align: center;
  margin-top: 14px;
  border-radius: 82px;
  border: 2px solid #252525;
`;

const Message = styled.span`
  color: var(--color-red);
`;
