import { useContext } from "react";
import styled from "styled-components";
import { ScrollOffscreenContext } from "../context/ScrollOffscreenProvider";

const OffscreenTrigger = () => {
  const { ref } = useContext(ScrollOffscreenContext);
  const enabledOnPage = window.location.href.includes("episode");

  // I don't want the dead space below the footer on pages where we aren't using this feature.
  // May be a better way to account for that in the future.
  if (!enabledOnPage) {
    return null;
  }

  return <ViewTrigger ref={ref} />;
};

const ViewTrigger = styled.div`
  width: 100%;
`;

export default OffscreenTrigger;
