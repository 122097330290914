import React from "react";
import { Col, Row } from "antd";
import parse from "html-react-parser";
import { styled } from "styled-components";

export default function QuestDisplayInfo({
  quest,
  submitText,
  countdownTimer,
  countdownClass,
  userActionEl,
  pollCompleted,
  rowColumns,
  myRef,
}) {
  return (
    <Container>
      <ObjectiveContainer>
        <InfoSubheader>Quest Objective</InfoSubheader>
        <ObjectiveText>{parse(quest.objectiveDescription)}</ObjectiveText>
      </ObjectiveContainer>

      {!pollCompleted && (
        <div className="mobile-quest-cta list-inner" ref={myRef}>
          {rowColumns === 1 ? (
            <Row className="mobile-submission-cta-row">
              <Col span={24} className="mobile-submission-cta-col">
                <h3
                  className={`submit-countdown ${countdownClass}`}
                  style={{
                    marginTop: 10,
                    fontWeight: "bold",
                  }}
                >
                  {submitText}
                </h3>
              </Col>
            </Row>
          ) : (
            <Row className="info-row">
              <Col span={12} className="timer-col">
                {submitText}
                {countdownTimer}
              </Col>
              <Col span={12} className="action-col">
                {userActionEl}
              </Col>
            </Row>
          )}
        </div>
      )}

      <QuestProgressSection>
        <InfoSubheader>Quest in Progress</InfoSubheader>
        <ProgressText>{parse(quest.description)}</ProgressText>
      </QuestProgressSection>
    </Container>
  );
}

const Container = styled.div`
  .info-row {
    padding: 0px 12px;
    height: 100%;
  }

  .timer-col {
    margin: auto;
    text-align: center;
    margin-left: 0px;
    margin-top: 17px;
  }

  .action-col {
    font-size: 13px !important;
    margin: auto;
    max-width: 140px;
    margin-left: 0px;
    margin-top: -8px;
  }

  .mobile-submission-cta-row {
    padding: 0px 24px;
    height: 70px;
  }

  .mobile-submission-cta-col {
    margin: auto !important;
    text-align: center;
    height: 100%;
    padding-top: 16px;
    margin-left: 0px;
  }
`;

const ObjectiveContainer = styled.div`
  margin-top: 36px;
`;

const InfoSubheader = styled.h5`
  margin-bottom: 10px;
  color: var(--color-gray-50);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;

  p {
    color: var(--Cream, #fef6f3);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */
  }
`;

const QuestProgressSection = styled.div`
  margin-top: 44px;
`;

const ObjectiveText = styled.div`
  color: var(--color-cream);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`;

const ProgressText = styled.div`
  color: var(--color-cream);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  img {
    width: 100%;
  }
`;
