import { useEffect, useRef, useState, useMemo, useContext } from "react";
import { styled } from "styled-components";
import { useQuery } from "@tanstack/react-query";
import ReactPlayer from "react-player";
import { Button } from "antd";
import parse from "html-react-parser";
import { getQuestAndPollStates, isOniOS } from "../../helpers/general";
import moment from "moment";
import {
  defaultArtQuestFull,
  defaultVoteFull,
  defaultWritingQuestFull,
} from "../../helpers/constants";
import QuestCta from "./QuestCta";
import { valueByQuestType } from "../quests/QuestFilter";
import { storyworldKeys } from "../../lib/queryKeys";
import { trackEvent } from "../../helpers/mixpanel";
import ListOfCreators from "../general/ListOfCreators";
import storyworldModel from "../../lib/firebase/storyworldModel";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { Link } from "react-router-dom";
import { Spacer } from "../../styles/CommonElements";
import { questDetailUrl } from "../../lib/routes";

export default function QuestHeader({ quest }) {
  const { addBackLocation } = useContext(BackLocationsContext);

  const videoPlayerRef = useRef(null);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [playedOnce, setPlayedOnce] = useState(false);

  const { data: storyworld } = useQuery({
    queryKey: storyworldKeys.storyworld(quest.storyworld),
    queryFn: async () => {
      return await storyworldModel.getOneById(quest.storyworld);
    },
  });

  const { quest: questState, poll: pollState } = useMemo(() => {
    return getQuestAndPollStates(quest);
  }, [quest]);

  useEffect(() => {
    if (questState.isOpen) {
      setEndTime(quest.endTimestamp.toDate());
    } else if (pollState?.isOpen && pollState?.confirmed) {
      setEndTime(quest.poll.endTimestamp.toDate());
    }
  }, [pollState?.confirmed, pollState?.isOpen, quest, questState.isOpen]);

  const heroImageUrl = useMemo(() => {
    const res = getQuestAndPollStates(quest);

    if (quest.thumbnailUrl && quest.thumbnailUrl !== "") {
      return quest.thumbnailUrl;
    }

    if (res.poll && (res.poll.isCompleted || res.poll.isOpen)) {
      return defaultVoteFull;
    }

    return valueByQuestType(
      quest,
      {
        writing: defaultWritingQuestFull,
        visual: defaultArtQuestFull,
      },
      // TODO: remove after ai is gone
      defaultArtQuestFull
    );
  }, [quest]);

  return (
    <Container>
      <QuestText>
        <StoryworldTitle
          to={`/stories/${quest.storyworld}`}
          onClick={() => {
            addBackLocation(questDetailUrl(quest.storyworld, quest.id));
          }}
        >
          {storyworld.title}
        </StoryworldTitle>
        <Spacer $marginTop="0.5rem" />
        <QuestTitle>{quest.title}</QuestTitle>
        <QuestSubtitle>
          by <ListOfCreators ids={storyworld && storyworld.creators} /> •{" "}
          <WatchIcon src="/icons/quests/clock.svg" alt="time icon" />{" "}
          <Time>
            {(questState.isCompleted && !pollState?.confirmed) ||
            pollState?.isCompleted
              ? `${
                  quest.poll && pollState?.confirmed && pollState?.isCompleted
                    ? "Ended"
                    : "Closed"
                } on ${moment(
                  quest.poll
                    ? quest.poll.endTimestamp.toDate()
                    : quest.endTimestamp.toDate()
                ).format("MM/DD/YY")}`
              : `${moment(endTime).fromNow(true)} left`}
          </Time>
        </QuestSubtitle>
      </QuestText>

      <VideoContainer>
        {quest.videoUrl ? (
          <>
            <ReactPlayer
              ref={videoPlayerRef}
              className="quest-video-player"
              controls={isVideoPlaying}
              url={quest.videoUrl}
              playing={isVideoPlaying}
            />

            {!isVideoPlaying && isOniOS() && !playedOnce && (
              <ThumbnailOverlay src={heroImageUrl} alt="" />
            )}

            {!isVideoPlaying && (
              <PlayButton
                onClick={() => {
                  setIsVideoPlaying(true);
                  setPlayedOnce(true);
                  trackEvent("Clicked Quest Video Play Button", {
                    quest_id: quest.id,
                    quest_name: quest.title,
                    storyworld_id: quest.storyworld,
                    // storyworld_name: storyworldQuery.data.title,
                    // title: `${storyworldQuery.data.title}: ${quest.title}`,
                  });
                }}
              >
                <img src="/icons/play-icon.svg" alt="" />
              </PlayButton>
            )}
          </>
        ) : (
          <Thumbnail src={heroImageUrl} alt="" />
        )}
      </VideoContainer>

      {quest.description && quest.description !== "" && (
        <Description>{parse(quest.description)}</Description>
      )}

      <QuestCta quest={quest} />
    </Container>
  );
}

const Container = styled.div`
  display: block;
  width: 90%;
  margin: auto;
`;

const StoryworldTitle = styled(Link)`
  color: var(--color-pink);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
`;

const QuestTitle = styled.div`
  color: var(--color-cream-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 28.56px;
  margin-bottom: 5px;
`;

const QuestText = styled.div`
  text-align: center;
  color: var(--color-pink);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 18px;
`;

export const QuestSubtitle = styled.span`
  color: var(--disabled-button-text);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const VideoContainer = styled.span`
  display: block;
  position: relative;

  .quest-video-player {
    border: 1px solid var(--border-light);
    background: black;
    height: 189px !important;
  }
`;

const Thumbnail = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--border-light);
  height: 189px;
  max-height: 189px;
  object-fit: cover;
  object-position: center;
`;

const ThumbnailOverlay = styled(Thumbnail)`
  position: absolute;
  top: 0;
`;

const Description = styled.div`
  color: var(--color-cream-base);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: 18px;

  img {
    width: 100%;
  }
`;

const WatchIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-bottom: 2px;
`;

const Time = styled.span`
  color: var(--color-pink);
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`;

const PlayButton = styled(Button)`
  position: absolute;
  display: block;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  height: auto;
  width: auto;
  border: 0px;
  z-index: 100;

  &:active {
    background: none !important;
  }

  &:hover {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;
