import React, { useContext, useState } from "react";
import styled from "styled-components";
import { InputArea, PaddedContainer, Spacer } from "../styles/CommonElements";
import SearchImages from "../components/SearchImages";
import UploadProvider from "../components/UploadProvider";
import { SUBMISSION_TYPES, maxIdeaBlockEntries } from "../helpers/constants";
import Backlink from "../components/Backlink";
import { useNavigate } from "react-router-dom";
import InputField from "../components/InputField";
import IdeaBlock from "../components/IdeaBlock";
import CharacterCounter from "../components/CharacterCounter";
import { Disclaimer } from "../components/general";
import Button from "./../components/Button.jsx";
import { BackLocationsContext } from "../context/BackLocationsProvider.js";
import parse from "html-react-parser";
import { questDetailUrl } from "../lib/routes.js";
import { trackEvent } from "../helpers/mixpanel.js";

const states = {
  START: "start",
  SEARCH: "search",
  FINALIZE: "finalize",
};

const maxTitleLength = 45;

const magGlassIcon = "/icons/search/pink-search.svg";
const imageIcon = "/icons/search/file-upload.svg";
const arrowIcon = "/images/about/arrow.svg";

export default function IdeaBlockGenerator({ questData, storyworld, submit }) {
  const navigate = useNavigate();
  const { backLocations, goBack } = useContext(BackLocationsContext);

  const [state, setState] = useState(states.START);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [ideaBlock, setIdeaBlock] = useState({
    images: [],
    text,
    title,
    type: "idea",
  });

  const addEntry = (newImage) => {
    const newImages = [...ideaBlock.images];
    newImages.push(newImage);
    const newIdeaBlock = { ...ideaBlock };
    newIdeaBlock.images = newImages;
    setIdeaBlock(newIdeaBlock);
  };

  const removeEntry = (removeThis) => {
    const newImages = ideaBlock.images.filter((img) => img !== removeThis);
    const newIdeaBlock = { ...ideaBlock };
    newIdeaBlock.images = newImages;
    setIdeaBlock(newIdeaBlock);
  };

  const cancelSubmission = () => {
    goBack();
  };

  const goToFinalize = () => {
    setState(states.FINALIZE);
  };

  const startSubmitting = () => {
    submit(SUBMISSION_TYPES.IDEA, {
      images: ideaBlock.images,
      storyworld,
      text,
      title,
    });
  };

  const includesText = questData?.submissionRequirements.includes("text");

  return (
    <Container>
      <PaddedContainer>
        <Backlink
          light={true}
          onClick={() => {
            if (state === states.START) {
              if (backLocations.length > 0) {
                goBack();
              } else {
                navigate(questDetailUrl(storyworld, questData.id));
              }
            } else if (state === states.SEARCH || state === states.FINALIZE) {
              setState(states.START);
            }
          }}
        />

        <Spacer $marginTop="10px" />
        <Header>{parse(questData.objectiveDescription)}</Header>
        <Spacer $marginTop="0.7rem" />

        <Subtitle>
          {state === states.START &&
            "Create an idea block with 3 images that show the overall look and feel of your idea"}
          {state === states.SEARCH && "Choose 3 images that capture your idea"}
          {state === states.FINALIZE &&
            `Give your idea block a title ${
              includesText ? "and description" : ""
            }`}
        </Subtitle>
      </PaddedContainer>

      <Spacer $marginTop="1.25rem" />

      {state === states.START && (
        <PaddedContainer>
          <Spacer $marginTop="2rem" />

          {ideaBlock?.images?.length >= maxIdeaBlockEntries ? (
            <SectionButton
              $filled={true}
              onClick={() => {
                setState(states.FINALIZE);
              }}
            >
              Continue
              <Icon src={arrowIcon} alt="" />
            </SectionButton>
          ) : (
            <>
              <SectionButton
                $filled={true}
                onClick={() => {
                  window.scroll(0, 0);
                  setState(states.SEARCH);
                  trackEvent("Clicked First Idea Block Action", {
                    cta: "search",
                  });
                }}
              >
                <Icon src={magGlassIcon} alt="" />
                Search for Images
              </SectionButton>
              <Spacer $marginTop="1rem" />
              <UploadProvider onSuccess={addEntry} fileBucket="submissions">
                <SectionButton
                  onClick={() => {
                    trackEvent("Completed First Idea Block Action", {
                      cta: "upload",
                    });
                  }}
                >
                  <Icon src={imageIcon} alt="" />
                  Upload Your Own
                </SectionButton>
              </UploadProvider>
            </>
          )}
        </PaddedContainer>
      )}

      {state === states.SEARCH && (
        <SearchImages
          addEntry={addEntry}
          entries={ideaBlock?.images}
          goToNextStep={goToFinalize}
          removeEntry={removeEntry}
          suggestedSearchTerms={questData?.suggestedSearchTerms ?? []}
        />
      )}

      {state === states.FINALIZE && (
        <PaddedContainer>
          <IdeaBlock idea={ideaBlock} size="large" hideTitle />
          <Spacer $marginTop="1.5rem" />
          <InputField
            placeholder="Title your idea"
            maxLength={maxTitleLength}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
          <Spacer $marginTop="0.4rem" />
          <CharacterCounter count={title.length} max={maxTitleLength} />

          {includesText && (
            <>
              <Spacer $marginTop="1rem" />
              <InputArea
                className="transition-colors"
                maxLength={questData?.textSubmissionLimit}
                onChange={(e) => setText(e.target.value)}
                placeholder="Fill in the details..."
                value={text}
              />
              <CharacterCounter
                count={text.length}
                max={questData?.textSubmissionLimit}
              />
            </>
          )}

          <Spacer $marginTop="1.5rem" />
          <Button
            size="large"
            onClick={() => startSubmitting()}
            disabled={title.length === 0}
          >
            {title.length === 0 && "Add Details to Continue"}
            {title.length > 0 && "Complete Quest"}
          </Button>
          <Spacer $marginTop="0.75rem" />
          <Button
            size="large"
            variant="ghost"
            onClick={() => cancelSubmission()}
          >
            Cancel
          </Button>
          <Disclaimer text="Your idea block won't be saved" />
          <Spacer $marginTop="3rem" />
        </PaddedContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  padding-top: 16px;
`;

const Header = styled.div`
  color: var(--color-cream);
  font-size: 24px;
  font-weight: 600;
  line-height: normal;

  p {
    margin-bottom: 0px !important;
  }
`;

const IdeaBlockContainer = styled.div`
  width: 95%;
  margin: auto;
`;

const Icon = styled.img`
  border-radius: 8px;
  border: 1px solid var(--borders-light);
  filter: brightness(0) invert(1);
`;

const SectionButton = styled.div`
  text-align: center;
  color: var(--color-cream);
  line-height: 160%;
  letter-spacing: 0.2px;
  border-radius: 8px;
  margin: auto;
  opacity: ${(props) => props.$disabled && "0.3"};
  background: ${(props) =>
    props.$filled ? "var(--color-blurple)" : "transparent"};
  display: flex;
  height: 45px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 16px;
  font-weight: 500;
  flex-shrink: 0;
  border: ${(props) => (props.$filled ? "none" : "1.5px solid #777e91")};
`;

const Subtitle = styled.div`
  color: var(--color-gray-50);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;
