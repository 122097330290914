import styled, { css } from "styled-components";
import Spinner from "./Spinner";

const Button = ({
  size = "medium",
  variant = "primary",
  rounded = "round",
  isLoading = false,
  disabled = false,
  as = "button",
  children,
  width = "100%",
  isActive,
  ...rest
}) => {
  const content = isLoading ? (
    <Loading>
      <div>{children}</div>
      <Spinner />
    </Loading>
  ) : (
    children
  );

  return (
    <Container
      as={as}
      $size={size}
      $variant={variant}
      $rounded={rounded}
      $isDisabled={disabled || isLoading}
      $isLoading={isLoading}
      $isActive={isActive}
      disabled={disabled || isLoading}
      $width={width}
      {...rest}
    >
      {content}
    </Container>
  );
};

const Container = styled.button`
  position: relative;
  width: ${(props) => props.$width};
  font-family: "Arial";
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px;

  /* Moving away from flex and fixed heights to padding is probably the preferred move */
  /* Until then, I will set the height to match the width if fully rounded */
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $size }) => {
    switch ($size) {
      case "large":
        return css`
          height: 50px;
          font-size: 18px;
          font-weight: 700;
        `;
      case "medium":
        return css`
          /* padding: 0.9rem 0; */
          height: 46px;
        `;
      case "medium-small":
        return css`
          /* padding: 0.9rem 0; */
          height: 40px;
          font-size: 0.875rem;
        `;
      case "small":
        return css`
          /* padding: 0.625rem 0; */
          height: 36px;
          font-size: 0.875rem;
        `;
    }
  }}

  ${({ $rounded }) => {
    switch ($rounded) {
      case "circle":
        return css`
          border-radius: 9999px;
          aspect-ratio: 1 / 1;
        `;
      case "round":
        return css`
          border-radius: 9999px;
        `;
      case "slight":
        return css`
          border-radius: 8px;
        `;
      case "none":
        return css`
          border-radius: 0px;
        `;
    }
  }}

  ${({ $variant, $isActive }) => {
    switch ($variant) {
      case "danger":
        return css`
          background-color: var(--color-red-error);
          color: var(--color-cream-base);
          border: none;
        `;
      case "ghost":
        return css`
          --active-color: var(--color-highlight);
          border: 1.5px solid;
          color: ${$isActive
            ? "var(--active-color)"
            : "var(--color-cream-base)"};
          border-color: ${$isActive ? "var(--active-color)" : "#777e91"};
          background-color: ${$isActive
            ? "rgba(119, 126, 145, 0.35)"
            : "transparent"};
        `;
      case "white":
        return css`
          --active-color: var(--color-highlight);
          border: 0px;
          color: ${$isActive ? "var(--active-color)" : "black"};
          background-color: white;
        `;
      case "primary":
        return css`
          background-color: var(--color-gray-200);
          color: var(--color-cream-base);
          border: none;
        `;
      case "publish":
        return css`
          background-color: var(--color-purple);
          color: var(--color-cream-base);
          border: none;
        `;
      case "text":
        return css`
          gap: 4px;
          font-weight: 400;
          background-color: transparent;
          color: inherit;
          border: none;
        `;
    }
  }}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      background-color: var(--color-gray-300);
      color: var(--disabled-button-text);
      cursor: not-allowed;
      opacity: 0.5;
    `}

    ${({ $isLoading }) =>
    $isLoading &&
    css`
      cursor: wait;
    `}
`;

const Loading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* Prevent the spinner div which is bigger than the icon itself from pushing stuff around the button */
  overflow: hidden;

  :first-child {
    visibility: hidden;
  }
  :last-child {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default Button;
