import React, { useContext, useEffect, useState } from "react";
import { Progress } from "antd";
import styled from "styled-components";
import { ScrollOffscreenContext } from "../context/ScrollOffscreenProvider";
import { Spacer } from "../styles/CommonElements";

const downArrow = "/icons/down-arrow.svg";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default function KeepScrollingSection({ loadNextEpisode = null }) {
  const [percentUntilTrigger, setPercentUntilTrigger] = useState(0);
  const { inView } = useContext(ScrollOffscreenContext);
  // To prevent a bit of toggling on the threshold of "in view"
  const debouncedInView = useDebounce(inView, 500);

  useEffect(() => {
    let increasingInterval;
    let decreasingInterval;

    if (debouncedInView) {
      clearInterval(decreasingInterval);
      increasingInterval = setInterval(() => {
        if (percentUntilTrigger < 100) {
          setPercentUntilTrigger(percentUntilTrigger + 1);
        }
      }, 40);
    } else {
      clearInterval(increasingInterval);
      decreasingInterval = setInterval(() => {
        if (percentUntilTrigger > 0) {
          setPercentUntilTrigger(percentUntilTrigger - 1);
        }
      }, 10);
    }

    if (percentUntilTrigger === 100) {
      loadNextEpisode();
      // Seemed to be needed to prevent additional loadNextEpisode calls
      setPercentUntilTrigger(0);
    }

    return () => {
      clearInterval(increasingInterval);
      clearInterval(decreasingInterval);
    };
  }, [debouncedInView, loadNextEpisode, percentUntilTrigger]);

  return (
    loadNextEpisode !== null && (
      <Container>
        <h4>Keep scrolling for next episode</h4>
        <Spacer $marginTop="20px" />
        <ProgressWrapper>
          <img src={downArrow} alt="" />
          <Progress
            type="circle"
            percent={percentUntilTrigger}
            size={60}
            format={() => ""}
            strokeColor="#505dff"
          />
        </ProgressWrapper>
        <Spacer $marginTop="20px" />
      </Container>
    )
  );
}

const Container = styled.div`
  padding: 48px 0px 48px 0px;
  overflow-y: scroll;
`;

const ProgressWrapper = styled.div`
  position: relative;
  width: max-content;
  margin: auto;

  & > img {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;
