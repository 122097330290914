import React from "react";
import { styled } from "styled-components";
import { Spinner } from "../../styles/CommonElements";

export default function EmptyQuests({ isLoading }) {
  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <Icon src="/images/quests/sword-gray.svg" alt="" />
      <Header>No quests open</Header>
      <Subheader>Check back later!</Subheader>
    </Container>
  );
}

const Container = styled.div`
  margin: auto;
  margin-top: 14px;
  padding: 18px;
  text-align: center;
`;

const Icon = styled.img`
  height: 127px;
  width: auto;
  text-align: center;
  margin-top: 20px;
`;

const Header = styled.div`
  color: var(--color-cream-dark);
  font-family: var(--font-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 14px;
`;

const Subheader = styled.div`
  color: var(--disabled-button-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-top: 8px;
  margin-bottom: 14px;
`;
