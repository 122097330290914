import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const IdeaRow = ({ idea }) => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (imagesLoaded === 3) {
      setLoading(false);
    }
  }, [imagesLoaded]);

  const handleLoad = () => {
    setImagesLoaded((prev) => prev + 1);
  };

  return (
    <Container>
      {idea.images.map((image) => (
        <BlockImage key={image} $isLoading={isLoading}>
          <img src={image} onLoad={handleLoad} />
        </BlockImage>
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const widthVal = "22px";

const BlockImage = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.8s ease;
    opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  }

  &:nth-of-type(1) {
    border-bottom-left-radius: min(20%, ${widthVal});
    border-top-left-radius: min(20%, ${widthVal});
  }

  &:nth-of-type(3) {
    border-top-right-radius: min(20%, ${widthVal});
    border-bottom-right-radius: min(20%, ${widthVal});
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      background-color: var(--color-gray-200);
      animation: pulse 1.5s infinite;
    `}
`;

export default IdeaRow;
