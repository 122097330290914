import React, { useEffect, useState, useContext } from "react";
import QuestDisplay from "../views/QuestDisplay";
import { getStoryworldById } from "../helpers/firebase";
import PollVote from "./PollVote";
import { styled, css } from "styled-components";
import questModel from "../lib/firebase/questModel";
import pollModel from "../lib/firebase/pollModel";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { useParams } from "react-router-dom";

export default function StoryworldQuest() {
  const { storyId, questId } = useParams();
  const [openQuests, setOpenQuests] = useState([]);
  const [hasSeenModal, setHasSeenModal] = useState(true);
  const [showBackground, setShowBackground] = useState(false);
  const [selectedVote, setSelectedVote] = useState(null);

  const { selectedStoryworld, setSelectedStoryworld } = useContext(
    SelectedStoryworldContext
  );

  useEffect(() => {
    async function fetchData() {
      const storyworldPromise = await getStoryworldById(storyId);
      setSelectedStoryworld(storyworldPromise);
    }
    if (!storyId && !selectedStoryworld) {
      fetchData();
    }
  }, [storyId, selectedStoryworld, setSelectedStoryworld]);

  useEffect(() => {
    async function fetchData() {
      const questInfo = await questModel.getOneById(questId);
      const pollInfo = await pollModel.getMany(
        ["limit", 1],
        ["isDraft", "==", false],
        ["questId", "==", questInfo.id]
      );
      if (pollInfo.length > 0) questInfo.poll = pollInfo[0];
      setOpenQuests([questInfo]);
      const storyworldPromise = await getStoryworldById(storyId);
      setSelectedStoryworld(storyworldPromise);
    }
    if (storyId) fetchData();
  }, [storyId, questId, setSelectedStoryworld, selectedVote]);

  return (
    <Container $showBackground={showBackground}>
      {!selectedVote ? (
        openQuests.map((quest) => (
          <QuestDisplay
            quest={quest}
            storyworld={selectedStoryworld}
            setSelectedVote={setSelectedVote}
            hasSeenModal={hasSeenModal}
            setHasSeenModal={setHasSeenModal}
            setShowBackground={setShowBackground}
            key={quest.id}
          />
        ))
      ) : (
        <PollVote />
      )}
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  width: 100%;
  margin: auto;
  margin-top: 0px;

  ${(props) => props.$showBackground && showBackgroundCss};

  .ant-image {
    width: 100%;
  }
`;

const showBackgroundCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background: url("/images/gen-ai/bg-pattern.svg");
  background-color: var(--color-gray-900);
  background-repeat: repeat;
  background-position: bottom;
`;
