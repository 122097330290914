import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { getQuestSubmissions } from "../../helpers/firebase";
import { shuffleArray } from "../../helpers/general";
import styled from "styled-components";
import parse from "html-react-parser";
import pluralize from "pluralize";
import VoteGrid from "./VoteGrid";
import VoteBanner from "./VoteBanner";
import { useNavigate } from "react-router-dom";
import QuestFilter, { getQuestType } from "../quests/QuestFilter";
import { QUEST_TYPES } from "../../helpers/constants";
import { voteKeys } from "../../lib/queryKeys";
import voteModel from "../../lib/firebase/voteModel";
import { getAuth } from "firebase/auth";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import Backlink from "../Backlink";
import { questDetailUrl } from "../../lib/routes";

export default function Vote({ quest, storyworld }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { backLocations, goBack } = useContext(BackLocationsContext);

  const backToQuestLink = questDetailUrl(quest.storyworld, quest.id);

  const [votes, setVotes] = useState([]);
  const [questSubmissions, setQuestSubmissions] = useState([]);

  const { mutate: submit, isPending } = useMutation({
    mutationFn: () => {
      const voteIds = votes.map((vote) => vote.id);
      return voteModel.create({
        quest,
        voteIds,
        storyworld,
      });
    },
    onSuccess: () => {
      const user = getAuth().currentUser;
      queryClient.invalidateQueries(
        {
          queryKey: voteKeys.poll_user_count(quest.poll?.id, user?.uid),
        },
        {
          queryKey: voteKeys.quest_user_vote(quest.id, user?.uid),
        }
      );
      navigate(backToQuestLink);
    },
  });

  useEffect(() => {
    if (!quest) return;

    async function fetchData() {
      const userQuestsPromise = await getQuestSubmissions(quest);
      const results = [];
      userQuestsPromise.forEach((q) => {
        if (!q.rejected) results.push(q);
      });
      setQuestSubmissions(shuffleArray(results));
    }

    // TODO: replace fetchData with useQuery
    fetchData();
  }, [quest]);

  const addToVotes = (submission) => {
    if (votes.length < quest.poll.votesPerUser) {
      setVotes([...votes, submission]);
    }
    // For convenience, switch vote on tap when only one is allowed
    else if (votes.length === 1 && quest.poll.votesPerUser === 1) {
      setVotes([submission]);
    }
  };

  const onBackClick = () => {
    if (backLocations.length > 0) {
      goBack();
    } else {
      navigate(backToQuestLink);
    }
  };

  const removeVote = (submission) => {
    setVotes(votes.filter((v) => v.id !== submission.id));
  };

  const questType = getQuestType(quest);

  return (
    <Container>
      <BackLinkWrapper>
        <Backlink onClick={onBackClick} />
      </BackLinkWrapper>
      <Header>
        <Title>{parse(quest && quest.poll && quest.poll.title)}</Title>
        <QuestFilter
          quest={quest}
          visual={
            <Subheader>
              <TapText>Tap</TapText> once to view more.{" "}
              <TapText>Double tap</TapText> to vote. You can vote for{" "}
              {quest.poll.votesPerUser > 1 ? "up to" : ""}{" "}
              {pluralize("idea", quest.poll.votesPerUser, true)}
            </Subheader>
          }
          fallback={
            <Subheader>
              <TapText>Tap</TapText> to select. Vote for{" "}
              {quest.poll.votesPerUser > 1 ? "up to" : ""}{" "}
              {pluralize("submission", quest.poll.votesPerUser, true)}
            </Subheader>
          }
        />
      </Header>
      <SubmissionsContainer>
        <VoteGrid
          questSubmissions={questSubmissions}
          addToVotes={addToVotes}
          removeVote={removeVote}
          votes={votes}
          isWritingQuest={questType === QUEST_TYPES.WRITING}
        />
      </SubmissionsContainer>

      <VoteBanner
        handleSubmit={submit}
        isSubmitting={isPending}
        isDisabled={votes.length < 1}
      />
    </Container>
  );
}

const Container = styled.span`
  display: block;
  margin: 20px auto 60px auto;
  width: 90%;
  text-align: left;
`;

const Title = styled.div`
  color: #fef6f3;
  text-align: left;
  font-family: var(--font-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 14px;

  p {
    margin-bottom: 14px;
  }
`;

const Header = styled.div`
  margin: auto !important;
  margin-top: 30px;
  margin-bottom: 20px !important;
`;

const Subheader = styled.div`
  color: var(--disabled-button-text);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`;

const TapText = styled.em`
  color: var(--color-pink);
  font-weight: 700;
`;

const BackLinkWrapper = styled.div`
  color: var(--color-lavender);
  margin-bottom: 18px;

  &:hover {
    color: white;
  }
`;

const SubmissionsContainer = styled.div`
  margin-top: 20px;
  display: block;
`;
