import React from "react";
import { Col, Image, Row } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import {
  downloadImage,
  isPollCompleted,
  isTimePassed,
} from "../../helpers/general";
import {
  getQuestSubmissions,
  getQuestVoters,
  getWinningQuestSubmissions,
} from "../../helpers/firebase";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import { OpenCountdown } from "../general";
import userModel from "../../lib/firebase/userModel";
import styled from "styled-components";
import {
  SmallAvatar,
  AdminWinner,
  QuestDisplayText,
  Spacer,
} from "../../styles/CommonElements";
import SubmissionFilter from "../submissions/SubmissionFilter";
import IdeaRow from "../IdeaRow";
import Button from "../Button";

const bookIcon = "../../icons/book.svg";

export default function AdminQuestDisplayColumns({
  quest,
  setSelectedPoll,
  setIsNewPoll,
  setQuestInfo,
  setSelectedEpisode,
}) {
  const [submissionAmount, setSubmissionAmount] = useState(0);
  const [voterAmount, setVoterAmount] = useState(0);
  const [winningSubmission, setWinningSubmission] = useState(null);
  const [winningUserInfo, setWinningUserInfo] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const userDatePromise = await userModel.getOneById(
        winningSubmission.creator
      );
      setWinningUserInfo(userDatePromise);
    }

    if (winningSubmission !== null) fetchData();
  }, [winningSubmission]);

  useEffect(() => {
    async function fetchData() {
      const questSubmissionPromise = await getQuestSubmissions(quest);
      setSubmissionAmount(questSubmissionPromise.length);
      if (quest.poll) {
        const questVoterPromise = await getQuestVoters(quest.poll);
        setVoterAmount(questVoterPromise.length);
      }
      if (quest.poll && isPollCompleted(quest.poll)) {
        const winningQuestSubmissionsPromise =
          await getWinningQuestSubmissions(quest);
        if (winningQuestSubmissionsPromise.length > 0) {
          setWinningSubmission(winningQuestSubmissionsPromise[0]);
        } else {
          setWinningSubmission(null);
        }
      }
    }

    fetchData();
  }, [quest, winningUserInfo, winningSubmission, setSelectedEpisode]);

  return (
    <StyledRow gutter={12}>
      <StyledColumn span={12}>
        {!quest.poll ? (
          /* there is no poll */
          <SubmissionText>
            {`${pluralize("Submission", submissionAmount, true)}`}
          </SubmissionText>
        ) : quest.poll && !isTimePassed(quest.poll.startTimestamp.toDate()) ? (
          /* quest has been created and it's before the start time: show Not Started thing and review button */
          <NotStarted>
            <NotStartedText>Not Started</NotStartedText>
          </NotStarted>
        ) : quest.poll &&
          isTimePassed(quest.poll.startTimestamp.toDate()) &&
          !isTimePassed(quest.poll.endTimestamp.toDate()) &&
          !quest.poll.isDraft ? (
          /* quest has been created and started: show submissions, voters (if any) and countdown timer until it ends and the review button */
          <>
            <SubmissionText>
              {`${pluralize("Submission", submissionAmount, true)}`}
            </SubmissionText>
            <QuestDisplayText style={{ textAlign: "center" }}>
              {`${pluralize("Voter", voterAmount, true)}`}
            </QuestDisplayText>
          </>
        ) : quest.poll && isPollCompleted(quest.poll) && winningSubmission ? (
          /* poll has ended and there is a winner */
          <>
            <WinningSubmissionContainer>
              <SubmissionFilter
                submission={winningSubmission}
                genAiArt={
                  <Image
                    className="admin-quest-display-poll-image"
                    src={winningSubmission ? winningSubmission.imageUrl : null}
                    alt=""
                    preview={false}
                    style={{ pointerEvents: "none" }}
                  />
                }
                idea={<IdeaRow idea={winningSubmission} />}
              />
              <WinningSubmissionPreview>
                <Row className="inner-content">
                  <SubmissionFilter
                    submission={winningSubmission}
                    idea={
                      <Col span={12} className="winning-submission-col">
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const [image1, image2, image3] =
                              winningSubmission.images;

                            // This will not work if disallowed by CORS
                            downloadImage(image1);
                            downloadImage(image2);
                            downloadImage(image3);
                          }}
                          style={{
                            marginLeft: !quest.episode ? "15%" : "0%",
                          }}
                        >
                          <Image
                            preview={false}
                            style={{
                              fontSize: 30,
                              margin: "auto",
                            }}
                            src="../../icons/download.svg"
                          />
                        </div>
                      </Col>
                    }
                  />
                  {!quest.episode && (
                    <Col span={12} className="winning-submission-col">
                      <Link
                        to={`/edit/${quest.storyworldId}/episodes`}
                        onClick={() => {
                          const ep = {
                            title: "",
                            number: 1,
                            winningSubmission,
                            isNewEpisode: true,
                          };
                          setSelectedEpisode(ep);
                        }}
                        style={{
                          marginRight: "15%",
                          zIndex: 10,
                        }}
                      >
                        <Image
                          preview={false}
                          style={{
                            fontSize: 30,
                            margin: "auto",
                          }}
                          src={bookIcon}
                        />
                      </Link>
                    </Col>
                  )}
                </Row>
              </WinningSubmissionPreview>
            </WinningSubmissionContainer>
            <AdminWinner>
              <SmallAvatar
                size="small"
                icon={
                  <Image
                    preview={false}
                    src={winningUserInfo ? winningUserInfo.pfp : null}
                  />
                }
              />
              <span>
                {winningSubmission &&
                  winningUserInfo &&
                  winningUserInfo.username}
              </span>
            </AdminWinner>
          </>
        ) : quest.poll && isPollCompleted(quest.poll) && !winningSubmission ? (
          /* poll has ended and there is NOT a winner */
          <span
            style={{
              textAlign: "center !important",
              margin: "auto",
            }}
          >
            <QuestDisplayText>No winner</QuestDisplayText>
          </span>
        ) : (
          <NotStarted>
            <NotStartedText>Not Started</NotStartedText>
          </NotStarted>
        )}
      </StyledColumn>
      <StyledColumn span={12}>
        <ColumnRightSection>
          {quest.ongoing ? (
            /* the quest is ongoing */
            <StyledButton
              size="medium-small"
              variant="primary"
              rounded="slight"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const poll = { title: "" };
                setSelectedPoll(poll);
                setIsNewPoll(true);
                setQuestInfo(quest);
              }}
            >
              Approve Submissions
            </StyledButton>
          ) : !quest.poll ? (
            /* there is no poll */
            <Button
              size="medium-small"
              variant="primary"
              rounded="slight"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const poll = { title: "" };
                setSelectedPoll(poll);
                setIsNewPoll(true);
                setQuestInfo(quest);
              }}
            >
              Create Poll
            </Button>
          ) : quest.poll &&
            !isTimePassed(quest.poll.startTimestamp.toDate()) ? (
            /* quest has been created and it's before the start time: show Not Started thing and review button */
            <>
              {!quest.poll.confirmed && (
                <>
                  <NeedsApproval>Needs Approval</NeedsApproval>
                  <Spacer $marginTop="4px" />
                </>
              )}
              <Button
                size="medium-small"
                variant="ghost"
                rounded="slight"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedPoll(quest.poll);
                  setIsNewPoll(false);
                  setQuestInfo(quest);
                }}
              >
                Review poll
              </Button>
            </>
          ) : quest.poll && isTimePassed(quest.poll.endTimestamp.toDate()) ? (
            /* quest has been created and ended: show winning submission and quest winner name + text */
            <Button
              size="medium-small"
              variant="ghost"
              rounded="slight"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedPoll(quest.poll);
                setIsNewPoll(false);
                setQuestInfo(quest);
              }}
            >
              Show results
            </Button>
          ) : quest.poll &&
            isTimePassed(quest.poll.startTimestamp.toDate()) &&
            !isTimePassed(quest.poll.endTimestamp.toDate()) &&
            !quest.poll.isDraft ? (
            /* quest has been created and started: show submissions, voters (if any) and countdown timer until it ends and the review button */
            <>
              <OpenCountdown time={quest.poll.endTimestamp.toDate()} />
              <Button
                size="medium-small"
                variant="ghost"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedPoll(quest.poll);
                  setIsNewPoll(false);
                  setQuestInfo(quest);
                }}
              >
                Review poll
              </Button>
            </>
          ) : (
            <Button
              size="medium-small"
              variant="ghost"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedPoll(quest.poll);
                setIsNewPoll(false);
                setQuestInfo(quest);
              }}
            >
              Publish poll
            </Button>
          )}
        </ColumnRightSection>
      </StyledColumn>
    </StyledRow>
  );
}

const ColumnRightSection = styled.div`
  display: contents;
`;

const NotStarted = styled.div`
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  max-width: 200;
  font-weight: bold;
  align-items: center;
  padding: 8px 16px;
  margin: auto;
  width: 112px;
  height: 63px;
  background: var(--notStartedGray);
  vertical-align: middle;
`;

const NotStartedText = styled.div`
  font: unset;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--inputFieldText);
  padding: 0px;
  margin: 0px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-top: 14px;
`;

const NeedsApproval = styled.div`
  color: #ff971d;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  height: 100%;
  line-height: 20px;
  background: var(--color-purple);
`;

const StyledColumn = styled(Col)`
  display: grid;
  height: 100%;
  margin: auto;
  align-content: space-around;
  justify-content: center;
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const SubmissionText = styled.div`
  margin: auto;
  font: unset;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #fcfcfc;
  white-space: pre-wrap;
  padding: 0px !important;
  display: block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100%;
  text-align: center;
`;

const WinningSubmissionContainer = styled.div`
  position: relative;
  margin: auto;
  z-index: 10;
  pointer-events: none;

  &::before {
    position: absolute;
    display: block;
    left: 50%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    content: "";
    z-index: 10;
    opacity: 1 !important;
  }

  &:hover * {
    opacity: 1;
  }
`;

const WinningSubmissionPreview = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  background: rgba(0, 0, 0, 0.5);

  .inner-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin: auto;
    height: 100%;
  }
`;
