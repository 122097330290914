import React, { useMemo, useEffect } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { questDetailUrl } from "../../lib/routes";

export default function ImageGeneratorHeader({
  quest,
  isFinalizing,
  setIsFinalizing,
  isButtonEnabled,
  selectedImage,
  setSelectedImage,
  genAiImages,
  setGenAiImages,
}) {
  const navigate = useNavigate();

  const navTextStatus = useMemo(() => {
    if ((!isButtonEnabled || genAiImages.length > 0) && !isFinalizing)
      return "edit";

    if (isFinalizing) return "finalize";

    return "";
  }, [isButtonEnabled, genAiImages, isFinalizing]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const finalizeImage = () => {
    setIsFinalizing(true);
  };

  const onBackEditArtwork = () => {
    setGenAiImages([]);
    setSelectedImage(null);
  };

  const onBackFinalizeArtwork = () => {
    setIsFinalizing(false);
  };

  const onBackCreateArt = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(questDetailUrl(quest.storyworld, quest.id));
  };

  return (
    <Container>
      <Row style={{ height: 30 }}>
        <Col span={12} style={{ marginTop: 2 }}>
          <BackToQuest
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (navTextStatus === "edit") onBackEditArtwork();
              else if (navTextStatus === "finalize") onBackFinalizeArtwork();
              else if (navTextStatus === "") onBackCreateArt();
            }}
          >
            <BackIcon src="/icons/left-carat.svg" />
            <NavText>
              {navTextStatus === "edit" && "Edit artwork"}
              {navTextStatus === "finalize" && "Edit"}
            </NavText>
          </BackToQuest>
        </Col>
        {selectedImage && !isFinalizing ? (
          <Col span={12}>
            <FinalizeButton shape="round" onClick={finalizeImage}>
              Continue
            </FinalizeButton>
          </Col>
        ) : (
          genAiImages.length > 0 &&
          !isFinalizing && (
            <Col span={12} className="auto-margin">
              <SelectOne>Select one</SelectOne>
            </Col>
          )
        )}
      </Row>
      <QuestObjective>{parse(quest.objectiveDescription)}</QuestObjective>
    </Container>
  );
}

const Container = styled.div`
  .auto-margin {
    margin: auto;
  }
`;

const QuestObjective = styled.h2`
  margin-top: 18px !important;
  color: var(--color-cream);
  font-family: var(--font-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const BackToQuest = styled.span`
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: var(--color-gray-50);

  .ant-image {
    width: auto !important;
    vertical-align: sub;
  }
`;

const NavText = styled.span`
  color: #fcfcfc;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 4px;
  vertical-align: bottom;
  line-height: 24px; /* 160% */
  letter-spacing: -0.15px;
  pointer-events: none;
`;

const FinalizeButton = styled.button`
  width: fit-content !important;
  height: 100%;
  float: right;
  border: none;
  background: var(--lightPurple);
  padding: 0px 26px !important;
  border-radius: 40px;
  color: var(--color-cream);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const BackIcon = styled.img`
  padding-right: 2px;
  padding-bottom: 4px;
  height: 20px;
  width: 20px;
`;

const SelectOne = styled.h5`
  color: rgba(194, 201, 221, 0.42);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin: auto;
`;
