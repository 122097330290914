import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { commentLengthMax } from "../../helpers/constants";
import { CommentInputContext } from "../../context/CommentInputProvider";
import { isOniOS } from "../../helpers/general";
import { AuthContext } from "../../context/AuthProvider";

const postButtonWidth = 70;
const postButtonLeftMargin = 10;

export default function CommentInputField() {
  const {
    commentInputRef,
    commentState: { comment, replyTo },
    replyToUsername,
    dispatch,
  } = useContext(CommentInputContext);
  const { user, requireLogin } = useContext(AuthContext);

  // Reset when input is mounted
  useEffect(() => {
    dispatch({ type: "RESET" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (replyTo) {
      commentInputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyTo]);

  return (
    <Container>
      {replyTo && (
        <ReplyToText
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch({ type: "CANCEL_REPLY" });
          }}
        >
          @{replyToUsername}
        </ReplyToText>
      )}
      <TextareaAutosize
        maxRows="4"
        className="autosize-textarea"
        placeholder="Add a comment"
        maxLength={commentLengthMax + 50}
        ref={commentInputRef}
        value={comment}
        onChange={(e) => {
          let newComment = e.target.value;
          dispatch({ type: "SET_COMMENT", comment: newComment });
        }}
        disabled={!user}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      {!user && <InputInterceptor onClick={() => requireLogin()} />}
    </Container>
  );
}

const Container = styled.div`
  /* width: calc(100% - ${postButtonWidth}px - ${postButtonLeftMargin}px); */
  width: 100%;
  border-radius: 20px;
  background: #0b0b0c;
  padding: 8px 16px 8px 16px;
  border: 0px !important;
  outline: 0px;
  resize: none;
  color: var(--color-cream-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  position: relative;
  text-align: left;

  .autosize-textarea {
    width: 100%;
    background: none;
    border: 0px !important;
    outline: 0px;
    resize: none;
    height: 20px;
    color: var(--color-cream-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    vertical-align: middle;

    &::placeholder {
      color: #777e91 !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
    }

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    &::placeholder {
      opacity: 0.5;
      color: var(--color-gray-100);
    }
  }
`;

const ReplyToText = styled.label`
  color: var(--color-pink);
  width: fit-content;
  padding-left: ${isOniOS() ? "6px" : "0"};
`;

const InputInterceptor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
