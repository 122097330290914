import { useContext } from "react";
import { styled } from "styled-components";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import Backlink from "../components/Backlink";
import FeedSettingsList from "../components/feed/FeedSettingsList";

function FeedSettings() {
  const { goBack } = useContext(BackLocationsContext);

  return (
    <Container>
      <TopBanner>
        <BacklinkWrapper>
          <Backlink onClick={goBack} />
        </BacklinkWrapper>
        Activity Feed Settings
      </TopBanner>
      <FeedSettingsList />
    </Container>
  );
}

export default FeedSettings;

const Container = styled.div`
  height: 100%;
`;

const TopBanner = styled.div`
  position: relative;
  margin: 20px;
  margin-bottom: 32px;
  text-align: center;

  color: var(--color-cream);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

const BacklinkWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
`;
