"use client";
// ----- Components Import -----
import { styled } from "styled-components";

// ----- Assets Import -----
import {
  FieldContainer,
  StyledCheckbox,
  StyledInput,
} from "../../styles/CommonElements";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/Button";
import { useState } from "react";
import { device } from "../../lib/breakpoints";
import ContactForm from "./ContactForm";

export default function HomeContact() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      agreedToTerms: true,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("on submit");
  };

  return (
    <Container>
      <ContactForm />
    </Container>
  );
}

// ----- Styles -----

const Container = styled.div`
  margin: 1rem auto 3rem auto;
  width: 100%;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  padding: 5rem 0;

  h2 {
    max-width: 61.5rem;
    text-align: center;
    margin: 0 auto 3rem auto;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: left;

  @media (max-width: 35.9375rem) {
    max-width: 100%;
  }

  &.ant-col:last-child {
    display: none;
  }
`;

const Header = styled.div`
  color: #fff;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 35.9375rem) {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }
`;

const Title = styled.div`
  text-decoration: uppercase;
`;
