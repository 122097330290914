import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

const IdeaBlock = ({ idea, hideTitle = false }) => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [imagesErrors, setImagesErrors] = useState([false, false, false]);
  const [isLoading, setLoading] = useState(true);
  const [mainImage, secondaryImage, tertiaryImage] = idea.images;

  useEffect(() => {
    if (imagesLoaded === 3) {
      setLoading(false);
    }
  }, [imagesLoaded]);

  const handleLoad = () => {
    setImagesLoaded((prev) => prev + 1);
  };

  const handleError = (index) => {
    setImagesLoaded((prev) => prev + 1);
    setImagesErrors((prev) => {
      const newErrors = [...prev];
      newErrors[index] = true;
      return newErrors;
    });
  };

  return (
    <Container>
      <Grid>
        <div>
          <Block $isLoading={isLoading} data-empty={imagesErrors[0]}>
            <MainBlock>
              {imagesErrors[0] ? (
                <ImageError src="/icons/image-error.svg" />
              ) : (
                <img
                  src={mainImage}
                  onLoad={handleLoad}
                  onError={() => handleError(0)}
                />
              )}
            </MainBlock>
          </Block>
        </div>
        <StackedImages>
          <Block $isLoading={isLoading} data-empty={imagesErrors[1]}>
            <SecondaryBlock>
              {imagesErrors[1] ? (
                <ImageError src="/icons/image-error.svg" />
              ) : (
                <img
                  src={secondaryImage}
                  onLoad={handleLoad}
                  onError={() => handleError(1)}
                />
              )}
            </SecondaryBlock>
          </Block>
          <Block $isLoading={isLoading} data-empty={imagesErrors[2]}>
            <TertiaryBlock>
              {imagesErrors[2] ? (
                <ImageError src="/icons/image-error.svg" />
              ) : (
                <img
                  src={tertiaryImage}
                  onLoad={handleLoad}
                  onError={() => handleError(2)}
                />
              )}
            </TertiaryBlock>
          </Block>
        </StackedImages>
      </Grid>
      {!hideTitle && <Title>{idea.title}</Title>}
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
`;

const Grid = styled.div`
  width: 100%;
  aspect-ratio: 40 / 33;
  overflow: hidden;

  display: grid;
  grid-template-columns: 55% 45%;
`;

const StackedImages = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
`;

const Title = styled.p`
  text-align: left;
  color: var(--color-gray-50);
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
`;

const Block = styled.div`
  width: 100%;
  height: 100%;
  contain: size;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      background-color: var(--color-gray-200);
      animation: pulse 1.5s infinite;
    `}

  /* All three xBlock elements  */
  > div {
    width: 100%;
    height: 100%;
  }

  /* All three if empty */
  &[data-empty="true"] > div {
    border: 2px solid;
    border-color: var(--color-gray-200);
  }

  /* Disable the left border for the second and third blocks */
  &:has(+ [data-empty]) > div,
  &:nth-of-type(2) > div {
    border-left: none;
  }

  /* Disable the bottom border for the second block if the third is also empty */
  &[data-empty="true"]:has(+ [data-empty="true"]) > div {
    border-bottom: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.8s ease;
    opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  }
`;

const MainBlock = styled.div`
  &,
  & > img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

const SecondaryBlock = styled.div`
  &,
  & > img {
    border-top-right-radius: 10px;
  }
`;

const TertiaryBlock = styled.div`
  &,
  & > img {
    border-bottom-right-radius: 10px;
  }
`;

const ImageError = styled(ReactSVG)`
  height: 100%;
  color: var(--color-gray-200);

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export default IdeaBlock;
