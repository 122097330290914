import React from "react";
import { styled } from "styled-components";
import { TabItem } from "../TabItem";
import { MENU_OPTIONS } from "./StoryworldQuestPageDesktop";

export default function StoryworldQuestMenuSwitch({ extraMenu, setExtraMenu }) {
  return (
    <Container>
      <StyledTabItem
        layoutId="storyworld-quests"
        isActive={extraMenu === MENU_OPTIONS.CREATE}
        key={MENU_OPTIONS.CREATE}
        onClick={() => {
          setExtraMenu(MENU_OPTIONS.CREATE);
        }}
      >
        {MENU_OPTIONS.CREATE}
      </StyledTabItem>

      <StyledTabItem
        layoutId="storyworld-quests"
        isActive={extraMenu === MENU_OPTIONS.VOTE}
        key={MENU_OPTIONS.VOTE}
        onClick={() => {
          setExtraMenu(MENU_OPTIONS.VOTE);
        }}
      >
        {MENU_OPTIONS.VOTE}
      </StyledTabItem>

      <StyledTabItem
        layoutId="storyworld-quests"
        isActive={extraMenu === MENU_OPTIONS.RESULTS}
        key={MENU_OPTIONS.RESULTS}
        onClick={() => {
          setExtraMenu(MENU_OPTIONS.RESULTS);
        }}
      >
        {MENU_OPTIONS.RESULTS}
      </StyledTabItem>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 2px solid #35383e;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }

  div {
    text-align: center;
    font-family: "Hoss Sharp";
    font-size: ${(props) => (props.$smallerFont ? "15px" : "16px")};
  }

  .activeUnderline {
    border-radius: 0px !important;
  }
`;

const StyledTabItem = styled(TabItem)`
  padding-bottom: 17px;
  margin-bottom: -2px;
  width: 100%;
  flex-basis: unset;
  font-size: 16px;
  text-transform: capitalize;
`;
