import React, { useContext, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import styled from "styled-components";
import { Dropdown, Space } from "antd";
import commentModel from "../../lib/firebase/commentModel";
import { adminEmails } from "../../helpers/adminEmails";
import ReportSheet from "./ReportSheet";
import { trackEvent } from "../../helpers/mixpanel";
import { commentKeys } from "../../lib/queryKeys";
import { CommentInputContext } from "../../context/CommentInputProvider";
import { AuthContext } from "../../context/AuthProvider";

export default function CommentDropdownButton({
  isCurrentUser,
  setEditComment,
  comment,
  children,
  setDeletedComments,
  mixpanelParams,
}) {
  const { user, requireLogin } = useContext(AuthContext);

  const [reportSheetOpen, setReportSheetOpen] = useState(false);
  const queryClient = useQueryClient();
  const { commentInputRef } = useContext(CommentInputContext);

  const { mutateAsync: deleteComment } = useMutation({
    mutationFn: (commentId) => {
      return commentModel.delete(commentId);
    },
  });

  const startDeletingComment = async () => {
    if (!children) children = [];
    setDeletedComments([comment, ...children]);
    await deleteComment(comment.id);
    children.forEach(async (c) => {
      await deleteComment(c.id);
    });
    queryClient.invalidateQueries({
      queryKey: commentKeys.subject(comment.subjectId),
    });
    trackEvent("Deleted Comment", { ...mixpanelParams });
  };

  let items;

  if (isCurrentUser) {
    items = [
      {
        label: (
          <WhiteHeader
            onClick={() => {
              if (!user) {
                requireLogin();
                return;
              }
              setEditComment(comment);
              commentInputRef.current.focus();
            }}
          >
            <DropdownIcon src="/icons/social/edit.svg" alt="" />
            Edit
          </WhiteHeader>
        ),
        key: "1",
      },
      {
        label: (
          <RedHeader
            onClick={() => {
              if (!user) {
                requireLogin();
                return;
              }
              startDeletingComment();
            }}
          >
            <DropdownIcon src="/icons/social/delete.svg" alt="" />
            Delete
          </RedHeader>
        ),
        key: "2",
      },
    ];
  } else {
    items = [
      /*
      {
        label: (
          <RedHeader
            onClick={() => {
             if (!user) {
                requireLogin();
                return;
              }
            }}
          >
            <DropdownIcon src="/icons/social/block.svg" alt="" />
            Block User
          </RedHeader>
        ),
        key: "1",
      },
      */
      {
        label: (
          <RedHeader
            onClick={() => {
              if (!user) {
                requireLogin();
                return;
              }
              setReportSheetOpen(true);
            }}
          >
            <DropdownIcon src="/icons/social/flag.svg" alt="" />
            Report
          </RedHeader>
        ),
        key: "1",
      },
      user &&
        adminEmails.includes(user.email) && {
          label: (
            <RedHeader onClick={async () => await startDeletingComment()}>
              <DropdownIcon src="/icons/social/delete.svg" alt="" />
              Admin Delete
            </RedHeader>
          ),
          key: "2",
        },
    ];
  }

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <Button onClick={(e) => e.preventDefault()}>
          <Space>
            <MoreIcon src="/icons/social/more.svg" alt="" />
          </Space>
        </Button>
      </Dropdown>
      <ReportSheet
        comment={comment}
        isOpen={reportSheetOpen}
        setOpen={setReportSheetOpen}
      />
    </>
  );
}

const Button = styled.button`
  background: none;
  border: 0;
`;

const MoreIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

const DropdownIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

const WhiteHeader = styled.span`
  color: var(--color-cream-base);
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
`;

const RedHeader = styled.div`
  color: var(--color-red-rejected);
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  margin-top: 2px !important;
`;
