import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

export default function Dropzone(props) {
  const onImageDropped = props.onImageDropped;
  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageDropped(acceptedFiles[0]);
    },
    [onImageDropped]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (props.predictions.length) return null;

  if (props.userUploadedImage) return null;

  return (
    <div
      className="absolute z-50 flex w-full h-full text-gray-500 text-sm text-center cursor-pointer select-none w-full h-full"
      {...getRootProps()}
    >
      <div className="m-auto">
        <input {...getInputProps()} />
        <UploadIconContainer>
          <img src="/icons/upload.svg" alt="" />
        </UploadIconContainer>
        {isDragActive ? (
          <p>Drop patient image here...</p>
        ) : (
          <p>Upload an image of the patient</p>
        )}
      </div>
    </div>
  );
}

const UploadIconContainer = styled.span`
  display: inline-flex;
`;
