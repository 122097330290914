import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import debounce from "debounce-promise";
import { generateRandomId, usernameExists } from "../../helpers/firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  containsProfanity,
  removeIllegalCharacters,
} from "../../helpers/general";
import { MIN_USERNAME_LENGTH, ERROR_PROFANITY } from "../../helpers/constants";
import userModel from "../../lib/firebase/userModel";
import { userKeys } from "../../lib/queryKeys";
import { FieldContainer, InputArea, Spacer } from "../../styles/CommonElements";
import { trackEvent } from "../../helpers/mixpanel";
import { useBreakpoints } from "../../lib/breakpoints";
import InputField from "../InputField";
import { ProfileImageSheet } from "../login";
import ProfileImageWizard from "../login/ProfileImageWizard";
import { AuthContext } from "../../context/AuthProvider";
import { useForm } from "react-hook-form";
import {
  DIALOG_NAME,
  useDialogSearchParam,
} from "../../helpers/useDialogSearchParam";

const EditProfileForm = () => {
  const { userInfo } = useContext(AuthContext);
  const [isImageSectionVisible, setIsImageSectionVisible] = useState(false);
  const [isImageSheetOpen, setIsImageSheetOpen] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    getFieldState,
    watch,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      username: userInfo?.username,
      profilePic: userInfo?.pfp,
    },
  });

  const { isMobile, isTabletOrAbove } = useBreakpoints();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isEditParamSet, removeEditParam] = useDialogSearchParam(
    DIALOG_NAME.edit
  );

  const { mutate: editProfile, isPending } = useMutation({
    mutationFn: async ({ username, profilePic }) => {
      const payload = {
        username,
      };

      if (typeof profilePic !== "string" && profilePic) {
        const storageRef = ref(
          getStorage(),
          `profileImage/${userInfo.id}/${generateRandomId()}`
        );
        const snapshot = await uploadBytes(storageRef, profilePic);
        const url = await getDownloadURL(snapshot.ref);
        payload.pfp = url;
      } else {
        payload.pfp = profilePic;
      }

      return userModel.update({ id: userInfo.id, data: payload });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.user(userInfo.id) });
      if (isEditParamSet) {
        removeEditParam();
      } else {
        navigate("/profile");
      }
    },
  });

  const onChangePassword = (e) => {
    e.preventDefault();
    navigate("/profile/change-password");
  };

  const onCancel = () => {
    if (isImageSectionVisible) {
      setIsImageSectionVisible(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <InfoContainer>
        <CancelContainer>
          <CancelButton onClick={onCancel}>
            <LeftCarat src="/icons/left-carat-lavender.svg" />
            <CancelText>{isImageSectionVisible ? "Back" : "Cancel"}</CancelText>
          </CancelButton>
        </CancelContainer>

        <EditProfilePic
          onClick={() => {
            if (isMobile) {
              setIsImageSheetOpen(true);
            } else if (isTabletOrAbove) {
              setIsImageSectionVisible(!isImageSectionVisible);
            }
          }}
        >
          <UserProfileImage src={watch("profilePic")} />
          <ProfileImgOverlay>
            <CameraIcon src="/icons/camera.svg" alt="" />
          </ProfileImgOverlay>
        </EditProfilePic>
      </InfoContainer>

      {(!isImageSectionVisible || isMobile) && (
        <FormContainer onSubmit={handleSubmit(editProfile)}>
          {/* The logic may have to stick outside of traditional form logic but we can maintain the state this way */}
          <input {...register("profilePic")} type="hidden" />
          <FieldContainer>
            <FieldLabel>Username</FieldLabel>
            <Spacer $marginTop="0.25rem" />
            <div style={{ position: "relative" }}>
              <InputField
                {...register("username", {
                  required: "Please enter a username",
                  minLength: {
                    value: MIN_USERNAME_LENGTH,
                    message: `Username must be at least ${MIN_USERNAME_LENGTH} characters`,
                  },
                  validate: {
                    profane: (value) =>
                      !containsProfanity(value) || ERROR_PROFANITY,
                    exists: debounce(async (value) => {
                      const doesUsernameExist = await usernameExists(value);

                      return (
                        !doesUsernameExist ||
                        value === userInfo?.username ||
                        "This username is already taken"
                      );
                    }, 300),
                  },
                  onChange: (e) =>
                    setValue(
                      "username",
                      removeIllegalCharacters(e.target.value)
                    ),
                })}
                className="transition-colors"
                placeholder={`Must be at least ${MIN_USERNAME_LENGTH} characters`}
                maxLength={40}
                type="text"
              />
              {getFieldState("username").isDirty && errors.username ? (
                <Icon src="/icons/x-thick.svg" alt="" style={{ height: 18 }} />
              ) : getFieldState("username").isDirty ? (
                <Icon
                  src="/icons/correct-checkmark.svg"
                  alt=""
                  style={{ height: 17 }}
                />
              ) : null}
            </div>

            {errors.username && (
              <ErrorText>{errors.username.message}</ErrorText>
            )}
          </FieldContainer>

          {isMobile && (
            <>
              <FieldContainer>
                <FieldLabel>Email</FieldLabel>

                <InputField
                  value={userInfo.email}
                  onChange={() => {}}
                  disabled
                />
              </FieldContainer>

              <ChangePasswordButton onClick={onChangePassword}>
                Change password
              </ChangePasswordButton>
            </>
          )}

          <SubmitButton disabled={!isValid || isPending}>
            {isPending ? "Saving..." : "Save"}
          </SubmitButton>
        </FormContainer>
      )}

      {isImageSectionVisible && isTabletOrAbove && (
        <ProfileImageWizard
          profilePic={watch("profilePic")}
          setProfilePic={(data) => setValue("profilePic", data)}
          onAction={() => setIsImageSectionVisible(false)}
        />
      )}

      <ProfileImageSheet
        isOpen={isImageSheetOpen}
        setOpen={setIsImageSheetOpen}
        profilePic={watch("profilePic")}
        setProfilePic={(data) => setValue("profilePic", data)}
      />
    </>
  );
};

export default EditProfileForm;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding-top: 10px;
  min-height: 240px;
`;

const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const CancelButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #ab9bea;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

const ProfileImgOverlay = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: white;
  background: var(--lightPurple);
  border-radius: 32px;
`;

const CameraIcon = styled.img`
  padding: 10px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
`;

const FieldLabel = styled.p`
  font-size: 14px;
  line-height: 25px;
  color: var(--color-gray-100);
  text-align: left;
`;

const ChangePasswordButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: var(--color-pink);
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  font-style: normal;
  letter-spacing: 0.2px;
  margin-left: 1%;
  width: fit-content;
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background: #737887;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: white;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  margin: auto;

  &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 50%;
  }
`;

const Icon = styled.img`
  width: auto;
  position: absolute;
  right: 14px;
  top: 17px;
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 160%;
  color: var(--color-red);
  margin-bottom: 0px;
  margin-top: 14px;
  text-align: left;
`;

const LeftCarat = styled.img`
  height: 24px;
  margin: 6px 2px 6px auto;
  filter: brightness(0);
`;

const CancelText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  color: black;
`;

const UserProfileImage = styled.img`
  object-fit: cover;
  height: 120px;
  width: 120px;
  border-radius: 100px;
  border: 4px solid var(--color-gray-100);
`;

const EditProfilePic = styled.button`
  border: 0;
  background: none;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
`;
