import styled, { css } from "styled-components";
import { Spacer } from "../../../styles/CommonElements";
import { EmptyComments, SubmissionCommentSection } from "../../social";
import WritingContent from "../../WritingContent";
import { ReadMore } from "../../general";
import LikeButton from "../../LikeButton";
import SubmissionFilter, { getSubmissionType } from "../SubmissionFilter";
import UserDisplay from "../../UserDisplay";
import { useQuery } from "@tanstack/react-query";
import { commentKeys } from "../../../lib/queryKeys";
import { getComments } from "../../../helpers/general";
import IdeaBlock from "../../IdeaBlock";

const SubmissionPreviewContent = ({
  submission,
  winner,
  isInVote,
  commentsEnabled,
  sortType,
  setSortType,
}) => {
  const { data: comments } = useQuery({
    queryKey: commentKeys.subject(submission?.id),
    queryFn: async () => {
      if (!submission?.id) return null;
      return getComments(submission.id);
    },
    enabled: !!submission?.id,
    placeholderData: [],
  });

  const submissionType = getSubmissionType(submission);

  return (
    <Content $submissionType={submissionType}>
      <SubmissionFilter
        submission={submission}
        idea={
          <>
            <div>
              <IdeaBlock idea={submission} hideTitle />
            </div>
            <Spacer $marginTop="12px" />
          </>
        }
      />
      <UserAndLikes>
        {!isInVote && (
          <UserInfo>
            <UserDisplay
              userId={submission ? submission.creator : null}
              size={28}
              showName={true}
            />
            {winner && <Crown src="/icons/crown.svg" alt="" />}
          </UserInfo>
        )}

        {!isInVote && (
          <LikeContainer>
            <LikeButton itemType="submission" itemId={submission.id} />
          </LikeContainer>
        )}
      </UserAndLikes>

      {submission?.text && submission.text !== "" && (
        <SubmissionFilter
          submission={submission}
          idea={
            <QuestTextContainer>
              {submission?.title && <div>{submission.title}</div>}
              <ReadMore text={submission.text} threshold={130} />
            </QuestTextContainer>
          }
          text={
            <WritingContent title={submission?.title} text={submission.text} />
          }
        />
      )}
      {commentsEnabled ? (
        <>
          <Spacer $marginTop="0.5rem" />
          <Break />
          <SubmissionCommentSection
            comments={comments}
            showSort={false}
            sortType={sortType}
            setSortType={setSortType}
          />
        </>
      ) : (
        <>
          <Spacer $marginTop="0.5rem" />
          <Line />
          <Spacer $marginTop="1.5rem" />{" "}
          <EmptyComments subheaderText="Comments are turned off for this submission" />
        </>
      )}
    </Content>
  );
};

export default SubmissionPreviewContent;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  ${({ $submissionType }) => {
    switch ($submissionType) {
      case "idea":
        return css`
          margin-top: 14px;
          gap: 0;
        `;
      default:
        return css`
          margin-top: 0;
          gap: 14px;
        `;
    }
  }}
`;

const UserAndLikes = styled.div`
  display: flex;
  align-items: top;
  position: relative;
`;

const UserInfo = styled.div`
  display: inline-flex;
  text-align: left;
  margin-bottom: 8px;
  font-size: 14px;
`;

const Crown = styled.img`
  height: 18px;
  width: 18px;
  display: inline-block;
  margin: auto;
  margin-left: 4px;
`;

const QuestTextContainer = styled.div`
  div:first-child {
    color: var(--color-cream-base);
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 4px;
  }

  p {
    color: #c2c9dd;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 26.5px; /* 189.286% */
    margin-bottom: 8px;

    span {
      line-height: 26.5px; /* 189.286% */
      font-size: 14px;
      z-index: 10;
    }
  }
`;

const Break = styled.div`
  width: 100%;
  margin: auto;
  border: 0.5px solid var(--border-light);
`;

const LikeContainer = styled.span`
  position: absolute;
  right: 0px;
`;

const Line = styled.div`
  background: var(--border-light);
  height: 1px;
`;
