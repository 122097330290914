import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PostedComment from "./PostedComment";
import SortDropdownButton from "./SortDropdownButton";
import likeModel from "../../lib/firebase/likeModel";
import { CommentInputContext } from "../../context/CommentInputProvider";
import { Spacer } from "../../styles/CommonElements";

export default function CommentSection({
  comments,
  showSort = true,
  showCount,
  mixpanelParams,
  sortType,
  setSortType,
}) {
  const [sortedComments, setSortedComments] = useState([]);
  const { setDeletedComments, dispatch } = useContext(CommentInputContext);

  useEffect(() => {
    async function sort() {
      await Promise.all(
        comments.map(async (c) => {
          const likeCount = await likeModel.count(["submissionId", "==", c.id]);
          c.likeCount = likeCount;
        })
      );
      const sorted = comments.sort(function (a, b) {
        return b.likeCount - a.likeCount || b.createdAt - a.createdAt;
      });
      setSortedComments([...sorted]);
    }
    if (sortType === "top") {
      sort();
    } else if (sortType === "newest") {
      const sorted = comments.sort(function (a, b) {
        return b.createdAt - a.createdAt;
      });
      setSortedComments([...sorted]);
    }
  }, [sortType, comments]);

  const setEditComment = (comment) => {
    dispatch({ type: "SET_EDIT_COMMENT", editComment: comment });
  };

  const setReplyTo = (comment) => {
    dispatch({ type: "SET_REPLY_TO", replyTo: comment });
  };

  return (
    <Container>
      <Header>
        <div>
          {showCount && (
            <CommentsCount>{`Comments (${comments.length})`}</CommentsCount>
          )}
        </div>
        {showSort && (
          <SortSection>
            Sort by:{" "}
            <SortDropdownButton
              setSortType={setSortType}
              sortType={sortType}
              comments={comments}
              setSortedComments={setSortedComments}
            />
          </SortSection>
        )}
      </Header>
      {showCount && (
        <>
          <HeaderHr />
          <Spacer $marginTop="0.85rem" />
        </>
      )}
      <InnerContainer>
        {sortedComments.map(
          (comment, index) =>
            comment.replyTo === null && (
              <PostedComment
                comment={comment}
                key={index}
                setEditComment={setEditComment}
                setReplyTo={setReplyTo}
                setDeletedComments={setDeletedComments}
                mixpanelParams={mixpanelParams}
              >
                {comments.filter((c) => {
                  return c.replyTo === comment.id;
                })}
              </PostedComment>
            )
        )}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 0px;

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }
`;

const InnerContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  position: relative;
  user-select: none;
  touch-action: pan-y;
  margin-bottom: 100px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const SortSection = styled.div`
  color: var(--color-cream-base);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

const CommentsCount = styled.div`
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  color: var(--color-cream);
`;

const HeaderHr = styled.hr`
  border: 1px solid var(--color-gray-400);
  margin: 0;
`;

// const Direction = styled.span`
//   color: var(--color-pink-normal);
//   font-family: Poppins;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 32.5px;
// `;
