import React, { useEffect, useState } from "react";
import UserDisplay from "./UserDisplay";
import { shuffleArray } from "../helpers/general";
import { NumberCircle } from "../views";
import styled from "styled-components";

const maxUserDisplay = 7;

export default function UserDisplayGroup({
  users = [],
  size = 36,
  shuffle = true,
}) {
  const [uniqueUsers, setUniqueUsers] = useState([]);

  useEffect(() => {
    const newUsers = [...new Set(users)];
    setUniqueUsers(shuffle ? shuffleArray(newUsers) : users);
  }, [users, shuffle]);

  return (
    <Container>
      <InnerContainer>
        {uniqueUsers.map(
          (id, index) =>
            index < maxUserDisplay && (
              <UserDisplay key={index} userId={id} size={size} />
            )
        )}
        {uniqueUsers?.length > maxUserDisplay && (
          <NumberCircle
            number={uniqueUsers.length - maxUserDisplay}
            showPlus={true}
            size={size + 2}
          />
        )}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  display: table;
  margin: auto;

  .ant-avatar-circle {
    border: 2px solid var(--color-gray-500);
  }

  .number-circle-master {
    padding: 0px !important;
    position: relative;
    margin-left: -10px !important;
    border: 2px solid var(--color-gray-500) !important;
  }
`;

const InnerContainer = styled.div`
  display: flex;
`;
