import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const Backlink = ({ to, onClick, color, className }) => {
  if (to) {
    return (
      <Container as={Link} to={to} $color={color} className={className}>
        <LeftCarat src="/icons/chevron-left.svg" />{" "}
      </Container>
    );
  }

  return (
    <Container
      onClick={onClick}
      $color={color}
      className={className}
      aria-label="navigate back"
    >
      <LeftCarat src="/icons/chevron-left.svg" />{" "}
    </Container>
  );
};

const Container = styled.button`
  /* reset */
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  /* end of reset */

  display: block;
  width: fit-content;
  color: ${(props) => props.$color || "inherit"};

  &:hover {
    color: white;
  }
`;

const LeftCarat = styled(ReactSVG)`
  height: 24px;
`;

export default Backlink;
