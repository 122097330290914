import React, { useState } from "react";
import styled from "styled-components";
import { isOniOS } from "../../helpers/general";
import CommentSection from "./CommentSection";
import CommentInputField from "./CommentInputField";
import EmptyComments from "./EmptyComments";
import CustomSheet from "../CustomSheet";
import CommentInputProvider, {
  CommentInputContext,
} from "../../context/CommentInputProvider";
import { Sheet } from "react-modal-sheet";
import {
  DIALOG_NAME,
  useDialogSearchParam,
} from "../../helpers/useDialogSearchParam";
import EditCommentSection from "../EditCommentSection";
import Button from "../Button";

export default function CommentSheet({ storyworld, episode, comments }) {
  const [sortType, setSortType] = useState("top");
  const [isParamSet, removeParam] = useDialogSearchParam(DIALOG_NAME.comments);

  const trackingData = {
    location: "episode",
    episode_id: episode?.id,
    episode_name: episode?.title,
    storyworld_id: storyworld?.id,
    storyworld_name: storyworld?.title,
    title: `${storyworld?.title}: ${episode?.title}`,
  };

  return (
    <>
      <CommentInputProvider
        value={{
          trackingData,
        }}
      >
        <CommentInputContext.Consumer>
          {({
            isFocused,
            startCommentSubmit,
            commentState: { commentOk, editComment },
            isSending,
            dispatch,
          }) => (
            <Container>
              <StyledSheet
                isOpen={isParamSet}
                onClose={removeParam}
                snapPoints={[700]}
                tweenConfig={{ ease: "easeOut", duration: 0.3 }}
                headerContent={`Comments (${comments.length})`}
                hasCloseIcon
              >
                <Sheet.Scroller>
                  {comments.length > 0 ? (
                    <CommentsContainer>
                      <CommentSection
                        comments={comments}
                        sortType={sortType}
                        setSortType={setSortType}
                        mixpanelParams={trackingData}
                      />
                    </CommentsContainer>
                  ) : (
                    <EmptyComments
                      headerText="No comments yet"
                      subheaderText="Be the first to discuss!"
                    />
                  )}
                </Sheet.Scroller>
                <Footer $isFocused={isFocused} $editComment={editComment}>
                  {editComment && (
                    <EditCommentSection
                      handleCancelEdit={() => {
                        dispatch({ type: "CANCEL_EDIT" });
                      }}
                    />
                  )}
                  <AddCommentSection>
                    <CommentInputField />
                    <PostButton>
                      <Button
                        disabled={!commentOk || isSending}
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await startCommentSubmit();
                          setSortType("newest");
                        }}
                      >
                        Post
                      </Button>
                    </PostButton>
                  </AddCommentSection>
                </Footer>
              </StyledSheet>
            </Container>
          )}
        </CommentInputContext.Consumer>
      </CommentInputProvider>
    </>
  );
}

const StyledSheet = styled(CustomSheet)`
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;

  && .react-modal-sheet-container {
    background-position: center;
    background-size: cover;
    padding: 0;
    border-radius: 20px 20px 0px 0px;
    position: relative;
    overflow: hidden;
  }
  & .react-modal-sheet-content {
    padding: 0;
  }
`;

const Container = styled.span`
  &::-webkit-scrollbar {
    display: none;
    background: none;
    opacity: 0;
  }

  .ant-notification-bottom {
    top: unset;
    bottom: 200px;
  }
`;

const CommentsContainer = styled.div`
  margin-top: 0px;
  padding: 0px 26px;
  overflow: auto;
`;

const Footer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  /* position: absolute; */
  background: #1a1c21;
  /* bottom: 0px; */
  border-top: ${(props) =>
    (props.$isFocused && isOniOS()) || props.$editComment
      ? "0px"
      : "0.5px solid var(--border-light)"};
  /* height: ${(props) => props.$isFocused && isOniOS() && "400px"}; */
`;

const AddCommentSection = styled.div`
  margin: auto;
  text-align: center;
  width: 100%;
  display: inline-flex;
  position: relative;
  padding: 10px 10px 0px 10px;
`;

const PostButton = styled.div`
  width: 70px;
  flex-shrink: 0;
  margin-left: 10px;
`;
