import styled, { css } from "styled-components";
import CustomDrawer from "../CustomDrawer";
import FeedList from "./FeedList";
import { ReactSVG } from "react-svg";
import { useState } from "react";
import FeedSettingsList from "./FeedSettingsList";
import Backlink from "../Backlink";

const FeedDrawer = ({ isOpen, onClose }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  return (
    <>
      <CustomDrawer isOpen={isOpen} onClose={onClose}>
        <TopBanner $settingsOpen={settingsOpen}>
          {settingsOpen ? (
            <BackLink
              onClick={() => {
                setSettingsOpen(false);
              }}
            />
          ) : (
            <SettingsGear
              src="/icons/gear.svg"
              alt="Open settings"
              onClick={() => {
                setSettingsOpen(true);
              }}
            />
          )}

          <span>Activity Feed {settingsOpen ? "Settings" : ""} </span>

          <CloseButton aria-label="close drawer" onClick={onClose}>
            <ReactSVG src="/icons/x.svg" />
          </CloseButton>
        </TopBanner>
        {settingsOpen ? <FeedSettingsList /> : <FeedList />}
      </CustomDrawer>
    </>
  );
};

export default FeedDrawer;

const TopBanner = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 1.5rem;

  color: var(--color-cream);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-family: var(--font-primary);

  ${({ $settingsOpen }) =>
    $settingsOpen &&
    css`
      padding-bottom: 1.5rem;
      border-bottom: 1px solid var(--border-light);
    `}
`;

const SettingsGear = styled(ReactSVG)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  color: var(--color-cream);
  cursor: pointer;
`;

const BackLink = styled(Backlink)`
  display: block;
  position: absolute;
  left: 0;

  color: var(--color-cream-base);
`;

const CloseButton = styled.button`
  /* reset */
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  /* end reset */

  height: 18px;
  width: 18px;

  position: absolute;
  right: 0;

  img {
    display: block;
  }
`;
