import styled from "styled-components";
import HomeMain from "./HomeMain";
import HomeFeatures from "./HomeFeatures";
import HomeContact from "./HomeContact";
import HomeBanner from "./HomeBanner";
import HomeCTA from "./HomeCTA";

export default function Home() {
  return (
    <Container>
      <HomeMain />
      <HomeContact />
      {/*
      <HomeBanner />
      <HomeFeatures />
      <HomeCTA />
      */}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
