import styled from "styled-components";
import Paint from "../Paint";

export default function Dashboard() {
  return (
    <Container>
      <Paint />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
