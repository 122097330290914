import React, { useEffect, useState } from "react";
import { Affix, Col, Row } from "antd";
import { styled } from "styled-components";

export default function QuestDisplayCta({
  rowColumns,
  extraMenu,
  userActionEl,
  countdownTimer,
  submitText,
}) {
  const [affixedPosition, setAffixedPosition] = useState("fixed-top");

  useEffect(() => {
    if (extraMenu === "quest") {
      setAffixedPosition("fixed-top");
    }
    if (extraMenu === "submissions") {
      setAffixedPosition("fixed-bottom submissions-bg");
    }
  }, [extraMenu]);

  return (
    <Container>
      <Affix
        className={`mobile-cta-affixed ${affixedPosition}`}
        style={{ background: "#23262F" }}
      >
        {rowColumns === 1 ? (
          <Row className="mobile-submission-cta-row">
            <Col span={24} className="mobile-submission-cta-col">
              {submitText}
            </Col>
          </Row>
        ) : (
          <Row className="mobile-submission-cta-row">
            <Col span={8} className="mobile-submission-cta-col"></Col>
            <Col span={8} className="mobile-submission-cta-col">
              {countdownTimer}
            </Col>
            <Col
              span={8}
              className="mobile-submission-cta-col"
              style={{ maxWidth: 140, marginLeft: 0 }}
            >
              {userActionEl}
            </Col>
          </Row>
        )}
      </Affix>
    </Container>
  );
}

const Container = styled.span`
  .ant-statistic {
    height: 100%;
    display: inline-block;
  }

  .ant-statistic-content {
    height: 100% !important;
    vertical-align: middle;
    display: block;
    margin: auto;
    margin-top: 4px;
  }

  .ant-statistic-content-value {
    font-size: 18px;
    height: 100%;
    margin: auto;
    vertical-align: middle;
    display: contents !important;
  }

  .mobile-submission-cta-row {
    padding: 0px 12px;
    height: 50px;
  }

  .mobile-submission-cta-col {
    margin: auto !important;
    text-align: center;
    height: 100%;
    margin-left: 0px;

    button {
      font-size: 14px !important;
      height: 38px;
      margin-top: 6px !important;
    }
  }
`;
