import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import { storyworldKeys } from "../lib/queryKeys";
import { ActiveStoryworldMark } from "../styles/CommonElements";
import { useMemo } from "react";
import { storyworldTypes } from "../helpers/constants";
import publishedStoryworlds from "../lib/advanced/publishedStoryworlds";
import StoryworldCarousel from "./StoryworldCarousel";
import StoryworldCollection from "./StoryworldCollection";
import Hidden from "./Hidden";

export default function ActiveStoryworldsCollection() {
  const { data: stories } = useQuery({
    queryKey: storyworldKeys.published(),
    queryFn: () => {
      return publishedStoryworlds();
    },
    placeholderData: [],
  });

  const activeStoryworlds = useMemo(() => {
    if (!stories) {
      return [];
    }

    return stories.filter(function (s) {
      return s.type === storyworldTypes.STORYWORLD;
    });
  }, [stories]);

  return (
    <Container>
      <>
        <Hidden tablet desktop>
          <StoryworldCollection
            title={
              <>
                Active Storyworlds
                <ActiveStoryworldMark $marginLeft="6px" />
              </>
            }
            stories={activeStoryworlds}
            showActiveMark={false}
            showBigIcon={true}
          />
        </Hidden>

        <Hidden mobile>
          <StoryworldCarousel
            title={
              <>
                Active Storyworlds
                <ActiveStoryworldMark $marginLeft="6px" />
              </>
            }
            stories={activeStoryworlds}
            showActiveMark={false}
          />
        </Hidden>
      </>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 22px 0px;
`;
