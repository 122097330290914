import React from "react";
import { styled } from "styled-components";

export default function EmptySubmissions() {
  return (
    <Container>
      <Icon src="/images/quests/pencil-spiral-gray.svg" alt="" />
      <Header>No entries yet</Header>
      <Subheader>Be the first to submit!</Subheader>
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 14px;
  padding: 18px;
  text-align: center;
`;

const Icon = styled.img`
  height: 127px;
  width: auto;
  text-align: center;
  margin-top: 10px;
`;

const Header = styled.div`
  color: var(--color-cream-dark);
  font-family: var(--font-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
`;

const Subheader = styled.div`
  color: var(--disabled-button-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-top: 6px;
  margin-bottom: 14px;
`;
