import { useContext, useEffect, useMemo, useState } from "react";
import { Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import RadioGroup from "./RadioGroup";
import parse from "html-react-parser";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { creatorPollVoteKeys, storyworldKeys } from "../../lib/queryKeys";
import creatorPollVoteModel from "../../lib/firebase/creatorPollVoteModel";
import UserDisplayGroup from "../UserDisplayGroup";
import pluralize from "pluralize";
import { getTimeFromDate } from "../../helpers/general";
import { trackEvent } from "../../helpers/mixpanel";
import storyworldModel from "../../lib/firebase/storyworldModel";
import { AuthContext } from "../../context/AuthProvider";

const { Text } = Typography;

const getUserVote = (user, votes) => {
  if (!user) return null;

  const currentUserId = user.uid;
  const currentUserVotes = votes.filter(
    (vote) => vote.creator === currentUserId
  );

  return currentUserVotes?.[0];
};

const CreatorPoll = ({ creatorPoll, width }) => {
  const [selected, setSelected] = useState(null);
  const [changingVote, setChangingVote] = useState(false);
  const queryClient = useQueryClient();
  const { user, requireLogin } = useContext(AuthContext);

  const { data: storyworld = null } = useQuery({
    queryKey: storyworldKeys.storyworld(creatorPoll.storyworld),
    queryFn: async () => {
      return await storyworldModel.getOneById(creatorPoll.storyworld);
    },
  });

  const { data: votes } = useQuery({
    queryKey: creatorPollVoteKeys.poll(creatorPoll.id),
    queryFn: async () => {
      return await creatorPollVoteModel.getMany([
        "creatorPollId",
        "==",
        creatorPoll.id,
      ]);
    },
    placeholderData: [],
  });

  const { mutate: placeVote, isPending: creationPending } = useMutation({
    mutationFn: (selected) => {
      return creatorPollVoteModel.create({
        creatorPollId: creatorPoll.id,
        voteResponses: [selected],
      });
    },
    onSuccess: (newVote) => {
      queryClient.setQueryData(
        creatorPollVoteKeys.poll(creatorPoll.id),
        (oldVotes) => {
          return [...oldVotes, newVote];
        }
      );
      trackVoteEvent();
      // Alternatively (but triggering another read):
      // queryClient.invalidateQueries({
      //   queryKey: creatorPollVoteKeys.poll(creatorPoll.id),
      // });
    },
  });

  const { mutate: updateVote, isPending: updatePending } = useMutation({
    mutationFn: (selected) => {
      const userVote = getUserVote(user, votes);

      return creatorPollVoteModel.update({
        voteResponses: [selected],
        voteId: userVote?.id,
      });
    },
    onSuccess: (updatedVote) => {
      const currentUserId = user?.uid;

      queryClient.setQueryData(
        creatorPollVoteKeys.poll(creatorPoll.id),
        (oldVotes) => {
          const otherVotes = oldVotes.filter(
            (vote) => vote.creator !== currentUserId
          );
          return [...otherVotes, updatedVote];
        }
      );
      trackVoteEvent();
      // Alternatively:
      // queryClient.invalidateQueries({
      //   queryKey: creatorPollVoteKeys.poll(creatorPoll.id),
      // });
      setChangingVote(false);
    },
  });

  useEffect(() => {
    const userVote = getUserVote(user, votes);
    const pollVote = userVote?.voteResponses?.[0];

    // Slightly concerned that updating state like this will lead to a stale query refetch reseting what a user had selected.
    if (pollVote) {
      setSelected(pollVote);
    }
  }, [user, votes]);

  const trackVoteEvent = () => {
    trackEvent("Creator Poll Vote Cast", {
      creator_poll_id: creatorPoll.id,
      creator_poll_question: parse(creatorPoll.question).props.children,
      storyworld_id: creatorPoll.storyworld,
      storyworld_name: storyworld && storyworld.title,
      title: `${storyworld && storyworld.title}: ${
        parse(creatorPoll.question).props.children
      }`,
      votedFor: [selected],
    });
  };

  // ex: [{ value: "choice A", percentVoted: 75 }]
  const optionsWithVoteCount = useMemo(() => {
    const totalVotes = votes.length;

    return creatorPoll.responseOptions.map((responseOption) => {
      const matchingVotes = votes.filter((vote) =>
        vote.voteResponses.includes(responseOption)
      ).length;
      const percentVoted = Math.round((matchingVotes / totalVotes) * 100);

      return {
        value: responseOption,
        percentVoted: percentVoted ? percentVoted : 0,
      };
    });
  }, [creatorPoll.responseOptions, votes]);

  const hasVoted = useMemo(
    () => Boolean(getUserVote(user, votes)),
    [user, votes]
  );
  const isClosed = useMemo(
    () => creatorPoll.endTimestamp.toDate() < new Date(),
    [creatorPoll.endTimestamp]
  );

  const votingUsers = useMemo(() => {
    const currentUserId = user?.uid;
    if (!currentUserId) return [];

    const creatorIds = votes.map((vote) => vote.creator);

    // We could attempt to shuffle this at some point, but that is just going to lead to less caching and more requests
    const firstAdditionalUsers = creatorIds
      .filter((id) => id !== currentUserId)
      .slice(0, hasVoted ? 2 : 3);

    return [hasVoted && currentUserId, ...firstAdditionalUsers];
  }, [user?.uid, votes, hasVoted]);

  const formattedCloseDate = useMemo(() => {
    return dayjs(creatorPoll.endTimestamp.toDate()).format("M/D/YY");
  }, [creatorPoll.endTimestamp]);

  return (
    <Container $width={width}>
      <Text style={{ color: "#949BA4", fontSize: "0.75rem" }}>
        Creator Poll
      </Text>
      <Content>
        <Question>{parse(creatorPoll.question)}</Question>
        <RadioGroup
          options={optionsWithVoteCount}
          selected={selected}
          setSelected={setSelected}
          showResults={(hasVoted && !changingVote) || isClosed}
        />
        {isClosed ? (
          <Button disabled>Poll Ended On {formattedCloseDate}</Button>
        ) : hasVoted && !changingVote ? (
          <ChangeVoteButton onClick={() => setChangingVote(true)}>
            <Pencil src="/icons/social/edit.svg" alt="" />
            Change Vote
          </ChangeVoteButton>
        ) : (
          <Button
            disabled={!selected}
            onClick={() => {
              if (!user) {
                requireLogin();
                return;
              }

              if (hasVoted) {
                updateVote(selected);
              } else {
                placeVote(selected);
              }
            }}
          >
            {creationPending || updatePending ? "Submitting..." : "Submit Vote"}
          </Button>
        )}
        <Footer>
          <StyledUserGroup users={votingUsers} size={20} shuffle={false} />
          <Votes>{pluralize("vote", votes.length, true)} ⸱</Votes>
          <TimeFromNow>
            <WatchIcon src="/icons/quests/clock.svg" alt="time icon" />{" "}
            {!getTimeFromDate(creatorPoll.endTimestamp.toDate())
              ? "Completed"
              : getTimeFromDate(creatorPoll.endTimestamp.toDate())}
          </TimeFromNow>
        </Footer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  --poll-background: var(--color-gray-600);
  background-color: var(--poll-background);
  border: 1px solid var(--border-light);
  border-radius: 12px;
  width: 100%;
  padding: 1rem;
  width: ${(props) => props.$width ?? "100%"};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.25rem;

  span {
    color: var(--color-cream-base);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.5px;
  }
`;

const Button = styled.button`
  border: none;
  width: 100%;
  padding: 16px 20px;
  border-radius: 8px;
  font-family: var(--font-primary);
  font-weight: 600;
  line-height: 1rem;
  color: var(--color-cream-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  color: ${(props) =>
    !props.disabled
      ? "var(--color-cream-base)"
      : "var(--disabled-button-text)"};
  background-color: ${(props) =>
    !props.disabled ? "var(--color-blurple)" : "var(--color-gray-300)"};
`;

const ChangeVoteButton = styled.button`
  border: none;
  width: 100%;
  padding: 16px 20px;
  background: transparent;
  color: var(--color-cream-base);
  border-radius: 6px;
  border: 1.5px solid #777e91;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
  height: 48px;
  gap: 4px;
  align-self: stretch;
  color: var(--color-cream-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const Pencil = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const Question = styled.div`
  color: var(--color-cream-base);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  p {
    margin-bottom: 2px;
  }
`;

const Footer = styled.div`
  display: inline-flex;
  margin: -8px auto -6px 0px;
`;

const Votes = styled.span`
  color: #949ba4 !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 26.5px;
  margin: auto;
  margin-left: 8px;
`;

const TimeFromNow = styled.span`
  color: var(--color-pink) !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  margin: auto;
  margin-left: 4px;
`;

const StyledUserGroup = styled(UserDisplayGroup)`
  display: inline-flex;
`;

const WatchIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-bottom: 2px;
`;

export default CreatorPoll;
