import React, { useEffect, useState, useMemo, useContext } from "react";
import styled from "styled-components";
import { getQuestAndPollStates } from "../helpers/general";
import {
  QUEST_TYPES,
  defaultArtQuestCard,
  defaultVoteCard,
  defaultWritingQuestCard,
} from "../helpers/constants";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";
import voteModel from "../lib/firebase/voteModel";
import submissionModel from "../lib/firebase/submissionModel";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { submissionKeys, voteKeys } from "../lib/queryKeys";
import {
  getMixpanelQuestState,
  getMixpanelQuestType,
  trackEvent,
} from "../helpers/mixpanel";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import { AuthContext } from "../context/AuthProvider";
import Button from "./Button";
import { Flex } from "antd";
import { getQuestType, valueByQuestType } from "./quests/QuestFilter";
import {
  questDetailUrl,
  storyworldQuestsUrl,
  submitContentUrl,
  voteUrl,
} from "../lib/routes";

export default function QuestCard({ quest, noPadding = false, width }) {
  const navigate = useNavigate();
  const { user, requireLogin } = useContext(AuthContext);

  const { addBackLocation } = useContext(BackLocationsContext);

  const [endTime, setEndTime] = useState(new Date());
  const [ctaText, setCtaText] = useState("");
  const [previousUserActions, setPreviousUserActions] = useState("");
  const [voted, setVoted] = useState(false);

  const { quest: questState, poll: pollState } = useMemo(
    () => getQuestAndPollStates(quest),
    [quest]
  );

  const questType = getQuestType(quest);

  const { data: userSubmission } = useQuery({
    queryKey: submissionKeys.user_submission(quest.id, user?.uid),
    queryFn: async () => {
      return submissionModel.getMany(
        ["questId", "==", quest.id],
        ["creator", "==", user?.uid],
        ["limit", 1]
      );
    },
    select: (data) => (data.length > 0 ? data[0] : null),
    enabled: !!user?.uid,
  });

  const { data: voteCount } = useQuery({
    queryKey: voteKeys.poll_count(quest.poll?.id),
    queryFn: async () => {
      return voteModel.count(["pollId", "==", quest.poll.id]);
    },
    enabled: !!quest.poll?.id,
  });

  const { data: userVoteCount } = useQuery({
    queryKey: voteKeys.poll_user_count(quest.poll?.id, user?.uid),
    queryFn: async () => {
      return voteModel.count(
        ["pollId", "==", quest.poll.id],
        ["creator", "==", user?.uid]
      );
    },
    enabled: !!quest.poll?.id && !!user?.uid,
  });

  const { data: submissionCount } = useQuery({
    queryKey: submissionKeys.quest_count(quest.id),
    queryFn: async () => {
      return submissionModel.count(["questId", "==", quest.id]);
    },
    enabled: !!quest.id,
  });

  useEffect(() => {
    if (voteCount) {
      setPreviousUserActions(pluralize("vote", voteCount, true));
    }

    if (submissionCount) {
      setPreviousUserActions(pluralize("submission", submissionCount, true));
    }

    if (userVoteCount) {
      setVoted(voteCount > 0);
    }

    if (questState.isCompleted && pollState?.isCompleted) {
      setCtaText("Go to Quest Results");
    } else {
      setCtaText("Go to Quest Details");
    }

    if (questState.isOpen) {
      setEndTime(quest.endTimestamp.toDate());
    } else if (pollState?.isOpen && pollState?.confirmed) {
      setEndTime(quest.poll.endTimestamp.toDate());
    }
  }, [pollState, quest, questState, submissionCount, userVoteCount, voteCount]);

  const heroImageUrl = useMemo(() => {
    const { poll: pollState } = getQuestAndPollStates(quest);

    if (quest.thumbnailUrl && quest.thumbnailUrl !== "") {
      return quest.thumbnailUrl;
    }

    if (pollState?.isCompleted || pollState?.isOpen) {
      return defaultVoteCard;
    }

    return valueByQuestType(
      quest,
      {
        writing: defaultWritingQuestCard,
        visual: defaultArtQuestCard,
      },
      // TODO: remove after ai is gone
      defaultArtQuestCard
    );
  }, [quest]);

  const trackingDetails = {
    quest_id: quest.id,
    quest_name: quest.title,
    quest_state: getMixpanelQuestState(quest),
    quest_type: getMixpanelQuestType(quest),
    storyworld_id: quest.storyworld,
    storyworld_name: quest.storyworldModel.title,
    title: `${quest.storyworldModel.title}: ${quest.title}`,
  };

  const onClickCta = () => {
    addBackLocation();
    navigate(questDetailUrl(quest.storyworldModel.id, quest.id));
    trackEvent("Clicked Go To Quest Details", trackingDetails);
  };

  const onClickVoteCta = () => {
    if (!user) {
      requireLogin();
      return;
    }

    addBackLocation();
    navigate(voteUrl(quest.storyworld, quest.id));
    // trackEvent("Clicked Vote CTA", trackingDetails);
  };

  const onClickCreateCta = () => {
    if (!user) {
      requireLogin();
      return;
    }

    navigate(submitContentUrl(quest.storyworldModel.id, quest.id));
    addBackLocation(storyworldQuestsUrl(quest.storyworldModel.id));
    trackEvent("Clicked Content Creation CTA", trackingDetails);
  };

  return (
    <Container $noPadding={noPadding} $width={width}>
      <HeroImage
        src={heroImageUrl}
        alt=""
        $isDefaultImage={[
          defaultArtQuestCard,
          defaultVoteCard,
          defaultWritingQuestCard,
        ].includes(heroImageUrl)}
        $noPadding={noPadding}
      />

      <Content>
        <TitleContainer>
          <Title>{quest.title}</Title>
        </TitleContainer>
        <Subheader>
          <UsersIcon src="/icons/general/users.svg" alt="" />{" "}
          {previousUserActions} •{" "}
          <TimerLabel>
            <WatchIcon src="/icons/quests/clock.svg" alt="time icon" />{" "}
            {(questState.isCompleted && pollState?.confirmed) ||
            pollState?.isCompleted
              ? `${
                  pollState?.confirmed && pollState?.isCompleted
                    ? "Ended"
                    : "Closed"
                } on ${moment(
                  quest.poll
                    ? quest.poll.endTimestamp.toDate()
                    : quest.endTimestamp.toDate()
                ).format("MM/DD/YY")}`
              : `${moment(endTime).fromNow(true)} left`}
          </TimerLabel>
        </Subheader>
        <Divider />
        <Flex gap="10px" vertical>
          {questState.isOpen &&
            !pollState?.isOpen &&
            !pollState?.isCompleted &&
            !userSubmission &&
            questType === QUEST_TYPES.VISUAL && (
              <Button rounded="slight" onClick={onClickCreateCta}>
                <Icon src="/icons/quests/create-idea-block.svg" alt="" />
                Create Idea Block
              </Button>
            )}

          {questState.isOpen &&
            !pollState?.isOpen &&
            !pollState?.isCompleted &&
            !userSubmission &&
            questType === QUEST_TYPES.WRITING && (
              <Button rounded="slight" onClick={onClickCreateCta}>
                <Icon src="/icons/quests/write-story.svg" alt="" />
                Write Story
              </Button>
            )}

          {pollState?.isOpen &&
            !pollState?.isCompleted &&
            pollState?.confirmed &&
            voted === false && (
              <Button rounded="slight" onClick={onClickVoteCta}>
                Vote Now
              </Button>
            )}

          {pollState?.isOpen && !pollState?.isCompleted && voted && (
            <Button rounded="slight" disabled>
              <Check src="/icons/quests/check-gray.svg" alt="" />
              You Voted
            </Button>
          )}

          {!pollState?.isOpen && !pollState?.isCompleted && userSubmission && (
            <Button rounded="slight" disabled>
              <Check src="/icons/quests/check-gray.svg" alt="" />
              You Submitted
            </Button>
          )}

          <Button variant="ghost" rounded="slight" onClick={onClickCta}>
            {ctaText}
          </Button>
        </Flex>
      </Content>
    </Container>
  );
}

const skeletonStyle = {
  backgroundColor: "var(--color-gray-300)",
  color: "transparent",
  borderRadius: "4px",
};

function Skeleton() {
  return (
    <Container>
      <SkeletonCoverImage />
      <Content>
        <StoryTitle className="animate-pulse">
          <span style={skeletonStyle}>Suns of Haven ⸱ #99</span>
        </StoryTitle>
        <TitleContainer className="animate-pulse">
          <Title style={skeletonStyle}>Hero returns home after 20</Title>
        </TitleContainer>

        <SkeletonButton className="animate-pulse" />
      </Content>
    </Container>
  );
}

QuestCard.Skeleton = Skeleton;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid var(--border-light);
  background: var(--color-gray-600);
  width: ${(props) => props.$width ?? "100%"};

  ${({ $noPadding }) =>
    $noPadding &&
    `
    border: 0;
    border-radius: 0px;
  `}
`;

const TimerLabel = styled.span`
  color: var(--color-pink);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

const SkeletonCoverImage = styled.div`
  width: 100%;
  height: 170px;
  background-color: var(--color-gray-400);
  color: "transparent";
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 20px 20px;
  text-align: left;
  width: 100%;
  font-family: Poppins;
`;

const StoryTitle = styled.h2`
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-pink-dark);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0px;
`;

const Title = styled.div`
  color: var(--color-cream-base);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const SkeletonButton = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background-color: var(--color-gray-300);
  margin-top: 20px;
`;

const WatchIcon = styled.img`
  height: 15px;
  width: 15px;
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 6px;
`;

const Check = styled.img`
  margin-right: 2px;
  width: 24px;
  height: 24px;
`;

const Divider = styled.div`
  margin-top: 10px;
  margin-bottom: 6px;
  background: #35383f;
  height: 1px;
`;

const HeroImage = styled.img`
  object-fit: cover;
  max-height: 125px;
  min-height: 100px;

  border-bottom: ${(props) =>
    props.$isDefaultImage ? "1px solid #35383F" : "none"};

  ${({ $noPadding }) =>
    $noPadding &&
    `
    border-bottom: 0;
  `}
`;

const Subheader = styled.div`
  color: #949ba4;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: 3px;
`;

const UsersIcon = styled.img`
  width: 14px;
  height: 14px;
`;
