import React from "react";
import { Col, Image, Row, Select, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import PollPreview from "./PollPreview";
import CreatePollButtons from "./CreatePollButtons";
import {
  getQuestSubmissionsAndVotes,
  isPollCompleted,
  isPollOpen,
  isTimePassed,
  shuffleArray,
} from "../helpers/general";
import { getQuestSubmissions, setPollConfirmed } from "../helpers/firebase";
import AdminSubmissionPreview from "./AdminSubmissionPreview";
import { createPollQuillModules, spinnerIcon } from "../helpers/constants";
import styled from "styled-components";
import { StyledQuill } from "../components/general";
import { RightAlignedCol, Spacer } from "../styles/CommonElements";
import Button from "../components/Button";

const pollQuestionMaxLength = 450;

export default function CreatePoll({
  userPolls,
  setUserPolls,
  setSelectedPoll,
  selectedPoll,
  isNewPoll,
  selectedStoryworldId,
  selectedQuest,
  questInfo,
  setUserQuests,
}) {
  const [title, setTitle] = useState(selectedPoll.title);
  const [startTimestamp, onStartTimestampChange] = useState(
    isNewPoll
      ? new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
      : selectedPoll.startTimestamp.toDate()
  );
  const [endTimestamp, onEndTimestampChange] = useState(
    isNewPoll
      ? new Date(new Date().getTime() + 60 * 60 * 48 * 1000)
      : selectedPoll.endTimestamp.toDate()
  );
  const [votesPerUser, setVotesPerUser] = useState(
    selectedPoll && selectedPoll.votesPerUser ? selectedPoll.votesPerUser : 1
  );
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [questSubmissions, setQuestSubmissions] = useState([]);
  const [pollConfirmed, setIsPollConfirmed] = useState(selectedPoll.confirmed);

  useEffect(() => {
    async function fetchData() {
      const results = await getQuestSubmissionsAndVotes(questInfo);
      shuffleArray(results);
      results.sort((a, b) => parseInt(b.voteCount) - parseInt(a.voteCount));
      setQuestSubmissions(results);
    }
    fetchData();
  }, [selectedStoryworldId, questInfo]);

  useEffect(() => {
    if (questInfo.ongoing) {
      setHeaderTitle("Approve Submissions");
    } else if (isPreviewing) {
      setHeaderTitle("Review Poll");
    } else if (isNewPoll) {
      setHeaderTitle("Create New Poll");
    } else if (selectedPoll !== undefined && isPollCompleted(selectedPoll)) {
      setHeaderTitle("Poll Results");
    } else {
      setHeaderTitle("Update Poll");
    }
  }, [isNewPoll, isPreviewing, questInfo, selectedPoll]);

  const approveSubmissions = async () => {
    setIsPollConfirmed(null);
    const userQuestsPromise = await getQuestSubmissions(questInfo);
    const confirmedSubmissionIds = [];

    userQuestsPromise.forEach((sub) => {
      if (!sub.rejected) {
        confirmedSubmissionIds.push(sub.id);
      }
    });
    if (selectedPoll.isDraft !== undefined) {
      await setPollConfirmed({
        poll: selectedPoll,
        confirmedSubmissionIds,
      });
    }

    selectedPoll.confirmed = true;
    setIsPollConfirmed(selectedPoll.confirmed);
    setSelectedPoll(selectedPoll);
  };

  return (
    <Container>
      {/* Create, Update, Delete Buttons */}
      <div style={{ width: "100%" }}>
        <Row>
          <Col span={12}>
            <HeaderTitle>{headerTitle}</HeaderTitle>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <CreatePollButtons
              selectedPoll={selectedPoll}
              setUserPoll={setUserPolls}
              userPolls={userPolls}
              isNewPoll={isNewPoll}
              questInfo={questInfo}
              setSelectedPoll={setSelectedPoll}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              title={title}
              showDelete={true}
              selectedStoryworldId={selectedStoryworldId}
              setIsPreviewing={setIsPreviewing}
              isPreviewing={isPreviewing}
              votesPerUser={votesPerUser}
              setUserQuests={setUserQuests}
              selectedQuest={selectedQuest}
              confirmed={pollConfirmed}
              isCompleted={
                selectedPoll !== undefined
                  ? isPollCompleted(selectedPoll)
                  : false
              }
            />
          </Col>
        </Row>
      </div>
      <Spacer $marginTop="40px" />
      {/* END of Create, Update, Delete Buttons */}

      {isPreviewing ||
      (selectedPoll !== undefined && isPollCompleted(selectedPoll)) ? (
        <PollPreview
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          title={title}
          votesPerUser={votesPerUser}
        />
      ) : (
        <div style={{ width: "100%" }}>
          <div style={{ textAlign: "right" }}>
            <div className="create-quest-content-section">
              {questInfo.ongoing && <Ongoing>Ongoing Quest</Ongoing>}
              {!questInfo.ongoing && (
                <div className="poll-form-section">
                  <h1 className="quest-create-header">Poll Information</h1>

                  <Row gutter={36} style={{ marginTop: 30 }}>
                    <Col span={18} className="edit-poll-title-div">
                      <Row>
                        <Col span={12}>
                          <div className="poll-form-element-label">
                            Poll Question
                          </div>
                        </Col>
                        <RightAlignedCol span={12}>
                          <Tooltip
                            placement="left"
                            title={`Max ${pollQuestionMaxLength} characters`}
                          >
                            <img src="icons/info.svg" alt="" />
                          </Tooltip>
                        </RightAlignedCol>
                      </Row>

                      <StyledQuill
                        placeholder={`Write your poll question here. Max ${pollQuestionMaxLength} characters.`}
                        value={title}
                        setValue={setTitle}
                        limit={pollQuestionMaxLength}
                        styles={{
                          minHeight: 170,
                          color: "white",
                        }}
                        modules={createPollQuillModules}
                      />
                    </Col>
                    <Col span={6} className="edit-poll-date-div">
                      <div
                        className="poll-form-element"
                        style={{ marginTop: 0 }}
                      >
                        <div className="poll-form-element-label">
                          Poll opens
                        </div>
                        {selectedPoll.startTimestamp &&
                        !selectedPoll.isDraft &&
                        isTimePassed(selectedPoll.startTimestamp.toDate()) ? (
                          <DateTimePicker
                            value={startTimestamp}
                            className="datetime-picker"
                            maxDate={endTimestamp}
                            clearIcon={null}
                            calendarIcon={null}
                            disableClock={true}
                            disabled
                          />
                        ) : (
                          <DateTimePicker
                            onChange={onStartTimestampChange}
                            value={startTimestamp}
                            className="datetime-picker"
                            maxDate={endTimestamp}
                            clearIcon={null}
                            calendarIcon={null}
                            disableClock={true}
                          />
                        )}
                      </div>

                      <div
                        className="poll-form-element"
                        style={{ marginTop: 0 }}
                      >
                        <Row>
                          <Col span={12}>
                            <div className="poll-form-element-label">
                              Poll closes
                            </div>
                          </Col>
                          <RightAlignedCol span={12} />
                        </Row>

                        {selectedPoll.endTimestamp &&
                        !selectedPoll.isDraft &&
                        isTimePassed(selectedPoll.endTimestamp.toDate()) ? (
                          <DateTimePicker
                            onChange={onEndTimestampChange}
                            value={endTimestamp}
                            className="datetime-picker"
                            minDate={startTimestamp}
                            clearIcon={null}
                            calendarIcon={null}
                            disableClock={true}
                            disabled
                          />
                        ) : (
                          <DateTimePicker
                            onChange={onEndTimestampChange}
                            value={endTimestamp}
                            className="datetime-picker"
                            minDate={startTimestamp}
                            clearIcon={null}
                            calendarIcon={null}
                            disableClock={true}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div
                    className="poll-form-element"
                    style={{
                      margin: 0,
                      display: "inline-flex",
                    }}
                  >
                    <div className="poll-form-element-label">
                      Votes per user:
                    </div>
                    {selectedPoll.startTimestamp &&
                    !selectedPoll.isDraft &&
                    isTimePassed(selectedPoll.startTimestamp.toDate()) ? (
                      <Select
                        defaultValue={votesPerUser}
                        disabled
                        style={{ colorIcon: "white" }}
                        className="create-poll-votes-select"
                        options={[
                          {
                            value: 1,
                            label: 1,
                          },
                          {
                            value: 2,
                            label: 2,
                          },
                          {
                            value: 3,
                            label: 3,
                          },
                        ]}
                      />
                    ) : (
                      <Select
                        defaultValue={votesPerUser}
                        style={{ colorIcon: "white" }}
                        className="create-poll-votes-select"
                        onChange={setVotesPerUser}
                        options={[
                          {
                            value: 1,
                            label: 1,
                          },
                          {
                            value: 2,
                            label: 2,
                          },
                          {
                            value: 3,
                            label: 3,
                          },
                        ]}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {questSubmissions.length > 0 && (
        <div style={{ marginTop: 60 }}>
          <div style={{ width: "100%" }}>
            <Row>
              <Col span={18}>
                <HeaderTitle>Quest Submissions</HeaderTitle>
              </Col>
              {!questInfo.ongoing && (
                <StyledCol span={6}>
                  <Button
                    size="medium"
                    variant={pollConfirmed ? "primary" : "ghost"}
                    width="150px"
                    className={`close-button confirm-submissions-button ${
                      pollConfirmed && "confirm-submissions-button-confirmed"
                    }`}
                    disabled={pollConfirmed === null}
                    onClick={() => approveSubmissions()}
                  >
                    {pollConfirmed !== null ? (
                      `👍 Approve${pollConfirmed === true ? "d" : ""}`
                    ) : (
                      <Image
                        className="spinner"
                        style={{
                          width: "24px",
                          margin: "auto",
                        }}
                        preview={false}
                        src={spinnerIcon}
                      />
                    )}
                  </Button>
                </StyledCol>
              )}
            </Row>
          </div>

          <Spacer $marginTop="2.5rem" />
          <div
            className="create-quest-content-section"
            style={{ padding: "24px 3% 60px 3%" }}
          >
            {questSubmissions.map((submission) => (
              <AdminSubmissionPreview
                key={submission.id}
                submission={submission}
                isPollOpen={isPollOpen(selectedPoll)}
                isPollConfirmed={selectedPoll.confirmed}
                isPollCompleted={
                  selectedPoll !== undefined
                    ? isPollCompleted(selectedPoll)
                    : false
                }
                isQuestOngoing={questInfo.ongoing ?? false}
              />
            ))}
          </div>
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  margin: auto;
  margin-top: 0px;
  display: inline-block;
  width: 100%;
  min-width: 350px;
  margin-bottom: 80px;
`;

const HeaderTitle = styled.div`
  font-size: 32px;
`;

const Ongoing = styled.div`
  margin: 10px 16px;
  text-align: left;
  color: var(--darkTextColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;

const StyledCol = styled(Col)`
  justify-content: end;
  display: flex;
`;
