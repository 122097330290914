import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import pluralize from "pluralize";
import { userKeys } from "../lib/queryKeys";
import userModel from "../lib/firebase/userModel";
import { AuthContext } from "../context/AuthProvider";
import Bell from "../components/Bell";
import { device } from "../lib/breakpoints";
import Hidden from "../components/Hidden";
import { Pagination } from "antd";
import { Spacer } from "../styles/CommonElements";
import useAlertConfig from "../lib/useAlertConfig";
import { comicUrl } from "../lib/routes";

const pageSize = 10;

export default function Episodes({
  storyworld,
  storyworldId: id,
  bookmark,
  episodes,
  toggleStoryworldFollow,
}) {
  const { user, requireLogin } = useContext(AuthContext);

  const [page, setPage] = useState(1);
  const [displayedEpisodes, setDisplayedEpisodes] = useState([]);
  const [sortDir, setSortDir] = useState("asc");
  const navigate = useNavigate();

  const {
    followingStatus: { episodeAlerts },
  } = useAlertConfig(id);

  const { data: userInfo } = useQuery({
    queryKey: userKeys.user(user?.uid),
    queryFn: () => {
      return userModel.getOneById(user?.uid);
    },
    enabled: !!user?.uid,
  });

  // TODO: Add true pagination to this component
  const episodesSorted = useMemo(() => {
    return [...episodes].sort((a, b) => {
      if (sortDir === "asc") {
        return b.publishedAt.toDate() - a.publishedAt.toDate();
      } else {
        return a.publishedAt.toDate() - b.publishedAt.toDate();
      }
    });
  }, [episodes, sortDir]);

  const onFilterClick = () => {
    setSortDir((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  useEffect(() => {
    if (episodesSorted?.length === 0) return;
    const lowerBound = (page - 1) * pageSize;
    const episodesToDisplay = episodesSorted.slice(
      lowerBound,
      lowerBound + pageSize
    );
    setDisplayedEpisodes(episodesToDisplay);
  }, [episodesSorted, page]);

  return (
    <Container>
      <FilterContainer>
        <FilterButton onClick={onFilterClick}>
          <motion.div
            animate={{ rotate: sortDir === "asc" ? 0 : 180 }}
            transition={{ type: "spring", bounce: 0.35 }}
          >
            <FilterIcon src="/icons/sort-episode.svg" />
          </motion.div>
          {episodes && (
            <FilterText>
              {pluralize("Episode", episodes.length, true)}
            </FilterText>
          )}
        </FilterButton>

        <Hidden mobile>
          <NotificationToggle
            onClick={() => {
              if (!user) {
                requireLogin();
                return;
              }
              toggleStoryworldFollow();
            }}
            alt={
              episodeAlerts ? "disable notifications" : "enable notifications"
            }
          >
            <StyledBell
              width="24px"
              height="24px"
              isDisabled={!episodeAlerts}
            />
            Notifications: {episodeAlerts ? "On" : "Off"}
          </NotificationToggle>
        </Hidden>
      </FilterContainer>

      <ListContainer>
        {displayedEpisodes?.map((episode) => (
          <EpisodeCell
            key={episode.id}
            onClick={() => navigate(comicUrl(id, episode.number))}
          >
            <EpisodeImage
              src={
                episode.thumbnailUrl
                  ? episode.thumbnailUrl
                  : storyworld.imageUrl
              }
              alt={episode.title}
            />
            <EpisodeInfo>
              <EpisodeDate
                $isRead={userInfo?.readingList?.includes(episode.id) ?? false}
              >
                #{episode.number} •{" "}
                {dayjs(episode.createdAt.toDate()).format("MMM D, YYYY")}
              </EpisodeDate>
              <EpisodeTitle
                $isRead={userInfo?.readingList?.includes(episode.id) ?? false}
              >
                {episode.title}
              </EpisodeTitle>
            </EpisodeInfo>

            {bookmark && bookmark.episodeId === episode.id && (
              <BookmarkIcon src="/icons/bookmark.svg" alt="Bookmark icon" />
            )}
          </EpisodeCell>
        ))}
      </ListContainer>

      <PaginationWrapper>
        <Pagination
          current={page}
          onChange={(newPage) => setPage(newPage)}
          total={episodes?.length}
          showSizeChanger={false}
          align="center"
          pageSize={pageSize}
        />
      </PaginationWrapper>

      <Hidden tablet desktop>
        <Spacer $marginTop="2rem" />
      </Hidden>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

const BookmarkIcon = styled.img`
  position: absolute;
  top: 0px;
  right: 10px;
  height: 28px;
  width: auto;
`;

const EpisodeCell = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  gap: 16px;
  cursor: pointer;
`;

const EpisodeDate = styled.div`
  color: ${(props) => (props.$isRead ? "var(--color-gray-200)" : "#949BA4")};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
`;

const EpisodeImage = styled.img`
  width: 99px;
  height: 99px;
  object-fit: cover;
`;

const EpisodeInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1.5px;
  padding-right: 2px;
  text-align: left;
  margin: auto;
  margin-left: 0;
`;

const EpisodeTitle = styled.h1`
  color: ${(props) =>
    props.$isRead ? "var(--color-gray-200)" : "var(--color-cream-base)"};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const FilterButton = styled.div`
  display: contents;
  cursor: pointer;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  margin-left: 20px;
  margin-bottom: 12px;
  text-align: left;
  color: var(--color-lavender);
  width: 100%;
  position: relative;

  @media ${device.tablet}, ${device.desktop} {
    margin-left: 0px;
  }
`;

const FilterIcon = styled.img`
  width: 24px;
  height: 24px;
  ${(props) => props.$reverse && `transform: rotate(180deg);`}
`;

const FilterText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 32.5px;
  margin-left: 4px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 96%;
  border-top: 1px solid var(--color-gray-350);
  margin-bottom: 20px;
  div + div {
    border-top: 1px solid var(--color-gray-350);
  }

  @media (${device.tablet}) {
    width: 100%;
  }
`;

const NotificationToggle = styled.button`
  border-radius: 69px;
  background: var(--color-gray-500);
  height: 40px;
  padding: 4px 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  position: absolute;
  color: var(--cream-base, #fff6f2);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  bottom: 0;

  @media (${device.tablet}) {
    right: 14px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.25rem;

  a {
    /* antd's ridiculous token system doesn't give us a way to change the color of these buttons */
    color: var(--color-cream-base) !important;
  }

  .ant-pagination-item-active {
    border-radius: 100px !important;
    background: var(--color-blurple) !important;
  }
`;

const StyledBell = styled(Bell)`
  margin-right: 8px;
`;
