import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Carousel } from "antd";
import { Spacer } from "../../styles/CommonElements";
import Button from "../Button";
import { SESSION_STORAGE } from "../../helpers/constants";
import { device, useBreakpoints } from "../../lib/breakpoints";

const SLIDE_COUNT = 3;
const FINAL_SLIDE = SLIDE_COUNT - 1;

export default function NewUserCarousel() {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();

  const [currentSlide, setCurrentSlide] = useState(0);

  let carouselRef = React.createRef();

  const afterChange = (newSlide) => {
    setCurrentSlide(newSlide);
  };

  return (
    <Container>
      {isMobile && (
        <TopSection>
          <img src="/images/logo-text.svg" />
          <SkipButton
            $finalSlide={currentSlide === FINAL_SLIDE}
            onClick={() => {
              carouselRef.current.goTo(FINAL_SLIDE);
            }}
          >
            Skip
          </SkipButton>
        </TopSection>
      )}

      <InnerContainer>
        <Carousel
          afterChange={afterChange}
          dots={false}
          ref={carouselRef}
          infinite={false}
        >
          <CarouselSection>
            <SlideImage src="/images/onboarding/page1.svg" />
            <HeaderContainer>
              <CarouselHeader>Read Great Stories</CarouselHeader>
              <CarouselSubheader>
                Dive into original webcomics and connect with fellow comic
                lovers
              </CarouselSubheader>
            </HeaderContainer>
          </CarouselSection>
          <CarouselSection>
            <Spacer $marginTop="1rem" />
            <SlideImage src="/images/onboarding/page2.svg" />
            <HeaderContainer>
              <CarouselHeader>
                Share Visual and Written Ideas with Creators
              </CarouselHeader>
              <CarouselSubheader>
                Submit your ideas to creator quests with text or Art{" "}
              </CarouselSubheader>
            </HeaderContainer>
          </CarouselSection>
          <CarouselSection>
            <SlideThreeImage src="/images/onboarding/page3.svg" />
            <HeaderContainer>
              <CarouselHeader>Decide Story Direction</CarouselHeader>
              <CarouselSubheader>
                Participate by voting on the best ideas to drive the story
                forward
              </CarouselSubheader>
            </HeaderContainer>
          </CarouselSection>
        </Carousel>

        <Spacer $marginTop="1.5rem" />

        <ButtonSection>
          <Pagination>
            {Array.from({ length: SLIDE_COUNT }).map((_, index) => (
              <Dot
                key={index}
                $activeSlide={index === currentSlide}
                onClick={() => carouselRef.current.goTo(index)}
              />
            ))}
          </Pagination>

          <Spacer $marginTop="32px" />

          <Button
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (currentSlide !== FINAL_SLIDE) {
                carouselRef.current.next();
              } else {
                // check sessionstorage for redirecturl
                const redirectUrl = sessionStorage.getItem(
                  SESSION_STORAGE.redirectUrl
                );
                if (redirectUrl) {
                  sessionStorage.removeItem(SESSION_STORAGE.redirectUrl);
                  navigate(redirectUrl, { replace: true });
                  return;
                }

                navigate("/stories", { replace: true });
              }
            }}
          >
            {currentSlide !== FINAL_SLIDE ? "Next" : "Let's Get Started!"}
          </Button>
        </ButtonSection>

        {isMobile && <Spacer $marginTop="1rem" />}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  @media ${device.mobile} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const InnerContainer = styled.div`
  height: auto;
  width: 100%;
  margin-top: 1rem;

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      background: #35383f;
    }

    .slick-active {
      width: 32px;
      height: 8px;
      border: 0;
      background: linear-gradient(286deg, #525cf5 0%, #f49090 100%);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    button {
      opacity: 0 !important;
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
`;

const Dot = styled.div`
  border-radius: 100px;
  height: 8px;
  cursor: pointer;

  transition: width 150ms ease-out;
  width: ${(props) => (props.$activeSlide ? "32px" : "8px")};
  background: ${(props) =>
    props.$activeSlide ? "var(--color-pink)" : "var(--grey-light, #555a67)"};
`;

const CarouselSection = styled.div`
  margin: 0;
  height: auto;
  width: 100%;
  color: white;
  overflow: hidden;
  overflow-y: ${(props) => props.$isTos && "scroll"};
  height: unset;
  max-height: 550px;

  @media ${device.tablet} {
    max-height: unset;
  }

  @media ${device.desktop} {
    max-height: unset;
  }
`;

const ButtonSection = styled.div`
  margin: auto;

  @media ${device.mobile} {
    width: 90%;
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 24px;
  opacity: initial;
  pointer-events: auto;
`;

const SkipButton = styled.a`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: white;
  display: block;
  color: rgba(255, 255, 255, 0.35);
  display: ${(props) => props.$finalSlide && "none"};

  &:hover {
    color: white;
  }
`;

const HeaderContainer = styled.div`
  margin: auto;
  width: 90%;
`;

const CarouselHeader = styled.div`
  color: var(--color-cream-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  margin-top: 10px;
`;

const CarouselSubheader = styled.div`
  color: var(--color-gray-50);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin: 4px;
`;

const SlideImage = styled.img`
  margin: auto;
`;

const SlideThreeImage = styled.img`
  margin-top: -5vh;
  overflow: hidden;
  margin: auto;

  @media ${device.mobile} {
    width: 70%;
    max-height: 450px;
    margin: 15px auto;
  }
`;
