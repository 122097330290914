import styled from "styled-components";
import useAlertConfig from "../../lib/useAlertConfig";
import { Spinner } from "../../styles/CommonElements";
import { configs } from "../../helpers/constants";
import { ConfigProvider, Switch, Tooltip } from "antd";
import { device } from "../../lib/breakpoints";

const FeedSettingsList = () => {
  const {
    config: configData,
    isLoading,
    toggleNotifications,
  } = useAlertConfig();

  const switchProps = (settingsId) => ({
    id: settingsId,
    value: configData?.[settingsId],
    onChange: (checked) =>
      toggleNotifications({
        settingId: settingsId,
        value: checked,
      }),
    defaultValue: false,
  });

  if (isLoading) {
    return (
      <Spinner
        style={{
          display: "flex",
          margin: "auto",
          marginTop: "40px",
          marginBottom: "60px",
        }}
      />
    );
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            trackHeight: 28,
            trackMinWidth: 52,
            handleSize: 24,
          },
        },
        token: {
          // Slightly darker than our primary color because antd applies an effect for an un-active checked Switch that can't be overridden....
          colorPrimaryHover: "#4752dc",
          // Matches the default background color
          colorTextTertiary: "rgba(255, 255, 255, 0.25)",
        },
      }}
    >
      <SettingsGroup>
        <legend>Social Settings</legend>
        <Setting>
          <label htmlFor={configs.likeYourComment}>
            Someone likes your comment
          </label>
          <Switch {...switchProps(configs.likeYourComment)} />
        </Setting>
        <Setting>
          <label htmlFor={configs.replyYourComment}>
            Someone replies to your comment
          </label>
          <Switch {...switchProps(configs.replyYourComment)} />
        </Setting>
        <Setting>
          <label htmlFor={configs.likeYourSubmission}>
            Someone likes your submission
          </label>
          <Switch {...switchProps(configs.likeYourSubmission)} />
        </Setting>
        <Setting>
          <label htmlFor={configs.replyYourSubmission}>
            Someone comments on your submission
          </label>
          <Switch {...switchProps(configs.replyYourSubmission)} />
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <Setting>
          <label htmlFor={configs.newWebcomic}>
            New webcomic released{" "}
            <Tooltip
              placement="top"
              title="Alerts you anytime a new webcomic releases episode 1"
              onClick={(e) => {
                // Doesn't full prevent click response so the toggle moves a bit, but it isn't the worst thing
                e.preventDefault();
              }}
            >
              <img
                src="/icons/info-outline.svg"
                alt="info"
                width="24px"
                height="24px"
              />
            </Tooltip>
          </label>
          <Switch {...switchProps(configs.newWebcomic)} />
        </Setting>
      </SettingsGroup>
      <SettingsGroup>
        <legend>Storyworld Activity</legend>
        <p>
          These settings only apply to Storyworlds that you have turned on
          Episode and/or Quest Alerts for
        </p>
        <Setting>
          <label htmlFor={configs.newEpisode}>New episode released</label>
          <Switch {...switchProps(configs.newEpisode)} />
        </Setting>
        <Setting>
          <label htmlFor={configs.newQuest}>New quest released</label>
          <Switch {...switchProps(configs.newQuest)} />
        </Setting>
        <Setting>
          <label htmlFor={configs.questVotingLive}>
            Quest voting goes live
          </label>
          <Switch {...switchProps(configs.questVotingLive)} />
        </Setting>
        <Setting>
          <label htmlFor={configs.resultsAnnounced}>
            Results announcements
          </label>
          <Switch {...switchProps(configs.resultsAnnounced)} />
        </Setting>
        <Setting>
          <label htmlFor={configs.creatorPollLive}>
            New Creator poll released
          </label>
          <Switch {...switchProps(configs.creatorPollLive)} />
        </Setting>
      </SettingsGroup>
    </ConfigProvider>
  );
};

const SettingsGroup = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-inline: 20px;
  padding-bottom: 24px;
  margin-bottom: 24px;

  &:has(+ fieldset) {
    border-bottom: 1px solid #35383f;
  }

  legend {
    text-align: left;
    text-transform: uppercase;
    color: #c2c9dd;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  p {
    text-align: left;
    color: #949ba4;
    font-size: 12px;
    font-weight: 500;
  }

  @media ${device.tablet}, ${device.desktop} {
    padding-inline: 0;
  }
`;

const Setting = styled.div`
  display: flex;
  justify-content: space-between;

  label {
    color: var(--color-cream);
    font-size: 14px;
    font-weight: 500;
    align-self: center;
    text-align: left;
  }
`;

export default FeedSettingsList;
