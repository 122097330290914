import { styled } from "styled-components";
import { Spacer } from "../styles/CommonElements";
import { device } from "../lib/breakpoints";
import StoryCard from "./stories/StoryCard";
import { storyworldQuestsUrl } from "../lib/routes";

export default function StoryworldGroup({ stories, title, subtitle = "" }) {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Spacer $marginTop="16px" />
      <InnerContainer>
        {stories.map((storyworld) => (
          <StoryCard
            key={storyworld.id}
            storyworld={storyworld}
            showActiveMark={false}
            showQuestCount={true}
            showGenre={false}
            showHoverCard={false}
            link={storyworldQuestsUrl(storyworld.id)}
          />
        ))}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  @media ${device.mobile} {
    width: 95%;
    margin: auto;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  gap: 16px;
  display: inline-flex;
  flex-wrap: wrap;
`;

const Subtitle = styled.div`
  color: #949ba4;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;

  @media ${device.mobile} {
    font-size: 12px;
  }

  @media ${device.tablet}, ${device.desktop} {
    font-size: 14px;
  }
`;

const Title = styled.div`
  color: var(--color-cream);
  text-align: left;
  text-transform: capitalize;
  font-family: var(--font-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;
