import React, { useState } from "react";
import { Typography } from "antd";
import { styled } from "styled-components";
import SubmissionPreview from "./preview/SubmissionPreview";
import SubmissionRank from "./SubmissionRank";
import { Spacer } from "../../styles/CommonElements";

const { Text, Paragraph } = Typography;

export default function Submission({
  submission,
  position = "initial",
  rank = null,
  showText = true,
  isClickable = false,
  winner,
  isImageQuest = true,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <SubmissionPreview
        submission={submission}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        winner={winner}
      />
      {isImageQuest ? (
        <ImageSubmission
          position={position}
          submission={submission}
          isClickable={isClickable}
          setDrawerOpen={setDrawerOpen}
          rank={rank}
          showText={showText}
        />
      ) : (
        <WritingSubmission
          position={position}
          submission={submission}
          isClickable={isClickable}
          setDrawerOpen={setDrawerOpen}
          rank={rank}
        />
      )}
    </>
  );
}

const ImageSubmission = ({
  position,
  submission,
  isClickable,
  setDrawerOpen,
  rank,
  showText,
}) => {
  return (
    <ImageContainer style={{ position }}>
      <ImageInnerContainer>
        <SubmissionImage
          src={submission ? submission.imageUrl : ""}
          alt=""
          className="submission"
          onClick={(event) => {
            if (isClickable) {
              event.stopPropagation();
              event.preventDefault();
              if (setDrawerOpen !== null) setDrawerOpen(true);
            }
          }}
        />
      </ImageInnerContainer>
      <SubmissionRank rank={rank} />
      {submission &&
        submission.text &&
        submission.text !== "" &&
        showText &&
        (rank === null || rank === 1) && (
          <>
            <DescriptionContainer
              onClick={(event) => {
                if (isClickable) {
                  event.stopPropagation();
                  if (setDrawerOpen !== null) setDrawerOpen(true);
                }
              }}
            >
              <DescriptionInnerContainer>
                {submission ? submission.text : ""}
              </DescriptionInnerContainer>
            </DescriptionContainer>
          </>
        )}
    </ImageContainer>
  );
};

const WritingSubmission = ({
  position,
  submission,
  rank,
  isClickable,
  setDrawerOpen,
}) => {
  return (
    <WritingContainer style={{ position }}>
      <WritingInnerContainer
        onClick={(event) => {
          if (isClickable) {
            event.stopPropagation();
            event.preventDefault();
            if (setDrawerOpen !== null) setDrawerOpen(true);
          }
        }}
      >
        <div>
          {submission.title && (
            <Text style={{ color: "var(--color-cream)" }} strong>
              {submission.title}
            </Text>
          )}
          {submission.title && submission.text && <Spacer $marginTop="6px" />}
          {submission.text && (
            <Paragraph style={{ color: "#C2C9DD" }} ellipsis={{ rows: 4 }}>
              {submission.text}
            </Paragraph>
          )}
        </div>
      </WritingInnerContainer>
      <SubmissionRank rank={rank} />
    </WritingContainer>
  );
};

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  /* max-height: 180px; */
  object-fit: contain;
  border-radius: 8px;
`;

const ImageInnerContainer = styled.div`
  height: 100%;
  left: 0;
  text-align: center;
  max-width: 248px;
  max-height: 248px;
  object-fit: cover;
  object-position: center;
  margin: auto;
  display: block;
  border-radius: 4px;
  border-radius: 8px;
  border: 3px solid #23262f;
`;

const WritingContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const WritingInnerContainer = styled.div`
  text-align: start;

  .ant-typography-expand {
    color: var(--color-pink);
    font-weight: 600;
  }
`;

const DescriptionInnerContainer = styled.div`
  font: unset;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--color-gray-50);
  white-space: pre-wrap;
  padding: 0px !important;
  margin: 0px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: absolute;
  bottom: 5px;
  width: 75%;
  text-align: center;
  margin-left: 12.5%;

  .ant-image {
    width: 20px !important;
  }
`;

const SubmissionImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 16px;
  object-fit: cover !important;
`;

const DescriptionContainer = styled.div`
  display: block;
  height: 60px;
  text-align: center;
  position: absolute;
  width: calc(100% - 10px);
  left: 5px;
  bottom: 4px;
  margin: auto;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(21, 23, 31, 0) 0%, #15171f 100%);
`;
