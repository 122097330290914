import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { createPoll, deleteUserPoll, updatePoll } from "../helpers/firebase";
import { getQuestsWithPolls } from "../helpers/general";
import styled from "styled-components";

export default function CreatePollButtons({
  setUserPolls,
  selectedPoll,
  userPolls,
  isNewPoll,
  questInfo,
  setSelectedPoll,
  title,
  startTimestamp,
  endTimestamp,
  showDelete,
  isPreviewing,
  setIsPreviewing,
  selectedStoryworldId,
  votesPerUser,
  setUserQuests,
  selectedQuest,
  confirmed = false,
  isCompleted,
}) {
  const [draftEditButton, setDraftEditButton] = useState(null);
  const [nextPublishButton, setNextPublishButton] = useState(null);
  const [, setDeleteButton] = useState(null);

  useEffect(() => {
    if (isCompleted === true) {
      setNextPublishButton(null);
      setDraftEditButton(null);
    } else if (isPreviewing && isNewPoll) {
      setDraftEditButton(
        <Button
          style={{ marginLeft: 12 }}
          className="close-button"
          type="default"
          onClick={async () => {
            setIsPreviewing(false);
          }}
        >
          Edit Poll
        </Button>
      );
    } else if (!isPreviewing && isNewPoll) {
      setDraftEditButton(
        <Button
          style={{ marginLeft: 12 }}
          className="close-button"
          type="default"
          onClick={async () => {
            const promise = await createPoll({
              questInfo,
              startTimestamp,
              endTimestamp,
              title,
              isDraft: true,
              storyworld: selectedStoryworldId,
              votesPerUser,
              createdAt: new Date(),
              lastUpdated: new Date(),
              confirmed,
            });
            const questsWithPollsPromise =
              await getQuestsWithPolls(selectedStoryworldId);
            setUserQuests(questsWithPollsPromise);
            if (promise === true) {
              setSelectedPoll(null);
            }
          }}
        >
          Save draft
        </Button>
      );
    } else if (isPreviewing && !isNewPoll) {
      setDraftEditButton(
        <Button
          style={{ marginLeft: 12 }}
          className="close-button"
          type="default"
          onClick={async () => {
            setIsPreviewing(false);
          }}
        >
          Edit Poll
        </Button>
      );
    } else if (!isPreviewing && !isNewPoll) {
      setDraftEditButton(
        <Button
          style={{ marginLeft: 12 }}
          className="close-button"
          type="default"
          onClick={async () => {
            const promise = await updatePoll({
              pollId: selectedPoll.id,
              questId: questInfo.id,
              selectedQuest,
              startTimestamp,
              endTimestamp,
              title,
              votesPerUser,
              isDraft: true,
              storyworld: selectedStoryworldId,
              createdAt: selectedPoll.createdAt,
              questInfo,
              confirmed,
            });
            const questsWithPollsPromise =
              await getQuestsWithPolls(selectedStoryworldId);
            setUserQuests(questsWithPollsPromise);
            if (promise === true) {
              setSelectedPoll(null);
            }
          }}
        >
          Save draft
        </Button>
      );
    }
  }, [
    isPreviewing,
    isNewPoll,
    endTimestamp,
    setIsPreviewing,
    title,
    selectedPoll.createdAt,
    selectedPoll.id,
    setSelectedPoll,
    setUserPolls,
    startTimestamp,
    selectedStoryworldId,
    votesPerUser,
    questInfo,
    setUserQuests,
    selectedPoll,
    selectedQuest,
    confirmed,
    isCompleted,
  ]);

  useEffect(() => {
    if (isPreviewing && isNewPoll) {
      setNextPublishButton(
        <Button
          style={{ marginLeft: 12 }}
          className="next-preview-button publish-quest-button"
          type="primary"
          onClick={async () => {
            const promise = await createPoll({
              questInfo,
              startTimestamp,
              endTimestamp,
              title,
              isDraft: false,
              storyworld: selectedStoryworldId,
              votesPerUser,
              createdAt: new Date(),
              lastUpdated: new Date(),
              confirmed,
            });
            const questsWithPollsPromise =
              await getQuestsWithPolls(selectedStoryworldId);
            setUserQuests(questsWithPollsPromise);
            if (promise === true) {
              setSelectedPoll(null);
            }
          }}
        >
          Publish Poll
        </Button>
      );
    } else if (isPreviewing && !isNewPoll) {
      setNextPublishButton(
        <Button
          style={{ marginLeft: 12 }}
          className="next-preview-button publish-quest-button"
          type="primary"
          onClick={async () => {
            const promise = await updatePoll({
              pollId: selectedPoll.id,
              questId: questInfo.id,
              startTimestamp,
              endTimestamp,
              title,
              votesPerUser,
              isDraft: false,
              storyworld: selectedStoryworldId,
              createdAt: selectedPoll.createdAt,
              questInfo,
              confirmed,
            });
            const questsWithPollsPromise =
              await getQuestsWithPolls(selectedStoryworldId);
            setUserQuests(questsWithPollsPromise);
            if (promise === true) {
              setSelectedPoll(null);
            }
          }}
        >
          Publish Poll
        </Button>
      );
    } else if (!isPreviewing && !isCompleted) {
      setNextPublishButton(
        <Button
          style={{ marginLeft: 12 }}
          className="next-preview-button"
          type="primary"
          onClick={async () => {
            setIsPreviewing(true);
          }}
        >
          Next: Preview
        </Button>
      );
    }
  }, [
    isPreviewing,
    selectedPoll,
    title,
    endTimestamp,
    startTimestamp,
    isNewPoll,
    selectedPoll.createdAt,
    setIsPreviewing,
    selectedPoll.id,
    setSelectedPoll,
    setUserPolls,
    selectedStoryworldId,
    votesPerUser,
    questInfo,
    setUserQuests,
    selectedQuest,
    confirmed,
    isCompleted,
  ]);

  useEffect(() => {
    if (!isNewPoll && showDelete) {
      setDeleteButton(
        <Button
          className="close-button"
          type="danger"
          onClick={async () => {
            await deleteUserPoll(selectedPoll.id);
            const filteredArray = userPolls.filter(function (e) {
              return e.id !== selectedPoll.id;
            });
            console.log(filteredArray);
            setUserQuests(filteredArray);
            setSelectedPoll(null);
          }}
        >
          Delete Quest
        </Button>
      );
    } else {
      setDeleteButton(null);
    }
  }, [
    showDelete,
    isNewPoll,
    selectedPoll.id,
    setSelectedPoll,
    setUserPolls,
    userPolls,
    setUserQuests,
  ]);

  return (
    <Container>
      {!isPreviewing && (
        <CloseButton
          onClick={async () => {
            setSelectedPoll(null);
          }}
        >
          Back
        </CloseButton>
      )}

      {!questInfo.ongoing && (
        <>
          {draftEditButton}
          {nextPublishButton}
        </>
      )}

      {/* {deleteButton} */}
    </Container>
  );
}

const Container = styled.div`
  display: inline-flex;
`;

const CloseButton = styled(Button)`
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 40px;
  border-radius: 8px;
  max-width: 200;
  margin-bottom: 0;
  margin-left: "auto";
  margin-right: 10;

  &:hover {
    color: var(--hoverColor) !important;
    border-color: var(--hoverColor) !important;
  }

  &:focus {
    /* color: rgba(255, 255, 255, 0.85) !important; */
    border-color: #6f6c6c !important;
  }
`;
