import { message, Upload } from "antd";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { generateRandomId } from "../helpers/firebase";
import styled from "styled-components";

const { Dragger } = Upload;

export default function UploadProvider({
  onSuccess,
  maxMb = 10,
  minWidth = 0,
  maxWidth = 10000,
  minHeight = 0,
  maxHeight = 10000,
  fileBucket = "misc",
  byPassRes = false,
  isDisabled = false,
  children,
}) {
  const storage = getStorage();

  const beforeUpload = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("Please upload a JPG/PNG/JPEG/WEBP image");
    }
    const isLt2M = file.size / 1024 / 1024 < maxMb;
    if (!isLt2M) {
      message.error(`Image must be smaller than ${maxMb}MB`);
    }
    const isRightResolution = new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;
        image.addEventListener("load", () => {
          const { width, height } = image;
          let goodRes = true;

          if (!byPassRes) {
            if (goodRes && minWidth) goodRes = width >= minWidth;
            if (goodRes && maxWidth) goodRes = width <= maxWidth;
            if (goodRes && minHeight) goodRes = width >= minHeight;
            if (goodRes && maxHeight) goodRes = height <= maxHeight;
          }

          if (goodRes) resolve(true);
          else {
            message.error(`Image must be the correct resolution`);
            resolve(false);
          }
        });
      });
    });
    return isJpgOrPng && isLt2M && isRightResolution;
  };

  const props = {
    name: "image",
    multiple: false,
    maxCount: 1,
    accept: ".jpeg,.jpg,.png,.webp",
    beforeUpload,
    action: async (file) => {
      const storageRef = ref(
        storage,
        `${fileBucket}/${new Date()} -- ${generateRandomId()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        () => {
          // handle progress state changes here
        },
        (error) => {
          console.error(error);
          message.error(`${file.name} file upload failed.`);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onSuccess(downloadURL);
            return;
          } catch (error) {
            console.error(error);
          }
        }
      );
    },
  };

  return (
    <Container>
      <StyledDragger {...props} disabled={isDisabled}>
        {children}
      </StyledDragger>
    </Container>
  );
}

const Container = styled.div`
  height: fit-content;
`;

const StyledDragger = styled(Dragger)`
  margin: 0px;

  .ant-upload-btn {
    padding: 0px !important;
  }

  .ant-upload.ant-upload-drag {
    height: fit-content !important;
    padding: 0px !important;
    width: 100%;
    background: transparent;
    border: 0px !important;
  }

  .ant-upload-list {
    display: none;
  }
`;
