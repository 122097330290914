import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { FaqSection } from "../components/general";
import { useEffect } from "react";
import { useBreakpoints } from "../lib/breakpoints";
import ContactForm from "../pages/home/ContactForm";
import { HeaderContainer, MainHeader, Spacer } from "../styles/CommonElements";

export default function Contact() {
  const location = useLocation();
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return (
    <Container>
      <HeaderContainer>
        <MainHeader>Contact us to get started</MainHeader>
      </HeaderContainer>
      {!isMobile && <Spacer $marginTop="1.5rem" />}
      <ContactForm />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
