import React from "react";
import { Avatar } from "antd";
import styled from "styled-components";
import userModel from "../../lib/firebase/userModel";
import { useQuery } from "@tanstack/react-query";
import { isUserBanned } from "../../helpers/report";
import { getDefaultProfilePicByUserId } from "../../helpers/authentication";
import { userKeys } from "../../lib/queryKeys";

// Like UserDisplay, but only the avatar and doesn't link to Profile
export default function UserAvatar({ userId, size = 40 }) {
  const userQuery = useQuery({
    queryKey: userKeys.user(userId),
    queryFn: () => {
      return userModel.getOneById(userId);
    },
    enabled: !!userId,
  });

  const banQuery = useQuery({
    queryKey: userKeys.user_banned(userId),
    queryFn: () => {
      return isUserBanned(userId);
    },
  });

  if (!userQuery.data || userQuery.status !== "success") return null;
  if (banQuery.status !== "success") return null;

  return (
    <Container>
      <Avatar
        size={size}
        icon={
          <UserProfileImage
            src={
              banQuery.data
                ? getDefaultProfilePicByUserId(userId)
                : userQuery.data.pfp
            }
          />
        }
      />
    </Container>
  );
}

const Container = styled.div`
  .username {
    margin-left: 6px !important;
  }
`;

const UserProfileImage = styled.img`
  image-size: contain;
`;
