import { useState } from "react";
import { useForm } from "react-hook-form";
import debounce from "debounce-promise";
import {
  FormError,
  InputHeader,
  Spacer,
  StyledInput,
} from "../../styles/CommonElements";
import styled from "styled-components";
import Button from "../Button";
import { getRandomProfilePic } from "../../helpers/authentication";
import { BioEditor } from "./CreateProfile";
import {
  containsProfanity,
  removeIllegalCharacters,
} from "../../helpers/general";
import { MIN_USERNAME_LENGTH, ERROR_PROFANITY } from "../../helpers/constants";
import ProfileImageWizard from "./ProfileImageWizard";
import Backlink from "../Backlink";
import { usernameExists } from "../../helpers/firebase";

const CreateProfileForm = ({ onSubmit, isSubmitting }) => {
  const [profileWizardVisible, setProfileWizardVisible] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    getFieldState,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      profilePic: getRandomProfilePic(),
    },
  });

  return (
    <>
      {profileWizardVisible && (
        <BackButton onClick={() => setProfileWizardVisible(false)} />
      )}

      <div style={{ textAlign: "center" }}>
        <EditProfilePic
          onClick={() => {
            setProfileWizardVisible(!profileWizardVisible);
          }}
        >
          <UserProfileImage src={watch("profilePic")} />
          <ProfileImgOverlay>
            <PencilIcon
              src="/icons/social/edit.svg"
              alt="Edit profile picture"
            />
          </ProfileImgOverlay>
        </EditProfilePic>
      </div>
      {profileWizardVisible && (
        <>
          <Spacer $marginTop="1.5rem" />
          <ProfileImageWizard
            profilePic={watch("profilePic")}
            setProfilePic={(data) => setValue("profilePic", data)}
          />
        </>
      )}
      <Spacer $marginTop="1.5rem" />
      {!profileWizardVisible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* The logic may have to stick outside of traditional form logic but we can maintain the state this way */}
          <input {...register("profilePic")} type="hidden" />

          <InputHeader htmlFor="username">Username</InputHeader>
          <div style={{ position: "relative" }}>
            <StyledInput
              {...register("username", {
                required: "Please enter a username",
                minLength: {
                  value: MIN_USERNAME_LENGTH,
                  message: `Username must be at least ${MIN_USERNAME_LENGTH} characters`,
                },
                validate: {
                  profane: (value) =>
                    !containsProfanity(value) || ERROR_PROFANITY,
                  exists: debounce(async (value) => {
                    const doesUsernameExist = await usernameExists(value);

                    return (
                      !doesUsernameExist || "This username is already taken"
                    );
                  }, 300),
                },
                onChange: (e) =>
                  setValue("username", removeIllegalCharacters(e.target.value)),
              })}
              id="username"
              className="transition-colors"
              placeholder={`Must be at least ${MIN_USERNAME_LENGTH} characters`}
              maxLength={40}
              type="text"
            />
            {getFieldState("username").isDirty && errors.username ? (
              <ErrorIcon src="/icons/x-thick.svg" />
            ) : (
              getFieldState("username").isDirty && (
                <Checkmark src="/icons/checkmark.svg" />
              )
            )}
          </div>
          {errors.username && <FormError>{errors.username.message}</FormError>}

          <Spacer $marginTop="1.5rem" />

          <InputHeader htmlFor="bio">Bio (optional)</InputHeader>
          <BioEditor
            {...register("bio", {
              validate: (value) => !containsProfanity(value) || ERROR_PROFANITY,
            })}
            id="bio"
            placeholder={"Write something about yourself"}
            maxLength={150}
          />
          {errors.bio && <FormError>{errors.bio.message}</FormError>}

          <Spacer $marginTop="3.5rem" />

          <Button
            type="submit"
            size="large"
            isLoading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            Create Profile
          </Button>
        </form>
      )}
    </>
  );
};

const EditProfilePic = styled.button`
  border: 0;
  background: none;
  margin-top: 20px;
  margin-bottom: -10px;
  position: relative;
  text-align: center;
`;

const UserProfileImage = styled.img`
  object-fit: cover;
  height: 120px;
  width: 120px;
  border-radius: 100px;
`;

const ProfileImgOverlay = styled.div`
  position: absolute;
  bottom: 0;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: white;
  background: var(--lightPurple);
  border-radius: 32px;
`;

const PencilIcon = styled.img`
  padding: 10px;
  cursor: pointer;
`;

const Checkmark = styled.img`
  right: 20px !important;
  top: 35%;
  width: 17px;
  height: 17px;
  position: absolute;
`;

const ErrorIcon = styled.img`
  right: 20px !important;
  top: 35%;
  width: 16px;
  height: 16px;
  position: absolute;
`;

const BackButton = styled(Backlink)`
  position: absolute;
  /* matches antdesign modal padding */
  top: 24px;
  left: 24px;

  color: var(--color-gray-200);

  svg {
    height: 22px;
    width: 22px;
  }
`;

export default CreateProfileForm;
