import React from "react";
import styled from "styled-components";
import { maxIdeaBlockEntries } from "../helpers/constants";
import UploadProvider from "./UploadProvider";
import { generateRandomId } from "../helpers/firebase";

const arrowIcon = "/icons/search/skinny-arrow.svg";
const uploadIcon = "/images/upload/upload.svg";
const xIcon = "/icons/x-thick.svg";
const paintingIcon = "/icons/idea-block/painting.svg";
const carrotIcon = "/icons/idea-block/carrot.svg";
const diamondIcon = "/icons/idea-block/diamond-small.svg";

const placeholderIcons = [paintingIcon, carrotIcon, diamondIcon];

export default function BottomBar({
  addEntry,
  entries = [],
  goToNextStep,
  removeEntry,
}) {
  return (
    <Container>
      {entries.map((entry, index) => (
        <Entry key={index}>
          <Image src={entry} alt="" />
          <RemoveButton
            onClick={() => {
              removeEntry(entry);
              const imgObj = document.querySelector(`img[src="${entry}"]`);
              if (imgObj) {
                imgObj.classList.remove("selected");
              }
            }}
          >
            <X src={xIcon} alt="" />
          </RemoveButton>
        </Entry>
      ))}

      {/* Add blank entries for the remaining slots */}
      {[...Array(maxIdeaBlockEntries - entries.length)].map((entry, index) => (
        <Entry key={generateRandomId() + entry}>
          <EmptyEntry>
            <PlaceholderIcon
              src={placeholderIcons[entries.length + index]}
              alt=""
              $width={"65%"}
            />
          </EmptyEntry>
        </Entry>
      ))}

      <CircleButton
        onClick={() => {
          if (entries.length === maxIdeaBlockEntries) {
            goToNextStep();
          }
        }}
      >
        <UploadProvider
          onSuccess={addEntry}
          fileBucket="submissions"
          isDisabled={entries.length >= maxIdeaBlockEntries}
        >
          {entries.length < maxIdeaBlockEntries && (
            <Icon src={uploadIcon} alt="" $size={"34px"} $marginTop={"12px"} />
          )}
          {entries.length === maxIdeaBlockEntries && (
            <Icon src={arrowIcon} alt="" $size={"30px"} $marginTop={"14px"} />
          )}
        </UploadProvider>
      </CircleButton>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  padding: 16px 16px 16px 16px;
  background: var(--color-gray-600);
  width: 100%;
  height: 106px;
  bottom: 0;
  left: 0;
  align-content: center;
  display: flex;
  z-index: 99999;
`;

const CircleButton = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 100px;
  background: var(--blurple);
  margin: auto 0 auto auto;
  opacity: ${(props) => props.disabled && "0.5"};
`;

const EmptyEntry = styled.div`
  width: 100%;
  height: 100%;
  border: 1px dashed #81889d;
  border-radius: 8px;
  margin-right: 10px;
  background: var(--color-gray-300);
  display: flex;
  justify-content: center;
`;

const Entry = styled.div`
  width: 59px;
  height: 100%;
  border-radius: 8px;
  position: relative;
  margin-right: 10px;
`;

const Icon = styled.img`
  height: ${(props) => props.$size};
  width: ${(props) => props.$size};
  margin: auto;
  margin-top: ${(props) => props.$marginTop};
  display: block;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const PlaceholderIcon = styled.img`
  width: ${(props) =>
    props.$width} !important; // needed !important to override the width: 10px
  height: auto !important; // needed !important to override the height: 10px
`;

const RemoveButton = styled.div`
  width: 24px;
  height: 24px;
  background: var(--color-gray-200);
  border-radius: 100px;
  position: absolute;
  top: -4px;
  right: -6px;
`;

const X = styled.img`
  height: 10px;
  width: 10px;
  margin: 6px auto auto auto;
  display: block;
  filter: brightness(0) invert(1);
`;
