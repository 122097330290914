"use client";
// ----- Components Import -----
import { styled } from "styled-components";
import { Col, Row } from "antd";

// ----- Assets Import -----
import { Container, Spacer } from "../../styles/CommonElements";
import features from "../../helpers/features";
import ServiceCard from "./ServiceCard";
import { device } from "../../lib/breakpoints";
import { APP_NAME } from "../../helpers/constants";

export default function HomeFeatures() {
  return (
    <FeaturesWrapper>
      <Header>Grow your business with {APP_NAME}</Header>
      <Container>
        <Spacer $vertical="40px" />
        <Row
          gutter={[
            { xs: 30, md: 30 },
            { xs: 40, sm: 40 },
          ]}
        >
          {features.map((item) => (
            <Col sm={24} md={8} key={item.id}>
              <FeatureItem>
                <ServiceCard feature={item} />
              </FeatureItem>
            </Col>
          ))}
        </Row>
      </Container>
    </FeaturesWrapper>
  );
}

// ----- Styles -----
const FeaturesWrapper = styled.section`
  padding: 5rem 0;

  h2 {
    max-width: 61.5rem;
    text-align: center;
    margin: 0 auto 3rem auto;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: left;

  @media (max-width: 35.9375rem) {
    max-width: 100%;
  }

  &.ant-col:last-child {
    display: none;
  }
`;

const Header = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  text-transform: capitalize;

  @media ${device.tablet} {
    left: 5%;
    top: 30%;
    scale: 0.8;
  }

  @media ${device.mobile} {
    left: 5%;
    top: 30%;
    scale: 0.8;
  }
`;
