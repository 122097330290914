import React, { useState } from "react";
import { Input } from "antd";
import styled from "styled-components";
import { Spacer } from "../../styles/CommonElements";
import CharacterCounter from "../CharacterCounter";

const { TextArea } = Input;

const maxResponseOptions = 5;
const maxResponseLimit = 90;

export default function ResponseEditor({
  disableEditor,
  responseOptions,
  setResponseOptions,
  handleResponseOptionChange,
}) {
  const [focusedIndex, setFocusedIndex] = useState(null);

  return (
    <Container>
      {responseOptions.map((response, index) => (
        <>
          <ResponseContainer key={index} $isFocused={index === focusedIndex}>
            <InputArea
              style={{
                resize: "none",
              }}
              placeholder={`Option ${index + 1}`}
              value={response}
              maxLength={maxResponseLimit}
              autoSize={{ maxRows: 1 }}
              onChange={(event) => handleResponseOptionChange(event, index)}
              onFocus={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFocusedIndex(index);
              }}
              onBlur={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFocusedIndex(null);
              }}
              disabled={disableEditor}
            />
            <CharacterCounter count={response.length} max={maxResponseLimit} />
            <DeleteIcon
              src="/icons/trash.svg"
              alt=""
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (!disableEditor)
                  setResponseOptions((ops) =>
                    ops.filter((p, i) => i !== index)
                  );
              }}
            />
          </ResponseContainer>
          <Spacer $marginTop="6px" />
        </>
      ))}
      {responseOptions.length < maxResponseOptions && (
        <AddOptionButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setResponseOptions((ops) => [...ops, ""]);
          }}
          disabled={disableEditor}
        >
          + Add another option <MaxText>Maximum of 5</MaxText>
        </AddOptionButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin: 20px 0px 30px 30px;
  width: 90%;
`;

const AddOptionButton = styled.button`
  border: 0;
  background: transparent;
  margin-top: 0px;
  display: inline-flex;
  justify-content: center;
  margin-top: 4px;
  align-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px; /* 200% */
  cursor: pointer;
`;

const MaxText = styled.span`
  color: var(--inputFieldText);
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; /* 200% */
  margin-left: 8px;
`;

const ResponseContainer = styled.div`
  border-radius: 8px;
  background: var(--color-gray-500);
  display: flex;
  height: 46px;
  transition: border 0.3s;
  border: ${(props) =>
    props.$isFocused ? "2px solid var(--color-blurple)" : "2px solid #353945"};

  div {
    width: 60px;
    margin: auto;
  }
`;

const InputArea = styled(TextArea)`
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px; /* 200% */
  border: 0;
  height: 100% !important;
  padding-top: 10px;

  &:hover {
    background: transparent;
    border: 0;
  }

  &:focus {
    background: transparent;
    box-shadow: 0 0 0 0px;
  }

  &:active {
    background: transparent;
    border: 0;
  }

  &:disabled {
    background: transparent;
  }
`;

const DeleteIcon = styled.img`
  color: var(--inputFieldText);
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; /* 200% */
  width: 60px;
  padding: 13px 0px;
  opacity: 0.5;
  margin-left: -4px;
  cursor: pointer;
`;
