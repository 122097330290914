import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  APP_NAME,
  APP_SUPPORT_EMAIL,
  APP_URL,
  FULL_COMPANY_NAME,
} from "../helpers/constants";
import { Spacer } from "../styles/CommonElements";

const APP_PRIVACY_URL = `https://${APP_URL}/privacy`;
const APP_TERMS_URL = `https://${APP_URL}/terms-of-use`;

export default function TermsOfService({ mustAcceptTos = false }) {
  return (
    <Container $mustAcceptTos={mustAcceptTos}>
      <MainHeader>Terms of Service</MainHeader>
      <Spacer $marginTop="2rem" />
      <TextContainer $mustAcceptTos={mustAcceptTos}>
        <TextSection>
          {APP_NAME} Services (“{APP_NAME}” or “Services”) are provided by{" "}
          {FULL_COMPANY_NAME} (“We”, “Our”, “{APP_NAME}”) to you (“User” or
          “Users”).
        </TextSection>
        <TextSection>
          If you use any of our Services, then you agree to these Terms of
          Services and our Privacy Policy (collectively the “Agreement”).
        </TextSection>

        <Header>Acceptance of the Terms of Service</Header>
        <List>
          <ListItem>
            By using the {APP_NAME} website or any other {APP_NAME} product or
            service provided to you by {APP_NAME} (collectively the “Services”),
            you signify your agreement to: 1) these terms and conditions (the
            “Terms of Service”); and 2) our Privacy Policy (found at{" "}
            <Link to="/privacy">{APP_PRIVACY_URL}</Link>). Although we will
            attempt to notify you when major changes are made to these Terms of
            Service, you should periodically review the most up to date version
            (found at <Link to="/terms-of-use">{APP_TERMS_URL}</Link>).{" "}
            {APP_NAME} may, in its sole discretion, modify or revise these Terms
            of Service at any time. All modifications to the Agreement that we
            post on the Services will be effective immediately upon posting, and
            your continued use of the Services after we post changes to the
            Agreement will confirm your acceptance of the changes. If you do not
            agree to the modified Agreement, you must stop using the Services.
            Nothing in these Terms of Service shall be deemed to confer any
            third party rights.
          </ListItem>
          <ListItem>
            If you are using our Services on behalf of a business, that business
            accepts these terms. It will hold harmless and indemnify {APP_NAME}{" "}
            and its officers, agents, and employees from any claim, suit or
            action arising from or related to the use of the Services or
            violation of these terms, including any liability or expense arising
            from claims, losses, damages, suits, judgments, litigation costs and
            attorneys' fees.
          </ListItem>
        </List>

        <Header>Ability to Accept the Terms of Service</Header>
        <List>
          <ListItem>
            To use the Services, you must be at least eighteen (18) years old
            (or the minimum permissible age under the applicable law of your
            jurisdiction, if higher) ("Minimum Age"). The use of the Service by
            anyone under the Minimum Age is unauthorized and unlicensed. By
            using the Service, you represent and warrant that you meet the
            foregoing requirement. If you do not meet this requirement, you may
            not access or use the Service.
          </ListItem>
        </List>

        <Header>Service</Header>
        <List>
          <ListItem>
            These Terms of Service apply to all users of {APP_NAME} Services,
            including users who are also contributors of Content to the Service.
            "Content" includes the text, software, images, photos, videos,
            audiovisual combinations, interactive features and other Content you
            may view on, access through, or contribute to the Service. The
            Service includes all aspects of any
            {APP_NAME} product, including but not limited to software and
            services offered via the {APP_NAME} website.
          </ListItem>
          <ListItem>
            The Service may contain links to third party websites that are not
            owned or controlled by {APP_NAME}. {APP_NAME} has no control over,
            and assumes no responsibility for, the content, privacy policies, or
            practices of any third party websites. In addition, {APP_NAME} will
            not and cannot censor or edit the content of any third-party site.
            By using the Service, you expressly relieve {APP_NAME} from any and
            all liability arising from your use of any third-party website.
          </ListItem>
          <ListItem>
            Accordingly, we encourage you to be aware when you leave a{" "}
            {APP_NAME} Service and to read the terms and conditions and other
            policies of each other website that you visit.
          </ListItem>
        </List>

        <Header>{APP_NAME} User Accounts</Header>
        <List>
          <ListItem>
            In order to access {APP_NAME} Services, you will have to create a{" "}
            {APP_NAME} user account. You may never use another's user account
            without permission. When creating your user account, you must
            provide accurate and complete information. You are solely
            responsible for the activity that occurs on your account, and you
            must keep your account password secure. You must notify {APP_NAME}{" "}
            immediately of any breach of security or unauthorized use of your
            account.
          </ListItem>
          <ListItem>
            Although {APP_NAME} will not be liable for your losses caused by any
            unauthorized use of your account, you may be liable for the losses
            of {APP_NAME} or others due to such unauthorized use.
          </ListItem>
        </List>

        <Header>General Use of the Service</Header>
        <TextSection>
          {APP_NAME} hereby grants you permission to access and use their
          Services as set forth in these Terms of Service, provided that:
        </TextSection>
        <List>
          <ListItem>
            You do not alter or modify any part of the Service.
          </ListItem>
          <ListItem>
            You agree not to use or launch any automated system, including
            without limitation, "robots," "spiders," or "offline readers," that
            accesses the Service in a manner that attempts to forge the presence
            of a user accessing the site.
          </ListItem>
          <ListItem>
            You agree not to collect or harvest any personally identifiable
            information, including user account names, from the Service, nor to
            use the communication systems provided by the Service for any
            commercial solicitation purposes.
          </ListItem>
          <ListItem>
            In your use of the Service, you will comply with all applicable
            laws.
          </ListItem>
          <ListItem>
            {APP_NAME} reserves the right to discontinue or otherwise alter or
            modify any aspect of the Service at any time.
          </ListItem>
        </List>

        <Header>Your Content and Conduct</Header>
        <List>
          <ListItem>
            As a {APP_NAME} account holder you may submit Content to certain{" "}
            {APP_NAME} Services, including images.
          </ListItem>
          <ListItem>
            You shall be solely responsible for your own Content and the
            consequences of submitting and publishing your Content on a
            {APP_NAME} Media Service.
          </ListItem>
          <ListItem>
            In order to operate the Service, we must obtain from you certain
            license rights in your submitted Content so that actions we take in
            operating the Service are not considered legal violations.
            Accordingly, by using the Service and submitting your Content, you
            grant us a license to access, use, host, cache, store, reproduce,
            transmit, display, publish, distribute, and modify your Content. You
            agree that these rights and licenses are royalty free, transferable,
            sub-licensable, worldwide and irrevocable, and include a right for
            us to make your Content available to, and pass these rights along
            to, others with whom we have contractual relationships related to
            the provision of the Services, and to otherwise permit access to or
            disclose your Content to third parties if we determine such access
            is necessary to comply with our legal obligations. To the fullest
            extent permitted by applicable law, the Company reserves the right,
            and has absolute discretion, to remove, screen, edit, or delete any
            of your Content at any time, for any reason, and without notice.
          </ListItem>
        </List>

        <Header>Termination</Header>
        <List>
          <ListItem>
            You have the right to opt out of the Services at anytime by deleting
            your account (can be found within your Profile)
          </ListItem>
          <ListItem>
            Users, who violate these Terms of Service, tamper with the operation
            of any program, or engage in any conduct that is detrimental or
            unfair to {APP_NAME}, the program or any other users (in each case
            as determined by {APP_NAME}' sole discretion) are subject to
            suspension or cancellation of their user account(s).
          </ListItem>
          <ListItem>
            {APP_NAME} will terminate a user's access to the Service if, under
            appropriate circumstances, the user is determined to be a repeat
            infringer.
          </ListItem>
          <ListItem>
            {APP_NAME} reserves the right to decide whether Content violates
            these Terms of Service for reasons other than copyright
            infringement, such as, but not limited to, pornography, obscenity,
            or excessive length. {APP_NAME} may at any time, without prior
            notice and in its sole discretion, remove such Content and/or
            terminate a user's account for submitting such material in violation
            of these Terms of Service.
          </ListItem>
        </List>

        <Header>Warranties and Disclaimers</Header>
        <List>
          <ListItem>
            Other than as expressly set out in these terms or in additional
            terms, {APP_NAME} will not make any specific promises about the
            Service. For example, {APP_NAME} will not make any commitments about
            the content within the service, the specific function of the Service
            or their reliability, availability, or ability to meet your needs.
          </ListItem>
          <ListItem>
            Some jurisdictions provide for certain warranties, like the implied
            warranty of merchantability, fitness for a particular purpose and
            non-infringement. To the extent permitted by law, we exclude all
            warranties.
          </ListItem>
        </List>

        <Header>Indemnification</Header>
        <TextSection>
          You release {APP_NAME} and any of its affiliates, directors, officers,
          employees, representatives, agents and agencies (collectively, the
          "Released Parties") from any liability whatsoever, and waive any and
          all causes of action related to any claims, costs, injuries, losses,
          or damages of any kind arising out of or in connection with any{" "}
          {APP_NAME} Service (including, without limitation, participation in
          the membership) or delivery, misdelivery, acceptance, possession, use
          of or inability to use any benefits (including, without limitation,
          claims, costs, injuries, losses and damages related to personal
          injuries, death, damage to or destruction of property, rights of
          publicity or privacy, defamation or portrayal in a false light,
          whether intentional or unintentional), whether under a theory of
          contract, tort (including negligence), warranty or other theory. Some
          jurisdictions do not allow (i) the exclusion of implied warranties on
          applicable statutory rights; and in the case of the countries of the
          United Kingdom, Luxembourg, the Netherlands and Spain, (ii)
          limitations on a contracting party's liability with regards to damages
          or death caused due to its negligence or intentional misconduct, so
          the above exclusions and limitations may not apply in some cases.
        </TextSection>

        <Header>Limitation of Liability</Header>
        <TextSection>
          The Service will be governed, construed and interpreted under the laws
          of the United States. Members agree to be bound by these Terms of
          Service and by the decisions of {APP_NAME}, which are final.{" "}
          {APP_NAME} reserves the right to suspend or cancel user accounts and
          any benefits should viruses, bugs, unauthorized human intervention or
          other causes beyond our control affect the administration, security or
          proper handling of the benefits or {APP_NAME} otherwise becomes (as
          determined in its sole discretion) incapable of running the program as
          planned. In case of bankruptcy, {APP_NAME} reserves the right to deny
          all benefits and obligations that it may have.
        </TextSection>
        <TextSection>
          When permitted by law, {APP_NAME} will not be responsible for lost
          profits, revenues, or data, financial losses or indirect, special,
          consequential, exemplary, or punitive damages.
        </TextSection>
        <TextSection>
          To the extent permitted by law, the total liability of {APP_NAME}, for
          any claim under these terms, including any implied warranties, is
          limited to the amount you paid us to use the Service (or, if we
          choose, to supply you the Service again).
        </TextSection>
        <TextSection>
          In all cases, {APP_NAME} will not be liable for any loss or damage
          that is not reasonably foreseeable.
        </TextSection>
        <TextSection>
          In case of any conflict or inconsistency, further agreements between
          you and {APP_NAME} supersede these Terms of Service.
        </TextSection>
        <Header>Contact</Header>
        <TextSection>
          For questions about these Terms of Service, send an email to{" "}
          <a
            href={`mailto:${APP_SUPPORT_EMAIL}`}
            rel="noreferrer"
            target="_blank"
          >
            {APP_SUPPORT_EMAIL}
          </a>
          .
        </TextSection>
      </TextContainer>
    </Container>
  );
}

const Container = styled.div`
  color: black;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  text-align: left;
  width: 80%;
  margin: auto;
  margin-bottom: ${(props) => (props.$mustAcceptTos ? "0" : "40")}px;
  margin-top: 3rem;

  a {
    color: var(--hoverColor);
  }
`;

const TextContainer = styled.div`
  overflow-y: ${(props) => (props.$mustAcceptTos ? "auto" : "unset")};
  overflow-x: ${(props) => (props.$mustAcceptTos ? "hidden" : "unset")};
  height: ${(props) => (props.$mustAcceptTos ? "500px" : "auto")};
  max-height: ${(props) => (props.$mustAcceptTos ? "500px" : "auto")};
  box-shadow: ${(props) =>
    props.$mustAcceptTos ? "0px 4px 0px 0px rgba(0, 0, 0, 0.1)" : "none"};
  padding-right: 5%;
  padding-bottom: 32px;

  @media screen and (max-height: 825px) {
    height: ${(props) => (props.$mustAcceptTos ? "400px" : "auto")};
    max-height: ${(props) => (props.$mustAcceptTos ? "400px" : "auto")};
  }
`;

const Header = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-top: 24px;
`;

const MainHeader = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 18px;
`;

const List = styled.ul`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const ListItem = styled.li`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 10px;
`;

const TextSection = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;
