import React from "react";
import styled from "styled-components";
import { Dropdown, Space, ConfigProvider } from "antd";
import likeModel from "../../lib/firebase/likeModel";

export default function SortDropdownButton({
  sortType,
  setSortType,
  comments,
  setSortedComments,
}) {
  const items = [
    {
      label: (
        <SortHeader
          selected={sortType === "top"}
          onClick={async () => {
            setSortType("top");
            await Promise.all(
              comments.map(async (c) => {
                const likeCount = await likeModel.count([
                  "submissionId",
                  "==",
                  c.id,
                ]);
                c.likeCount = likeCount;
              })
            );
            const sorted = comments.sort(function (a, b) {
              return b.likeCount - a.likeCount || b.createdAt - a.createdAt;
            });
            setSortedComments(sorted);
          }}
        >
          Top
        </SortHeader>
      ),
      key: "1",
    },
    {
      label: (
        <SortHeader
          selected={sortType === "newest"}
          onClick={() => {
            setSortType("newest");
            const sorted = comments.sort(function (a, b) {
              return b.createdAt - a.createdAt;
            });
            setSortedComments(sorted);
          }}
        >
          Newest
        </SortHeader>
      ),
      key: "2",
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: {
            paddingBlock: 10,
            controlPaddingHorizontal: 20,
          },
        },
      }}
    >
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <Button>
          <Space>
            <Header>{sortType}</Header>
            <Carat src="/icons/social/carat-down-white.svg" alt="" />
          </Space>
        </Button>
      </Dropdown>
    </ConfigProvider>
  );
}

const Button = styled.button`
  background: transparent;
  border: 0;
  padding-block: 10px;
`;

const Header = styled.span`
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  color: var(--color-pink);
  text-transform: capitalize;
`;

const Carat = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 2px;
`;

const SortHeader = styled.span`
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  color: ${(props) =>
    props.selected ? "var(--color-pink)" : "var(--color-gray-200)"};
`;
