import React, { useRef, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import Spinner from "./Spinner";
import styled from "styled-components";

const Canvas = (props) => {
  const canvas = useRef();

  const onChange = async () => {
    const paths = await canvas.current.exportPaths();

    // only respond if there are paths to draw (don't want to send a blank canvas)
    if (paths.length) {
      const data = await canvas.current.exportImage("svg");
      props.onDraw(data);
    }
  };

  useEffect(() => {
    if (props.predictions.length) {
      canvas.current.clearCanvas();
    }
  }, [props.predictions]);

  return (
    <Container>
      {/* USER UPLOADED IMAGE */}
      {props.userUploadedImage && (
        <PreviewImage
          src={URL.createObjectURL(props.userUploadedImage)}
          alt="preview image"
          className="absolute w-full h-full"
        />
      )}

      {props.userUploadedImage && (
        <SketchContainer style={{ zIndex: 1000 }}>
          <ReactSketchCanvas
            ref={canvas}
            strokeWidth={40}
            strokeColor="black"
            canvasColor="transparent"
            onChange={onChange}
          />
        </SketchContainer>
      )}
    </Container>
  );
};

export default Canvas;

const Container = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
`;

const PredictionImage = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const PreviewImage = styled.img`
  object-fit: contain;
`;

const SketchContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
