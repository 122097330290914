import React, { useContext } from "react";
import { Button, Col, Divider, Row } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import {
  getEpisodesByStoryworldId,
  getStoryworldPolls,
  getStoryworldQuests,
} from "../helpers/firebase";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { AdminQuestDisplay, CreatePoll, CreateQuest } from "../views";
import styled from "styled-components";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { useOutletContext } from "react-router-dom";

export default function QuestAdmin() {
  const [isNewQuest, setIsNewQuest] = useState(false);
  const [startArrowDirection, setStartArrowDirection] = useState("ascending");
  const [endArrowDirection, setEndArrowDirection] = useState("ascending");
  const [userQuests, setUserQuests] = useState([]);
  const [storyworldPolls, setStoryworldPolls] = useState([]);
  const [isNewPoll, setIsNewPoll] = useState(true);
  const [questInfo, setQuestInfo] = useState(null);

  const { selectedStoryworld } = useContext(SelectedStoryworldContext);
  const {
    selectedQuest,
    setSelectedQuest,
    selectedPoll,
    setSelectedPoll,
    setStoryworldQuests,
    setSelectedEpisode,
  } = useOutletContext();

  const selectedStoryworldId = selectedStoryworld?.id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedQuest]);

  useEffect(() => {
    async function fetchData() {
      if (selectedStoryworldId === undefined) return;
      const storyworldPollsPromise =
        await getStoryworldPolls(selectedStoryworldId);
      setStoryworldPolls(storyworldPollsPromise);
      const storyworldQuestsPromise =
        await getStoryworldQuests(selectedStoryworldId);
      storyworldQuestsPromise.forEach((el) => {
        const result = storyworldPollsPromise.filter(function (e) {
          return e.questId === el.id;
        });
        if (result.length > 0) {
          el.poll = result[0];
        }
      });
      const storyworldEpisodesPromise =
        await getEpisodesByStoryworldId(selectedStoryworldId);
      storyworldQuestsPromise.forEach((el) => {
        const result = storyworldEpisodesPromise.filter(function (e) {
          return e.winningSubmission && e.winningSubmission.questId === el.id;
        });
        if (result.length > 0) {
          el.episode = result[0];
        }
      });
      setUserQuests(storyworldQuestsPromise);
      setStoryworldQuests(storyworldQuestsPromise);
    }
    fetchData();
  }, [selectedStoryworldId, setStoryworldQuests]);

  function switchDirection(val, setVal) {
    if (val === "descending") {
      val = "ascending";
    } else if (val === "ascending") {
      val = "descending";
    }
    setVal(val);
  }

  const handleStartSort = () => {
    setEndArrowDirection("ascending");
    switchDirection(startArrowDirection, setStartArrowDirection);
    const direction = startArrowDirection;
    let arr;
    if (direction === "ascending") {
      arr = [...userQuests].sort(function compare(a, b) {
        if (a.startTimestamp < b.startTimestamp) return 1;
        if (a.startTimestamp > b.startTimestamp) return -1;
        return 0;
      });
    } else if (direction === "descending") {
      arr = [...userQuests].sort(function compare(a, b) {
        if (a.startTimestamp > b.startTimestamp) return 1;
        if (a.startTimestamp < b.startTimestamp) return -1;
        return 0;
      });
    }
    setUserQuests(arr);
  };

  const handleEndSort = () => {
    setStartArrowDirection("ascending");
    switchDirection(endArrowDirection, setEndArrowDirection);
    const direction = endArrowDirection;
    let arr;
    if (direction === "ascending") {
      arr = [...userQuests].sort(function compare(a, b) {
        if (a.endTimestamp < b.endTimestamp) return 1;
        if (a.endTimestamp > b.endTimestamp) return -1;
        return 0;
      });
    } else if (direction === "descending") {
      arr = [...userQuests].sort(function compare(a, b) {
        if (a.endTimestamp > b.endTimestamp) return 1;
        if (a.endTimestamp < b.endTimestamp) return -1;
        return 0;
      });
    }
    setUserQuests(arr);
  };

  return (
    <>
      {selectedQuest === null && selectedPoll === null && (
        <>
          <div style={{ marginBottom: 40 }}>
            <Row>
              <Col span={12}>
                <h2>Quests</h2>
              </Col>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    className="new-quest-button"
                    onClick={async () => {
                      const quest = { title: "" };
                      setIsNewQuest(true);
                      setSelectedQuest(quest);
                    }}
                    size="medium"
                  >
                    + New Quest
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Row gutter={48}>
            <Col span={11} className="column-title">
              Information
            </Col>
            <Col span={3}>
              <Row>
                <Col span={18} className="column-title">
                  Start
                </Col>
                <Col span={6}>
                  <Button
                    className="chevron-button"
                    onClick={() => {
                      handleStartSort();
                    }}
                  >
                    {startArrowDirection === "ascending" ? (
                      <DownOutlined />
                    ) : (
                      <UpOutlined />
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              <Row>
                <Col span={18} className="column-title">
                  End
                </Col>
                <Col span={6}>
                  <Button
                    className="chevron-button"
                    onClick={() => {
                      handleEndSort();
                    }}
                  >
                    {endArrowDirection === "ascending" ? (
                      <DownOutlined />
                    ) : (
                      <UpOutlined />
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={7} className="column-title">
              <Row>
                <Col span={18}>Poll Status</Col>
                <Col span={6}></Col>
              </Row>
            </Col>
          </Row>
          <Divider />
        </>
      )}
      {selectedQuest !== null && (
        <CreateQuest
          setUserQuests={setUserQuests}
          selectedQuest={selectedQuest}
          setSelectedQuest={setSelectedQuest}
          userQuests={userQuests}
          isNewQuest={isNewQuest}
        />
      )}
      {selectedQuest === null && selectedPoll === null && (
        <div>
          {userQuests.map((quest) => (
            // TODO: supply react key
            // eslint-disable-next-line react/jsx-key
            <AdminQuestDisplayContainer>
              <AdminQuestDisplay
                quest={quest}
                setSelectedQuest={setSelectedQuest}
                setIsNewQuest={setIsNewQuest}
                setSelectedPoll={setSelectedPoll}
                setIsNewPoll={setIsNewPoll}
                setQuestInfo={setQuestInfo}
                setSelectedEpisode={setSelectedEpisode}
              />
            </AdminQuestDisplayContainer>
          ))}
        </div>
      )}
      {selectedPoll && (
        <CreatePoll
          setUserPolls={setStoryworldPolls}
          selectedPoll={selectedPoll}
          setSelectedPoll={setSelectedPoll}
          userPolls={storyworldPolls}
          isNewPoll={isNewPoll}
          selectedStoryworldId={selectedStoryworldId}
          questInfo={questInfo}
          setUserQuests={setUserQuests}
          selectedQuest={selectedQuest}
        />
      )}
    </>
  );
}

const AdminQuestDisplayContainer = styled.div`
  margin: 14px 0px;
`;
