import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import {
  DIALOG_NAME,
  useOpenParamDialog,
} from "../helpers/useDialogSearchParam";
import { comicUrl } from "../lib/routes";
import { ReactSVG } from "react-svg";

export default function ComicReaderFooter({
  episodes,
  currentEpisode,
  storyworld,
  setEpNumber,
  comments,
}) {
  const expandCommentSheet = useOpenParamDialog(DIALOG_NAME.comments);

  if (!storyworld || !currentEpisode) return null;

  return (
    <>
      <Container>
        <OpenCommentsContainer>
          <OpenCommentsButton
            onClick={() => {
              expandCommentSheet();
            }}
          >
            <CommentIcon src="/icons/social/comment-icon.svg" alt="" />
            <CommentNumber>{comments.length}</CommentNumber>
          </OpenCommentsButton>
        </OpenCommentsContainer>
        <ScrollContainer>
          {episodes.map((ep, index) => (
            <SquareContainer
              key={ep.id}
              className={`${
                currentEpisode.number === index + 1
                  ? "episode-square-selected"
                  : ""
              }`}
            >
              <Link
                to={comicUrl(storyworld.id, index + 1)}
                onClick={() => {
                  setEpNumber(index + 1);
                  // setCommentsOpen(false);
                }}
              >
                <EpImage
                  src={ep.thumbnailUrl ? ep.thumbnailUrl : storyworld.imageUrl}
                  $showImage={currentEpisode.number !== index + 1}
                />
                <EpNumber>{index + 1}</EpNumber>
              </Link>
            </SquareContainer>
          ))}
        </ScrollContainer>
      </Container>
    </>
  );
}

const commentButtonContainerWidth = 55;

const Container = styled.span`
  width: 100%;
  height: 50px;
  background: #353a46;
  position: fixed;
  bottom: 0;
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  padding: 0px 4px;
  z-index: 1;

  .episode-square-selected {
    background: var(
      --grad-1,
      linear-gradient(208deg, #505dff 8.02%, #f5a2ff 86.31%)
    ) !important;
  }
`;

const ScrollContainer = styled.span`
  width: calc(100% - ${commentButtonContainerWidth}px);
  margin: auto;
  height: 50px;
  background: #353a46;
  position: fixed;
  bottom: 0;
  display: -webkit-inline-box;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 0px 4px;
  z-index: 1;
  right: 0;

  .episode-square-selected {
    background: var(
      --grad-1,
      linear-gradient(208deg, #505dff 8.02%, #f5a2ff 86.31%)
    ) !important;
  }
`;

const OpenCommentsContainer = styled.span`
  width: ${commentButtonContainerWidth}px;
  margin: auto;
  height: 50px;
  background: #353a46;
  position: fixed;
  bottom: 0;
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  padding: 0px 4px;
  z-index: 1;
  left: 0;
  text-align: center;
`;

const SquareContainer = styled.div`
  position: relative;
  height: 40px;
  width: 42px !important;
  margin: auto;
  margin: 5px 2px 4px 2px;
  background-size: cover !important;
  border-radius: 4px;
  background-position: center !important;
  font-weight: bold !important;

  span {
    font-weight: bold;
    color: white;
    margin: auto;
    height: 100%;
    display: block;
    margin-top: 8px;
  }
`;

const EpImage = styled.img`
  opacity: 0.3;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
  display: ${(props) => !props.$showImage && "none"};
`;

const EpNumber = styled.span`
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  color: var(--color-cream-dark);
  text-align: center;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`;

const OpenCommentsButton = styled.span`
  display: flex;
  width: 50px;
  z-index: 1;
  margin: auto;
  text-align: center !important;
`;

const CommentIcon = styled(ReactSVG)`
  display: block;
  margin-right: 4px;
  margin-left: 8px;
  margin-top: 1px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const CommentNumber = styled.span`
  color: var(--color-cream-base);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.895px;
`;
