import React, { useState } from "react";
import CreateAccount from "./CreateAccount";
import CreateProfile from "./CreateProfile";
import { useSearchParams } from "react-router-dom";
import { device } from "../../lib/breakpoints";
import styled from "styled-components";

export default function SignUp() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [goToProfileCreation, setGoToProfileCreation] = useState(false);

  return (
    <SignupContainer>
      {!goToProfileCreation && (
        <CreateAccount
          emailAddress={email}
          setEmailAddress={setEmail}
          setGoToProfileCreation={setGoToProfileCreation}
        />
      )}
      {goToProfileCreation && <CreateProfile />}
    </SignupContainer>
  );
}

// If a user is forced here on a larger device because they didn't complete their profile,
// this is a quick fix to make the page work and not get complicated with forcing them into a modal.
// Exported for storybook
export const SignupContainer = styled.div`
  @media ${device.tablet} {
    width: 500px;
    margin-top: 5vh;
    margin-inline: auto;
  }

  @media ${device.desktop} {
    width: 600px;
    margin-top: 10vh;
    margin-inline: auto;
  }
`;
