import styled from "styled-components";

const InputField = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-50);
  padding-left: 18px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: black;
  margin: auto;

  &:focus {
    // border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }

  &:disabled {
    background: var(--color-gray-100);
    pointer-events: none;
    user-select: none;
    color: var(--color-gray-300);
  }
`;

export default InputField;
