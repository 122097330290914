import React, { useContext, useMemo } from "react";
import { styled } from "styled-components";
import { getWinningQuestSubmissions } from "../../helpers/firebase";
import { shuffleArray } from "../../helpers/general";
import UserDisplay from "../UserDisplay";
import { useQuery } from "@tanstack/react-query";
import { Row, Col } from "antd";
import { questKeys } from "../../lib/queryKeys";
import { Center, Spinner } from "../../styles/CommonElements";
import { AuthContext } from "../../context/AuthProvider";
import { valueByQuestType } from "../quests/QuestFilter";
import {
  WritingQuestResult,
  WritingQuestWinner,
} from "./results/WritingQuestResult";
import { ArtQuestResult, ArtQuestWinner } from "./results/ArtQuestResult";
import { IdeaResult, IdeaWinner } from "./results/IdeaResult";
import SubmissionFilter from "../submissions/SubmissionFilter";

export default function QuestResults({ quest }) {
  const { user } = useContext(AuthContext);

  const {
    data: results,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: questKeys.winners(quest.id),
    queryFn: async () => {
      return getWinningQuestSubmissions(quest);
    },
  });

  const shuffledSubmissions = useMemo(() => {
    if (!results) {
      return [];
    }

    const winners = results.slice(1, 3);
    const nonWinners = results.slice(3);

    const userSubmissionIndex = nonWinners.findIndex(
      (result) => result.creator === user?.uid
    );

    let unrankedUserSubmission = [];
    if (userSubmissionIndex !== -1) {
      unrankedUserSubmission = nonWinners.splice(userSubmissionIndex, 1);
    }

    // It has been requested that a user's submission that otherwise doesn't place in the top 3 is slotted into spot 4 on the results
    const shuffledResults =
      userSubmissionIndex !== -1
        ? [...unrankedUserSubmission, ...shuffleArray(nonWinners)]
        : shuffleArray(nonWinners);

    return [...winners, ...shuffledResults];
  }, [results, user?.uid]);

  const winningSubmission = results?.[0];

  const resultColSpan = valueByQuestType(
    quest,
    {
      text: 24,
    },
    12
  );

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  // TODO: Fallback states rather than returning null
  if (!isSuccess) return;
  if (!winningSubmission) return;

  return (
    <Container>
      <BackgroundPattern
        src="/images/contribute/contributor-sheet-bg.svg"
        alt=""
      />
      <SubmissionFilter
        submission={winningSubmission}
        text={
          <WritingQuestWinner>
            <WritingQuestResult entry={winningSubmission} isWinner={true}>
              <UserDisplayContainer>
                <UserDisplay
                  userId={winningSubmission.creator}
                  showName={true}
                  size={31}
                  isWinner={true}
                />
                <Crown src="/icons/crown.svg" alt="" />
              </UserDisplayContainer>
              <PlaceText>1st Place</PlaceText>
            </WritingQuestResult>
          </WritingQuestWinner>
        }
        genAiArt={
          <ArtQuestWinner>
            <ArtQuestResult entry={winningSubmission} isWinner={true}>
              <UserDisplayContainer>
                <UserDisplay
                  userId={winningSubmission.creator}
                  showName={true}
                  size={31}
                  isWinner={true}
                />
                <Crown src="/icons/crown.svg" alt="" />
              </UserDisplayContainer>
              <PlaceText>1st Place</PlaceText>
            </ArtQuestResult>
          </ArtQuestWinner>
        }
        idea={
          <IdeaWinner>
            <IdeaResult entry={winningSubmission} isWinner={true}>
              <UserDisplayContainer>
                <UserDisplay
                  userId={winningSubmission.creator}
                  showName={true}
                  size={31}
                  isWinner={true}
                />
                <Crown src="/icons/crown.svg" alt="" />
              </UserDisplayContainer>
              <PlaceText>1st Place</PlaceText>
            </IdeaResult>
          </IdeaWinner>
        }
      />
      <OtherSubmissionContainer>
        <Row gutter={[10, 10]}>
          {shuffledSubmissions.map((submission, index) => (
            <Col key={submission?.id} span={resultColSpan}>
              <SubmissionFilter
                submission={submission}
                text={
                  <WritingQuestResult entry={submission}>
                    <OtherUserDisplayContainer>
                      <UserDisplay
                        userId={submission.creator}
                        showName={true}
                        size={31}
                        isWinner={index < 3}
                      />
                      {index === 0 && <OtherPlaces>• 2nd place</OtherPlaces>}
                      {index === 1 && <OtherPlaces>• 3rd place</OtherPlaces>}
                    </OtherUserDisplayContainer>
                  </WritingQuestResult>
                }
                genAiArt={
                  <ArtQuestResult entry={submission}>
                    <OtherUserDisplayContainer>
                      <UserDisplay
                        userId={submission.creator}
                        showName={true}
                        size={31}
                        isWinner={index < 3}
                      />
                      {index === 0 && <OtherPlaces>• 2nd</OtherPlaces>}
                      {index === 1 && <OtherPlaces>• 3rd</OtherPlaces>}
                    </OtherUserDisplayContainer>
                  </ArtQuestResult>
                }
                idea={
                  <IdeaResult entry={submission}>
                    <OtherUserDisplayContainer>
                      <UserDisplay
                        userId={submission.creator}
                        showName={true}
                        size={31}
                        isWinner={index < 3}
                      />
                      {index === 0 && <OtherPlaces>• 2nd</OtherPlaces>}
                      {index === 1 && <OtherPlaces>• 3rd</OtherPlaces>}
                    </OtherUserDisplayContainer>
                  </IdeaResult>
                }
              />
            </Col>
          ))}
        </Row>
      </OtherSubmissionContainer>
    </Container>
  );
}

const Container = styled.div`
  display: block;
  position: relative;
  width: 90%;
  margin: auto;
  margin-top: 14px;

  img {
    max-width: 100%;
  }

  .ant-row {
    margin-bottom: 18px !important;
  }
`;

const Crown = styled.img`
  height: 18px;
  width: 18px;
  margin-top: 3px;
  margin-left: 15px;
  display: inline-block;
`;

const BackgroundPattern = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: 2px;
  margin: auto !important;
  display: flex;
  text-align: center;
`;

const UserDisplayContainer = styled.div`
  text-align: center;
  margin-top: 16px;
  display: flex;
  justify-content: center;

  .ant-avatar {
    height: 26px !important;
    width: 26px !important;
  }
`;

const OtherUserDisplayContainer = styled.div`
  text-align: left;
  margin-top: 6px;
  display: flex;

  font-size: 12px;

  .ant-avatar {
    height: 22px !important;
    width: 22px !important;
  }
`;

const OtherSubmissionContainer = styled.div``;

const PlaceText = styled.div`
  color: #777e91;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  margin-top: -3px;
`;

const OtherPlaces = styled.span`
  color: #777e91;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: -10px;
`;
