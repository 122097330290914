import { useEffect } from "react";
import styled from "styled-components";
import AlertSourceContext, { SOURCE } from "../../context/AlertSourceContext";
import SubmissionFilter from "../submissions/SubmissionFilter";
import VoteIdeaSubmission from "./VoteIdeaSubmission";
import VoteTextSubmission from "./VoteTextSubmission";

export default function VoteGrid({
  questSubmissions,
  addToVotes,
  removeVote,
  votes,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleVote = (submission) => {
    if (!votes.includes(submission)) addToVotes(submission);
    else removeVote(submission);
  };

  return (
    <SubmissionGrid>
      {questSubmissions.map((submission) => (
        <AlertSourceContext.Provider
          key={submission.id}
          value={{ sourceType: SOURCE.submission, sourceId: submission.id }}
        >
          <SubmissionFilter
            submission={submission}
            idea={
              <VoteIdeaSubmission
                submission={submission}
                handleVote={handleVote}
                isSelected={votes.includes(submission)}
              />
            }
            text={
              <VoteTextSubmission
                submission={submission}
                handleVote={handleVote}
                isSelected={votes.includes(submission)}
              />
            }
          />
        </AlertSourceContext.Provider>
      ))}
    </SubmissionGrid>
  );
}

const SubmissionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
  row-gap: 32px;
`;
