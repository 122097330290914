import styled, { css } from "styled-components";
import { useSnapCarousel } from "react-snap-carousel";
import CustomSheet from "./CustomSheet";
import Button from "./Button";
import { ReactSVG } from "react-svg";
import IdeaCarousel from "./IdeaCarousel";

const IdeaBlockEnlargedSheet = ({
  isOpen,
  isSelected,
  handleClose,
  handleVote,
  submission,
}) => {
  const { pages, scrollRef, activePageIndex, prev, next, goTo } =
    useSnapCarousel();

  return (
    <StyledSheet
      isOpen={isOpen}
      onClose={handleClose}
      tweenConfig={{ ease: "easeOut", duration: 0.3 }}
      hasCloseIcon
    >
      <Container>
        <Top>
          <IdeaCarousel images={submission.images} ref={scrollRef} />
        </Top>
        <Footer>
          <Title>{submission.title}</Title>
          {submission.text && <Description>{submission.text}</Description>}
          <CarouselControls>
            <div>
              <Arrow
                src="/images/quests/arrow-thin.svg"
                alt="Scroll to previous image"
                style={{
                  transform: "rotate(180deg)",
                }}
                onClick={prev}
              />
            </div>
            <div>
              {pages.map((_, index) => (
                <PageCircle
                  key={index}
                  $isActive={activePageIndex === index}
                  onClick={() => goTo(index)}
                />
              ))}
            </div>
            <div>
              <Arrow
                src="/images/quests/arrow-thin.svg"
                alt="Scroll to next image"
                onClick={next}
              />
            </div>
          </CarouselControls>
          <AddToVote>
            <Button
              size="small"
              variant={isSelected ? "ghost" : "primary"}
              onClick={() => {
                handleClose();
                handleVote();
              }}
            >
              {isSelected ? "Remove Vote" : "Add to Vote"}
            </Button>
          </AddToVote>
        </Footer>
      </Container>
    </StyledSheet>
  );
};

const StyledSheet = styled(CustomSheet)`
  & .react-modal-sheet-container {
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 20px;
`;

const Top = styled.div`
  min-height: 0px;
  flex-grow: 2;
`;

const Footer = styled.div`
  min-height: 180px;
  flex-shrink: 0;
  padding-inline: 20px;
`;

const Title = styled.div`
  margin-block: 16px;
  color: var(--color-cream-base);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
`;

const Description = styled.div`
  color: white;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
`;

const CarouselControls = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 160px;
  margin-top: 58px;
  margin-inline: auto;
  margin: 58px auto 32px auto;
`;

const AddToVote = styled.div`
  max-width: 170px;
  margin-inline: auto;
  margin-bottom: 28px;
`;

const Arrow = styled(ReactSVG)`
  color: var(--color-gray);

  /* Accounting for added divs by React-SVG */
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    height: 24px;
    width: auto;
  }
`;

const PageCircle = styled.button`
  /* Button reset */
  padding: 0;
  margin: 0;
  border: none;
  outline: none;

  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #555a67;
  margin-inline: 4px;
  cursor: pointer;

  ${(props) =>
    props.$isActive &&
    css`
      background: white;
    `}
`;

export default IdeaBlockEnlargedSheet;
