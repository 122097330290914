import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { queryMany, queryOne } from "./all";
import { userTable } from "../../helpers/tables";
import { getAuth } from "firebase/auth";

const table = userTable;

const userModel = {
  create: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const { bio, email, id, pfp, username } = props;
    const body = JSON.stringify({
      username,
      usernameLowercase: username.toLowerCase(),
      bio,
      email,
      id,
      pfp,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body,
      });

      return await response.json();
    } catch (error) {
      console.error("Error creating user profile:", error);
      throw error;
    }
  },
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });
    return data;
  },
  update: async ({ id, data }) => {
    const userRef = doc(getFirestore(), table, id);
    await updateDoc(userRef, {
      ...data,
      usernameLowercase: data.username.toLowerCase(),
    });
    return await queryOne({ table, id });
  },
};

export default userModel;
