import { styled } from "styled-components";
import { useContext, useEffect, Fragment } from "react";
import { useLocalStorage } from "react-use";
import { useInfiniteQuery } from "@tanstack/react-query";
import { alertKeys } from "../../lib/queryKeys";
import alertModel from "../../lib/firebase/alertModel";
import hasAlertModel from "../../lib/firebase/hasAlertModel";
import { Spinner } from "../../styles/CommonElements";
import { Alert } from "../feed";
import { ALERT_AMOUNT_PER_PAGE, LOCAL_STORAGE } from "../../helpers/constants";
import { AuthContext } from "../../context/AuthProvider";
import { useInView } from "react-intersection-observer";

const FeedList = () => {
  const { user, loading } = useContext(AuthContext);
  const [, setHasAlerts] = useLocalStorage(LOCAL_STORAGE.hasAlerts);
  const { ref, inView } = useInView();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    isPending,
  } = useInfiniteQuery({
    queryKey: alertKeys.user(user?.uid),
    queryFn: async ({ pageParam }) => {
      return alertModel.getPage(pageParam);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.length < ALERT_AMOUNT_PER_PAGE) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (_0, _1, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    refetchOnMount: "always",
    enabled: !!user,
  });

  useEffect(() => {
    if (isSuccess) {
      alertModel.setAllRead();
      hasAlertModel.setSeen();
      setHasAlerts(false);
      // Force the browser to trigger storage event
      window.dispatchEvent(new Event("storage"));
    }
  }, [isSuccess, setHasAlerts]);

  // Trigger infinite scroll
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <Container>
      {(isPending || loading) && (
        <Spinner style={{ margin: "auto", marginTop: "32px" }} />
      )}

      {!isPending && !data?.pages?.[0]?.length > 0 && (
        <EmptyFeed>
          <img src="icons/eyes-sparkle.svg" alt="" />

          <h3>Welcome to the Feed</h3>
          <p>
            Where you can stay up to date on all the cool things that are
            happening
          </p>
        </EmptyFeed>
      )}

      {!isPending &&
        data?.pages?.[0]?.length > 0 &&
        data.pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page.map((alert, index) => (
              <Alert alert={alert} key={index} />
            ))}
          </Fragment>
        ))}
      {/* Intersection being observed */}
      {hasNextPage && <div ref={ref} style={{ height: "1px", width: "1px" }} />}
      {isFetchingNextPage && (
        <Spinner style={{ margin: "auto", marginTop: "12px" }} />
      )}
    </Container>
  );
};

export default FeedList;

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const EmptyFeed = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 18px;
  flex: 1;

  > div {
    /* color-gray-500 */
    background-color: rgba(35, 38, 47, 0.7);

    h3 {
      color: var(--color-cream);
      font-family: var(--font-primary);
      font-size: 24px;
      font-weight: 600;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    p {
      color: #737784;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.2px;
    }

    img {
      transform: translateX(10px);
    }
  }
`;
