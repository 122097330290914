import React, { useContext, useMemo } from "react";
import { styled } from "styled-components";
import { getQuestAndPollStates } from "../../helpers/general";
import { useQuery } from "@tanstack/react-query";
import submissionModel from "../../lib/firebase/submissionModel";
import voteModel from "../../lib/firebase/voteModel";
import { useNavigate } from "react-router-dom";
import { storyworldKeys, submissionKeys, voteKeys } from "../../lib/queryKeys";
import {
  getMixpanelQuestState,
  getMixpanelQuestType,
  trackEvent,
} from "../../helpers/mixpanel";
import storyworldModel from "../../lib/firebase/storyworldModel";
import { AuthContext } from "../../context/AuthProvider";
import { getQuestType, valueByQuestType } from "../quests/QuestFilter";
import { QUEST_TYPES } from "../../helpers/constants";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { questDetailUrl, submitContentUrl, voteUrl } from "../../lib/routes";

export default function QuestCta({ quest }) {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { addBackLocation } = useContext(BackLocationsContext);

  const questType = getQuestType(quest);

  const { quest: questState, poll: pollState } = useMemo(() => {
    return getQuestAndPollStates(quest);
  }, [quest]);

  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(quest.storyworld),
    queryFn: async () => {
      return storyworldModel.getOneById(quest.storyworld);
    },
  });

  const userSubmissionQuery = useQuery({
    queryKey: submissionKeys.user_submission(quest.id, user?.uid),
    queryFn: async () => {
      return submissionModel.getMany(
        ["questId", "==", quest.id],
        ["creator", "==", user?.uid],
        ["limit", 1]
      );
    },
    select: (data) => (data.length > 0 ? data[0] : null),
    enabled: !!user?.uid,
  });

  const userVoteQuery = useQuery({
    queryKey: voteKeys.quest_user_vote(quest.id, user?.uid),
    queryFn: async () => {
      return voteModel.getMany(
        ["questId", "==", quest.id],
        ["creator", "==", user?.uid],
        ["limit", 1]
      );
    },
    select: (data) => (data.length > 0 ? data[0] : null),
    enabled: !!user?.uid,
  });

  const onClickCreateCta = () => {
    addBackLocation(questDetailUrl(quest.storyworld, quest.id));
    navigate(submitContentUrl(quest.storyworld, quest.id));
  };

  const onClickVoteCta = () => {
    addBackLocation(questDetailUrl(quest.storyworld, quest.id));
    navigate(voteUrl(quest.storyworld, quest.id));
    trackEvent("Clicked Vote CTA in Quest Details", {
      quest_id: quest.id,
      quest_name: quest.title,
      quest_state: getMixpanelQuestState(quest),
      quest_type: getMixpanelQuestType(quest),
      storyworld_id: quest.storyworld,
      storyworld_name: storyworldQuery.data.title,
      title: `${storyworldQuery.data.title}: ${quest.title}`,
    });
  };

  if (userSubmissionQuery.status !== "success") return null;
  if (userVoteQuery.status !== "success") return null;

  return (
    <Container>
      {questState.isOpen &&
        !pollState?.isOpen &&
        !pollState?.isCompleted &&
        !userSubmissionQuery.data &&
        questType === QUEST_TYPES.VISUAL && (
          <ActionButton onClick={onClickCreateCta}>
            <Icon src="/icons/quests/create-idea-block.svg" alt="" />
            Create Idea Block
          </ActionButton>
        )}

      {questState.isOpen &&
        !pollState?.isOpen &&
        !pollState?.isCompleted &&
        !userSubmissionQuery.data &&
        questType === QUEST_TYPES.WRITING && (
          <ActionButton onClick={onClickCreateCta}>
            <Icon src="/icons/quests/write-story.svg" alt="" />
            Write Story
          </ActionButton>
        )}

      {/* quest closed, user did not submit */}
      {!questState.isOpen &&
        (!quest.poll ||
          (quest.poll && !pollState?.isOpen && !pollState?.isCompleted)) &&
        !userSubmissionQuery.data && (
          <SuccessSection>
            <SuccessIcon
              src={valueByQuestType(
                quest,
                {
                  writing: "/icons/pencil-spiral.svg",
                },
                // TODO: remove after ai is gone
                "/images/cta/submission.svg"
              )}
              alt=""
            />
            <SuccessInnerSection>
              <SubmissionSuccess>Submissions are closed!</SubmissionSuccess>
              <SubmissionSubheader>Vote will be live soon</SubmissionSubheader>
            </SuccessInnerSection>
          </SuccessSection>
        )}

      {/* quest open and/or closed, user has submitted */}
      {(!quest.poll ||
        (quest.poll && !pollState?.isOpen && !pollState?.confirmed)) &&
        userSubmissionQuery.data && (
          <SuccessSection>
            <SuccessIcon
              src={valueByQuestType(quest, {
                writing: "/icons/pencil-spiral.svg",
                visual: "/images/cta/submission.svg",
                // TODO: remove after ai is gone
                fallback: "/images/cta/submission.svg",
              })}
              alt=""
            />
            <SuccessInnerSection>
              <SubmissionSuccess>Your submission is in!</SubmissionSuccess>
              <SubmissionSubheader>
                Nice work - vote will be live soon
              </SubmissionSubheader>
            </SuccessInnerSection>
          </SuccessSection>
        )}

      {pollState?.isOpen && quest.poll && userVoteQuery.data && (
        <SuccessSection>
          <SuccessIcon src="/images/carrot.svg" alt="" />
          <SuccessInnerSection>
            <SubmissionSuccess>Your vote is in!</SubmissionSuccess>
            <SubmissionSubheader>
              Results will be posted here soon
            </SubmissionSubheader>
          </SuccessInnerSection>
        </SuccessSection>
      )}

      {pollState?.confirmed &&
        pollState?.isOpen &&
        quest.poll &&
        !userVoteQuery.data && (
          <ActionButton onClick={onClickVoteCta}>Vote Now</ActionButton>
        )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 6px;
`;

const SuccessIcon = styled.img`
  height: 100%;
  width: auto;
  margin-right: 12px;
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background-color: var(--nextPreviewButton);
  cursor: pointer;
  margin-top: 10px;
  border-radius: 8px;
  border: 0;
  color: var(--color-cream-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const SuccessSection = styled.div`
  color: var(--color-cream-base);
  width: 100%;
  height: 78px;
  padding: 16px;
  border-radius: 12px;
  border: 1.5px solid var(--border-light);
  display: flex;
`;

const SuccessInnerSection = styled.div`
  display: block;
`;

const SubmissionSuccess = styled.div`
  color: var(--color-cream-base);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
`;

const SubmissionSubheader = styled.div`
  color: #949ba4;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
