import CustomModal from "../CustomModal";
import { Spacer, StyledInput } from "../../styles/CommonElements";
import styled from "styled-components";
import { Col, notification, Row } from "antd";
import {
  FacebookShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
} from "react-share";
import { useEffect, useState } from "react";
import { APP_NAME_LOWER } from "../../helpers/constants";

// Social media icons
const FACEBOOK_ICON = "/icons/share/facebook.svg";
const X_ICON = "/icons/share/x.svg";
const TUMBLR_ICON = "/icons/share/tumblr.svg";
const REDDIT_ICON = "/icons/share/reddit.svg";

const COPY_ICON = "/icons/social/copy-link.svg";

const WINDOW_WIDTH = 1080;
const WINDOW_HEIGHT = 720;

const ShareModal = ({ isOpen, onClose, storyworld }) => {
  const [api, contextHolder] = notification.useNotification();

  const [customHashtag, setCustomHashtag] = useState(
    storyworld?.title?.replace(/[^a-zA-Z0-9]/g, "")
  );

  useEffect(() => {
    if (storyworld) {
      const newHashtag = storyworld?.title?.replace(/[^a-zA-Z0-9]/g, "");
      setCustomHashtag(newHashtag);
    }
  }, [storyworld]);

  const openNotification = () => {
    api.open({
      description: (
        <Row>
          <Col span={22}>Copied link to clipboard.</Col>
          <Col span={2}></Col>
        </Row>
      ),
      duration: 4,
      className: "report-notification",
      closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
      placement: "top",
    });
  };

  return (
    <>
      {contextHolder}
      <StyledModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        width={576}
        isCentered={true}
      >
        <ModalHeader>Share with</ModalHeader>

        <Spacer $marginTop="34px" />

        <ShareButtonContainer>
          <FacebookShareButton
            url={window.location.href}
            windowWidth={WINDOW_WIDTH}
            windowHeight={WINDOW_HEIGHT}
          >
            <ShareButton>
              <SocialIcon src={FACEBOOK_ICON} alt="facebook" />
            </ShareButton>
            <SocialTitle>Facebook</SocialTitle>
          </FacebookShareButton>

          <TwitterShareButton
            url={window.location.href}
            hashtags={[APP_NAME_LOWER, customHashtag]}
            windowWidth={WINDOW_WIDTH}
            windowHeight={WINDOW_HEIGHT}
          >
            <ShareButton>
              <SocialIcon src={X_ICON} alt="x" />
            </ShareButton>
            <SocialTitle>X</SocialTitle>
          </TwitterShareButton>

          <TumblrShareButton
            url={window.location.href}
            windowWidth={WINDOW_WIDTH}
            windowHeight={WINDOW_HEIGHT}
          >
            <ShareButton>
              <SocialIcon src={TUMBLR_ICON} alt="tumblr" />
            </ShareButton>
            <SocialTitle>Tumblr</SocialTitle>
          </TumblrShareButton>

          <RedditShareButton
            url={window.location.href}
            windowWidth={WINDOW_WIDTH}
            windowHeight={WINDOW_HEIGHT}
          >
            <ShareButton>
              <SocialIcon src={REDDIT_ICON} alt="reddit" />
            </ShareButton>
            <SocialTitle>Reddit</SocialTitle>
          </RedditShareButton>
        </ShareButtonContainer>

        <Spacer $marginTop="2.5rem" />

        <ShareText>Or share with link</ShareText>
        <Spacer $marginTop="16px" />
        <LinkContainer>
          <StyledStyledInput
            id="email"
            placeholder="email@email.com"
            type="email"
            value={window.location.href}
            disabled
          />
          <CopyButton
            onClick={() => {
              openNotification();
              navigator.clipboard.writeText(window.location);
            }}
          >
            <CopyIcon src={COPY_ICON} alt="" />
          </CopyButton>
        </LinkContainer>

        <Spacer $marginTop="0.5rem" />
      </StyledModal>
    </>
  );
};

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
  top: 10px;
`;

const CopyButton = styled.div`
  display: inline-block;
  padding: 8px 14px;
  position: absolute;
  right: 8px;
  top: 6px;
  cursor: pointer;
`;

const CopyIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const LinkContainer = styled.div`
  position: relative;
  line-height: 17.5px;
  border-radius: 16px;
  background: rgba(157, 157, 157, 0.04);
  height: 53px;
`;

const StyledModal = styled(CustomModal)`
  .ant-modal-content {
    background-color: var(--color-gray-600);
  }
`;

const ModalHeader = styled.div`
  color: var(--color-cream-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  text-align: center;
  display: block;
  position: absolute;
  top: 28px;
  left: 32px;
`;

const ShareButtonContainer = styled.div`
  display: flex;
  gap: 38px;
  justify-content: center;
`;

const ShareButton = styled.div`
  border-radius: 37px;
  background: rgba(157, 157, 157, 0.04);
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 72px;
  height: 72px;
`;

const ShareText = styled.div`
  color: var(--color-cream);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
`;

const SocialIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const SocialTitle = styled.div`
  color: var(--color-cream);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin-top: 8px;
`;

const StyledStyledInput = styled(StyledInput)`
  color: var(--color-gray-50);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88%;
`;

export default ShareModal;
