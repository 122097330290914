import { useCallback, useContext, useEffect, useState } from "react";
import {
  DIALOG_NAME,
  useDialogSearchParam,
  useOpenParamDialog,
} from "../../helpers/useDialogSearchParam";
import CustomModal, { ModalDescription, ModalTitle } from "../CustomModal";
import AccountCreationForm from "./AccountCreationForm";
import { useMutation } from "@tanstack/react-query";
import {
  firebaseAuth,
  registerFirebaseAuth,
} from "../../helpers/authentication";
import { message } from "antd";
import CreateProfileForm from "./CreateProfileForm";
import useCreateProfileMutation from "../../lib/useCreateProfileMutation";
import NewUserCarousel from "./NewUserCarousel";
import Button from "../Button";
import { Highlight, Spacer } from "../../styles/CommonElements";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthProvider";
import OrDivider from "../OrDivider";
import ThirdPartyAuth from "./ThirdPartyAuth";
import { Link } from "react-router-dom";
import { APP_LOGO, APP_NAME } from "../../helpers/constants";

const SignupModal = () => {
  const [onWelcomeScreen, setOnWelcomeScreen] = useState(true);
  const [accountCreated, setAccountCreated] = useState(false);
  const [profileCreated, setProfileCreated] = useState(false);
  const [requestCloseConfirmation, setCloseConfirmationRequested] =
    useState(false);

  const { user, userInfo, isUserInfoLoading, isThirdPartyAuthUser } =
    useContext(AuthContext);

  const [isParamSet, removeParam] = useDialogSearchParam(DIALOG_NAME.signup);
  const openLogin = useOpenParamDialog(DIALOG_NAME.login);

  const {
    mutate: createAccount,
    isPending: accountCreationPending,
    error: createAccountErrors,
  } = useMutation({
    mutationFn: async ({ email, password }) => {
      const result = await registerFirebaseAuth({
        email,
        password,
      });

      if (!result) {
        throw new Error("An account with this email already exists");
      }
    },
    onSuccess: () => {
      setAccountCreated(true);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const { mutate: createProfile, isPending: profileCreationPending } =
    useCreateProfileMutation();

  const onClose = useCallback(
    (e, force = false) => {
      const requiresCloseConfirmation =
        (accountCreated && !profileCreated) ||
        (isThirdPartyAuthUser && !profileCreated);

      // The only way to close from here is to call onClose with force via the handleExitHalfway function (see below)
      if (requiresCloseConfirmation && !force) {
        setCloseConfirmationRequested(true);
        return;
      }

      setAccountCreated(false);
      setProfileCreated(false);
      setOnWelcomeScreen(true);
      removeParam();
    },
    [accountCreated, isThirdPartyAuthUser, profileCreated, removeParam]
  );

  // Trying to account for the user opening the modal at a weird stage in the process
  useEffect(() => {
    if (user && !isUserInfoLoading) {
      // The onWelcomeScreen condition is to ensure that the modal doesn't close itself at the end of regisration when the user
      // should be seeing the NewUserCarousel.
      if (userInfo && onWelcomeScreen) {
        // I have to add isOpen as a dependency because the useEffect is fired before the modal is opened
        // As a result, these two conditions shouldn't be &&'d either.
        if (isParamSet) {
          onClose();
        }
      }
      // The user exists in Firebase Auth but not in our data yet, so we skip to profile creation
      // Third party auth users still should be shown a screen to "approve" TOS first.
      else if (!userInfo && !isThirdPartyAuthUser) {
        setAccountCreated(true);
      }
    }
  }, [
    isParamSet,
    isThirdPartyAuthUser,
    isUserInfoLoading,
    onClose,
    onWelcomeScreen,
    user,
    userInfo,
  ]);

  const onAccountCreationSubmit = (data) => {
    if (isThirdPartyAuthUser) {
      setAccountCreated(true);
      return;
    }

    createAccount(data);
  };

  const onProfileCreationSubmit = (data) => {
    createProfile(data, {
      onSuccess: () => {
        setProfileCreated(true);
      },
    });
  };

  const handleExitHalfway = () => {
    firebaseAuth.signOut();
    onClose({}, true);
    setCloseConfirmationRequested(false);
  };

  return (
    <CustomModal
      isOpen={isParamSet}
      onClose={onClose}
      showCloseIcon={!requestCloseConfirmation}
      isCentered={true}
      width={550}
    >
      {!requestCloseConfirmation && (
        <>
          {/* Email Check */}
          {!accountCreated && onWelcomeScreen && (
            <>
              <LogoWrapper>
                <img src={APP_LOGO} alt="" />
              </LogoWrapper>
              <Spacer $marginTop="0.5rem" />
              <ModalTitle>Create an account</ModalTitle>
              <ModalDescription>
                Sign up to create your own influencer
              </ModalDescription>
              <Spacer $marginTop="1.5rem" />
              <Button size="large" onClick={() => setOnWelcomeScreen(false)}>
                Continue with Email
              </Button>

              <Spacer $marginTop="1rem" />
              <OrDivider />
              <Spacer $marginTop="1rem" />
              {/*
              <ThirdPartyAuth
                cta="Sign up"
                onCompletion={() => {
                  setOnWelcomeScreen(false);
                }}
              />
              */}
              <Spacer $marginTop="16px" />
              <Terms>
                By continuing, you agree to our{" "}
                <Link
                  to="/terms-of-use"
                  target="_blank"
                  style={{ color: "var(--color-pink)" }}
                >
                  Terms of Service
                </Link>
                .
              </Terms>
              <Spacer $marginTop="16px" />
              <LoginCta>
                Already have an account?{" "}
                <Highlight
                  onClick={() => {
                    openLogin();
                  }}
                >
                  Log In
                </Highlight>
              </LoginCta>
              <Spacer $marginTop="0.5rem" />
            </>
          )}
          {/* Account Creation  */}
          {!accountCreated && !onWelcomeScreen && (
            <>
              <LogoWrapper>
                <img src={APP_LOGO} alt="" />
              </LogoWrapper>
              <ModalTitle>Create an account</ModalTitle>
              <ModalDescription>
                Enter a new password to create your {APP_NAME} account.
              </ModalDescription>
              <AccountCreationForm
                isThirdPartyUser={isThirdPartyAuthUser}
                onSubmit={onAccountCreationSubmit}
                isSubmitting={accountCreationPending}
              />
              {createAccountErrors && (
                <MutationError>{createAccountErrors.message}</MutationError>
              )}
              <LoginCta>
                Already have an account?{" "}
                <Highlight
                  onClick={() => {
                    openLogin();
                  }}
                >
                  Log In
                </Highlight>
              </LoginCta>
              <Spacer $marginTop="0.25rem" />
            </>
          )}
          {/* Profile Creation */}
          {accountCreated && !profileCreated && (
            <>
              <Subtitle>Almost there!</Subtitle>
              <ModalTitle>Create a public profile</ModalTitle>
              <CreateProfileForm
                onSubmit={onProfileCreationSubmit}
                isSubmitting={profileCreationPending}
              />
            </>
          )}
          {/* New User Info */}
          {profileCreated && <NewUserCarousel />}
        </>
      )}
      {requestCloseConfirmation && (
        <>
          <ModalTitle>Quit Profile Creation?</ModalTitle>
          <ModalDescription>
            You’ve created your account, but your profile is incomplete. If you
            leave now, your changes won’t be saved.
          </ModalDescription>
          <Button
            variant="primary"
            onClick={() => {
              setCloseConfirmationRequested(false);
            }}
          >
            Complete Profile
          </Button>
          <Spacer $marginTop="0.75rem" />
          <Button variant="ghost" onClick={handleExitHalfway}>
            Close
          </Button>
        </>
      )}
      <Spacer $marginTop="1rem" />
    </CustomModal>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  height: 60px;
  width: auto;
  margin: auto;
`;

const Subtitle = styled.p`
  color: var(--color-green-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-transform: uppercase;
  margin: 0;
`;

const LoginCta = styled.div`
  color: var(--color-text);
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  margin-top: 16px;

  span {
    cursor: pointer;
  }
`;

const MutationError = styled.div`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--color-red);
  margin-top: 12px;
`;

const Terms = styled.div`
  color: #777e91;
  text-align: center;
  font-size: 0.625rem;
  font-weight: 400;
`;

export default SignupModal;
