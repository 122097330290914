import React, { useContext, useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ImageGenerator from "./ImageGenerator";
import { styled } from "styled-components";
import {
  QuestComments,
  QuestDisplayMenuSwitch,
  QuestFaq,
  QuestHeader,
  QuestInfo,
  QuestResults,
  QuestSubmissions,
} from "../components/quest-display";
import { useLocation } from "react-router-dom";
import { Row, Col, notification } from "antd";
import { getQuestAndPollStates } from "../helpers/general";
import { useQuery } from "@tanstack/react-query";
import submissionModel from "../lib/firebase/submissionModel";
import { submissionKeys } from "../lib/queryKeys";
import AlertSourceContext, { SOURCE } from "../context/AlertSourceContext";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import Backlink from "../components/Backlink";

const backLocationLengthThreshold = 0;

export default function QuestDisplay({
  quest,
  hasSeenModal,
  setHasSeenModal,
  setShowBackground,
}) {
  const { search } = useLocation();

  const { backLocations, goBack } = useContext(BackLocationsContext);

  const [extraMenu, setExtraMenu] = useState("");
  const [isSubmittingArt, setIsSubmittingArt] = useState(false);

  const { quest: questState, poll: pollState } = useMemo(() => {
    return getQuestAndPollStates(quest);
  }, [quest]);

  useEffect(() => {
    const infoEnabled = quest.moreInfo && quest.moreInfo !== "";
    const resultsEnabled = pollState?.confirmed && pollState?.isCompleted;
    const submissionsEnabled = questState.isOpen;

    // Search parameter gets priority over other contextual setting of the tabs
    if (search) {
      const query = new URLSearchParams(search);
      const tabName = query.get("tab");

      if (tabName === "info" && infoEnabled) {
        setExtraMenu("info");
        return;
      } else if (tabName === "results" && resultsEnabled) {
        setExtraMenu("results");
        return;
      } else if (tabName === "submissions" && submissionsEnabled) {
        setExtraMenu("submissions");
        return;
      } else if (tabName === "comments") {
        setExtraMenu("comments");
        return;
      }
    }

    if (infoEnabled && !pollState?.isCompleted) setExtraMenu("info");
    else if (resultsEnabled) setExtraMenu("results");
    else if (submissionsEnabled) setExtraMenu("submissions");
    else setExtraMenu("comments");
  }, [
    pollState?.confirmed,
    pollState?.isCompleted,
    quest,
    questState.isOpen,
    search,
  ]);

  const questSubmissionsQuery = useQuery({
    queryKey: submissionKeys.gallery_count(quest.id),
    queryFn: async () => {
      return submissionModel.count(
        ["questId", "==", quest.id],
        ["rejected", "==", false]
      );
    },
  });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.open({
      description: (
        <Row>
          <Col span={22}>Copied link to clipboard.</Col>
          <Col span={2}></Col>
        </Row>
      ),
      duration: 4,
      className: "report-notification",
      closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
      placement: "top",
    });
  };

  return (
    <AlertSourceContext.Provider
      value={{ sourceType: SOURCE.quest, sourceId: quest.id }}
    >
      {contextHolder}
      <Container>
        {!isSubmittingArt ? (
          <QuestContainer>
            <ActionsOverlay>
              <div>
                {backLocations.length > backLocationLengthThreshold && (
                  <Backlink onClick={goBack} />
                )}
              </div>
              <ShareButton
                onClick={() => {
                  openNotification();
                  navigator.clipboard.writeText(window.location);
                }}
              >
                <ShareIcon src="/icons/general/social-share.svg" alt="" />
              </ShareButton>
            </ActionsOverlay>

            <Spacer />

            <QuestHeader quest={quest} />

            <QuestDisplayMenuSwitch
              quest={quest}
              extraMenu={extraMenu}
              setExtraMenu={setExtraMenu}
              submissionAmount={
                questSubmissionsQuery.status === "success"
                  ? questSubmissionsQuery.data
                  : 0
              }
              showResults={pollState?.confirmed && pollState?.isCompleted}
            />

            <ExtraMenuContainer>
              {extraMenu === "info" && <QuestInfo quest={quest} />}
              {extraMenu === "comments" && (
                <AlertSourceContext.Provider
                  value={{ sourceType: SOURCE.quest, sourceId: quest.id }}
                >
                  <QuestComments quest={quest} />
                </AlertSourceContext.Provider>
              )}
              {extraMenu === "submissions" && (
                <QuestSubmissions
                  quest={quest}
                  submissionAmount={
                    questSubmissionsQuery.status === "success"
                      ? questSubmissionsQuery.data
                      : 0
                  }
                />
              )}
              {extraMenu === "results" && <QuestResults quest={quest} />}
              {extraMenu === "faq" && <QuestFaq quest={quest} />}
            </ExtraMenuContainer>
          </QuestContainer>
        ) : (
          <ImageGenerator
            quest={quest}
            setExtraMenu={setExtraMenu}
            setIsSubmittingArt={setIsSubmittingArt}
            hasSeenModal={hasSeenModal}
            setHasSeenModal={setHasSeenModal}
            setShowBackground={setShowBackground}
          />
        )}
      </Container>
    </AlertSourceContext.Provider>
  );
}

const Container = styled.div`
  padding-bottom: 50px;
  position: relative;
  width: 100%;
  background-size: cover;
`;

const QuestContainer = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
`;

const Spacer = styled.div`
  height: 10px;
`;

const ActionsOverlay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  margin: auto;
  margin-top: 12px;
  padding: 0 16px;
`;

const ExtraMenuContainer = styled.div`
  width: 100%;
  margin: auto;
`;

const ShareButton = styled.span`
  border-radius: 65px;
  background: #2d313b;
  display: inline-block;
  padding: 8px 14px;
`;

const ShareIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
  top: 10px;
`;
