import { Image, Upload } from "antd";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { spinnerIcon } from "../helpers/constants";
import useComicUpload from "../lib/useComicUpload";
import { UploadHintNoHide } from "../styles/CommonElements";
import styled from "styled-components";

const { Dragger } = Upload;

export default function UploadDragAndDropComic({
  comicEntries,
  setComicEntries,
  maxFileSizeInMb,
  fileBucket,
  byPassRes = false,
}) {
  const { beforeUpload, onChange, customRequest, isUploading } = useComicUpload(
    {
      fileBucket,
      maxFileSizeInMb,
      setFileList: setComicEntries,
      fileList: comicEntries,
      bypassRes: byPassRes,
    }
  );

  return (
    <Container
      name="image"
      beforeUpload={beforeUpload}
      onChange={onChange}
      customRequest={customRequest}
      multiple={true}
    >
      {isUploading ? (
        <div style={{ textAlign: "center" }}>
          <Image
            className="spinner"
            style={{ width: "50px", margin: "10px 0px" }}
            preview={false}
            src={spinnerIcon}
          />
        </div>
      ) : (
        <>
          <p
            className="ant-upload-text"
            style={{ width: "fit-content !important" }}
          >
            <span className="ant-upload-drag-icon" style={{ marginBottom: 8 }}>
              <img src="icons/upload.svg" alt="" />
            </span>
            Drag and drop image files or click here
          </p>
          <UploadHintNoHide>
            PNG or JPEG only. Max file size 20MB.
          </UploadHintNoHide>
        </>
      )}
    </Container>
  );
}

const Container = styled(Dragger)`
  .ant-upload.ant-upload-drag {
    border: 2px dashed var(--color-gray-200) !important;

    &:hover {
      border: 2px dashed var(--color-gray-50) !important;
    }
  }
`;
