import styled from "styled-components";
import UploadProfilePictureButton from "./UploadProfilePictureButton";
import { useEffect, useState } from "react";
import { defaultProfileImages } from "../../helpers/constants";
import { reduceArrayToFourRows } from "../../helpers/general";
import DefaultImageIcon from "../profile/DefaultImageIcon";
import { getRandomProfilePic } from "../../helpers/authentication";
import { Spacer } from "../../styles/CommonElements";
import { device } from "../../lib/breakpoints";
import Hidden from "../Hidden";

const ProfileImageWizard = ({ profilePic, setProfilePic, onAction }) => {
  const [imageRows, setImageRows] = useState([]);

  useEffect(() => {
    const rows = defaultProfileImages.reduce(reduceArrayToFourRows, []);
    setImageRows(rows);
  }, []);

  return (
    <Container>
      <UploadProfilePictureButton
        fileBucket="profileImage"
        setProfilePic={setProfilePic}
        onAction={onAction}
      />
      <DefaultImageContainer>
        {imageRows.map((row) =>
          row.map((icon) => (
            <DefaultImageIcon
              key={icon.imageUrl}
              imageUrl={icon}
              profilePic={profilePic}
              setProfilePic={setProfilePic}
            />
          ))
        )}
      </DefaultImageContainer>

      {/*
      <DeleteImageSection>
        <DeleteImageButton
          onClick={async () => {
            setProfilePic(getRandomProfilePic());
          }}
        >
          <DeleteIcon src="/icons/trash-red.svg" alt="" />
          Remove current picture
        </DeleteImageButton>
      </DeleteImageSection>
      */}

      <Hidden mobile>
        <Spacer $marginTop="1.25rem" />
      </Hidden>
    </Container>
  );
};

const Container = styled.div`
  @media ${device.tablet}, ${device.desktop} {
    width: 90%;
    margin: auto;
  }
`;

const DefaultImageContainer = styled.div`
  display: block;
  margin-top: 20px;
`;

const DeleteImageSection = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const DeleteImageButton = styled.button`
  color: var(--exitColor);
  line-height: 14px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  display: inline-flex;
`;

const DeleteIcon = styled.img`
  width: 12px;
  height: auto;
  margin: 0px 7px 3px 0px;
`;

export default ProfileImageWizard;
