import React from "react";
import { Col, Image, Row, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import date from "date-and-time";
import { datePattern } from "../helpers/constants";
import { getAdjustedEpisodeText } from "../helpers/general";
import userModel from "../lib/firebase/userModel";
import {
  AdminWinner,
  QuestDisplayText,
  QuestDisplayTimeText,
  QuestDisplayTitle,
  SmallAvatar,
} from "../styles/CommonElements";
import styled from "styled-components";

export default function AdminEpisodeDisplay({
  episode,
  setSelectedEpisode,
  setIsNewEpisode,
  winningSubmission,
}) {
  const [publishedDate, setPublishedDate] = useState("");
  const [questStatus, setQuestStatus] = useState(null);
  const [winningUserInfo, setWinningUserInfo] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const userDatePromise = await userModel.getOneById(
        winningSubmission.creator
      );
      setWinningUserInfo(userDatePromise);
    }
    if (winningSubmission) fetchData();
  }, [winningSubmission]);

  useEffect(() => {
    setPublishedDate(
      date.format(
        episode.publishedAt ? episode.publishedAt.toDate() : new Date(),
        datePattern
      )
    );
    if (episode.isDraft)
      setQuestStatus(
        <h5>
          <img style={{ marginRight: 4 }} src="icons/calendar.svg" alt="" />{" "}
          Draft Saved
        </h5>
      );
  }, [episode]);

  return (
    <Container
      onClick={async () => {
        setSelectedEpisode(episode);
        setIsNewEpisode(false);
      }}
      key={episode.id}
    >
      <Row gutter={36}>
        <Col span={13} style={{ display: "inline-block" }}>
          <Tooltip>
            <QuestDisplayTitle>
              {getAdjustedEpisodeText({
                number: episode.number,
                showEpText: false,
              })}
            </QuestDisplayTitle>
          </Tooltip>
          <Tooltip title={episode.title} placement="bottomLeft">
            <QuestDisplayText style={{ marginTop: "-4px" }}>
              {episode.title}
            </QuestDisplayText>
          </Tooltip>
          {questStatus}
        </Col>
        <Col span={4} style={{ margin: "auto", textAlign: "center" }}>
          <Tooltip title={publishedDate}>
            <h4>{publishedDate}</h4>
          </Tooltip>
        </Col>
        <Col span={4} style={{ margin: "auto", textAlign: "center" }}>
          {winningSubmission ? (
            <QuestDisplayTimeText className="center">
              <div>
                <div style={{ pointerEvents: "none !important" }}>
                  <Image
                    className="admin-quest-display-poll-image"
                    src={winningSubmission ? winningSubmission.imageUrl : null}
                    alt=""
                    preview={false}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                {winningSubmission.creator && (
                  <AdminWinner>
                    <SmallAvatar
                      size="small"
                      icon={
                        <Image
                          preview={false}
                          src={winningUserInfo ? winningUserInfo.pfp : null}
                        />
                      }
                      style={{ marginLeft: 0 }}
                    />
                    <span>
                      {winningUserInfo ? winningUserInfo.username : null}
                    </span>
                  </AdminWinner>
                )}
              </div>
            </QuestDisplayTimeText>
          ) : (
            <QuestDisplayText style={{ marginLeft: "10%" }}>
              No winner
            </QuestDisplayText>
          )}
        </Col>
        <Col
          span={3}
          style={{ margin: "auto", textAlign: "center" }}
          className="poll-column"
        >
          {episode.views ? episode.views.toLocaleString() : 0}
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 12px;
  background: #23262f;
  padding: 26px 20px 36px 20px;
  cursor: pointer;
  min-height: 110px;
`;
