import { v4 as uuidv4 } from "uuid";
import feature01 from "./../images/features/man-005.svg";
import feature02 from "./../images/features/man-007.svg";
import feature03 from "./../images/features/man-006.svg";
import { APP_NAME } from "./constants";

const features = [
  {
    id: uuidv4(),
    icon: feature01,
    title: `Cutting Edge Technology`,
    desc: `${APP_NAME} is built by a team of technology experts. We use the latest advancements in AI to provide the most accurate possible results for your patients.`,
  },
  {
    id: uuidv4(),
    icon: feature02,
    title: "Quick Results",
    desc: `${APP_NAME} is designed to return results within a minute, allowing patients to see the possibilities available from your team. Start their journey on the right foot and make it easy for your patients to take the next step.`,
  },
  {
    id: uuidv4(),
    icon: feature03,
    title: "24/7 Availability",
    desc: `${APP_NAME} is available all day and every day of the week. No matter when you have a consultation, we're here to help you and your patients move forward.`,
  },
];

export default features;
