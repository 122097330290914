import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Col, Input, Row, Modal, Tooltip, Select, Switch } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { DeleteFilled } from "@ant-design/icons";
import styled from "styled-components";
import { UploadDragAndDropImage } from "../../views";
import storyworldModel from "../../lib/firebase/storyworldModel";
import { useNavigate } from "react-router-dom";
import { storyworldKeys } from "../../lib/queryKeys";
import { genreOptions } from "../../helpers/genres";
import {
  QuestDisplayText,
  RightAlignedCol,
  Spacer,
} from "../../styles/CommonElements";

const { TextArea } = Input;

const maxLengthTitle = 60;
const maxLengthDescription = 250;

export default function CreateStoryworldModal({
  isOpen,
  setOpen,
  setSelectedStoryworld,
  storyworldBeingEdited,
}) {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
  const [imageDivClass, setImageDivClass] = useState(
    imageUrl !== null ? "upload-drag-and-drop-storyworld-div" : ""
  );
  const [backgroundImageDivClass, setBackgroundImageDivClass] = useState(
    backgroundImageUrl !== null ? "upload-drag-and-drop-storyworld-div" : ""
  );
  const [coverFilename, setCoverFilename] = useState("");
  const [bgFilename, setBgFilename] = useState("");
  const [isStoryworld, setIsStoryworld] = useState(false);
  const [genre, setGenre] = useState(genreOptions[0].value);

  const queryClient = useQueryClient();

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    if (storyworldBeingEdited) {
      setTitle(storyworldBeingEdited.title ?? "");
      setDescription(storyworldBeingEdited.description ?? "");
      setImageUrl(storyworldBeingEdited.imageUrl ?? null);
      setBackgroundImageUrl(storyworldBeingEdited.backgroundImageUrl ?? null);
      setGenre(storyworldBeingEdited.genre ?? genreOptions[0].value);
      setIsStoryworld(storyworldBeingEdited.isStoryworld ?? false);
    } else {
      setTitle("");
      setDescription("");
      setImageUrl(null);
      setBackgroundImageUrl(null);
      setGenre(genreOptions[0].value);
      setIsStoryworld(false);
    }
  }, [storyworldBeingEdited]);

  useEffect(() => {
    if (imageUrl !== null) {
      setImageDivClass("upload-drag-and-drop-storyworld-div");
    } else {
      setImageDivClass("");
    }
  }, [imageUrl]);

  useEffect(() => {
    if (backgroundImageUrl !== null) {
      setBackgroundImageDivClass("upload-drag-and-drop-storyworld-div");
    } else {
      setBackgroundImageDivClass("");
    }
  }, [backgroundImageUrl]);

  const toggleIsStoryworld = () => {
    setIsStoryworld(!isStoryworld);
  };

  return (
    <Modal
      open={isOpen}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      closable={false}
      className="modal create-storyworld-modal"
      style={{
        top: "5%",
      }}
      footer={[
        // TODO: supply react key
        // eslint-disable-next-line react/jsx-key
        <Button className="close-button" onClick={() => setOpen(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="next-preview-button create-storyworld-button"
          style={{ marginLeft: 14 }}
          onClick={async () => {
            setOpen(false);
            if (storyworldBeingEdited) {
              // Edit Storyworld
              await storyworldModel.update({
                id: storyworldBeingEdited.id,
                data: {
                  title,
                  description,
                  imageUrl,
                  backgroundImageUrl,
                  genre,
                  isStoryworld,
                },
              });
            } else {
              // Create Storyworld
              const createdStoryworld = await storyworldModel.create({
                title,
                description,
                imageUrl,
                backgroundImageUrl,
                genre,
                isStoryworld,
              });
              if (createdStoryworld) {
                setSelectedStoryworld(createdStoryworld);
                navigate(`/edit/${createdStoryworld.id}/episodes`);
              }
            }
            queryClient.invalidateQueries({
              queryKey: storyworldKeys.all,
            });
            setImageUrl(null);
            setBackgroundImageUrl(null);
          }}
        >
          {storyworldBeingEdited ? "Edit" : "Create"} Storyworld
        </Button>,
      ]}
    >
      <Container>
        <Header>{storyworldBeingEdited ? "Edit" : "Create"} Storyworld</Header>
        <div style={{ marginBottom: 46 }}>
          <Row>
            <Col span={12}>
              <Category>Title</Category>
            </Col>
            <Col span={12}>
              <Counter>
                {title.length}/{maxLengthTitle}
              </Counter>
            </Col>
          </Row>
          <Input
            value={title}
            placeholder="The title of the new Storyworld"
            className="create-quest-input-field create-storyworld-title-field"
            maxLength={maxLengthTitle}
            onChange={handleTitleChange}
          />
          <Spacer $marginTop="24px" />
          <Row>
            <Col span={12}>
              <Category>About</Category>
            </Col>
            <Col span={12}>
              <Counter>
                {description.length}/{maxLengthDescription}
              </Counter>
            </Col>
          </Row>
          <TextArea
            value={description}
            placeholder="What's your storyworld about?"
            className="create-quest-input-field create-storyworld-title-field"
            maxLength={maxLengthDescription}
            onChange={handleDescriptionChange}
            rows={4}
            style={{ resize: "none" }}
          />
          <Row gutter={60} style={{ marginTop: 36 }}>
            <Col span={12}>
              <Category>Main story image</Category>
              <ImageHintContainer>
                <ImageHint>
                  Minimum image width: 2500px. Maximum file size 5MB. JPEG or
                  PNG only.
                </ImageHint>
              </ImageHintContainer>

              <div className={backgroundImageDivClass}>
                {backgroundImageUrl !== null ? (
                  <StoryworldImage alt="" src={backgroundImageUrl} />
                ) : (
                  <UploadDragAndDropImage
                    setImageUrl={setBackgroundImageUrl}
                    setFilename={setCoverFilename}
                    className="upload-drag-and-drop-storyworld"
                    maxFileSizeInMb={5}
                    minWidth={1920}
                    maxWidth={1920}
                    minHeight={1080}
                    maxHeight={1080}
                    fileBucket="storyworldImages"
                  />
                )}
              </div>

              {backgroundImageUrl !== null && (
                <Row style={{ marginTop: 10 }}>
                  <Col span={20}>
                    <Tooltip title={coverFilename}>
                      <QuestDisplayText>{coverFilename}</QuestDisplayText>
                    </Tooltip>
                  </Col>
                  <RightAlignedCol span={4}>
                    <DeleteButton
                      onClick={async () => {
                        setBackgroundImageUrl(null);
                      }}
                    >
                      <DeleteFilled style={{ color: "#7d818e" }} />
                    </DeleteButton>
                  </RightAlignedCol>
                </Row>
              )}
            </Col>
            <Col span={12} style={{ marginTop: 0 }}>
              <Category>Cover image</Category>
              <div style={{ marginBottom: 10 }}>
                <ImageHint>
                  Dimensions: 232 x 330. Max file size 1MB. JPEG or PNG only.
                </ImageHint>
              </div>
              <div style={{ width: 130 }}>
                <div className={imageDivClass}>
                  {imageUrl !== null ? (
                    <StoryworldImage src={imageUrl} alt="" />
                  ) : (
                    <UploadDragAndDropImage
                      setImageUrl={setImageUrl}
                      setFilename={setBgFilename}
                      className="upload-drag-and-drop-storyworld"
                      maxFileSizeInMb={5}
                      minWidth={1080}
                      maxWidth={1080}
                      minHeight={1920}
                      maxHeight={1920}
                      fileBucket="storyworldImages"
                    />
                  )}
                </div>{" "}
              </div>
              {imageUrl !== null && (
                <Row style={{ marginTop: 10 }}>
                  <Col span={20}>
                    <Tooltip title={bgFilename}>
                      <QuestDisplayText>{bgFilename}</QuestDisplayText>
                    </Tooltip>
                  </Col>
                  <RightAlignedCol span={4}>
                    <DeleteButton
                      onClick={async () => {
                        setImageUrl(null);
                      }}
                    >
                      <DeleteFilled style={{ color: "#7d818e" }} />
                    </DeleteButton>
                  </RightAlignedCol>
                </Row>
              )}
            </Col>
          </Row>

          <SelectionContainer>
            <Row>
              <Col span={12}>
                <Category>Genre</Category>
              </Col>
              <Col span={12}>
                <Selector
                  defaultValue={genreOptions[0].value}
                  onChange={setGenre}
                  options={genreOptions}
                />
              </Col>
            </Row>
          </SelectionContainer>

          <SelectionContainer>
            <Row>
              <Col span={12}>
                <Category>Is this currently a 📖 Storyworld?</Category>
              </Col>
              <Col span={12}>
                <Switch checked={isStoryworld} onClick={toggleIsStoryworld} />
              </Col>
            </Row>
          </SelectionContainer>
        </div>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  padding: 0rem 1rem;
`;

const Selector = styled(Select)`
  width: 100%;
`;

const SelectionContainer = styled.div`
  margin-top: 2rem;
  pointer-events: ${(props) => props.$disabled && "none"};
  opacity: ${(props) => props.$disabled && "0.2"};
`;

const Category = styled.div`
  display: inline-flex;
  margin-right: 5%;
  color: var(--color-cream);
  font-weight: 500;
`;

const ImageHintContainer = styled.div`
  margin: 0px 0px 10px 0px;
`;

const ImageHint = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--inputFieldText);
  padding: 0px !important;
  margin: 0px;
  display: block;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 18.5px; /* 185% */
  letter-spacing: -0.1px;
`;

const Header = styled.div`
  line-height: 24px;
  letter-spacing: -0.01em;
  color: var(--color-cream);
  font-weight: 500;
  margin-bottom: 40px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.2px;
`;

const StoryworldImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const DeleteButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0px;
`;

const Counter = styled.div`
  color: var(--inputFieldText);
  margin-top: 3px;
  letter-spacing: 0.1px;
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.12px;
`;
