import { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CommentAlert from "./CommentAlert";
import LikeAlert from "./LikeAlert";
import QuestAlert from "./QuestAlert";
import EpisodeAlert from "./EpisodeAlert";
import StoryworldAlert from "./StoryworldAlert";
import SubmissionPreview from "../submissions/preview/SubmissionPreview";
import { submissionKeys } from "../../lib/queryKeys";
import submissionModel from "../../lib/firebase/submissionModel";
import AlertSourceContext, { SOURCE } from "../../context/AlertSourceContext";
import CreatorPollAlert from "./CreatorPollAlert";
import { trackEvent } from "../../helpers/mixpanel";
import { getMixpanelAlertType } from "../../helpers/alerts";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { device } from "../../lib/breakpoints";
import Hidden from "../Hidden";

const alertMap = {
  comment: CommentAlert,
  episode: EpisodeAlert,
  like: LikeAlert,
  poll: CreatorPollAlert,
  quest: QuestAlert,
  storyworld: StoryworldAlert,
};

export default function Alert({ alert }) {
  const { addBackLocation } = useContext(BackLocationsContext);

  const [submissionOpen, setSubmissionOpen] = useState(false);

  const submissionId =
    alert.context?.sourceType === SOURCE.submission
      ? alert.context?.sourceId
      : undefined;

  const { data: submission } = useQuery({
    queryKey: submissionKeys.submission(submissionId),
    queryFn: () => {
      return submissionModel.getOneById(submissionId);
    },
    enabled: !!submissionId && submissionOpen,
  });

  const AlertComponent = alertMap[alert.type];

  const callTrackingEvent = async () => {
    const type = getMixpanelAlertType(alert);
    trackEvent("Clicked Alert", {
      type,
    });
  };

  return (
    <>
      {/* In this one case we don't really have source context  */}
      <AlertSourceContext.Provider
        value={{
          sourceType: SOURCE.submission,
          sourceId: submission?.id,
        }}
      >
        <SubmissionPreview
          submission={submission}
          drawerOpen={submissionOpen}
          setDrawerOpen={setSubmissionOpen}
        />
      </AlertSourceContext.Provider>

      <Container $new={!alert.seen}>
        {submissionId ? (
          <AlertComponent
            alert={alert}
            onClick={() => {
              setSubmissionOpen(true);
              callTrackingEvent();
            }}
          />
        ) : alert.link ? (
          <Link
            to={alert.link}
            onClick={() => {
              addBackLocation("/feed");
              callTrackingEvent();
            }}
          >
            <AlertComponent alert={alert} />
          </Link>
        ) : (
          <AlertComponent alert={alert} />
        )}

        {!alert.seen && (
          <>
            <NewBar />
            <Hidden mobile>
              <NewBg />
            </Hidden>
          </>
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  background: ${(props) =>
    props.$new ? "rgba(255, 246, 242, 0.05)" : "var(--color-gray-500)"};
  border-top: 1px solid var(--border-light);

  &:first-of-type {
    border-top: none;
  }

  @media ${device.tablet}, ${device.desktop} {
    background: transparent;
  }
`;

const NewBar = styled.div`
  background: var(--color-pink);
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0;
  top: 0;

  @media ${device.tablet}, ${device.desktop} {
    left: calc(var(--drawer-body-padding) * -1);
  }
`;

const NewBg = styled.div`
  background: var(--color-cream-base);
  opacity: 0.05;
  position: absolute;
  height: 100%;
  width: 150%;
  left: 0;
  top: 0;

  @media ${device.tablet}, ${device.desktop} {
    left: calc(var(--drawer-body-padding) * -1);
  }
`;
