import styled from "styled-components";
import GenreCollections from "../components/GenreCollections";
import { device, useBreakpoints } from "../lib/breakpoints";
import { Spacer } from "../styles/CommonElements";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../helpers/constants";

const TABLET_BANNER = "/images/genres/banner_tablet.svg";
const DESKTOP_BANNER = "/images/genres/banner_desktop.svg";

export default function Genres() {
  const { isTablet, isDesktop } = useBreakpoints();

  const [bannerImage, setBannerImage] = useState(TABLET_BANNER);

  useEffect(() => {
    if (isTablet) {
      setBannerImage(TABLET_BANNER);
    } else if (isDesktop) {
      setBannerImage(DESKTOP_BANNER);
    }
  }, [isDesktop, isTablet]);

  return (
    <>
      <Helmet>
        <title>{APP_NAME} - Genre</title>
      </Helmet>
      <Container>
        <Banner src={bannerImage} alt="" />
        <Spacer $marginTop="2.5rem" />
        <InnerContainer>
          <GenreCollections />
        </InnerContainer>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--color-gray-500);
  padding-bottom: 2.5rem;
`;

const Banner = styled.img`
  width: 100%;
  height: auto;
  pointer-events: none;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  @media ${device.tablet} {
    width: 596px;
  }

  @media ${device.desktop} {
    width: 908px;
  }
`;
