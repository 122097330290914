import { message, Upload } from "antd";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { useState } from "react";
import styled from "styled-components";
import { generateRandomId } from "../../helpers/firebase";

export default function UploadDragAndDropImageQuestThumbnail({
  setImageUrl,
  setFilename,
  maxFileSizeInMb,
  maxWidth,
  maxHeight,
  minWidth,
  fileBucket = "thumbnailImages",
  byPassRes = false,
}) {
  const { Dragger } = Upload;
  const storage = getStorage();

  const [, setIsUploading] = useState(false);

  const beforeUpload = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg";
    if (!isJpgOrPng) {
      message.error("Please upload a JPG/PNG/SVG file");
    }
    const isLt2M = file.size / 1024 / 1024 < maxFileSizeInMb;
    if (!isLt2M) {
      message.error(`Image must be smaller than ${maxFileSizeInMb}MB`);
    }
    const isRightResolution = new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;
        image.addEventListener("load", () => {
          const { width, height } = image;
          var goodRes;
          if (minWidth) {
            goodRes = width >= minWidth;
          } else if (maxWidth && maxHeight) {
            goodRes = width === maxWidth && height === maxHeight;
          } else if (byPassRes) {
            goodRes = true;
          }
          if (!goodRes) {
            message.error(`Image must be the correct resolution`);
            resolve(false);
          }
          resolve(true);
        });
      });
    });
    return isJpgOrPng && isLt2M && isRightResolution;
  };

  const props = {
    name: "image",
    multiple: false,
    maxCount: 1,
    accept: ".jpeg,.jpg,.png", // handled by beforeUpload
    beforeUpload,
    action: async (file) => {
      setIsUploading(true);

      const storageRef = ref(
        storage,
        `${fileBucket}/${new Date()} -- ${generateRandomId()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        () => {
          // handle progress state changes here
        },
        (error) => {
          console.error(error);
          message.error(`${file.name} file upload failed.`);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setImageUrl(downloadURL);
            if (setFilename) setFilename(file.name);
            message.success(`${file.name} file uploaded successfully`);
            return downloadURL;
          } catch (error) {
            console.error(error);
            message.error(`${file.name} file upload failed.`);
          }
          setIsUploading(false);
        }
      );
    },
  };

  return (
    <Container>
      <Dragger {...props}>
        <UploadIcon src="/icons/general/upload.svg" alt="" />
        <UploadHint>Drag & drop or click to upload</UploadHint>
      </Dragger>
    </Container>
  );
}

const Container = styled.div`
  .ant-upload.ant-upload-drag {
    height: 160px !important;
    border: 2px dashed var(--color-gray-200) !important;

    &:hover {
      border: 2px dashed var(--color-gray-50) !important;
    }
  }
`;

const UploadIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
`;

const UploadHint = styled.div`
  color: var(--inputFieldText);
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 18.5px; /* 185% */
`;
