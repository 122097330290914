import React, { useContext } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import userModel from "../../lib/firebase/userModel";
import { userKeys } from "../../lib/queryKeys";
import { useNavigate } from "react-router-dom";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { QuestSubtitle } from "../quest-display/QuestHeader";
import { ProfileTitle } from "../profile/ProfileInfo";
import { device } from "../../lib/breakpoints";

export default function Username({
  userId,
  isCreator,
  isLinkToProfile = false,
}) {
  const navigate = useNavigate();
  const { addBackLocation } = useContext(BackLocationsContext);

  const userInfoQuery = useQuery({
    queryKey: userKeys.user(userId),
    queryFn: () => {
      return userModel.getOneById(userId);
    },
    enabled: !!userId,
  });

  const handleClick = () => {
    if (!isLinkToProfile) {
      return;
    }

    addBackLocation();
    navigate(`/profile/${userId}`);
  };

  if (userInfoQuery.status !== "success" || !userInfoQuery.data) return null;

  return (
    <UserText $isCreator={isCreator} onClick={handleClick}>
      {userInfoQuery.data.username}
    </UserText>
  );
}

const UserText = styled.span`
  font-size: 12px;
  font-style: normal;
  line-height: 22px;
  color: ${(props) =>
    props.$isCreator ? "var(--color-green-base)" : "inherit"} !important;

  /* It would be best to make these properties inheritable instead of set above, but this works for now */
  ${QuestSubtitle} & {
    font-size: 14px;
    font-weight: 600;
  }

  .ant-comment-content-author-name & {
    font-size: 14px;
    font-weight: 600;
  }
`;
