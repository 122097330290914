"use client";
// ----- Components Import -----
import { styled } from "styled-components";
import { Col, Row } from "antd";

// ----- Assets Import -----
import { APP_NAME } from "../../helpers/constants";
import { device, useBreakpoints } from "../../lib/breakpoints";

const bannerImage = "/images/home/man-004.svg";

export default function HomeBanner() {
  const { isTabletOrAbove } = useBreakpoints();

  return (
    <Container>
      {isTabletOrAbove ? (
        <StyledRow>
          <Col span={10}>
            <Banner />
          </Col>
          <StyledCol span={14}>
            <Title>Help your patients take the next step</Title>
            <Subtitle>
              {APP_NAME} was created by a team of hair restoration patients who
              understand the importance in their choice of a provider. Your team
              can help change a patient's life for the better, and the{" "}
              {APP_NAME} team makes it easier for your patients to take that
              step forward.
            </Subtitle>
          </StyledCol>
        </StyledRow>
      ) : (
        <>
          <Banner />
          <StyledRow>
            <StyledCol span={24}>
              <Title>Help your patients take the next step</Title>
              <Subtitle>
                {APP_NAME} was created by a team of hair restoration patients
                who understand the importance in their choice of a provider.
                Your team can help change a patient's life for the better, and
                the {APP_NAME} team makes it easier for your patients to take
                that step forward.
              </Subtitle>
            </StyledCol>
          </StyledRow>
        </>
      )}
    </Container>
  );
}

// ----- Styles -----
const Container = styled.div`
  width: 100%;
  height: fit-content;
  background: rgba(0, 0, 0, 0.1);
`;

const Banner = styled.div`
  width: 100%;
  height: 100%;
  min-height: 350px;
  background-image: url(${bannerImage});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
`;

const StyledCol = styled(Col)`
  padding: 1rem 3rem;
  display: inline-flex;
  gap: 18px;
  justify-content: center;
  flex-direction: column;

  @media ${device.mobile} {
    padding: 2rem 3rem;
  }
`;

const StyledRow = styled(Row)`
  height: 100%;
  text-align: left;
`;

const Subtitle = styled.div`
  font-size: 16px;

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  text-transform: capitalize;

  @media ${device.tablet} {
    font-size: 28px;
  }

  @media ${device.mobile} {
    font-size: 24px;
  }
`;
