import React from "react";
import styled from "styled-components";
import {
  AddButton,
  InputSection,
  RightAlignedCol,
  Section,
} from "../../styles/CommonElements";
import { DeleteFilled } from "@ant-design/icons";
import { Row } from "antd";
import { removeItemOnce } from "../../helpers/general";

export default function QuestWinners({ contributors, setContributors }) {
  return (
    <Container>
      <Section>
        <FieldHeader>Quest Winner(s)</FieldHeader>
        <InputSection>
          <InputHeader>
            Add the quest winner(s). They will be recognized at the end of this
            episode.
          </InputHeader>
          {contributors.map((c) => (
            // TODO: supply react key
            // eslint-disable-next-line react/jsx-key
            <ContributorContainer>
              <Row>
                <RightAlignedCol span={24}>
                  <DeleteButton
                    onClick={() => {
                      let newItems = [...contributors];
                      newItems = removeItemOnce(newItems, c);
                      setContributors(newItems);
                    }}
                  >
                    <DeleteFilled />
                  </DeleteButton>
                </RightAlignedCol>
              </Row>
            </ContributorContainer>
          ))}

          <AddButtonContainer>
            <AddButton
              onClick={() => {
                setContributors([
                  ...contributors,
                  { questId: null, userId: null },
                ]);
              }}
            >
              + Add another user
            </AddButton>
          </AddButtonContainer>
        </InputSection>
      </Section>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px;
  background: var(--color-gray-500);
  padding: 18px 12px;
  margin-top: 8px;
`;

const FieldHeader = styled.div`
  color: var(--darkTextColor);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  letter-spacing: -0.32px;
`;

const InputHeader = styled.div`
  color: #efefef;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom: 14px;
`;

const AddButtonContainer = styled.div`
  margin: auto;
  margin-top: 36px;
  text-align: center;
`;

const ContributorContainer = styled.div`
  margin-bottom: 18px;
`;

const DeleteButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
`;
