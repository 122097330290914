import { getAuth } from "firebase/auth";
import { predictionsTable } from "../../helpers/tables";
import { queryOne, queryMany } from "./all";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { sleep } from "../../helpers/general";
import { addBackgroundToPNG } from "../add-background-to-png";
import { dataUriToBuffer } from "../data-uri-to-buffer";

const sizeOf = require("image-size");

const table = predictionsTable;

const getReplicatePredictionWaitTime = 0.5;

const getResolution = (dataUri) => {
  const buffer = dataUriToBuffer(dataUri);
  const dimensions = sizeOf(buffer);
  return dimensions;
};

const predictionModel = {
  createGraydient: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const { image, maskImage } = props;

    if (!image) throw new Error("No image supplied");
    if (!maskImage) throw new Error("No maskImage supplied");

    const body = {
      creatorId: user.uid,
      init_image: image,
      mask_image: maskImage,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/predictGraydient`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const prediction = await response.json();
    return prediction;

    /*
    while (
      prediction.status !== "succeeded" &&
      prediction.status !== "failed"
    ) {
      await sleep(200);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getPredictionGraydient/` +
          prediction.id
      );
      prediction = await response.json();
      if (response.status !== 200) {
        return prediction.detail;
      }

      return prediction;
    }
    */
  },
  createReplicate: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const { image, maskImage } = props;

    if (!image) throw new Error("No image supplied");
    if (!maskImage) throw new Error("No maskImage supplied");

    const mask = addBackgroundToPNG(maskImage);

    /*
    console.log(image);
    console.log(maskImage);
    const dimensions = getResolution(image);
    console.log(`Width: ${dimensions.width}, Height: ${dimensions.height}`);
    const dimensions2 = getResolution(maskImage);
    console.log(`Width: ${dimensions2.width}, Height: ${dimensions2.height}`);
    */

    /*
    sizeOf(image, function (err, dimensions) {
      console.log(dimensions.width, dimensions.height);
    });

    sizeOf(maskImage, function (err, dimensions) {
      console.log(dimensions.width, dimensions.height);
    });
    */
    /*
    const maskBlob = await fetch(mask).then((res) => res.blob());
    const maskSize = maskBlob.size / (1024 * 1024); // Convert bytes to megabytes
    console.log(mask);
    console.log(maskSize);
    */

    const body = {
      creatorId: user.uid,
      image,
      mask,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/predict`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    let prediction = await response.json();

    /*
    if (response.status !== 201) {
      setError(prediction.detail);
      return;
    }
    setPredictions(predictions.concat([prediction]));
    */

    while (
      prediction.status !== "succeeded" &&
      prediction.status !== "failed"
    ) {
      await sleep(getReplicatePredictionWaitTime);
      const predictionBody = {
        id: prediction.id,
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getReplicatePrediction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(predictionBody),
        }
      );

      prediction = await response.json();
      if (response.status !== 200) {
        return prediction.detail;
      }

      if (prediction.status === "succeeded") {
        return prediction;
      }
    }
  },
  delete: async (id) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    await deleteDoc(doc(getFirestore(), table, id));
  },
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({ table, conditions: [...args] });
    return data;
  },
  getManyByPatientId: async (patientId, ...args) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const data = await queryMany({
      table,
      conditions: [
        ["providerId", "==", user.uid],
        ["patientId", "==", patientId],
        ...args,
      ],
    });
    return data;
  },
};

export default predictionModel;
