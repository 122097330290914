import React, { useContext } from "react";
import styled from "styled-components";
import UserDisplay from "../UserDisplay";
import { Disclaimer } from "../general";
import CustomSheet from "../CustomSheet";
import { AuthContext } from "../../context/AuthProvider";

export default function PreviewWritingQuestSheet({
  title,
  text,
  isOpen,
  setOpen,
  submit,
}) {
  const { user } = useContext(AuthContext);

  return (
    <CustomSheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      snapPoints={[500]}
      tweenConfig={{ ease: "easeOut", duration: 0.3 }}
      headerContent="Submission Preview"
      hasCloseIcon
    >
      <InnerContainer>
        <WritingContainer>
          <WritingHeader>
            {title !== "" && <Title>{title}</Title>}
            <Writing>{text}</Writing>
          </WritingHeader>
          <UserDisplaySection>
            <UserDisplay userId={user?.uid} size={24} showName />
          </UserDisplaySection>
        </WritingContainer>

        <SubmitButton
          disabled={text === ""}
          style={{ opacity: text === "" && 0.25 }}
          onClick={submit}
        >
          Submit
        </SubmitButton>

        <Disclaimer text="No edits are allowed after submitting" />
      </InnerContainer>
    </CustomSheet>
  );
}

const WritingContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: left;
  gap: 4px;
  border-radius: 12px;
  border: 1.5px solid var(--border-light);
  width: 100%;
`;

const WritingHeader = styled.div`
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: left;
  gap: 4px;
  width: 100%;
`;

const Title = styled.div`
  color: var(--color-cream-base);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
`;

const Writing = styled.div`
  color: var(--color-gray-50);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  white-space: pre-wrap;
`;

const InnerContainer = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-bottom: 60px;
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 50px;
  background: var(--blurple);
  border: 0;
  border-radius: 90px;
  margin-top: 20px;
  cursor: pointer;
  line-height: 16px;
  letter-spacing: 0em;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: stretch;
  color: var(--color-cream-base);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;

const UserDisplaySection = styled.div`
  border-top: 1px solid var(--border-light);
  padding: 10px 0px;
`;
