import styled from "styled-components";
import { device } from "../lib/breakpoints";
import { APP_SUPPORT_EMAIL } from "../helpers/constants";

const ErrorFallback = ({ children }) => {
  return (
    <Container>
      <img src="/icons/eyes-sparkle.svg" alt="" />
      <ErrorContext>
        <Title>Something went wrong</Title>
        {children}
      </ErrorContext>
    </Container>
  );
};

export const StandardMessage = () => {
  return (
    <Message>
      Try refreshing the page and try again. If this problem persists, reach out
      to us at{" "}
      <a href={`mailto:${APP_SUPPORT_EMAIL}`} rel="noreferrer" target="_blank">
        {APP_SUPPORT_EMAIL}
      </a>
    </Message>
  );
};

export default ErrorFallback;

const Container = styled.div`
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media ${device.tablet}, ${device.desktop} {
    gap: 50px;
    max-width: 480px;
  }
`;

const ErrorContext = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Title = styled.h1`
  color: var(--color-cream-base);
  text-align: center;
  font-family: Arial;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;

  @media ${device.tablet}, ${device.desktop} {
    font-size: 1.75rem;
  }
`;

const Message = styled.p`
  color: var(--color-gray-50);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500 a {
    color: var(--color-error);
    text-decoration: none;
  }

  padding: 0 10px;
`;
