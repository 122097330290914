import React from "react";
import { Col, Row } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import date from "date-and-time";
import { isTimePassed, removeTags } from "../helpers/general";
import { datePattern } from "../helpers/constants";
import styled from "styled-components";
import {
  LiveCountdown,
  QuestDisplayText,
  QuestDisplayTimeText,
  QuestDisplayTitle,
  Spacer,
} from "../styles/CommonElements";
import { AdminQuestDisplayColumns } from "../components/create-quest";

export default function AdminQuestDisplay({
  quest,
  setSelectedQuest,
  setIsNewQuest,
  setSelectedPoll,
  setIsNewPoll,
  setQuestInfo,
  setSelectedEpisode,
}) {
  const [startDate, setStartDate] = useState("StartDate");
  const [startTime, setStartTime] = useState("StartTime");
  const [endDate, setEndDate] = useState("EndDate");
  const [endTime, setEndTime] = useState("EndTime");

  useEffect(() => {
    const startDate = quest.startTimestamp.toDate();
    const endDate = quest.endTimestamp.toDate();
    setStartDate(date.format(startDate, datePattern));
    setEndDate(date.format(endDate, datePattern));
    const timePattern = date.compile("hh:mm A");
    setStartTime(date.format(startDate, timePattern));
    setEndTime(date.format(endDate, timePattern));
  }, [quest, setIsNewPoll, setQuestInfo, setSelectedPoll]);

  return (
    <Container
      onClick={async () => {
        setSelectedQuest(quest);
        setIsNewQuest(false);
      }}
      key={quest.id}
    >
      <Row gutter={48}>
        <StyledCol span={11}>
          <QuestDisplayTitle>{quest.title}</QuestDisplayTitle>
          <QuestDisplayText>{removeTags(quest.description)}</QuestDisplayText>
          {isTimePassed(quest.endTimestamp.toDate()) ? (
            <Completed>
              <Icon src="/icons/checkmark.svg" alt="" /> Completed
            </Completed>
          ) : quest.isDraft ? (
            <Draft>
              <Icon src="/icons/calendar.svg" alt="" /> Draft Saved
            </Draft>
          ) : (
            <LiveText>
              Live
              {quest.startTimestamp.toDate() > new Date() && (
                <CountdownText>
                  {" "}
                  in <LiveCountdown value={quest.startTimestamp.toDate()} />
                </CountdownText>
              )}
            </LiveText>
          )}
        </StyledCol>
        <Col span={3}>
          <QuestDisplayTimeText>{startDate}</QuestDisplayTimeText>
          <Spacer $marginTop="12px" />
          <QuestDisplayText>{startTime}</QuestDisplayText>
        </Col>
        <Col span={3}>
          <QuestDisplayTimeText>{endDate}</QuestDisplayTimeText>
          <Spacer $marginTop="12px" />
          <QuestDisplayText>{endTime}</QuestDisplayText>
        </Col>
        <Col span={7} style={{ paddingLeft: 0 }}>
          <AdminQuestDisplayColumns
            quest={quest}
            setSelectedPoll={setSelectedPoll}
            setIsNewPoll={setIsNewPoll}
            setQuestInfo={setQuestInfo}
            setSelectedEpisode={setSelectedEpisode}
          />
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 12px;
  background: #23262f;
  padding: 26px 20px 36px 20px;
  cursor: pointer;
`;

const Completed = styled.div`
  color: #71c274;
  font-size: 10px;
  line-height: 24px;
  margin-top: 4px;
`;

const CountdownText = styled.span`
  color: var(--hoverColor);
  font-size: 10px;
  line-height: 24px;
`;

const Draft = styled.div`
  color: white;
  font-size: 10px;
  line-height: 24px;
  margin-top: 4px;
`;

const Icon = styled.img`
  margin-right: 4px;
`;

const LiveText = styled.div`
  color: var(--hoverColor);
  font-size: 10px;
  line-height: 24px;
  margin-top: 4px;
`;

const StyledCol = styled(Col)`
  display: inline-block;
`;
