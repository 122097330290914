import { Drawer } from "antd";
import styled from "styled-components";
import { useBreakpoints } from "../lib/breakpoints";
import { useEffect } from "react";

const CustomDrawer = ({
  isOpen,
  onClose,
  offsetTop,
  children,
  removeMask = false,
}) => {
  const { isMobile, isTablet } = useBreakpoints();

  useEffect(() => {
    if (isMobile && isOpen) {
      onClose();
    }
  }, [isMobile, isOpen, onClose]);

  const drawerWidth = isTablet ? 368 : 400;

  return (
    <StyledDrawer
      open={isOpen && !isMobile}
      onClose={onClose}
      width={drawerWidth}
      closeIcon={null}
      rootStyle={{
        height: offsetTop ? `calc(100% - ${offsetTop}px)` : "100%",
        top: offsetTop ? `${offsetTop}px` : "0",
      }}
      mask={!removeMask}
    >
      {children}
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  --drawer-scrollbar-padding: 0.25rem;
  --drawer-body-padding: 1.5rem;

  &.ant-drawer-content {
    background-color: var(--color-gray-600);
    padding-right: var(--drawer-scrollbar-padding);
  }

  .ant-drawer-body {
    padding: var(--drawer-body-padding);
    padding-right: calc(
      var(--drawer-body-padding) - var(--drawer-scrollbar-padding)
    );
    scrollbar-color: #777e91 transparent;
    scrollbar-width: thin;
  }
`;

export default CustomDrawer;
