import React, { useContext } from "react";
import UserDisplay from "../UserDisplay";
import SubmissionsDisplay from "../submissions/SubmissionsDisplay";
import { styled } from "styled-components";
import { APP_NAME, discordLink } from "../../helpers/constants";
import { ProfileBugOverlayContainer } from "../../styles/CommonElements";
import { AuthContext } from "../../context/AuthProvider";

export default function QuestDisplaySubmissions({
  quest,
  currentUserSubmission,
  submissionAmount,
  pollCompleted,
  confirmedSubmissions,
  questPollState,
  hasVoted,
}) {
  const { user } = useContext(AuthContext);

  return submissionAmount > 0 || submissionAmount === "5+" ? (
    <>
      {hasVoted && !pollCompleted && (
        <VoteMattersSection>
          <VoteMatters>✨ Your vote matters ✨</VoteMatters>
          <VoteMattersSubheader>
            Results will be posted soon. Come hang out with the quest community
            in the{" "}
            <DiscordLink href={discordLink} target="_blank">
              {APP_NAME} Discord ✌️
            </DiscordLink>
          </VoteMattersSubheader>
        </VoteMattersSection>
      )}
      <SubmissionsDisplay
        quest={quest}
        currentUserSubmission={currentUserSubmission}
        state={questPollState}
        confirmedSubmissions={confirmedSubmissions}
        hasVoted={hasVoted}
      />
    </>
  ) : (
    <EmptySubmissionsContainer>
      <SubmissionsContainer>
        <div
          className="quest-submission-preview-container"
          style={{
            border: "1px dashed #D3E349",
            height: "98px",
          }}
        >
          <ProfileBugOverlayContainer>
            <UserDisplay userId={user?.uid} size={30} />
          </ProfileBugOverlayContainer>
          <div className="quest-submission-preview" />
        </div>
      </SubmissionsContainer>
    </EmptySubmissionsContainer>
  );
}

const EmptySubmissionsContainer = styled.div``;

const SubmissionsContainer = styled.div`
  display: contents;
  margin: auto;
  text-align: center;
  margin-top: 10px;
`;

const VoteMattersSection = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const VoteMatters = styled.div`
  color: var(--color-cream);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.5px;
`;

const VoteMattersSubheader = styled.div`
  color: var(--color-gray-50);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-top: 10px;
`;

const DiscordLink = styled.a`
  color: var(--color-pink);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
`;
