import { useContext } from "react";
import { ReactSVG } from "react-svg";
import { styled } from "styled-components";
import { AuthContext } from "../context/AuthProvider";

const Bell = ({
  hasNotifications = false,
  isActive = false,
  isDisabled = false,
  width,
  height,
  ...props
}) => {
  const { user } = useContext(AuthContext);

  if (isDisabled) {
    return (
      <StyledSvg
        src="/icons/bell-disabled.svg"
        $width={width}
        $height={height}
        {...props}
      />
    );
  }

  return (
    <StyledSvg
      src="/icons/bell.svg"
      $width={width}
      $height={height}
      $isActive={isActive}
      $hasNotifications={hasNotifications}
      $user={user}
      {...props}
    />
  );
};

const StyledSvg = styled(ReactSVG)`
  color: ${(props) =>
    props.$isActive ? "var(--color-highlight)" : "var(--color-cream)"};

  svg {
    width: ${(props) => props.$width && props.$width};
    height: ${(props) => props.$height && props.$height};
  }

  circle {
    display: ${(props) => (!props.$hasNotifications || !props.$user) && "none"};
  }

  /* Accounting for added divs by React-SVG */
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default Bell;
