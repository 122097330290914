import { useContext } from "react";
import { Affix } from "antd";
import { AuthContext } from "../../context/AuthProvider";
import Button from "../Button";
import styled from "styled-components";

const VoteBanner = ({ isDisabled, isSubmitting, handleSubmit }) => {
  const { user, requireLogin } = useContext(AuthContext);

  return (
    <VoteAffix>
      <div>
        <Button
          isLoading={isSubmitting}
          disabled={isDisabled}
          size="large"
          rounded="slight"
          onClick={() => {
            if (!user) {
              requireLogin();
              return;
            }
            handleSubmit();
          }}
        >
          Submit Vote
        </Button>
      </div>
    </VoteAffix>
  );
};

const VoteAffix = styled(Affix)`
  display: block;
  position: fixed;
  width: 100%;
  z-index: 5;
  bottom: 0;
  background: #2f323e !important;
  box-shadow: 0px -4px 9px 0px rgba(0, 0, 0, 0.1);
  left: 0;
  padding: 10px 5%;

  & > div {
    width: "100%";
  }
`;

export default VoteBanner;
