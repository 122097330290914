import React from "react";
import { Col, Input, Row, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { RightAlignedCol } from "../styles/CommonElements";

const { TextArea } = Input;

export default function SuggestedSearchEditor({
  suggestedSearchTerms,
  setSuggestedSearchTerms,
}) {
  const handleSearchTermChange = (e, index) => {
    let prompts = [...suggestedSearchTerms];
    prompts[index] = e.target.value;
    setSuggestedSearchTerms(prompts);
  };

  return (
    <Container>
      <Row>
        <Col span={12}>
          <InputHeader>
            Suggested Search Terms ({suggestedSearchTerms.length})
          </InputHeader>
        </Col>
        <RightAlignedCol span={12}>
          <Tooltip placement="left" title={"Max 5 Terms"}>
            <img src="icons/info.svg" alt="" />
          </Tooltip>
        </RightAlignedCol>
      </Row>
      {suggestedSearchTerms.map((prompt, index) => (
        <Row gutter={8} style={{ marginBottom: 8 }} key={index}>
          <Col span={23}>
            <StarterPromptTextArea
              placeholder="Enter search term"
              value={prompt}
              maxLength={150}
              autoSize={{ minRows: 1 }}
              onChange={(event) => handleSearchTermChange(event, index)}
            />
          </Col>
          <Col span={1} style={{ textAlign: "right", margin: "auto" }}>
            <DeleteOutlined
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setSuggestedSearchTerms((starterPrompts) =>
                  starterPrompts.filter((p, i) => i !== index)
                );
              }}
            />
          </Col>
        </Row>
      ))}
      {suggestedSearchTerms.length < 25 && (
        <AddPromptButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSuggestedSearchTerms((starterPrompts) => [
              ...starterPrompts,
              "",
            ]);
          }}
        >
          + Add search term
        </AddPromptButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin: 30px 0px;
`;

const AddPromptButton = styled.button`
  border-radius: 8px;
  border: 2px solid #353945;
  background: var(--color-gray-500);
  margin-top: 0px;
  display: inline-flex;
  padding: 7px 17px 5px 15px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: -0.12px;
  cursor: pointer;
`;

const InputHeader = styled.div`
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom: 14px;
`;

const StarterPromptTextArea = styled(TextArea)`
  background: var(--textAreaColor);
  font-family: "Roboto Mono";
  border: 0px;
  vertical-align: top;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--inputFieldText);
  padding: 14px;
  line-height: 24px;
  border-radius: 8px;
  color: white !important;
  resize: "none";
`;
