import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { ReactSVG } from "react-svg";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import userModel from "../../lib/firebase/userModel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authRoutes } from "../../helpers/authentication";
import { DIALOG_NAME } from "../../helpers/useDialogSearchParam";

const auth = getAuth();
const provider = new GoogleAuthProvider();

const ICON_GOOGLE = "/icons/google.svg";

export default function ThirdPartyAuth({
  cta = "log in",
  size = "large",
  onCompletion,
}) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const openGoogleAuth = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        const userInfo = await userModel.getOneById(user.uid);
        const currentLocation = window.location.pathname;
        if (user && !userInfo) {
          // Ideally we wouldn't redirect if they are already on the signup page or in the signup modal
          if (
            !authRoutes.includes(currentLocation) &&
            searchParams.get(DIALOG_NAME.signup) === null
          ) {
            return navigate("/signup");
          }
        } else if (!authRoutes.includes(currentLocation)) {
          searchParams.delete(DIALOG_NAME.login);
          setSearchParams(searchParams);
        }
        onCompletion?.();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Button variant="primary" size={size} onClick={() => openGoogleAuth()}>
        <Logo src={ICON_GOOGLE} />
        <Cta>{cta}</Cta> with Google
      </Button>
    </>
  );
}

const Logo = styled(ReactSVG)`
  position: absolute;
  left: 12px;
`;

const Cta = styled.div`
  text-transform: capitalize;
  margin-right: 4px;
`;
