import { getAuth } from "firebase/auth";
import { promosTable } from "../../helpers/tables";
import { queryMany, queryOne } from "./all";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../helpers/firebase";

const table = promosTable;

const promoModel = {
  create: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const ref = await addDoc(collection(db, table), {
      ...props,
    });

    const doc = await queryOne({ table, id: ref.id });
    if (!doc) throw new Error("Promo not created");
    return doc;
  },
  delete: async (id) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    await deleteDoc(doc(getFirestore(), table, id));
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });
    return data;
  },
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  incrementClicks: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const { id } = props;
    const body = JSON.stringify({ id });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/incrementClicks`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "content-type": "application/json",
          },
          body,
        }
      );

      return await response.json();
    } catch (error) {
      console.error("Error incrementing promo clicks:", error);
      throw error;
    }
  },
  update: async (id, data) => {
    const promoRef = doc(getFirestore(), table, id);
    await updateDoc(promoRef, data);
  },
};

export default promoModel;
