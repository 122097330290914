import React, { useState } from "react";
import { message } from "antd";
import { callGenAi, initImage } from "../helpers/genAI";
import { increaseGenerationQuota } from "../helpers/firebase";
import { getFileFromUrl, sleep } from "../helpers/general";
import styled from "styled-components";
import FinalizeGeneratedImage from "./FinalizeGeneratedImage";
import { EditGenAi, Ideas, ImageGrid, PromptDisplay } from "../components";
import { ImageGeneratorHeader } from "../components/gen-ai";
import { Spacer } from "../styles/CommonElements";
import { getMixpanelQuestType, trackEvent } from "../helpers/mixpanel";
import { SUBMISSION_TYPES } from "../helpers/constants";

const maxPromptLength = 200;
const defaultModelId = "ac614f96-1082-45bf-be9d-757f2d31c174";
const limitReachedText =
  "You have reached your limit for this quest. Thank you for participating!";

export default function ImageGenerator({ questData, storyworld, submit }) {
  const [genAiImages, setGenAiImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [text, setText] = useState("");
  const [prompt, setPrompt] = useState("");
  const [negativePrompt, setNegativePrompt] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [typedImageUrl, setTypedImageUrl] = useState("");
  const [isButtonEnabled, setButtonEnabled] = useState(true);
  const [genAiError, setGenAiError] = useState(null);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [usedStarterPrompt, setUsedStarterPrompt] = useState(false);
  const [hasUploadedImage, setHasUploadedImage] = useState(false);
  const [hasTypedInUrl, setHasTypedInUrl] = useState(false);

  const handleChange = (event) => {
    setPrompt(event.target.value);
    if (prompt !== "") {
      setGenAiError(null);
    }
  };

  const getImageUrlToUpload = (imageUrl, typedImageUrl) => {
    if (imageUrl) return imageUrl;
    if (typedImageUrl !== "") return typedImageUrl;
    return null;
  };

  const startSubmitting = () => {
    submit(SUBMISSION_TYPES.GEN_AI, {
      text,
      prompt,
      usedStarterPrompt,
      usedNegativePrompt: negativePrompt !== "",
      usedSeedImage: hasUploadedImage,
      usedTypedUrl: hasTypedInUrl,
      storyworld,
      selectedImage,
    });
  };

  const createGeneration = async () => {
    setButtonEnabled(false);
    const canGenerate = await increaseGenerationQuota(questData.id);

    if (!canGenerate) {
      setLimitReached(true);
      message.error(limitReachedText);
      setButtonEnabled(true);
      return;
    }
    setSelectedImage(null);
    if (prompt === "") {
      setGenAiError("Please provide a Gen AI prompt to generate your image");
      setButtonEnabled(true);
      return;
    }
    setGenAiImages([]);
    setButtonEnabled(false);

    const imageUrlToUpload = getImageUrlToUpload(imageUrl, typedImageUrl);
    let initImageId;
    console.log("Image init ID is unavailable");

    if (imageUrlToUpload) {
      const blob = await getFileFromUrl(imageUrlToUpload);
      if (!blob) {
        console.log("Image init ID is unavailable");
        initImageId = null;
      } else {
        initImageId = await initImage(blob);
        await sleep(3);
      }
    }

    const promise = await callGenAi({
      quest: questData.id,
      prompt,
      modelId: storyworld.modelId ? storyworld.modelId : defaultModelId,
      initImageId,
      negativePrompt,
      storyworld,
      usedStarterPrompt,
      usedSeedImage: imageUrl !== null,
      usedTypedUrl: typedImageUrl !== "",
      includesCharacters: questData.includesCharacters
        ? questData.includesCharacters
        : false,
    });

    if (typeof promise === "string") {
      message.error(promise);
    } else {
      setGenAiImages(promise);
    }

    setButtonEnabled(true);
  };

  const createVariations = async () => {
    setButtonEnabled(false);
    trackEvent("Clicked Create Variations Button", {
      quest_id: questData.id,
      quest_name: questData.title,
      quest_type: getMixpanelQuestType(questData),
      storyworld_id: questData.storyworld,
      storyworld_name: storyworld.title,
      title: `${storyworld.title}: ${questData.title}`,
    });
    const canGenerate = await increaseGenerationQuota(questData.id);
    if (canGenerate) {
      setGenAiImages([]);

      // upload reference id and save id
      let imageUrlToUpload = null;
      if (selectedImage) imageUrlToUpload = selectedImage;
      setSelectedImage(null);

      await getFileFromUrl(imageUrlToUpload).then(async (blob) => {
        let initImageId;
        if (!blob) {
          initImageId = null;
          console.log("Image init ID is unavailable");
        } else {
          initImageId = await initImage(blob);
          await sleep(3);
        }

        if (prompt === "") {
          setGenAiError(
            "Please provide a Gen AI prompt to generate your image"
          );
        } else {
          setGenAiImages([]);
          setButtonEnabled(false);
          const promise = await callGenAi({
            quest: questData.id,
            prompt,
            modelId: storyworld.modelId ? storyworld.modelId : defaultModelId,
            initImageId,
            negativePrompt,
            storyworld: storyworld,
            usedStarterPrompt,
            usedSeedImage: imageUrl !== null,
            usedTypedUrl: typedImageUrl !== "",
            includesCharacters: questData.includesCharacters
              ? questData.includesCharacters
              : false,
          });
          if (typeof promise === "string") message.error(promise);
          setGenAiImages(promise);
          setButtonEnabled(true);
        }
      });
    } else {
      setLimitReached(true);
      message.error(limitReachedText);
    }
    setButtonEnabled(true);
  };

  return (
    <>
      <Container>
        <ImageGeneratorHeader
          quest={questData}
          isFinalizing={isFinalizing}
          setIsFinalizing={setIsFinalizing}
          isButtonEnabled={isButtonEnabled}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          genAiImages={genAiImages}
          setGenAiImages={setGenAiImages}
        />
        <Spacer $marginTop="10px" />
        <div>
          {!isFinalizing ? (
            <ImageGrid
              genAiImages={genAiImages}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              isButtonEnabled={isButtonEnabled}
            />
          ) : (
            <FinalizeGeneratedImage
              quest={questData}
              selectedImage={selectedImage}
              text={text}
              setText={setText}
              submit={startSubmitting}
            />
          )}

          {!isButtonEnabled || (genAiImages.length > 0 && !isFinalizing) ? (
            <>
              <Spacer $marginTop="36px" />
              <PromptDisplay
                prompt={prompt}
                setPrompt={setPrompt}
                maxPromptLength={maxPromptLength}
                editIcon="/icons/icon-edit.svg"
                placeholder="Describe the art you want to create..."
              />
              <Spacer $marginTop="24px" />
              <EditGenAi
                quest={questData}
                isGenerating={!isButtonEnabled}
                selectedImage={selectedImage}
                createGeneration={createGeneration}
                createVariations={createVariations}
                negativePrompt={negativePrompt}
                setNegativePrompt={setNegativePrompt}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                typedImageUrl={typedImageUrl}
                setTypedImageUrl={setTypedImageUrl}
                limitReached={limitReached}
                hasUploadedImage={hasUploadedImage}
                setHasUploadedImage={setHasUploadedImage}
                hasTypedInUrl={hasTypedInUrl}
                setHasTypedInUrl={setHasTypedInUrl}
              />
            </>
          ) : (
            !isFinalizing && (
              <div>
                <TextArea
                  className="transition-colors"
                  value={prompt}
                  placeholder="Describe the art you want to create..."
                  maxLength={maxPromptLength}
                  onChange={handleChange}
                />
              </div>
            )
          )}
          <Spacer $marginTop="22px" />
          {isButtonEnabled && genAiImages.length === 0 && (
            <>
              {prompt === "" || limitReached ? (
                <GenerateButtonDisabled>Create art</GenerateButtonDisabled>
              ) : (
                <GenerateButton
                  onClick={async () => {
                    createGeneration();
                    trackEvent("Clicked Create Art Button", {
                      quest_id: questData.id,
                      quest_name: questData.title,
                      storyworld_id: questData.storyworld,
                      storyworld_name: storyworld.title,
                      title: `${storyworld.title}: ${questData.title}`,
                    });
                  }}
                >
                  Create art
                </GenerateButton>
              )}
              <Spacer $marginTop="46px" />
              {questData.starterPrompts &&
                questData.starterPrompts.length > 0 && (
                  <NeedIdeas>Need Ideas?</NeedIdeas>
                )}
            </>
          )}
          {genAiError !== null && <span>{genAiError}</span>}
        </div>
      </Container>

      {isButtonEnabled && genAiImages.length === 0 && (
        <Ideas
          ideas={questData.starterPrompts ? questData.starterPrompts : []}
          setPrompt={setPrompt}
          setUsedStarterPrompt={setUsedStarterPrompt}
        />
      )}
    </>
  );
}

const Container = styled.div`
  text-align: left;
  padding: 16px 16px 0px 16px;

  .one-line-prompt {
    color: var(--color-cream);
    line-height: 27px;
  }

  .edit-icon {
    vertical-align: bottom;
  }
`;

const NeedIdeas = styled.div`
  color: var(--color-cream);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
`;

const TextArea = styled.textarea`
  background: var(--color-gray-300);
  border-radius: 8px;
  border: 1px solid transparent;
  resize: none;
  width: 100%;
  min-height: 138px;
  outline: none;
  touch-action: manipulation;
  border: 0px;
  vertical-align: top;
  font-size: 14px;
  padding: 18px;
  color: white;

  font-weight: 600;

  &:focus {
    border-color: #978ad7;
  }

  &::placeholder {
    color: #7d818e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.14px;
    color: var(--inputFieldText) !important;
  }
`;

const GenerateButton = styled.button`
  margin: auto;
  background: var(--lightPurple);
  display: block;
  color: white;
  padding: 16px 20px;
  border: 0;
  border-radius: 40px;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const GenerateButtonDisabled = styled.button`
  margin: auto;
  background: hsla(0, 0%, 100%, 0.08);
  display: block;
  color: gray;
  pointer-events: none;
  border: 0;
  border-radius: 40px;
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;
