import React from "react";
import styled from "styled-components";
import GalleryEntry from "../../../views/GalleryEntry";

export const ArtQuestResult = ({ entry, isWinner = false, children }) => {
  const WinnerCheck = isWinner ? WinningImage : React.Fragment;

  return (
    <Container $isWinner={isWinner}>
      <WinnerCheck>
        <div>
          <GalleryEntry entry={entry} hideUi={true} winner={isWinner} />
        </div>
      </WinnerCheck>
      {children}
    </Container>
  );
};

export const ArtQuestWinner = styled.div`
  margin-bottom: 34px;
  padding-top: 70px;
`;

const Container = styled.div`
  position: relative;
`;

const WinningImage = styled.div`
  left: 0;
  text-align: center;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  margin: auto;
  display: block;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
