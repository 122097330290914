import { ConfigProvider, Modal } from "antd";
import styled from "styled-components";

const CustomModal = ({
  isOpen,
  onClose,
  children,
  showCloseIcon = true,
  isCentered = false,
  width = 395,
  ...rest
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorIcon: "var(--color-gray-200)",
        },
      }}
    >
      <StyledModal
        open={isOpen}
        onCancel={onClose}
        footer={[]}
        closeIcon={showCloseIcon}
        centered={isCentered}
        width={width}
        {...rest}
      >
        {children}
      </StyledModal>
    </ConfigProvider>
  );
};

export const ModalTitle = styled.div`
  color: var(--color-text);
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const ModalDescription = styled.p`
  color: #949ba4;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
`;

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: block;
    position: relative;
    top: unset;
    inset-inline-end: unset;
    margin-left: auto;
    color: var(--color-gray-200);

    &:hover {
      color: var(--color-gray-200);
    }

    svg {
      margin-left: -3px;
    }
  }

  .ant-modal-content {
    border-radius: 10px;
    // border: 2px solid var(--color-gray-100);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background: white;
  }

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-footer {
    display: none;
  }
`;

export default CustomModal;
