import { useEffect, useState } from "react";
import { message } from "antd";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { generateRandomId } from "../helpers/firebase";

const minWidth = undefined;
const maxWidth = undefined;
const maxHeight = undefined;

const checkResolution = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload((event) => {
      const _loadedImageUrl = event.target?.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        var goodRes;
        if (minWidth) {
          goodRes = width >= minWidth;
        } else if (maxWidth && maxHeight) {
          goodRes = width === maxWidth && height === maxHeight;
        }
        if (!goodRes) {
          message.error(`Image must be the correct resolution`);
          resolve(false);
        }
        resolve(true);
      });
    });
  });
};

const useComicUpload = ({
  fileBucket,
  bypassRes,
  maxFileSizeInMb,
  setFileList,
  fileList: filesInState,
}) => {
  const [fileBatch, setFileBatch] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [batchTotal, setBatchTotal] = useState(0);
  const [sessionTotal, setSessionTotal] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const storage = getStorage();

  // Only update the file list when all files have their downloadUrl fetched
  useEffect(() => {
    if (batchTotal === 0) {
      return;
    }

    if (batchTotal === completed) {
      // They may not come in the proper order due to asynchronicity
      const orderedFiles = fileBatch.sort(
        (a, b) => a.pageNumber - b.pageNumber
      );

      setFileList([...filesInState, ...orderedFiles]);
      setFileBatch([]);
      setSessionTotal(sessionTotal + batchTotal);
      setCompleted(0);
      setBatchTotal(0);
      setIsUploading(false);
    }
  }, [
    batchTotal,
    completed,
    filesInState,
    sessionTotal,
    setFileList,
    fileBatch,
  ]);

  // antd cancels upload on a return of false
  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Please upload a JPG/PNG file");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < maxFileSizeInMb;
    if (!isLt2M) {
      message.error(`Image must be smaller than ${maxFileSizeInMb}MB`);
      return false;
    }

    if (!bypassRes) {
      const validResolution = await checkResolution(file);
      return validResolution;
    }

    return true;
  };

  const customRequest = ({ onError, onSuccess, file }) => {
    const storageRef = ref(
      storage,
      `${fileBucket}/${new Date()} -- ${generateRandomId()}`
    );

    uploadBytes(storageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((downloadUrl) => {
        onSuccess({ downloadUrl });
      })
      .catch(() => {
        onError();
      });
  };

  // fileList here is internal state used by antd. It doesn't account for previously uploaded files before the current upload session,
  // so we have to introduce several intermediary state values.
  const onChange = async ({ file, fileList }) => {
    setBatchTotal(fileList.length - sessionTotal);
    setIsUploading(true);

    if (file.status === "done") {
      const { downloadUrl } = file.response;

      // Using the callback function on setState functions helps deal with race conditions.
      // We still need to delay the update until all the files have loaded, hence the regretable two state values.
      setFileBatch((prevState) => {
        const fileIndex = fileList.findIndex((fileInList) => {
          return fileInList.uid === file.uid;
        });

        // Unfortunately complicated. Length of all files in parent component,
        // minus the current session (everything we have added to the state), plus the index in antd's fileList, plus one.
        const pageNumber = filesInState.length - sessionTotal + fileIndex + 1;

        return [
          ...prevState,
          {
            // could also provide additional file information
            // ...file,
            url: downloadUrl,
            fileName: file.name,
            pageNumber,
            id: file.name + "-" + new Date(),
          },
        ];
      });
      setCompleted((prevState) => prevState + 1);

      message.success(`${file.name} file uploaded successfully`);
    }

    if (file.status === "error") {
      message.error(`${file.name} file upload failed.`);
    }
  };
  return { isUploading, beforeUpload, onChange, customRequest };
};

export default useComicUpload;
