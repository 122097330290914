import React, { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { DeleteUserModal } from "../components/profile";
import userModel from "../lib/firebase/userModel";
import { userKeys } from "../lib/queryKeys";
import { AuthContext } from "../context/AuthProvider";
import { useBreakpoints } from "../lib/breakpoints";
import EditProfileForm from "../components/profile/EditProfileForm";

export default function EditProfile() {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { user } = useContext(AuthContext);

  const { isMobile } = useBreakpoints();

  const { data: userInfo, isSuccess } = useQuery({
    queryKey: userKeys.user(user?.uid),
    queryFn: () => {
      return userModel.getOneById(user.uid);
    },
    enabled: !!user,
  });

  if (!userInfo || !isSuccess) return null;

  return (
    <>
      <DeleteUserModal
        isDeleteOpen={isDeleteOpen}
        setIsDeleteOpen={setIsDeleteOpen}
      />

      <EditProfileForm />

      {isMobile && (
        <DeleteUserButton
          onClick={() => {
            setIsDeleteOpen(true);
          }}
        >
          <DeleteIcon src="/icons/trash-red.svg" alt="" />
          Delete account
        </DeleteUserButton>
      )}
    </>
  );
}

const DeleteUserButton = styled.button`
  color: var(--exitColor);
  line-height: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const DeleteIcon = styled.img`
  width: 12px;
  height: auto;
  margin: 0px 7px 3px 0px;
`;
