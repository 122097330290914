import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Hits, SearchBox } from "react-instantsearch";
import { InstantSearch } from "react-instantsearch-core";
import { TypesenseSearch } from "../../styles/CommonElements";
import styled from "styled-components";
import { useEffect, useState } from "react";
import questModel from "../../lib/firebase/questModel";
import userModel from "../../lib/firebase/userModel";
import { sleep } from "../../helpers/general";

export default function SearchInterface({
  collection,
  queryBy,
  placeholder,
  searchIconPath,
  setContributors,
  allSelections,
  selection,
  index,
}) {
  const [isFocused, setFocused] = useState(false);
  const [iconUrl, setIconUrl] = useState("");
  const [entryTitle, setEntryTitle] = useState("");

  useEffect(() => {
    if (selection === null) return;
    async function fetchData() {
      if (collection === "users") {
        const userData = await userModel.getOneById(selection);
        setIconUrl(userData.pfp);
        setEntryTitle(userData.username);
      }

      if (collection === "quests") {
        const questData = await questModel.getOneById(selection);
        let newIconUrl = "";

        if (questData) {
          if (questData.thumbnailUrl && questData.thumbnailUrl !== "") {
            newIconUrl = questData.thumbnailUrl;
          } else {
            newIconUrl = "";
          }

          setIconUrl(newIconUrl);
          setEntryTitle(questData.title);
        }
      }
    }
    fetchData();
  }, [collection, selection]);

  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: process.env.REACT_APP_TYPESENSE_API_KEY, // Be sure to use a Search API Key
      nodes: [
        {
          host: "7fw6v8yqpgt5ljunp-1.a1.typesense.net", // where xxx is the ClusterID of your Typesense Cloud cluster
          port: "443",
          protocol: "https",
        },
      ],
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  queryBy is required.
    additionalSearchParameters: {
      query_by: queryBy,
    },
  });

  const searchClient = typesenseInstantsearchAdapter.searchClient;

  const Hit = ({ hit }) => {
    if (collection === "quests") {
      return (
        <HitButton
          onClick={() => {
            const newItems = [...allSelections];
            newItems[index].questId = hit.id;
            setContributors(newItems);
            setFocused(false);
          }}
        >
          {hit.title}
        </HitButton>
      );
    }

    if (collection === "users") {
      return (
        <HitButton
          onClick={() => {
            const newItems = [...allSelections];
            newItems[index].userId = hit.id;
            setContributors(newItems);
            setFocused(false);
          }}
        >
          {hit.username}
        </HitButton>
      );
    }
  };

  return (
    <Container
      style={{
        cursor:
          !allSelections[index].questId &&
          collection === "users" &&
          "not-allowed",
      }}
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={`${collection}-${process.env.REACT_APP_DEPLOYMENT}`}
      >
        <TypesenseSearch>
          {collection === "quests" ? (
            <QuestIcon
              src={iconUrl !== "" && selection ? iconUrl : searchIconPath}
              alt=""
              focused={selection || isFocused}
              selection={selection}
            />
          ) : (
            <Icon
              src={iconUrl !== "" && selection ? iconUrl : searchIconPath}
              alt=""
              focused={selection || isFocused}
              selection={selection}
            />
          )}
          {selection ? (
            <SelectionTitle
              onClick={() => {
                const newItems = [...allSelections];
                if (collection === "quests") {
                  newItems[index].questId = null;
                  newItems[index].userId = null;
                } else if (collection === "users")
                  newItems[index].userId = null;
                setEntryTitle("");
                setIconUrl("");
                setContributors(newItems);
              }}
            >
              {entryTitle}
            </SelectionTitle>
          ) : (
            <SearchBox
              placeholder={placeholder}
              onFocus={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFocused(true);
              }}
              onBlur={async () => {
                await sleep(0.2);
                setFocused(false);
              }}
              style={{
                pointerEvents:
                  !allSelections[index].questId &&
                  collection === "users" &&
                  "none",
              }}
            />
          )}
        </TypesenseSearch>
        {isFocused && (
          <HitsContainer>
            <Hits hitComponent={Hit} />
          </HitsContainer>
        )}
      </InstantSearch>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 2px;
  height: 100%;

  .ais-SearchBox-form {
    height: 100%;
  }

  .ais-SearchBox-input:focus-visible {
    outline: 0px !important;
  }

  .ais-Hits-list {
    margin-top: 8px;
    padding-left: 16px;

    li::marker {
      content: "";
    }
  }
`;

const HitsContainer = styled.div`
  width: 100%;
  position: absolute;
  background: var(--color-gray-500);
  border-radius: 16px;
  border: 1px solid var(--color-gray-300);
  box-shadow:
    0px 32px 48px -8px rgba(0, 0, 0, 0.1),
    0px 0px 14px -4px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(16px);
  z-index: 10;
`;

const HitButton = styled.div`
  margin: auto;
  padding: 6px 14px;
  margin-right: 12px;
  border-radius: 4px;
  color: #9a9fa5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    background: var(--color-gray-300);
    overflow: hidden;
    color: white;
    font-weight: 600;
  }
`;

const Icon = styled.img`
  height: 26px;
  width: 26px;
  opacity: ${(props) => (props.focused ? 1 : 0.3)};
  border-radius: ${(props) => props.selection && 50}px;
  object-fit: ${(props) => props.selection && "cover"};
`;

const QuestIcon = styled.img`
  height: 26px;
  width: 26px;
  opacity: ${(props) => (props.focused ? 1 : 0.3)};
  object-fit: ${(props) => props.selection && "cover"};
  border-radius: ${(props) => props.selection && 4}px;
`;

const SelectionTitle = styled.div`
  width: 85%;
  margin-left: 10px;
  color: white;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.15px;
  white-space: nowrap;
  word-wrap: break-word !important;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis !important;
  overflow: hidden;
  cursor: pointer;
`;
