import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import UserDisplay from "../UserDisplay";
import storyworldModel from "../../lib/firebase/storyworldModel";
import { adminUserId } from "../../helpers/constants";
import { storyworldKeys } from "../../lib/queryKeys";
import { trackEvent } from "../../helpers/mixpanel";
import { Spacer } from "../../styles/CommonElements";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { device, useBreakpoints } from "../../lib/breakpoints";
import Button from "../Button";
import { ShareModal } from "../general";
import { storyworldDetailUrl } from "../../lib/routes";
import Bell from "../Bell";
import useAlertConfig from "../../lib/useAlertConfig";
import StoryworldQuestMenuSwitch from "./StoryworldQuestMenuSwitch";
import Hidden from "../Hidden";
import QuestCardContainer from "./QuestCardContainer";

export const MENU_OPTIONS = {
  CREATE: "create",
  VOTE: "vote",
  RESULTS: "results",
};

const SHARE_ICON = "/icons/share-hollow.svg";

export default function StoryworldQuestPageDesktop({ toggleQuestsFollow }) {
  const { search } = useLocation();

  const { storyId: id } = useParams();
  const { isDesktop } = useBreakpoints();

  const [isOpen, setIsOpen] = useState(false);
  const [extraMenu, setExtraMenu] = useState(MENU_OPTIONS.CREATE);

  const { addBackLocation } = useContext(BackLocationsContext);

  const {
    followingStatus: { questAlerts },
  } = useAlertConfig(id);

  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(id),
    queryFn: () => storyworldModel.getOneById(id),
    select: (data) => ({
      ...data,
      heroImage: data.backgroundImageUrl ?? data.imageUrl,
    }),
  });

  useEffect(() => {
    // Search parameter gets priority over other contextual setting of the tabs
    if (search) {
      const query = new URLSearchParams(search);
      const tabName = query.get("tab");

      if (tabName === "create") {
        setExtraMenu(MENU_OPTIONS.CREATE);
        return;
      } else if (tabName === "vote") {
        setExtraMenu(MENU_OPTIONS.VOTE);
        return;
      } else if (tabName === "results") {
        setExtraMenu(MENU_OPTIONS.RESULTS);
        return;
      }
    }
  }, [search]);

  if (storyworldQuery.status !== "success" || storyworldQuery.data === null)
    return null;

  return (
    <>
      <Container>
        <Spacer $marginTop="1.25rem" />
        <DesktopContainer>
          <DesktopLeftContainer>
            <Image
              src={
                storyworldQuery.data.backgroundImageUrl ??
                storyworldQuery.data.imageUrl
              }
            />
          </DesktopLeftContainer>

          <DesktopRightContainer>
            <Section>
              <Title>{storyworldQuery.data?.title}</Title>
              <Spacer $marginTop="0.3rem" />
              <Users>
                {storyworldQuery.data.creators.map(
                  (creatorId) =>
                    creatorId !== adminUserId && (
                      <UserDisplay
                        key={creatorId}
                        size={25}
                        userId={creatorId}
                        showName={true}
                        isAuthor={true}
                        fontSize="12px"
                      />
                    )
                )}
              </Users>

              <Hidden mobile tablet>
                <Spacer $marginTop="32px" />
              </Hidden>
              <Hidden mobile desktop>
                <Spacer $marginTop="20px" />
              </Hidden>

              <Link
                to={storyworldDetailUrl(id)}
                onClick={() => {
                  trackEvent("Clicked Read Episode CTA", {
                    storyworld_id: id,
                    storyworld_name: storyworldQuery.data.title,
                  });
                  addBackLocation(`/stories/${id}`);
                }}
              >
                <Button
                  size={isDesktop ? "medium" : "small"}
                  variant="ghost"
                  rounded="slight"
                >
                  Read Story
                </Button>
              </Link>

              <Spacer $marginTop="0.5rem" />

              <StyledButton
                size={isDesktop ? "medium" : "small"}
                variant="ghost"
                rounded="slight"
                onClick={() => {
                  toggleQuestsFollow();
                }}
              >
                <Bell width="24px" height="24px" />
                {!questAlerts ? "Turn on Notifications" : "Notifications: On"}
              </StyledButton>

              <Spacer $marginTop="0.25rem" />

              <ShareButton
                size="medium"
                variant="text"
                rounded="slight"
                onClick={() => setIsOpen(true)}
              >
                <ShareIcon src={SHARE_ICON} alt="" /> Share
              </ShareButton>
            </Section>
          </DesktopRightContainer>
        </DesktopContainer>
        <Spacer $marginTop="2.5rem" />
        <StoryworldQuestMenuSwitch
          extraMenu={extraMenu}
          setExtraMenu={setExtraMenu}
        />
        <Spacer $marginTop="2rem" />

        <QuestCardContainer extraMenu={extraMenu} />

        <Spacer $marginTop="3rem" />
      </Container>

      <ShareModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        storyworld={storyworldQuery?.data}
      />
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;

  @media (${device.mobile}) {
    background-color: #1d232d;
    position: relative;
  }

  @media (${device.tablet}) {
    width: 597px;
  }

  @media (${device.desktop}) {
    width: 907px;
  }
`;

const DesktopContainer = styled.div`
  display: inline-flex;
  gap: 13px;
  position: relative;
  text-align: left;
  width: 100%;

  @media (${device.tablet}) {
    min-height: 232px;
  }

  @media (${device.desktop}) {
    min-height: 294px;
  }
`;

const DesktopLeftContainer = styled.div`
  height: 100%;

  @media (${device.tablet}) {
    width: 349px;
  }

  @media (${device.desktop}) {
    width: 523px;
  }
`;

const DesktopRightContainer = styled.div`
  height: 100%;

  @media (${device.tablet}) {
    width: 233px;
  }

  @media (${device.desktop}) {
    width: 369px;
  }
`;

const Image = styled.img`
  object-fit: cover;
  border-radius: 12px;
  width: 100%;
  height: 100%;

  @media (${device.tablet}) {
    height: 232px;
  }

  @media (${device.desktop}) {
    height: 294px;
  }
`;

const Section = styled.div`
  border-radius: 12px;
  border: 1px solid var(--border-light);
  background: var(--color-gray-600);
  overflow: hidden;
  height: 100%;
  min-height: 100%;

  @media (${device.tablet}) {
    min-height: 232px;
    padding: 16px 16px 8px 16px;
  }

  @media (${device.desktop}) {
    min-height: 294px;
    padding: 24px;
  }
`;

const ShareButton = styled(Button)`
  color: var(--color-cream-base);
`;

const ShareIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const StyledButton = styled(Button)`
  gap: 4px;
`;

const Title = styled.h1`
  margin-bottom: 0px;
  font-family: "Hoss Sharp";
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  color: var(--color-cream-base);
  text-align: left;
`;

const Users = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (${device.tablet}) {
    gap: 4px;
    flex-wrap: wrap;
  }
`;
