import styled from "styled-components";
import NewUserCarousel from "./NewUserCarousel";
import useCreateProfileMutation from "../../lib/useCreateProfileMutation";
import { device } from "../../lib/breakpoints";
import CreateProfileForm from "./CreateProfileForm";

export default function CreateProfile() {
  const {
    mutate: createProfile,
    isPending: isRegistering,
    isSuccess: isProfileCreated,
  } = useCreateProfileMutation();

  const onSubmit = async (data) => {
    createProfile(data);
  };

  return (
    <>
      {!isProfileCreated ? (
        <Container>
          <InnerContainer>
            <Header>Create a public profile</Header>
            <CreateProfileForm
              onSubmit={onSubmit}
              isSubmitting={isRegistering}
            />
          </InnerContainer>
          <Diamond src="/images/diamond.svg" alt="" />
          <Clouds src="/images/clouds.svg" alt="" />
        </Container>
      ) : (
        <NewUserCarousel />
      )}
    </>
  );
}

const Container = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 3rem;
  position: relative;
`;

const InnerContainer = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  display: block;
  z-index: 5;
`;

const Header = styled.div`
  color: var(--color-cream);
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: var(--font-primary);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 48px */
`;

export const BioEditor = styled.textarea`
  display: block;
  position: relative !important;
  width: 100%;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  resize: none;
  margin-top: -2px;
  margin-left: -2px;
  background: var(--color-gray-300);
  height: 100px;
  padding: 10px 5%;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  border-color: transparent;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

const Diamond = styled.img`
  position: fixed;
  bottom: 2.5rem;
  width: 18%;
  max-width: 200px;
  height: auto;
  z-index: 0;
  left: 1.25rem;

  @media ${device.desktop} {
    left: 10%;
  }
`;

const Clouds = styled.img`
  position: fixed;
  bottom: 0px;
  width: 90%;
  max-width: 1000px;
  height: auto;
  right: 0px;
  z-index: 0;
`;
