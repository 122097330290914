import styled from "styled-components";

const CharacterCounter = ({ count = 0, max }) => {
  return (
    <Container>
      {count} / {max}
    </Container>
  );
};

const Container = styled.div`
  color: var(--disabled-button-text);
  font-size: 12px;
  font-weight: 400;
  text-align: right;
`;

export default CharacterCounter;
