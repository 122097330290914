import styled from "styled-components";
import {
  PaddedContainer,
  Spacer,
  Spinner,
  StyledInput,
} from "../styles/CommonElements";
// import { useDebounce } from "react-use";
import imageSearchModel from "../lib/firebase/imageSearchModel";
import { useEffect, useState } from "react";
import "react-photo-album/columns.css";
import BottomBar from "./BottomBar";
import Button from "./Button";
import { useQuery } from "@tanstack/react-query";
import { randomIntFromInterval } from "../helpers/general";
import ImageAlbum from "./ImageAlbum";
import SearchImagesEmpty from "./SearchImagesEmpty";
import ErrorFallback, { StandardMessage } from "./ErrorFallback";

const maxSearchQueryLength = 50;

const xIcon = "/icons/x-thick.svg";
const magGlassIcon = "/icons/search/pink-search.svg";

const DEFAULT_SEARCH_TERMS = ["nature", "abstract", "art", "pattern"];

const SearchImages = ({
  addEntry,
  entries,
  goToNextStep,
  removeEntry,
  suggestedSearchTerms,
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const onSearchQueryChanged = async (event) => {
    setSearchInputValue(event.target.value);
  };

  const {
    data: searchResults,
    isPending,
    isError,
  } = useQuery({
    queryKey: ["search", searchTerm],
    queryFn: async () => {
      if (searchTerm === "") return;

      return imageSearchModel.search({
        query: searchTerm,
      });
    },
    enabled: !!searchTerm,
    gcTime: 1000 * 60 * 60,
    retry: false,
  });

  useEffect(() => {
    let term;
    if (suggestedSearchTerms && suggestedSearchTerms.length > 0) {
      term =
        suggestedSearchTerms[
          randomIntFromInterval(0, suggestedSearchTerms.length)
        ];
    } else {
      term =
        DEFAULT_SEARCH_TERMS[
          randomIntFromInterval(0, DEFAULT_SEARCH_TERMS.length)
        ];
    }
    if (term !== "") setSearchTerm(term);
  }, [suggestedSearchTerms]);

  const selectSearchQuery = async (newQuery) => {
    window.scroll(0, 0);
    setSearchInputValue(newQuery);
    setSearchTerm(newQuery);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchTerm(searchInputValue);
  };

  return (
    <>
      <PaddedContainer>
        <form onSubmit={onSubmit}>
          <InputContainer>
            <Icon src={magGlassIcon} alt="" />
            <Input
              $borderRadius="12px"
              enterKeyHint="search"
              $hideFocusBorder={false}
              placeholder="Search"
              $placeholderFontStyle="normal"
              maxLength={maxSearchQueryLength}
              onChange={onSearchQueryChanged}
              value={searchInputValue}
            />
            {searchInputValue !== "" && (
              <ClearIcon
                src={xIcon}
                alt=""
                onClick={() => setSearchInputValue("")}
              />
            )}
          </InputContainer>
          <Spacer $marginTop="1rem" />
          <Button
            disabled={
              searchInputValue === searchTerm || !searchInputValue || isPending
            }
            size="small"
            variant={searchInputValue || isPending ? "primary" : "ghost"}
          >
            Search
          </Button>
        </form>
      </PaddedContainer>

      <Spacer $marginTop="1rem" />

      {isPending && (
        <>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          <Spacer $marginTop="1rem" />
        </>
      )}

      {isError && (
        <ErrorFallback>
          <StandardMessage />
        </ErrorFallback>
      )}

      {searchResults?.length === 0 && !isPending && (
        <>
          <Spacer $marginTop="2.25rem" />
          <SearchImagesEmpty
            addEntry={addEntry}
            entries={entries}
            failedSearchQuery={searchTerm}
            selectSearchQuery={selectSearchQuery}
            suggestedSearchTerms={suggestedSearchTerms}
          />
        </>
      )}

      {searchResults?.length > 0 && !isPending && (
        <PaddedContainer>
          <ImageAlbum
            addEntry={addEntry}
            entries={entries}
            finalSearchResults={searchResults}
            removeEntry={removeEntry}
          />
        </PaddedContainer>
      )}

      <Spacer $marginTop="1rem" />

      <BottomBar
        addEntry={addEntry}
        entries={entries}
        goToNextStep={goToNextStep}
        removeEntry={removeEntry}
      />
    </>
  );
};

const ClearIcon = styled.img`
  width: 14px;
  height: 14px;
  position: absolute;
  right: 14px;
  top: 17px;
`;

const Icon = styled.img`
  border-radius: 8px;
  border: 1px solid var(--borders-light);
  filter: brightness(0) invert(1);
`;

const Input = styled(StyledInput)`
  padding-left: 10px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: var(--color-gray-300);
  padding-left: 12px;
  border-radius: 8px;
`;

const SpinnerContainer = styled.div`
  text-align: center;
`;

export default SearchImages;
