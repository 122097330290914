import { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import UserDisplay from "../components/UserDisplay";
import storyworldModel from "../lib/firebase/storyworldModel";
import { Col, Row, notification } from "antd";
import { APP_NAME, adminUserId } from "../helpers/constants";
import { storyworldKeys } from "../lib/queryKeys";
import { trackEvent } from "../helpers/mixpanel";
import Bell from "../components/Bell";
import { NotificationCloseIcon } from "../styles/CommonElements";
import useAlertConfig from "../lib/useAlertConfig";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import { AuthContext } from "../context/AuthProvider";
import Backlink from "../components/Backlink";
import { device } from "../lib/breakpoints";
import Hidden from "../components/Hidden";
import StoryworldDetailDesktop from "../components/storyworld-details/StoryworldDetailDesktop";
import StoryworldDetailMobile from "../components/storyworld-details/StoryworldDetailMobile";
import { Helmet } from "react-helmet";

export default function StoryworldDetail() {
  const { storyId: id } = useParams();

  const { backLocations, addBackLocation, goBack } =
    useContext(BackLocationsContext);
  const { user, requireLogin } = useContext(AuthContext);

  const {
    toggleFollow,
    followingStatus: { episodeAlerts },
  } = useAlertConfig(id);

  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(id),
    queryFn: () => storyworldModel.getOneById(id),
    select: (data) => ({
      ...data,
      heroImage: data.backgroundImageUrl ?? data.imageUrl,
    }),
  });

  useEffect(() => {
    if (storyworldQuery.data) {
      trackEvent("Visited Storyworld Page", {
        storyworld_id: id,
        storyworld_name: storyworldQuery.data.title,
      });
    }
  }, [storyworldQuery.data, id]);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (updatedStatus) => {
    const key = `open${Date.now()}`;
    api.open({
      description: (
        <Row>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Bell isDisabled={!updatedStatus} width="24px" height="24px" />
          </Col>
          <Col span={18}>
            {`Episode alerts and emails are turned ${
              updatedStatus ? "on" : "off"
            } for ${storyworldQuery.data.title}.`}
            <Link
              to="/feed/settings"
              onClick={() => {
                addBackLocation(`/stories/${id}`);
              }}
            >
              {" "}
              See all settings
            </Link>
          </Col>
          <Col span={2}></Col>
        </Row>
      ),
      key,
      duration: 10,
      className: "report-notification",
      closeIcon: (
        <NotificationCloseIcon src="/icons/x-icon-gray.svg" alt="close" />
      ),
    });
  };

  const toggleStoryworldFollow = () => {
    toggleFollow(
      { alertType: "episode" },
      {
        onSuccess: ({ episodeAlertStories }) => {
          const notifcationsEnabled = episodeAlertStories.includes(id);
          openNotification(notifcationsEnabled);
        },
      }
    );
    trackEvent("Toggled Episode Alert", {
      toggled: !episodeAlerts ? "on" : "off",
    });
  };

  if (storyworldQuery.status !== "success" || storyworldQuery.data === null)
    return null;

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>
          {APP_NAME} - {storyworldQuery.data.title}
        </title>
      </Helmet>
      <Container>
        <Hero>
          <HeroImage src={storyworldQuery.data.heroImage} />

          <Hidden tablet desktop>
            <ActionsOverlay>
              {backLocations.length > 0 && (
                <FilteredBacklink onClick={goBack} />
              )}

              <NotificationToggle
                onClick={() => {
                  if (!user) {
                    requireLogin();
                    return;
                  }
                  toggleStoryworldFollow();
                }}
                alt={
                  episodeAlerts
                    ? "disable notifications"
                    : "enable notifications"
                }
              >
                <Bell width="16px" height="16px" isDisabled={!episodeAlerts} />
              </NotificationToggle>
            </ActionsOverlay>
            <HeroInfoOverlay>
              {storyworldQuery.data.genre && (
                <Genre>{storyworldQuery.data.genre}</Genre>
              )}
              <Title>{storyworldQuery.data.title}</Title>
              <Users>
                {storyworldQuery.data.creators.map(
                  (creatorId) =>
                    creatorId !== adminUserId && (
                      <UserDisplay
                        key={creatorId}
                        size={25}
                        userId={creatorId}
                        showName={true}
                        isAuthor={true}
                      />
                    )
                )}
              </Users>
            </HeroInfoOverlay>
          </Hidden>
        </Hero>

        <Hidden tablet desktop>
          <StoryworldDetailMobile />
        </Hidden>

        <Hidden mobile>
          <StoryworldDetailDesktop
            toggleStoryworldFollow={toggleStoryworldFollow}
          />
        </Hidden>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  @media (${device.mobile}) {
    background-color: #1d232d;
    position: relative;
  }
`;

const ActionsOverlay = styled.div`
  position: absolute;
  top: 8px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  z-index: 3;
  width: 90%;
  margin: auto;
`;

const Genre = styled.div`
  color: var(--color-cream-base);
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: -0.2rem;
  color: ${(props) => props.$isPink && "var(--color-pink)"};
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Hero = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to bottom,
      rgba(35, 38, 47, 0.7),
      rgba(35, 38, 47, 0)
    );
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(
      to top,
      rgba(35, 38, 47, 1) 0%,
      rgba(35, 38, 47, 0.9) 20%,
      rgba(35, 38, 47, 0)
    );
  }

  @media (${device.tablet}) {
    width: 100%;
    position: absolute;
    z-index: 0;
  }

  @media (${device.desktop}) {
    width: 100%;
    position: absolute;
    z-index: 0;
  }
`;

const HeroImage = styled.img`
  min-height: 200px;
  object-fit: cover;
  max-height: 220px;
  width: 100%;
  z-index: 1;
  opacity: 0.8;
  pointer-events: none;

  @media (${device.tablet}) {
    max-height: 344px;
    opacity: 0.3;
  }

  @media (${device.desktop}) {
    max-height: 344px;
    opacity: 0.3;
  }
`;

const HeroInfoOverlay = styled.div`
  position: absolute;
  bottom: 14px;
  left: 18px;
  width: calc(100% - 18px); // the 18 above
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 3;
  margin: auto;
`;

const FilteredBacklink = styled(Backlink)`
  svg {
    filter: drop-shadow(0 0 0.25rem black);
  }
`;

const NotificationToggle = styled.button`
  border-radius: 69px;
  background: #353a46;
  height: 30px;
  padding: 4px 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
`;

const Title = styled.h1`
  margin-bottom: 0px;
  font-family: var(--font-primary);
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--color-cream-base);
  text-align: left;
`;

const Users = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 12px;
`;

/*
          <ActionButton $width={35} onClick={onShare}>
            <ShareIcon src="/icons/share-episode.svg" />
          </ActionButton>
          */
/*
          <ActionButton
            onClick={onSubscribe}
            style={{ gap: 7 }}
            initial={{ width: 100 }}
            animate={{ width: subscriptionQuery.data ? 120 : 100 }}
          >
            {subscriptionQuery.data && (
              <svg
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  d="M1.5 5.00001L4.83357 8.5L11.5 1.5"
                  stroke="#AB9BEA"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                />
              </svg>
            )}
            <span>{subscriptionQuery.data ? "Subscribed" : "Subscribe"}</span>
          </ActionButton>
            */

// const subscriptionQuery = useQuery({
//   queryKey: ["storyworld-subscription", id],
//   queryFn: async () => {
//     const user = getAuth().currentUser;
//     if (!user) throw new Error("User not logged in");

//     return subscriptionModel.getManyByStoryworldId(
//       id,
//       ["limit", 1],
//       ["creator", "==", user.uid]
//     );
//   },
//   select: (data) => (data.length > 0 ? data[0] : null),
// });

// const subscribeMutation = useMutation({
//   mutationFn: async ({ action, docId }) => {
//     if (action === "create") {
//       return subscriptionModel.create({ storyworldId: id });
//     }

//     if (action === "delete") {
//       if (!docId) throw new Error("Subscription ID not provided");
//       return subscriptionModel.delete(docId);
//     }
//   },
//   onSuccess: () => subscriptionQuery.refetch(),
// });

// const onShare = async () => {
//   const analyticsPayload = {
//     location: "storyworld",
//     storyworld_id: id,
//     episode_id: null,
//   };
//   trackEvent("Clicked Share Button", analyticsPayload);
//   const data = storyworldQuery.data;
//   if (!data || !data.heroImage || !navigator.share) return;

//   const image = await fetch(data.heroImage);
//   const blob = await image.blob();
//   const file = new File([blob], "image.jpg", { type: "image/jpeg" });
//   await navigator.share({
//     title: data.title,
//     text: "Check this out",
//     files: [file],
//     url: location.pathname,
//   });
//   trackEvent("Completed Share", analyticsPayload);
// };

// const onSubscribe = async () => {
//   if (
//     subscriptionQuery.status !== "success" ||
//     subscribeMutation.status === "loading"
//   )
//     return;

//   const subscription = subscriptionQuery.data;

//   await subscribeMutation.mutateAsync({
//     storyworldId: id,
//     action: subscription ? "delete" : "create",
//     docId: subscription?.id,
//   });
// };

// const ActionButton = styled(motion.div)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   position: absolute;
//   right: 0;
//   top: 0;
//   background-color: #353a46;
//   color: var(--color-lavender);
//   border-radius: 9999px;
//   height: 35px;
//   font-family: var(--font-primary);
//   font-size: 14px;
//   font-weight: 700;
//   ${(props) => props.$width && `width: ${props.$width}px;`}
// `;
