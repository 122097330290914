import React, { useContext } from "react";
import { Col, Divider, Row } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { AdminEpisodeDisplay } from "../views";
import CreateEpisode from "../views/CreateEpisode";
import styled from "styled-components";
import { getEpisodesByStoryworldId } from "../helpers/firebase";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { useOutletContext } from "react-router-dom";

export default function EpisodeAdmin() {
  const [episodes, setEpisodes] = useState([]);
  const [isNewEpisode, setIsNewEpisode] = useState(false);
  const [, setFetchedEpisodes] = useState(false);

  const { selectedStoryworld } = useContext(SelectedStoryworldContext);
  const { selectedEpisode, setSelectedEpisode } = useOutletContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedEpisode]);

  useEffect(() => {
    async function fetchData() {
      if (selectedStoryworld === undefined || selectedStoryworld === null)
        return;
      const storyworldEpisodesPromise = await getEpisodesByStoryworldId(
        selectedStoryworld.id
      );
      const latestPublishDateEpisodes = storyworldEpisodesPromise.sort(
        function (a, b) {
          return b.publishedAt.toDate() - a.publishedAt.toDate();
        }
      );
      setEpisodes(latestPublishDateEpisodes);
      setFetchedEpisodes(true);
    }
    setFetchedEpisodes(false);
    fetchData();
  }, [selectedStoryworld]);

  return (
    <>
      {selectedEpisode === null && (
        <>
          <TitleBar>
            <Row>
              <Col span={12}>
                <h2>Episodes</h2>
              </Col>
              <Col span={12}>
                <RightSection>
                  <NewEpisodeButton
                    onClick={async () => {
                      const episode = {
                        title: "",
                        number: episodes.length + 1,
                        winningSubmission: null,
                      };
                      setIsNewEpisode(true);
                      setSelectedEpisode(episode);
                    }}
                  >
                    + New Episode
                  </NewEpisodeButton>
                </RightSection>
              </Col>
            </Row>
          </TitleBar>
          <Row
            gutter={36}
            style={{ padding: "0px 16px 0px 0px", textAlign: "center" }}
          >
            <Col
              span={13}
              className="column-title"
              style={{ padding: "0px 0px 0px 16px", textAlign: "left" }}
            >
              Episode
            </Col>
            <Col span={4} className="column-title">
              <span>Published on</span>
            </Col>
            <Col span={4} className="column-title">
              <span>Quest Winner</span>
            </Col>
            <Col span={3} className="column-title">
              Views
            </Col>
          </Row>
          <Divider />
        </>
      )}
      {selectedEpisode !== null ? (
        <CreateEpisode
          selectedEpisode={selectedEpisode}
          setSelectedEpisode={setSelectedEpisode}
          episodes={episodes}
          setEpisodes={setEpisodes}
          isNewEpisode={isNewEpisode}
          selectedStoryworld={selectedStoryworld}
          winningSubmission={
            selectedEpisode.winningSubmission
              ? selectedEpisode.winningSubmission
              : null
          }
        />
      ) : (
        <div>
          {episodes.map((ep) => (
            <AdminEpisodeDisplayContainer key={ep.id}>
              <AdminEpisodeDisplay
                episode={ep}
                setSelectedEpisode={setSelectedEpisode}
                setIsNewEpisode={setIsNewEpisode}
                winningSubmission={
                  ep.winningSubmission ? ep.winningSubmission : null
                }
              />
            </AdminEpisodeDisplayContainer>
          ))}
        </div>
      )}
    </>
  );
}

const TitleBar = styled.div`
  margin-bottom: 40px;
`;

const NewEpisodeButton = styled.button`
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  background: var(--nextPreviewButton);
  border-radius: 8px;
  max-width: 200;
  border: 0;
  margin-left: 0px 10px 0px auto;
  font-weight: bold;
  width: auto;
  cursor: pointer;
`;

const RightSection = styled.div`
  text-align: right;
`;

const AdminEpisodeDisplayContainer = styled.div`
  margin: 14px 0px;
`;
