import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Spacer } from "../styles/CommonElements";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import InputField from "../components/InputField";
import { WritingQuestPreviewSheet } from "../components/content-creation";
import TextareaAutosize from "react-textarea-autosize";
import { Disclaimer } from "../components/general";
import { containsProfanity } from "../helpers/general";
import { Col, Row, notification } from "antd";
import CharacterCounter from "../components/CharacterCounter";
import { SUBMISSION_TYPES } from "../helpers/constants";
import { questDetailUrl } from "../lib/routes";

const maxTitleLength = 45;

export default function WritingGenerator({ questData, submit }) {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const maxTextLength = questData.textSubmissionLimit;

  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [sheetOpen, setSheetOpen] = useState(false);

  const onBack = () => {
    navigate(questDetailUrl(questData.storyworld, questData.id));
  };

  useEffect(() => {
    if (containsProfanity(text) || containsProfanity(title)) {
      const key = "profanity-notification";
      api.open({
        description: (
          <Row>
            <Col span={22}>
              <ErrorText>
                Oops! Please use kind words. Let's keep our community positive
                and respectful
              </ErrorText>
            </Col>
            <Col span={2}></Col>
          </Row>
        ),
        key,
        duration: 0,
        className: "error-notification",
        closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
      });
    } else api.destroy("profanity-notification");
  }, [text, title, api]);

  return (
    <>
      {contextHolder}
      <Container>
        <HeaderContainer>
          <BackToQuest>
            <BackIcon onClick={onBack} src="/icons/chevron-left.svg" />
          </BackToQuest>

          <QuestObjective>
            {parse(questData.objectiveDescription)}
          </QuestObjective>
        </HeaderContainer>

        <Spacer $marginTop="10px" />

        <TextArea
          className="autosize-textarea"
          value={text}
          placeholder="Write out a story to answer this quest objective..."
          onChange={(e) => setText(e.target.value)}
          maxLength={maxTextLength}
        />
        <Spacer $marginTop="6px" />
        <CharacterCounter count={text.length} max={maxTextLength} />
        <Spacer $marginTop="16px" />

        <Input
          value={title}
          maxLength={maxTitleLength}
          placeholder="Give your submission a title (optional)"
          onChange={(e) => setTitle(e.target.value)}
        />
        <Spacer $marginTop="6px" />
        <CharacterCounter count={title.length} max={maxTitleLength} />

        <Spacer $marginTop="75px" />
        <PreviewButton
          disabled={
            text === "" || containsProfanity(text) || containsProfanity(title)
          }
          style={{
            opacity:
              (text === "" ||
                containsProfanity(text) ||
                containsProfanity(title)) &&
              0.25,
          }}
          onClick={() => setSheetOpen(true)}
        >
          Preview Submission
        </PreviewButton>
        <CancelButton onClick={() => onBack()}>Cancel</CancelButton>
        <Disclaimer text="Your work won't be saved" />
      </Container>

      <WritingQuestPreviewSheet
        text={text}
        title={title}
        isOpen={sheetOpen}
        setOpen={setSheetOpen}
        submit={() => submit(SUBMISSION_TYPES.TEXT, { text, title })}
      />
    </>
  );
}

const Container = styled.div`
  text-align: left;
  padding: 16px 16px 0px 16px;

  .one-line-prompt {
    color: var(--color-cream);
    line-height: 27px;
  }

  .edit-icon {
    vertical-align: bottom;
  }
`;

const HeaderContainer = styled.div`
  .auto-margin {
    margin: auto;
  }
`;

const QuestObjective = styled.h2`
  margin-top: 18px !important;
  color: var(--color-cream);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const BackToQuest = styled.span`
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: var(--color-gray-50);

  .ant-image {
    width: auto !important;
    vertical-align: sub;
  }
`;

const BackIcon = styled.img`
  padding-right: 2;
  padding-bottom: 2;
  height: 24px;
  width: 24px;
`;

const PreviewButton = styled.button`
  margin: auto;
  border: 0;
  border-radius: 100px;
  background: var(--blurple);
  color: var(--color-cream);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;

  &:focus {
    background: var(--blurple);
    color: var(--color-cream);
  }

  &:hover {
    background: var(--blurple);
    color: var(--color-cream);
  }
`;

const CancelButton = styled.button`
  margin: auto;
  border: 2px solid #777e91;
  border-radius: 100px;
  background: transparent;
  color: var(--color-cream);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &:focus {
    background: transparent;
    color: var(--color-cream);
  }

  &:hover {
    background: transparent;
    color: var(--color-cream);
  }
`;

const TextArea = styled(TextareaAutosize)`
  border-radius: 8px;
  background: #3b3e49;
  border-radius: 8px;
  resize: none;
  width: 100%;
  min-height: 138px;
  outline: none;
  touch-action: manipulation;
  border: 0px;
  vertical-align: top;
  font-size: 14px;
  font-family: inherit;
  padding: 18px;
  color: white;
  font-weight: 600;

  &:focus {
    border-color: #978ad7;
  }

  &::placeholder {
    color: #7d818e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.14px;
    color: var(--inputFieldText) !important;
  }

  &:focus {
    color: var(--color-cream);
  }

  &:hover {
    color: var(--color-cream);
  }
`;

const Input = styled(InputField)`
  &:focus {
    border-color: #978ad7;
  }

  &::placeholder {
    color: #7d818e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.14px;
    color: var(--inputFieldText) !important;
  }

  &:focus {
    color: var(--color-cream);
    border-color: transparent;
  }

  &:hover {
    color: var(--color-cream);
  }
`;

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
`;

const ErrorText = styled.span`
  color: var(--color-red);
`;
