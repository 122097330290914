import React, { useMemo, useEffect, useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import GalleryEntry from "../views/GalleryEntry";
import parse from "html-react-parser";
import { Button, Col, Row } from "antd";
import styled from "styled-components";
import { submissionKeys } from "../lib/queryKeys";
import submissionModel from "../lib/firebase/submissionModel";
import { galleryPageLimit } from "../helpers/constants";

function GallerySection({ poll, setActiveUsers = null }) {
  // This is tracked in the alertModel for that infiniteQuery, but that works because there is only one query called on a page at a time.
  // Doesn't work here, so we track it in state and manage that in the queryFn even though that isn't ideal.
  const [lastVisibleDocument, setLastVisibleDocument] = useState();

  const {
    data: gallery,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: submissionKeys.gallery_results(poll.questId),
    queryFn: async ({ pageParam }) => {
      const response = await submissionModel.getGalleryPage(
        poll.questId,
        pageParam,
        lastVisibleDocument
      );

      const lastDoc =
        response.documentSnapshots.docs[
          response.documentSnapshots.docs.length - 1
        ];
      setLastVisibleDocument(lastDoc);

      return response.results;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.length < galleryPageLimit) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (_0, _1, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    enabled: !!poll,
  });

  const sortedSubmissions = useMemo(() => {
    const submissions = gallery?.pages;

    if (!submissions || submissions.length === 0) {
      return [];
    }

    return submissions
      .flat()
      .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
  }, [gallery?.pages]);

  const { data: gallerySubmissionCount } = useQuery({
    queryKey: submissionKeys.gallery_count(poll.questId),
    queryFn: async () => {
      return submissionModel.getGalleryCount(poll.questId);
    },
    // No need to check the count unless after the first request the page's length is exactly that of the pageLimit
    enabled: sortedSubmissions.length === galleryPageLimit,
    placeholderData: 0,
  });

  useEffect(() => {
    if (sortedSubmissions.length === 0) return;

    const questActiveUsers = sortedSubmissions.map((sub) => {
      return sub.creator;
    });

    if (setActiveUsers) {
      // TODO: can we append new users instead?
      setActiveUsers((activeUsers) => activeUsers.concat(questActiveUsers));
    }
  }, [sortedSubmissions, setActiveUsers]);

  return (
    <>
      {sortedSubmissions.length > 0 && poll && (
        <Container>
          <PollTitle>{poll ? parse(poll.title) : null}</PollTitle>
          <SubmissionRow>
            <Row gutter={10} style={{ marginTop: 10 }}>
              {sortedSubmissions.map((submission) => (
                <Col key={submission.id} span={12}>
                  <GalleryEntry entry={submission} />
                </Col>
              ))}
            </Row>
          </SubmissionRow>
          {hasNextPage &&
            sortedSubmissions.length !== gallerySubmissionCount && (
              <div style={{ textAlign: "center" }}>
                <SeeMoreButton
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage}
                >
                  See more
                </SeeMoreButton>
              </div>
            )}
        </Container>
      )}
    </>
  );
}

export default GallerySection;

const SubmissionRow = styled.div`
  display: grid;
  margin-top: 0px;

  .submission-image {
    width: 100%;
    height: 100px;
  }
`;

const Container = styled.div`
  text-align: left;
  width: 100%;
  margin: auto;
  margin: 40px auto 80px auto;

  &.ant-image {
    width: 100%;
  }
`;

const PollTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;

  &p {
    margin-bottom: 6px;
  }
`;

const SeeMoreButton = styled(Button)`
  width: 60%;
  height: 40px;
  padding: 8px 16px;
  border-radius: 20px;
  border: 2px solid gray;
  min-width: 200px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 0px;
  margin-top: 10px;
  color: #fcfcfc;
`;
