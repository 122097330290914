import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { loginUser } from "../helpers/authentication";
import { passwordErrorText } from "../helpers/constants";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";
import moment from "moment";
import { useMemo } from "react";

const banText =
  "Your account has been banned. You will no longer be able to sign in.";
const getSuspensionText = (liftDate) =>
  `Your account has been suspended. Your suspension will end on ${moment(
    new Date(liftDate.seconds * 1000)
  ).format("MMMM D, YYYY")}.`;

const openAdverseNotification = ({ api, type, date }) => {
  const key = `open${Date.now()}`;
  api.open({
    description: (
      <Row>
        <Col span={22}>
          <Message>
            {type === "banned" && banText}
            {type === "suspended" && getSuspensionText(date)}
          </Message>
        </Col>
        <Col span={2}></Col>
      </Row>
    ),
    key,
    duration: 10,
    className: "admin-notification",
    closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
  });
};

const useLoginMutation = ({ api }) => {
  const navigate = useNavigate();

  const {
    mutate: login,
    isPending: loginPending,
    error,
  } = useMutation({
    mutationFn: async ({ email, password }) => {
      return loginUser(email, password);
    },
    onSuccess: (userData) => {
      if (!userData) {
        navigate("/signup");
      }
    },
    onError: (error) => {
      if (error.code === "banned") {
        openAdverseNotification({ api, type: "banned" });
        return;
      }
      if (error.code === "suspended") {
        openAdverseNotification({
          api,
          type: "suspended",
          date: error?.expiresAt,
        });
        return;
      }
    },
  });

  // This doesn't necessairly need to be memoized, but nice to avoid the state and work around the switch
  const loginError = useMemo(() => {
    if (!error) return null;

    switch (error.code) {
      case "auth/invalid-credential":
        return passwordErrorText;
      case "auth/too-many-requests":
        return "You have tried to log in too many times. Try again in a few minutes.";
      case "banned":
        return banText;
      case "suspended":
        return getSuspensionText(error?.expiresAt);
      default:
        return passwordErrorText;
    }
  }, [error]);

  return { login, loginPending, loginError };
};

const Message = styled.span`
  color: var(--color-red);
`;

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
  top: 10px;
`;

export default useLoginMutation;
