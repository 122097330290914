import { PNG } from "pngjs";
import { dataUriToBuffer } from "./data-uri-to-buffer";
import bufferToDataUrl from "buffer-to-data-url";

// Take a PNG image as a data URL, add a white background,
// and return a new PNG as a data URL.
//
// This is a hack, and it's synchronous. Not so good.
// Ideally this should be done client-side when first
// generating the PNG
export function addBackgroundToPNG(dataUrl, returnPNG = false) {
  const options = {
    colorType: 2,
    bgColor: {
      red: 255,
      green: 255,
      blue: 255,
    },
  };

  const png = PNG.sync.read(dataUriToBuffer(dataUrl));
  const buffer = PNG.sync.write(png, options);

  if (returnPNG) {
    return buffer;
  } else {
    return bufferToDataUrl("image/png", buffer);
  }
}
