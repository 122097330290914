import React, { useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { ActiveStoryworldMark } from "../../styles/CommonElements";
import { storyworldTypes } from "../../helpers/constants";
import { device } from "../../lib/breakpoints";
import { SelectedStoryworldContext } from "../../context/SelectedStoryworldProvider";
import { ReactSVG } from "react-svg";
import useFetchQuestCards from "../quests/useFetchQuestCards";

const QUEST_COUNT_ICON = "/icons/book-open-no-sparkles.svg";

export default function StoryCard({
  storyworld,
  showActiveMark = true,
  showQuestCount = false,
  showGenre = true,
  showHoverCard = true,
  link = `/stories/${storyworld.id}`,
}) {
  const navigate = useNavigate();
  const { cards } = useFetchQuestCards(storyworld);

  const storyElementRef = useRef();

  const { addBackLocation } = useContext(BackLocationsContext);
  const { setSelectedStoryworld, setStoryworldElement } = useContext(
    SelectedStoryworldContext
  );

  const activeCardCount = cards?.filter(
    (card) => card.endTimestamp.toDate() > new Date()
  ).length;

  return (
    <>
      <Container
        ref={storyElementRef}
        onMouseEnter={() => {
          if (showHoverCard) {
            setSelectedStoryworld(storyworld);
            setStoryworldElement(storyElementRef.current);
          }
        }}
      >
        <ImageWrapper>
          <StoryIcon
            src={storyworld.imageUrl}
            alt={`Link to ${storyworld.title}`}
            onClick={() => {
              addBackLocation();
              navigate(link);
            }}
          />
          {showQuestCount && activeCardCount > 0 && (
            <QuestCount>
              {activeCardCount}
              <QuestCountIcon src={QUEST_COUNT_ICON} alt="" />
            </QuestCount>
          )}
        </ImageWrapper>
        {showGenre && <Label>{storyworld.genre}</Label>}
        <Link to={link}>
          {showActiveMark && storyworld.type === storyworldTypes.STORYWORLD && (
            <ActiveStoryworldMark $marginRight="4px" />
          )}
          {storyworld.title}
        </Link>
      </Container>
    </>
  );
}

const Container = styled.div`
  flex: none;
  width: 117px;
  cursor: pointer;
  vertical-align: top;
  display: "inline-table";

  a {
    display: inline-block;
    color: var(--color-cream);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }

  @media ${device.mobile} {
    width: 108px;
  }

  @media ${device.tablet} {
    width: 110px;
  }

  @media ${device.desktop} {
    width: 138px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 14px;
  position: relative;

  @media ${device.mobile} {
    height: 192px;
  }

  @media (${device.tablet}) {
    width: 110px;
    height: 195px;
  }

  @media (${device.desktop}) {
    height: 245px;
    width: 134px;
  }
`;

const Label = styled.div`
  color: #949ba4;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: capitalize;
`;

const QuestCount = styled.div`
  border-radius: 70px;
  height: 24px;
  padding: 0px 8px;
  background: var(--color-gray-500);
  position: absolute;
  left: 8px;
  bottom: 10px;
  display: inline-flex;
  gap: 3px;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
`;

const QuestCountIcon = styled(ReactSVG)`
  color: #9c86e1;
  transform: scale(1.05);
`;

const StoryIcon = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
