import { useParams } from "react-router-dom";
import CustomDrawer from "../CustomDrawer";
import { useQuery } from "@tanstack/react-query";
import { commentKeys } from "../../lib/queryKeys";
import unreportedEpisodeComments from "../../lib/advanced/unreportedEpisodeComments";
import usePublishedEpisodesQuery from "../../lib/usePublishedEpisodesQuery";
import { useContext, useState } from "react";
import { SelectedStoryworldContext } from "../../context/SelectedStoryworldProvider";
import CommentInputProvider, {
  CommentInputContext,
} from "../../context/CommentInputProvider";
import CommentSection from "./CommentSection";
import EmptyComments from "./EmptyComments";
import styled from "styled-components";
import EditCommentSection from "../EditCommentSection";
import CommentInputField from "./CommentInputField";

const CommentDrawer = ({ isOpen, onClose, offsetTop }) => {
  const { storyId: storyworldId, epNum } = useParams();
  const { episodes } = usePublishedEpisodesQuery(storyworldId);
  const { selectedStoryworld } = useContext(SelectedStoryworldContext);
  const [sortType, setSortType] = useState("top");

  const currentEpisode = episodes.find(
    (episode) => episode.episodeNumber === parseInt(epNum)
  );

  const trackingData = {
    location: "episode",
    episode_id: currentEpisode?.id,
    episode_name: currentEpisode?.title,
    storyworld_id: storyworldId,
    storyworld_name: selectedStoryworld?.title,
    title: `${selectedStoryworld?.title}: ${currentEpisode?.title}`,
  };

  const { data: comments } = useQuery({
    queryKey: commentKeys.subject(currentEpisode?.id),
    queryFn: () => {
      if (!epNum) {
        return [];
      }

      return unreportedEpisodeComments(currentEpisode.id);
    },
    enabled: !!currentEpisode,
    placeholderData: [],
  });

  return (
    <CustomDrawer
      isOpen={isOpen}
      onClose={onClose}
      offsetTop={offsetTop}
      removeMask
    >
      <CommentInputProvider value={{ trackingData }}>
        <CommentInputContext.Consumer>
          {({
            startCommentSubmit,
            commentState: { commentOk, editComment, comment, replyTo },
            isSending,
            dispatch,
          }) => (
            <>
              {comments.length > 0 ? (
                <CommentSection
                  comments={comments}
                  sortType={sortType}
                  setSortType={setSortType}
                  mixpanelParams={trackingData}
                  showCount
                />
              ) : (
                <EmptyComments
                  headerText="No comments yet"
                  subheaderText="Be the first to discuss!"
                />
              )}
              <Footer $hasTyped={!!comment || !!replyTo}>
                {editComment && (
                  <EditCommentSection
                    handleCancelEdit={() => {
                      dispatch({ type: "CANCEL_EDIT" });
                    }}
                  />
                )}
                <AddCommentContainer>
                  <CommentInputField />

                  <Actions $hasTyped={!!comment || !!replyTo}>
                    <CancelButton
                      onClick={() => {
                        dispatch({ type: "RESET" });
                      }}
                    >
                      Cancel
                    </CancelButton>
                    <PostButton
                      disabled={!commentOk || isSending}
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await startCommentSubmit();
                        setSortType("newest");
                      }}
                    >
                      Post
                    </PostButton>
                  </Actions>
                </AddCommentContainer>
              </Footer>
            </>
          )}
        </CommentInputContext.Consumer>
      </CommentInputProvider>
    </CustomDrawer>
  );
};

export default CommentDrawer;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  background: var(--color-gray-600);
  width: 100%;
  padding: 16px;
  text-align: center;
  border-top: 0.5px solid var(--border-light);

  transform: translateY(50px);
  transition: transform 0.3s ease;

  ${(props) => props.$hasTyped && "transform: translateY(0px);"}
`;

const AddCommentContainer = styled.div`
  width: 100%;
  position: relative;

  & > *:first-child {
    margin-bottom: 12px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  visibility: hidden;
  transition: visibility 0.2s ease;

  ${(props) => props.$hasTyped && "visibility: visible;"}
`;

const PostButton = styled.button`
  cursor: pointer;
  width: 70px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 0px;
  outline: 0px;
  border-radius: 100px;
  background: ${(props) =>
    props.disabled ? "var(--color-gray-300)" : "var(--blurple)"};
  color: ${(props) =>
    props.disabled ? "var(--disabled-button-text)" : "var(--color-cream-base)"};
  text-align: center;
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */

  &::focus-visible {
    outline: 0px;
  }
`;

const CancelButton = styled.button`
  background: none;
  border: none;
  color: var(--color-cream-base);
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  cursor: pointer;
`;
