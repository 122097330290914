import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { shuffleArray } from "../helpers/general";
import IdeaSheet from "./imageGenerator/IdeaSheet";
import { IdeaCard } from "./imageGenerator";

export default function Ideas({ ideas, setPrompt, setUsedStarterPrompt }) {
  const [shuffledIdeas, setShuffledIdeas] = useState([]);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [sheetDisplayText, setSheedDisplayText] = useState("");

  useEffect(() => {
    setShuffledIdeas(shuffleArray(ideas));
  }, [ideas]);

  return (
    ideas.length > 0 && (
      <>
        <div>
          <CardContainer>
            <LeftPadding />
            {shuffledIdeas.map((idea, index) => (
              // TODO: supply react key
              // eslint-disable-next-line react/jsx-key
              <IdeaCard
                index={index}
                idea={idea}
                setPrompt={setPrompt}
                setSheetOpen={setSheetOpen}
                setSheedDisplayText={setSheedDisplayText}
                setUsedStarterPrompt={setUsedStarterPrompt}
              />
            ))}
          </CardContainer>
        </div>
        <IdeaSheet
          isOpen={sheetOpen}
          setOpen={setSheetOpen}
          setPrompt={setPrompt}
          text={sheetDisplayText}
        />
      </>
    )
  );
}

const CardContainer = styled.div`
  margin: 20px auto auto auto;
  display: inline-flex;
  height: 160px;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  padding-left: 4%;

  &::-webkit-scrollbar {
    display: none !important;
  }

  .need-idea-text {
    word-wrap: break-word !important;
    text-overflow: ellipsis !important;
    white-space: normal;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--color-cream-dark);
  }
`;

const LeftPadding = styled.div`
  margin-left: 4%;
`;
