import { useState, useRef } from "react";
import { validateEmail } from "../helpers/authentication";
import styled from "styled-components";
import {
  APP_LOGO,
  APP_NAME,
  passwordErrorText,
  SESSION_STORAGE,
} from "../helpers/constants";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import {
  Highlight,
  InputHeader,
  Spacer,
  StyledInput,
} from "../styles/CommonElements";
import { ThirdPartyAuth } from "../components/login";
import Button from "../components/Button";
import RevealPassword from "../components/RevealPassword";
import useLoginMutation from "../lib/useLoginMutation";
import { ModalTitle } from "../components/CustomModal";
import { Link } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const passwordRef = useRef();
  const [api, contextHolder] = notification.useNotification();
  const { login, loginPending, loginError } = useLoginMutation({ api });

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [revealPassword, setRevealPassword] = useState(false);

  const onEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    login(
      {
        email: emailAddress,
        password,
      },
      {
        onSuccess: (userData) => {
          // The case where users haven't accepted is covered by the mutation config's onSuccess
          if (userData) {
            // check sessionstorage for redirecturl
            const redirectUrl = sessionStorage.getItem(
              SESSION_STORAGE.redirectUrl
            );
            if (redirectUrl) {
              sessionStorage.removeItem(SESSION_STORAGE.redirectUrl);
              navigate(redirectUrl, { replace: true });
              return;
            }

            navigate("/", { replace: true });
          }
        },
      }
    );
  };

  return (
    <>
      {contextHolder}
      <Container>
        <Link to="/">
          <WelcomeImage src={APP_LOGO} alt="" />
        </Link>
        <Spacer $marginTop="0.5rem" />
        <ModalTitle>Welcome to {APP_NAME}</ModalTitle>
        <Spacer $marginTop="0.5rem" />

        <form onSubmit={onSubmit} style={{ position: "relative", zIndex: 1 }}>
          <InputHeader>Email</InputHeader>
          <InputContainer>
            <StyledInput
              placeholder="email@email.com"
              value={emailAddress}
              onChange={onEmailChange}
            />
          </InputContainer>

          <Spacer $marginTop="2rem" />

          <InputHeader>Password</InputHeader>
          <PasswordInputContainer>
            <StyledInput
              placeholder="Enter Password"
              maxLength={40}
              type={revealPassword ? "text" : "password"}
              onChange={onPasswordChange}
              ref={passwordRef}
            />
            {loginError === passwordErrorText ? (
              <ErrorMark src="/icons/x-thick.svg" alt="" />
            ) : (
              <RevealPassword
                isRevealed={revealPassword}
                toggleRevealed={() => setRevealPassword(!revealPassword)}
                passwordRef={passwordRef}
              />
            )}
          </PasswordInputContainer>

          <Spacer $marginTop="0.5rem" />

          <ForgotPasswordSection>
            <Highlight
              onClick={() =>
                navigate(
                  `/forgot-password${
                    emailAddress !== "" ? `?email=${emailAddress}` : ""
                  }`
                )
              }
            >
              Forgot password?
            </Highlight>
          </ForgotPasswordSection>

          {loginError !== "" && <LoginError>{loginError}</LoginError>}

          <SignInButtons>
            <ButtonSection>
              <Button
                type="submit"
                size="large"
                disabled={
                  !(
                    validateEmail(emailAddress) &&
                    emailAddress !== "" &&
                    password !== ""
                  ) || loginPending
                }
              >
                {loginPending ? (
                  <Spinner alt="" src="/icons/loading-spheres.svg" />
                ) : (
                  "Log In"
                )}
              </Button>
            </ButtonSection>
          </SignInButtons>
        </form>

        <Spacer $marginTop="1rem" />

        <Terms>
          By continuing, you agree to our{" "}
          <Link to="/terms-of-use" target="_blank">
            <Highlight>Terms of Service</Highlight>
          </Link>
          .
        </Terms>

        <Spacer $marginTop="1rem" />

        <SignUpCta>
          <Link to="/contact">
            Don't have an account? Click here to Contact Us
          </Link>
        </SignUpCta>
        {/*
        <ThirdPartyAuth cta="log in" />
        */}
        {/*
        <Spacer $marginTop="1.5rem" />
        */}
        {/*
        <SignUpCta>
          Don't have an account?{" "}
          <Highlight
            onClick={() =>
              navigate(
                `/signup${emailAddress !== "" ? `?email=${emailAddress}` : ""}`
              )
            }
          >
            Sign up
          </Highlight>
        </SignUpCta>
        */}
      </Container>
    </>
  );
}

const Container = styled.div`
  text-align: left;
  margin: 1.5rem auto auto auto;
  width: 90%;
  position: relative;
`;

const ButtonSection = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const ErrorMark = styled.img`
  position: absolute;
  right: 14px;
  top: 35%;
  height: 14px;
  width: 14px;
`;

const LoginError = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 12px;
  color: var(--color-red);
`;

const InputContainer = styled.div`
  position: relative;
`;

const SignInButtons = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  text-align: left;
  margin: auto;
  text-align: center;
  margin-top: 28px;
`;

const ForgotPasswordSection = styled.div`
  text-align: right;
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.2px;
`;
const PasswordInputContainer = styled.div`
  position: relative;
`;

const SignUpCta = styled.div`
  color: var(--color-text);
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const Terms = styled.p`
  color: #777e91;
  text-align: center;
  font-size: 0.625rem;
  font-weight: 400;
`;

const WelcomeImage = styled.img`
  width: 80px;
  height: auto;
  margin: auto;
  display: block;
`;
