import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import Button from "../Button";
import { Spacer } from "../../styles/CommonElements";
import { SelectedStoryworldContext } from "../../context/SelectedStoryworldProvider";
import Hidden from "../Hidden";
import { useQuery } from "@tanstack/react-query";
import { bookmarkKeys, episodeKeys } from "../../lib/queryKeys";
import bookmarkModel from "../../lib/firebase/bookmarkModel";
import { AuthContext } from "../../context/AuthProvider";
import episodeModel from "../../lib/firebase/episodeModel";
import { useNavigate } from "react-router-dom";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { comicUrl } from "../../lib/routes";

export default function StoryHoverCard({
  isVisible = false,
  storyworld,
  target,
}) {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { addBackLocation } = useContext(BackLocationsContext);
  const { setSelectedStoryworld, setStoryworldElement } = useContext(
    SelectedStoryworldContext
  );

  const [oldBookmark, setOldBookmark] = useState(null);
  const [oldStoryworld, setOldStoryworld] = useState(null);

  const { data: bookmark } = useQuery({
    queryKey: bookmarkKeys.storyworld(storyworld?.id),
    queryFn: async () => {
      const bm = await bookmarkModel.getManyByStoryworldId(storyworld?.id, [
        "creator",
        "==",
        user.uid,
      ]);
      return bm[0]?.id ? bm[0] : null;
    },
    enabled: !!user && !!storyworld?.id && storyworld !== oldStoryworld,
    retry: false,
  });

  const { data: bookmarkedEpisode, isLoading } = useQuery({
    queryKey: episodeKeys.episode(bookmark?.episodeId),
    queryFn: async () => {
      return episodeModel.getOneById(bookmark?.episodeId);
    },
    enabled: bookmark !== oldBookmark,
  });

  useEffect(() => {
    if (storyworld === oldStoryworld) return;
    setOldStoryworld(storyworld);
  }, [storyworld, oldStoryworld]);

  useEffect(() => {
    if (bookmark === oldBookmark) return;
    setOldBookmark(bookmark);
  }, [bookmark, oldBookmark]);

  return (
    <Hidden mobile tablet>
      <Container
        $isVisible={isVisible && target}
        $target={getOffset(target)}
        onMouseLeave={() => {
          setSelectedStoryworld(null);
          setStoryworldElement(null);
        }}
        onClick={() => {
          addBackLocation();
          navigate(`/stories/${storyworld?.id}`);
        }}
      >
        <Image alt="" src={storyworld?.imageUrl} />
        <TextContainer>
          <Title>{storyworld?.title}</Title>
          <Spacer $marginTop="0.25rem" />
          <Divider />
          <Text>{storyworld?.description}</Text>
          <Spacer $marginTop="1rem" />
          <StyledButton
            rounded="slight"
            onClick={(e) => {
              e.stopPropagation();
              let epNum = 1;
              if (bookmarkedEpisode?.episodeNumber) {
                epNum = bookmarkedEpisode.episodeNumber;
              }
              navigate(comicUrl(storyworld?.id, epNum));
            }}
          >
            {isLoading && "Read Episode 1"}
            {!isLoading &&
              (bookmarkedEpisode?.episodeNumber
                ? `Continue Episode ${bookmarkedEpisode.episodeNumber}`
                : "Read Episode 1")}
          </StyledButton>
        </TextContainer>
      </Container>
    </Hidden>
  );
}

const Container = styled.div`
  width: 226px;
  min-height: 402px;
  background: var(--color-gray-600);
  border-radius: 8px;
  position: absolute;
  overflow: hidden;
  text-align: left;
  display: ${(props) => !props.$isVisible && "none"};
  top: ${(props) => props.$target?.top - 60}px;
  left: ${(props) =>
    props.$target?.left !== 0
      ? props.$target?.left - 46
      : -999999}px; // hacky way to hide the element
  cursor: pointer;

  @keyframes scale {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  animation: ${(props) => props.$isVisible && "scale 0.05s linear"};
`;

const Divider = styled.hr`
  width: 24px;
  height: 1px;
  display: inline-block;
`;

const Image = styled.img`
  width: 100%;
  height: 125px;
  object-fit: cover;
  object-position: center;
`;

const StyledButton = styled(Button)`
  width: 194px;
  position: absolute;
  left: 16px;
  bottom: 16px;
  margin: auto;
`;

const TextContainer = styled.div`
  margin: auto;
  padding: 16px 16px 8px 16px;
`;

const Text = styled.div`
  color: #949ba4;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  height: 128px;
  word-wrap: break-word;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
`;

const Title = styled.div`
  color: var(--color-cream);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

function getOffset(el) {
  if (!el) return;
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
}
