import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UploadImageGenAi from "../UploadImageGenAi";
import PromptDisplay from "../PromptDisplay";
import { useQuery } from "@tanstack/react-query";
import { getStoryworldById } from "../../helpers/firebase";
import { storyworldKeys } from "../../lib/queryKeys";
import { trackEvent } from "../../helpers/mixpanel";
import CustomSheet from "../CustomSheet";

export default function EditGenAiOptions({
  quest,
  isOpen,
  setOpen,
  negativePrompt,
  setNegativePrompt,
  imageUrl,
  setImageUrl,
  // eslint-disable-next-line
  typedImageUrl,
  // eslint-disable-next-line
  setTypedImageUrl,
  createGeneration,
  hasUploadedImage,
  setHasUploadedImage,
  hasTypedInUrl,
  setHasTypedInUrl,
}) {
  const [canGenerate, setCanGenerate] = useState(false);
  const [imageFilename, setImageFilename] = useState("");

  useEffect(() => {
    setCanGenerate(hasTypedInUrl || hasUploadedImage || negativePrompt !== "");
  }, [hasTypedInUrl, hasUploadedImage, negativePrompt]);

  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(quest.storyworld),
    queryFn: () => {
      return getStoryworldById(quest.storyworld);
    },
  });

  return (
    <StyledSheetGenAi
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      snapPoints={[850]}
      tweenConfig={{ ease: "easeOut", duration: 0.3 }}
      className="edit-gen-ai-options"
      headerContent="Image Generation Settings"
      hasCloseIcon
    >
      <NegativePromptSection>
        <HeaderText>Add a Negative Prompt</HeaderText>
        <PromptDisplay
          prompt={negativePrompt}
          setPrompt={setNegativePrompt}
          maxPromptLength={300}
          editIcon="/icons/gen-ai/circle-with-slash-purple.svg"
          placeholder="Type what you don't want to see..."
        />
      </NegativePromptSection>
      <ImageSeedSection>
        <HeaderText>Add an Image Seed</HeaderText>
        <div
          style={{
            opacity: hasTypedInUrl ? 0.3 : 1,
          }}
        >
          <HeaderSubtext>
            Generate a new image based on an existing one
          </HeaderSubtext>
          <div style={{ marginTop: 10 }} />
          {imageUrl !== null ? (
            <UploadedImageSection>
              <UploadedImage alt="" src={imageUrl} />
              <UploadedFilename>{imageFilename}</UploadedFilename>
              <DeleteButton
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setImageUrl(null);
                  setHasTypedInUrl(false);
                  setImageFilename("");
                  setHasUploadedImage(false);
                }}
              >
                <DeleteIcon src="/icons/trash-outline.svg" alt="" />
              </DeleteButton>
            </UploadedImageSection>
          ) : (
            <div>
              <UploadImageGenAi
                maxFileSizeInMb={5}
                setImageUrl={setImageUrl}
                setImageFilename={setImageFilename}
                setHasUploadedImage={setHasUploadedImage}
                byPassRes={true}
                fileBucket="genAiSeeds"
                isDisabled={hasTypedInUrl}
              />
            </div>
          )}
        </div>
        {/*
            <div
              style={{
                marginTop: 36,
                opacity: hasUploadedImage ? 0.3 : 1,
              }}
            >
              <HeaderSubtext>
                Generate a new image based on an image URL
              </HeaderSubtext>
              <ImageUrlContainer>
                <ImageUrlInput
                  value={typedImageUrl}
                  placeholder="Paste the image's URL here"
                  onChange={handleTypedUrlChange}
                  maxLength={300}
                  rows="1"
                  disabled={hasUploadedImage}
                />
              </ImageUrlContainer>
            </div>
            */}
      </ImageSeedSection>
      <ButtonSection>
        {canGenerate ? (
          <GenerateButton
            onClick={() => {
              createGeneration();
              setOpen(false);
              window.scroll(0, 0);
              trackEvent("Clicked Create Art Button", {
                quest_id: quest.id,
                quest_name: quest.title,
                storyworld_id: quest.storyworld,
                storyworld_name: storyworldQuery.data.title,
                title: `${storyworldQuery.data.title}: ${quest.title}`,
              });
            }}
          >
            Generate
          </GenerateButton>
        ) : (
          <GenerateButtonDisabled>Generate</GenerateButtonDisabled>
        )}
      </ButtonSection>
    </StyledSheetGenAi>
  );
}

const StyledSheetGenAi = styled(CustomSheet)`
  textarea::placeholder {
    color: var(--color-gray-50) !important;
  }

  input::placeholder {
    color: var(--color-gray-50) !important;
  }

  && .react-modal-sheet-container {
    padding: 12px;
  }

  && .react-modal-sheet-header {
    min-height: unset;
    border-bottom: none;
    padding-top: 0px;
    padding-bottom: 0px;

    color: var(--color-gray-50);
    font-family: Poppins;
    font-size: 14px;
    text-align: left;
  }
`;

const HeaderText = styled.h5`
  color: var(--color-green-base);
  font-family: var(--font-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.4px;
  width: 100%;
  margin-left: 8px;
  margin-right: auto;
`;

const HeaderSubtext = styled.div`
  color: var(--color-gray-50);
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: 0.2px;
  margin-left: 8px;
  margin-right: 30px;
`;

const UploadedImageSection = styled.div`
  height: 50px;
  width: 100%;
  margin: auto;
  display: inline-flex;
  border-radius: 12px;
  background: var(--color-gray-300);
  padding: 0px 10px 0px 14px;
  overflow: hidden;
  text-align: left;
`;

const UploadedImage = styled.img`
  display: block;
  border-radius: 4px;
  width: 37px !important;
  height: 37px !important;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  object-fit: cover;
  aspect-ratio: 1/1;
`;

const UploadedFilename = styled.span`
  margin: auto;
  margin-left: 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  color: var(--color-cream);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 8px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DeleteButton = styled.button`
  border: 0;
  background: none;
`;

const DeleteIcon = styled.img`
  height: 17px;
  width: 16px;
`;

const ButtonSection = styled.div`
  margin-top: 30px;
`;

const GenerateButton = styled.button`
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  border: 0;
  border-radius: 100px;
  background: var(--blurple);
  color: var(--cream);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const GenerateButtonDisabled = styled.button`
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  justify-content: center;
  border: 0;
  border-radius: 100px;
  background: var(--color-gray-300);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--disabled-button-text);
`;

const NegativePromptSection = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;

const ImageSeedSection = styled.div`
  margin-top: 20px;

  .keyboard {
    height: calc(100% + 500px); /* add padding for keyboard */
  }
`;

{
  /*
const ImageUrlContainer = styled.div`
  border-radius: 8px !important;
  background: var(--color-gray-300);
  display: block;
  position: relative !important;
  width: 100%;
  height: auto;
  padding: 10px 5%;
  color: white;
  margin-top: 16px;
`;
*/
}

{
  /*
const ImageUrlInput = styled.input`
  text-align: left;
  font-size: 14px;
  color: var(--color-cream);
  width: 100%;
  border: 0;
  background: none;
  resize: none;
  display: block;
  margin-top: -2px;
  margin-left: -2px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.2px;

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    opacity: 0.5;
  }
`;
*/
}
