import styled from "styled-components";
import { Modal } from "antd";
import DeleteUserModalContent from "./DeleteUserModalContent";

export default function DeleteUserModal({ isDeleteOpen, setIsDeleteOpen }) {
  return (
    <Modal
      open={isDeleteOpen}
      onCancel={(event) => {
        setIsDeleteOpen(false);
        event.stopPropagation();
      }}
      closable={false}
      footer={null}
    >
      <InnerModalContainer>
        <DeleteUserModalContent
          onDelete={() => setIsDeleteOpen(false)}
          onCancel={() => setIsDeleteOpen(false)}
        />
      </InnerModalContainer>
    </Modal>
  );
}

const InnerModalContainer = styled.div`
  height: 100%;
  display: contents;
  text-align: center;
`;
