import styled from "styled-components";
import WritingEntry from "../../../views/WritingEntry";

export const WritingQuestResult = ({ entry, isWinner = false, children }) => {
  return (
    <Container $isWinner={isWinner}>
      <div>
        <WritingEntry entry={entry} hideUi={true} winner={isWinner} />
      </div>
      {children}
    </Container>
  );
};

export const WritingQuestWinner = styled.div`
  margin-bottom: 16px;
  padding-top: 95px;
`;

const Container = styled.div`
  position: relative;
  padding: 16px;
  border-radius: 12px;
  border: ${(props) => !props.$isWinner && "1.5px solid var(--border-light)"};
  background-color: ${(props) => props.$isWinner && "#23262f"};

  &::before {
    content: ${(props) => (props.$isWinner ? "''" : "none")};
    position: ${(props) => (props.$isWinner ? "absolute" : "relative")};
    background-image: ${(props) =>
      props.$isWinner && "var(--gradient-blurple-pink)"};

    border-radius: inherit;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    z-index: -1;
  }
`;
