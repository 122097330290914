import { styled } from "styled-components";
import moment from "moment";
import UserDisplay from "../UserDisplay";
import WritingContent from "../WritingContent";
import SubmissionFilter from "./SubmissionFilter";
import IdeaBlock from "../IdeaBlock";

const VotedOverlay = () => {
  return (
    <VotedOverlayWrapper>
      <VotedOverlayCheckmarkSection>
        <VotedOverlayCheckmark src="/icons/checkmark-white-thin.svg" alt="" />
      </VotedOverlayCheckmarkSection>
    </VotedOverlayWrapper>
  );
};

const SubmissionCard = ({ submission, hasVoted, isVotedByUser, onClick }) => {
  return (
    <>
      <SubmissionFilter
        submission={submission}
        genAiArt={
          <OverlayContainer>
            {isVotedByUser && <VotedOverlay />}
            {!hasVoted && (
              <UserDisplayOverlay $nolink={!hasVoted}>
                <UserDisplay userId={submission.creator} size={55} />
              </UserDisplayOverlay>
            )}
            <div
              className={
                hasVoted
                  ? "quest-submission-preview"
                  : "quest-submission-preview quest-submission-preview-blurred"
              }
              onClick={onClick}
              style={{
                backgroundImage: `url("${submission.imageUrl}")`,
              }}
            />
          </OverlayContainer>
        }
        idea={
          <>
            <OverlayContainer>
              {isVotedByUser && <VotedOverlay />}

              {!hasVoted && (
                <UserDisplayOverlay $nolink={!hasVoted}>
                  <UserDisplay userId={submission.creator} size={55} />
                </UserDisplayOverlay>
              )}
              <div
                className={hasVoted ? "" : "quest-submission-preview-blurred"}
                onClick={onClick}
              >
                <IdeaBlock idea={submission} hideTitle />
              </div>
            </OverlayContainer>
            {/* Had to separate from ideablock so it isn't highlighted with a border from the overlay */}
            <Title>{submission.title}</Title>
          </>
        }
        text={
          <>
            {isVotedByUser && <VotedOverlay />}
            <TextSubmission onClick={onClick} hasVoted={hasVoted}>
              <WritingContent
                title={submission.title}
                text={submission.text}
                isExpandable={hasVoted}
                omitBackground
              />
              {!hasVoted && (
                <UserBanner>
                  <UserDisplay
                    userId={submission.creator}
                    size={25}
                    showName={true}
                  />
                  <Time>{moment(submission.createdAt).fromNow(true)} ago</Time>
                </UserBanner>
              )}
            </TextSubmission>
          </>
        }
      ></SubmissionFilter>
    </>
  );
};

const TextSubmission = styled.div`
  text-align: start;
  border: 1.5px solid var(--border-light);
  border-radius: 12px;
  background: rgba(217, 217, 217, 0.05);
  & > div:first-of-type {
    padding: 16px;
    filter: ${(props) => (!props.hasVoted ? "blur(4px)" : "none")};
  }
`;

const UserBanner = styled.div`
  border-radius: 0px 0px 12px 12px;
  border-top: 1.5px solid var(--border-light);
  padding: 16px;
  color: var(--color-gray-50);
  display: flex;
  justify-content: space-between;
  background: var(--color-gray-500);
  font-size: 14px;
`;

const UserDisplayOverlay = styled.div`
  position: absolute;
  z-index: 2;
  left: 35%;
  top: 30%;
  pointer-events: ${(props) => (props.$nolink ? "none" : "auto")};

  @media (min-width: 415px) {
    left: 36%;
    top: 32.5%;
  }
`;

const Time = styled.span`
  color: var(--color-gray-50);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Title = styled.div`
  color: var(--color-cream-base);
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 12px;
  text-align: left;
`;

const OverlayContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;

const VotedOverlayWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  border: 4px solid var(--nextPreviewButton);
  border-radius: 8px;
  background: none;
  pointer-events: none;
`;

const VotedOverlayCheckmarkSection = styled.div`
  position: absolute;
  height: 38px;
  width: 38px;
  border-radius: 0px 0px 0px 14px;
  right: 0px;
  background: var(--nextPreviewButton);
`;

const VotedOverlayCheckmark = styled.img`
  position: absolute;
  height: 26px;
  width: 26px;
  top: 45%;
  left: 55%;
  transform: translate(-50%, -50%);
`;

export default SubmissionCard;
