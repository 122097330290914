import { Sheet } from "react-modal-sheet";
import styled from "styled-components";
import { CommentInputField } from "../../social";
import { isOniOS } from "../../../helpers/general";
import CustomSheet from "../../CustomSheet";
import EditCommentSection from "../../EditCommentSection";
import SubmissionPreviewContent from "./SubmissionPreviewContent";
import { useContext } from "react";
import { CommentInputContext } from "../../../context/CommentInputProvider";

export default function SubmissionSheet({
  submission,
  drawerOpen,
  setDrawerOpen,
  winner = false,
  isInVote = false,
  isFocused,
  commentsEnabled,
  sortType,
  setSortType,
}) {
  const { commentState, isSending, startCommentSubmit, dispatch } =
    useContext(CommentInputContext);
  return (
    <StyledSheet
      isOpen={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      snapPoints={[0.9]}
      tweenConfig={{ ease: "easeOut", duration: 0.3 }}
      hasCloseIcon
    >
      <Sheet.Scroller>
        <SubmissionPreviewContent
          submission={submission}
          winner={winner}
          isInVote={isInVote}
          commentsEnabled={commentsEnabled}
          sortType={sortType}
          setSortType={setSortType}
        />
      </Sheet.Scroller>
      {commentsEnabled && (
        <Footer $isFocused={isFocused}>
          {commentState.editComment && (
            <EditCommentSection
              handleCancelEdit={() => {
                dispatch({ type: "CANCEL_EDIT" });
              }}
            />
          )}
          <AddCommentContainer $isFocused={isFocused}>
            <CommentInputField />
            <PostButton
              disabled={!commentState.commentOk || isSending}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await startCommentSubmit(e);
                setSortType("newest");
              }}
            >
              Post
            </PostButton>
          </AddCommentContainer>
        </Footer>
      )}
    </StyledSheet>
  );
}

const StyledSheet = styled(CustomSheet)`
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;

  & .react-modal-sheet-container {
    padding: 0;
  }
  & .react-modal-sheet-content {
    padding: 0;
    touch-action: auto !important;
    /* overflow-y: auto;
    overflow-x: hidden;
    touch-action: unset; */
  }

  & .react-modal-sheet-header {
    padding: 0;
    width: 96% !important;
    margin: auto;
    margin-top: 12px;
    margin-bottom: 16px !important;
  }
`;

const Footer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  background: #1a1c21;
  border-top: ${(props) =>
    props.$isFocused && isOniOS() ? "0px" : "0.5px solid var(--border-light)"};
`;

const PostButton = styled.button`
  width: 70px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 0px !important;
  outline: 0px;
  border-radius: 100px;
  background: ${(props) =>
    props.disabled ? "var(--color-gray-300)" : "var(--blurple)"};
  color: ${(props) =>
    props.disabled ? "var(--disabled-button-text)" : "var(--color-cream-base)"};
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */

  &::focus-visible {
    outline: 0px;
  }
`;

const AddCommentContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px 10px 0px 10px;
  position: relative;
`;
