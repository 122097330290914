import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Col, Row, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { sendSupportMessage } from "../../helpers/support-message";
import userModel from "../../lib/firebase/userModel";
import { AuthContext } from "../../context/AuthProvider";
import { Spacer } from "../../styles/CommonElements";
import { APP_NAME } from "../../helpers/constants";

const maxMessageLength = 500;

const CONTACT_TYPES = {
  feedback: {
    subject: `I have feedback about ${APP_NAME}`,
    placeholder: `Hi. I have some feedback about ${APP_NAME}...`,
  },
  help: {
    subject: `I need help with ${APP_NAME}`,
    placeholder: `Hi. I need some help with ${APP_NAME}...`,
  },
};

export default function ContactSupport({ type, onClose }) {
  const [api, contextHolder] = notification.useNotification();

  const { user } = useContext(AuthContext);

  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState("");

  const openNotification = () => {
    const key = `open${Date.now()}`;
    api.open({
      description: (
        <Row>
          <Col span={22}>Your message has been received. Thank you!</Col>
          <Col span={2}></Col>
        </Row>
      ),
      key,
      duration: 10,
      className: "creator-message-notification",
      closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
    });
  };

  const startSendingMessage = async () => {
    setIsSending(true);
    const sentByUser = await userModel.getOneById(user?.uid);
    const data = {
      subject: CONTACT_TYPES[type].subject ?? "No subject",
      message: message,
      type: type,
      sentByUser: {
        username: sentByUser.username,
        email: sentByUser.email,
        id: sentByUser.id,
      },
    };
    const result = await sendSupportMessage(data);
    if (result.success) {
      openNotification();
      setMessage("");
      setIsSending(false);
      onClose();
    }
  };

  if (!user) return null;

  return (
    <>
      {contextHolder}
      <Header>{CONTACT_TYPES[type].subject}</Header>
      <MessageInput
        className="transition-colors"
        value={message}
        placeholder={CONTACT_TYPES[type].placeholder}
        maxLength={maxMessageLength}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Spacer $marginTop="1rem" />
      {!isSending ? (
        <SendReportButton
          disabled={message === ""}
          style={{ opacity: message === "" && 0.25 }}
          onClick={async () => await startSendingMessage()}
        >
          Send Message
        </SendReportButton>
      ) : (
        <SendReportButton disabled>
          <Spinner alt="" src="/icons/loading-spheres.svg" />
        </SendReportButton>
      )}
      <Spacer $marginTop="0.5rem" />
    </>
  );
}

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
`;

const Header = styled.div`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`;

const MessageInput = styled(TextArea)`
  resize: none !important;
  margin-top: 18px;
  padding: 14px;
  border-radius: 8px;
  background: var(--color-gray-300);
  height: 185px !important;
  width: 100%;
  border: 0;

  &::placeholder {
    color: var(--disabled-button-text);
  }

  &::focus {
    border: 0px !important;
  }

  &::focus-visible {
    border: 0px !important;
  }
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const SendReportButton = styled.button`
  width: 100%;
  height: 50px;
  background: var(--blurple);
  border: 0;
  border-radius: 90px;
  margin-top: 20px;
  cursor: pointer;
  line-height: 16px;
  letter-spacing: 0em;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: stretch;
  color: var(--color-cream-base);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;
