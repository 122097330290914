import { getAuth } from "firebase/auth";
import { db } from "../../helpers/firebase";
import { hasAlertTable } from "../../helpers/tables";
import { LOCAL_STORAGE } from "../../helpers/constants";
import { deleteDoc, doc, onSnapshot } from "firebase/firestore";

const table = hasAlertTable;

const hasAlertModel = {
  listen: async () => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const sub = onSnapshot(doc(db, table, user.uid), (doc) => {
      if (doc.data()?.newAlerts) {
        localStorage.setItem(LOCAL_STORAGE.hasAlerts, true);
        // Force the browser to trigger storage event
        window.dispatchEvent(new Event("storage"));
      }
    });

    return sub;
  },
  setSeen: async () => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    await deleteDoc(doc(db, table, user.uid));

    return;
  },
};

export default hasAlertModel;
