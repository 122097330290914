// TODO: find alternative to async/await
/* eslint-disable no-async-promise-executor */
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from "./firebase";
import { banTable, suspensionsTable } from "./tables";
import banModel from "../lib/firebase/banModel";
import suspensionModel from "../lib/firebase/suspensionModel";
import { firebaseAuth } from "./authentication";

export async function sendReport(data) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/sendReport`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error sending report:", error);
    throw error;
  }
}

export async function suspendUser({ userId, suspendUntilDate }) {
  return new Promise(async (resolve) => {
    try {
      await setDoc(doc(db, suspensionsTable, userId), {
        user: userId,
        expiresAt: suspendUntilDate,
        createdAt: new Date(),
        lastUpdated: new Date(),
      });
      resolve(true);
    } catch (error) {
      console.error("Suspend Error:", error);
      resolve(false);
    }
  });
}

export async function banUser(userId) {
  return new Promise(async (resolve) => {
    try {
      const currentBanCount = await banModel.count(["user", "==", userId]);
      if (currentBanCount < 1) {
        await setDoc(doc(db, banTable, userId), {
          user: userId,
          createdAt: new Date(),
          lastUpdated: new Date(),
        });
      }
      resolve(true);
    } catch (error) {
      console.error("Ban Error:", error);
      resolve(false);
    }
  });
}

export async function unbanUser(userId) {
  return new Promise(async (resolve) => {
    const currentBans = await banModel.getMany(["user", "==", userId]);
    currentBans.forEach(async (ban) => {
      await deleteDoc(doc(db, banTable, ban.id));
    });
    resolve(true);
  });
}

export async function checkForSuspension(userId) {
  const isSuspended = await isUserSuspended(userId);
  if (isSuspended) {
    try {
      await firebaseAuth.signOut();
      window.location = "/login";
    } catch (error) {
      console.error("Sign Out Error", error);
    }
  }
}

export async function isUserSuspended(userId) {
  const suspensions = await suspensionModel.getMany(["user", "==", userId]);
  const suspensionCount = suspensions.filter(function (s) {
    return s.expiresAt.toDate() > new Date();
  }).length;
  if (suspensionCount > 0)
    return { suspended: true, expiresAt: suspensions[0].expiresAt };
  return { suspended: false };
}

export async function checkForBan(userId) {
  const isBanned = await isUserBanned(userId);
  if (isBanned) {
    try {
      await firebaseAuth.signOut();
      window.location = "/login";
    } catch (error) {
      console.error("Sign Out Error", error);
    }
  }
}

export async function isUserBanned(userId) {
  const banCount = await banModel.count(["user", "==", userId]);
  if (banCount > 0) return true;
  return false;
}
