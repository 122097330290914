import { useMedia } from "react-use";

export const DEFAULT_SCREEN_SIZES = {
  MOBILE: 375,
  TABLET: 768,
  DESKTOP: 1280,
};

const size = {
  mobile: DEFAULT_SCREEN_SIZES.MOBILE,
  tablet: DEFAULT_SCREEN_SIZES.TABLET,
  desktop: DEFAULT_SCREEN_SIZES.DESKTOP,
};

export const device = {
  mobile: `(max-width: ${size.tablet - 1}px)`,
  tablet: `(min-width: ${size.tablet}px) and (max-width: ${
    size.desktop - 1
  }px)`,
  desktop: `(min-width: ${size.desktop}px)`,
};

export const useBreakpoints = () => {
  const isMobile = useMedia(device.mobile);
  const isTablet = useMedia(device.tablet);
  const isDesktop = useMedia(device.desktop);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isTabletOrAbove: isTablet || isDesktop,
  };
};
