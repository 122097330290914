import "react-photo-album/columns.css";
import styled from "styled-components";
import { ColumnsPhotoAlbum } from "react-photo-album";
import { maxIdeaBlockEntries } from "../helpers/constants";

const ImageAlbum = ({ addEntry, entries, finalSearchResults, removeEntry }) => {
  return (
    <Container>
      <ColumnsPhotoAlbum
        onClick={({ event, photo }) => {
          event.preventDefault();
          const hasSelectedPhoto =
            entries.filter((e) => e === photo?.src).length > 0;
          if (entries.length < maxIdeaBlockEntries && !hasSelectedPhoto) {
            addEntry(photo.src);
            event.target.classList.add("selected");
          } else if (hasSelectedPhoto) {
            removeEntry(photo.src);
            // Remove "selected" class from src element
            const imgObj = document.querySelector(`img[src="${photo.src}"]`);
            if (imgObj) {
              imgObj.classList.remove("selected");
            }
          }
        }}
        photos={finalSearchResults}
        render={{
          extras: (_, { photo }) =>
            entries.filter((e) => e === photo?.src).length > 0 && (
              <SelectedOverlay>
                <Number>
                  {entries.indexOf(entries.filter((e) => e === photo?.src)[0]) +
                    1}
                </Number>
              </SelectedOverlay>
            ),
        }}
        skeleton={<div style={{ width: "100%", minHeight: 800 }} />}
        spacing={2}
      />
    </Container>
  );
};

const selectedRadius = "8px";

const Container = styled.div`
  .selected {
    border-radius: ${selectedRadius} !important;
  }
`;

const Number = styled.div`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

const SelectedOverlay = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: ${selectedRadius};
  border: 1px solid var(--color-cream-base);
  background: #000000b2;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export default ImageAlbum;
