import React, { useState } from "react";
import { UserDisplayGroup, GallerySection } from "../components";
import styled from "styled-components";

export default function Gallery({ completedPolls }) {
  const [activeUsers, setActiveUsers] = useState([]);

  return (
    <div>
      <ArtGalleryTitle>Art Gallery</ArtGalleryTitle>
      <UserDisplayGroup users={activeUsers} />
      {completedPolls.map((poll, index) => (
        <GalleryContainer key={index}>
          <GallerySection poll={poll} setActiveUsers={setActiveUsers} />
        </GalleryContainer>
      ))}
    </div>
  );
}

const GalleryContainer = styled.div`
  width: 90%;
  margin: auto;
`;

const ArtGalleryTitle = styled.h4`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: white;
  z-index: -100;
`;
