import React from "react";
import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import { storyworldKeys } from "../lib/queryKeys";
import publishedStoryworlds from "../lib/advanced/publishedStoryworlds";
import { useMemo } from "react";
import StoryworldCarousel from "./StoryworldCarousel";
import StoryworldCollection from "./StoryworldCollection";
import { useBreakpoints } from "../lib/breakpoints";

export default function GenreCollections() {
  const { isMobile, isTabletOrAbove } = useBreakpoints();

  const { data: stories } = useQuery({
    queryKey: storyworldKeys.published(),
    queryFn: () => {
      return publishedStoryworlds();
    },
    placeholderData: [],
  });

  const genres = useMemo(() => {
    if (!stories) {
      return [];
    }

    const genresOnly = stories
      .map((story) => story.genre)
      .filter((genre) => Boolean(genre));
    return [...new Set(genresOnly)];
  }, [stories]);

  return (
    <Container>
      {genres.map((genre) => (
        <React.Fragment key={genre}>
          {isMobile && (
            <StoryworldCollection
              title={genre}
              stories={stories.filter((story) => story.genre === genre)}
            />
          )}

          {isTabletOrAbove && (
            <StoryworldCarousel
              title={genre}
              stories={stories.filter((story) => story.genre === genre)}
            />
          )}
        </React.Fragment>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
