import React from "react";
import Submission from "../components/submissions/Submission";
import { LikeButton, UserDisplay } from "../components";
import { styled } from "styled-components";
import AlertSourceContext, { SOURCE } from "../context/AlertSourceContext";
import { LikeButtonOverlay } from "../styles/CommonElements";

export default function WritingEntry({
  entry,
  hideUi = false,
  winner = false,
}) {
  return (
    <AlertSourceContext.Provider
      value={{ sourceType: SOURCE.submission, sourceId: entry.id }}
    >
      <Container>
        <Submission
          submission={entry}
          position="relative"
          isClickable={true}
          winner={winner}
          isImageQuest={false}
        />
        {!hideUi && (
          <>
            <UserBanner>
              <UserDisplay
                userId={entry ? entry.creator : null}
                size={25}
                showName={true}
              />
            </UserBanner>
            <LikeButtonOverlay>
              <LikeButton itemType="submission" itemId={entry?.id} />
            </LikeButtonOverlay>
          </>
        )}
      </Container>
    </AlertSourceContext.Provider>
  );
}

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: start;
`;

const UserBanner = styled.div`
  border-radius: 0px 0px 12px 12px;
  border-top: 1.5px solid var(--border-light);
  padding: 16px;
  color: var(--color-gray-50);
  display: flex;
  justify-content: space-between;
  background: var(--color-gray-500);
  font-size: 14px;
`;
