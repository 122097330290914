import { useState } from "react";
import AlertSourceContext, { SOURCE } from "../../context/AlertSourceContext";
import LikeButton from "../LikeButton";
import SubmissionPreview from "../submissions/preview/SubmissionPreview";
import styled from "styled-components";
import IdeaBlock from "../IdeaBlock";

export const ProfileSubmission = ({ submission }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Not sure why this is here
  const onLikeClick = (event) => {
    event.stopPropagation();
  };

  return (
    <AlertSourceContext.Provider
      key={submission.id}
      value={{ sourceType: SOURCE.submission, sourceId: submission.id }}
    >
      <ProfileSubmissionWrapper
        style={{ cursor: "pointer" }}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <IdeaBlock idea={submission} hideTitle />
        <LikeButtonContainer>
          <LikeButton
            ghost
            itemType="submission"
            itemId={submission.id}
            onClick={onLikeClick}
          />
        </LikeButtonContainer>
      </ProfileSubmissionWrapper>
      <SubmissionPreview
        submission={submission}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </AlertSourceContext.Provider>
  );
};

export const ProfileSubmissionWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const LikeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 6px;
`;
