import styled, { css } from "styled-components";

const Arrow = ({ direction = "right", width = 10 }) => {
  return <Container $direction={direction} $width={width} />;
};

const Container = styled.i`
  border: solid black;
  display: inline-block;

  ${({ $width }) => {
    return css`
      border-width: 0 ${$width}px ${$width}px 0;
      padding: ${$width * 2}px;
    `;
  }}

  ${({ $direction }) => {
    switch ($direction) {
      case "right":
        return css`
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        `;
      case "down":
        return css`
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        `;
      case "left":
        return css`
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        `;
      case "up":
        return css`
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        `;
    }
  }}
`;

export default Arrow;
