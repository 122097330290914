import { Statistic } from "antd";
import styled from "styled-components";

const { Countdown } = Statistic;

const OpenCountdown = ({ time }) => {
  return (
    <Container>
      <OpenIcon src="/icons/wifi.svg" alt="" className="wifi-icon" />
      <StyledCountdown value={time} />
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  margin: auto;
  display: inline-flex;
  margin-bottom: 0px;
`;

const OpenIcon = styled.img`
  height: 8px;
  margin-right: 10px;
  margin-bottom: 2px;
`;

const StyledCountdown = styled(Countdown)`
  line-height: 0px;

  .ant-statistic-content-value {
    color: #71c274;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 0px;
  }
`;

export default OpenCountdown;
