import React from "react";
import styled from "styled-components";
import { Typography } from "antd";

const { Paragraph } = Typography;

export default function IdeaCard({
  index,
  idea,
  setPrompt,
  setSheetOpen,
  setSheedDisplayText,
  setUsedStarterPrompt,
}) {
  return (
    <Container key={index}>
      <Paragraph
        ellipsis={{
          rows: 5,
        }}
        className="idea"
        onClick={() => {
          setSheetOpen(true);
          setSheedDisplayText(idea);
        }}
      >
        {idea}
      </Paragraph>
      <SetPromptButton
        onClick={() => {
          setPrompt(idea);
          setUsedStarterPrompt(true);
        }}
      >
        <CopyIcon src="/icons/copy-icon-squares-lavender.svg" alt="" />
      </SetPromptButton>
    </Container>
  );
}

const Container = styled.div`
  width: 160px !important;
  min-width: 160px;
  height: 100%;
  padding: 9px 11px 10px 10px;
  border-radius: 8px;
  background: var(--color-gray-300);
  margin-right: 10px;
  position: relative;

  .idea {
    word-wrap: break-word !important;
    text-overflow: ellipsis !important;
    white-space: normal;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
    -webkit-line-clamp: 5;
    color: var(--color-cream-dark);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const SetPromptButton = styled.button`
  border: 0;
  background: none;
  position: absolute;
  bottom: 5%;
  left: 2%;
  padding: 0px 5px;
`;

const CopyIcon = styled.img`
  width: 22px;
  height: 22px;
`;
