import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import CustomDrawer from "./CustomDrawer";
import usePublishedEpisodesQuery from "../lib/usePublishedEpisodesQuery";
import styled, { css } from "styled-components";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { AuthContext } from "../context/AuthProvider";
import { Link } from "react-router-dom";
import { comicUrl } from "../lib/routes";

const EpisodeDrawer = ({ isOpen, onClose, offsetTop }) => {
  const { storyId: storyworldId, epNum } = useParams();
  const { userInfo } = useContext(AuthContext);
  const { selectedStoryworld } = useContext(SelectedStoryworldContext);
  const { episodes } = usePublishedEpisodesQuery(storyworldId);

  const episodesSorted = useMemo(() => {
    return [...episodes].sort((a, b) => {
      return b.publishedAt.toDate() - a.publishedAt.toDate();
    });
  }, [episodes]);

  const currentEpisode = episodes.find(
    (episode) => episode.episodeNumber === parseInt(epNum)
  );

  return (
    <CustomDrawer
      isOpen={isOpen}
      onClose={onClose}
      offsetTop={offsetTop}
      removeMask
    >
      {episodesSorted.map((episode, index) => {
        return (
          <Link
            key={episode.id}
            to={comicUrl(storyworldId, episode.episodeNumber)}
          >
            <EpisodeWrapper
              $isCurrent={currentEpisode.id === episode.id}
              $isRead={userInfo?.readingList?.includes(episode.id)}
              $isLast={index === episodes.length - 1}
            >
              {currentEpisode.id === episode.id && (
                <BookmarkIcon src="/icons/bookmark.svg" alt="Bookmark icon" />
              )}
              <EpisodeArt
                src={
                  episode.thumbnailUrl && episode.thumbnailUrl !== ""
                    ? episode.thumbnailUrl
                    : selectedStoryworld?.imageUrl
                }
                alt={episode.title}
                $isCurrent={currentEpisode.id === episode.id}
                $isRead={userInfo?.readingList?.includes(episode.id)}
              />
              <div>{episode.title}</div>
            </EpisodeWrapper>
          </Link>
        );
      })}
    </CustomDrawer>
  );
};

export default EpisodeDrawer;

const EpisodeWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: ${({ $isLast }) =>
    $isLast ? "none" : "1px solid var(--border-light)"};

  font-size: 1rem;
  font-weight: 500;

  color: ${({ $isCurrent, $isRead }) =>
    $isCurrent
      ? "var(--color-highlight)"
      : $isRead
      ? "#949BA4"
      : "var(--color-cream-base)"};

  transition: color 0.2s ease-in;

  &:hover {
    color: var(--color-cream-base);
  }

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const EpisodeArt = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  flex-shrink: 0;
  transition: opacity 0.2s ease-in;

  opacity: ${({ $isRead }) => ($isRead ? 0.6 : 1)};

  ${EpisodeWrapper}:hover & {
    opacity: 1;
  }

  ${({ $isCurrent }) =>
    $isCurrent &&
    css`
      border: 3px solid var(--color-pink);
    `}
`;

const BookmarkIcon = styled.img`
  position: absolute;
  top: 0px;
  right: 10px;
  height: 26px;
  width: auto;
`;
