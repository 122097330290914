import styled from "styled-components";
import UserAvatar from "../components/user/UserAvatar";
import { device } from "../lib/breakpoints";

const WRITING_QUEST_ICON = "/icons/writing-quest-pencil.svg";
const ART_QUEST_ICON = "/icons/magic-wand.svg";
const CREATOR_POLL_ICON = "/icons/creator-poll.svg";

export const AlertContainer = styled.div`
  width: 100%;
  min-height: 74px;
  text-align: left;
  display: inline-flex;
  justify-content: center;
  gap: 16px;
  cursor: pointer;

  @media ${device.tablet}, ${device.desktop} {
    justify-content: left;
  }
`;

export const AlertTextContainer = styled.div`
  display: block;
  margin: auto 0px auto 0px;
  width: calc(100% - 90px);

  @media ${device.tablet}, ${device.desktop} {
    min-width: 0;
    margin-block: 1rem;
  }
`;

export const AlertTitle = styled.div`
  color: var(--color-cream-base);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 183.333% */
`;

export const AlertDescription = styled.div`
  color: #949ba4;
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  white-space: pre-wrap;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AlertText = styled.span`
  color: #949ba4;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

export const AlertWatchIcon = styled.img`
  height: 15px;
  width: 15px;
`;

export const AlertTimeLeft = styled.span`
  color: var(--color-pink);
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 44px;
  margin: auto 0px;
  text-align: center;
  flex-shrink: 0;
`;

const CustomImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: ${(props) =>
    props.$isOverridden ? "var(--color-blurple)" : "none"};
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: ${(props) => (props.$isOverridden ? "50%" : "100%")};
    height: ${(props) => (props.$isOverridden ? "50%" : "100%")};
    border-radius: 4px;
    object-fit: cover;
  }
`;

const CornerAvatar = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(25%) translateY(25%);

  span {
    background: none;
  }

  img {
    border: 1.5px solid #23262f;
    border-radius: 50%;
  }
`;

// This function exists to account for all the edge cases on what is shown with the alert in the design
const overrideAlertImage = (alert) => {
  if (alert.type === "poll") {
    return CREATOR_POLL_ICON;
  } else if (alert.type === "quest") {
    if (alert.state === "results") {
      // handled by QuestAlert.jsx
      return undefined;
    }
    if (alert.alertImage) return;
    return alert.context?.questType === "art"
      ? ART_QUEST_ICON
      : WRITING_QUEST_ICON;
  } else if (alert.context?.sourceType === "submission" && !alert.alertImage) {
    return WRITING_QUEST_ICON;
  }

  return undefined;
};

export const AlertIcon = ({ alert, userId }) => {
  const overriddenImage = overrideAlertImage(alert);
  const customImage = overriddenImage || alert.alertImage;

  // Kind of an edge case that we don't want to preview the submission image for comments, so we are bypassing the logic below
  if (
    alert.replyTo ||
    (alert.context?.itemType === "comment" && alert.type === "like")
  ) {
    return (
      <IconWrapper>
        <UserAvatar userId={userId} size={44} />
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      {customImage ? (
        <CustomImage $isOverridden={!!overriddenImage}>
          <img src={customImage} alt="" />
          {userId && (
            <CornerAvatar>
              <UserAvatar userId={userId} size={26} />
            </CornerAvatar>
          )}
        </CustomImage>
      ) : (
        <UserAvatar userId={userId} />
      )}
    </IconWrapper>
  );
};

export const AlertStoryIcon = styled.div`
  width: 40px;
  height: 59px;
  margin: auto 0px;
  flex-shrink: 0;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;
