import { DeleteFilled } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Image, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { QuestDisplayText } from "../styles/CommonElements";

const dragIcon = "/icons/drag.svg";

export default function DragCard({ idx, comicEntry, removeComicEntry }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: comicEntry.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <InputHeader>Page {comicEntry.pageNumber}</InputHeader>
      <Card
        key={idx}
        style={{
          background: comicEntry.url,
        }}
      >
        <Image src={comicEntry.url} preview={false} {...listeners} />
        <Footer>
          <FooterContent>
            <div
              style={{ marginInline: "4px", paddingBottom: 2 }}
              {...listeners}
            >
              <Image preview={false} src={dragIcon} />
            </div>
            <div>
              <Tooltip title={comicEntry.fileName}>
                <QuestDisplayText>{comicEntry.fileName}</QuestDisplayText>
              </Tooltip>
            </div>
            <div>
              <DeleteComicButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeComicEntry(comicEntry);
                }}
              >
                <DeleteFilled style={{ color: "#7d818e" }} />
              </DeleteComicButton>
            </div>
          </FooterContent>
        </Footer>
      </Card>
    </div>
  );
}

const InputHeader = styled.div`
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom: 14px;
`;

const Footer = styled.div`
  bottom: 0;
  width: 100%;
  position: relative;
  color: white;
  background: var(--color-black);
  display: block;
  transition: 0s ease;
  opacity: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  height: 30px;
  overflow: hidden;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--color-gray-50);
  white-space: pre-wrap;
  padding: 0px !important;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  bottom: 0;
  position: relative;

  .ant-image {
    width: 20px !important;
  }
`;

const DeleteComicButton = styled(Button)`
  border: 0;
  margin: 0;
  padding: 0;
`;

const Card = styled.div`
  height: 315px;
  width: 177px;
  max-width: 177px;
  max-height: 315px;
  object-fit: fit;
  overflow: hidden;
  margin: auto;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top !important;
  position: relative !important;
  border-radius: 12px !important;
  margin: 0px !important;
  margin: 10px !important;
`;
