import React, { useContext } from "react";
import { Button } from "antd";
import { useState } from "react";
import styled from "styled-components";
import date from "date-and-time";
import { Link, useOutletContext } from "react-router-dom";
import CreateStoryworldModal from "../components/create-storyworld/CreateStoryworldModal";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { useQuery } from "@tanstack/react-query";
import { storyworldKeys } from "../lib/queryKeys";
import { getUserStoryworlds } from "../helpers/firebase";

const datePattern = date.compile("MMMM YYYY");

const editIcon = "/icons/social/edit.svg";

export default function StoryworldSelector() {
  const [isModalOpen, setModalOpen] = useState(false);
  const { setSelectedStoryworld } = useContext(SelectedStoryworldContext);
  const { setCurrent } = useOutletContext();

  const [storyworldBeingEdited, setStoryworldBeingEdited] = useState(null);

  const { data: storyworlds } = useQuery({
    queryKey: storyworldKeys.user_storyworlds(),
    queryFn: () => {
      return getUserStoryworlds();
    },
    placeholderData: [],
  });

  const openEditStoryworld = (e, storyworld) => {
    e.preventDefault();
    e.stopPropagation();
    setStoryworldBeingEdited(storyworld);
    setModalOpen(true);
  };

  return (
    <>
      <HeaderContainer>
        <Header>Storyworlds</Header>
        <Button
          type="primary"
          onClick={() => {
            setStoryworldBeingEdited(null);
            setModalOpen(true);
          }}
          size="medium"
        >
          + Create Storyworld
        </Button>
      </HeaderContainer>
      {storyworlds.map((storyworld) => (
        <StoryworldButtonContainer key={storyworld.id}>
          <StoryworldButton
            onClick={() => {
              setSelectedStoryworld(storyworld);
              setCurrent("0");
            }}
          >
            <EditIconContainer
              onClick={(e) => openEditStoryworld(e, storyworld)}
            >
              <EditIcon src={editIcon} alt="" />
            </EditIconContainer>

            <Link to={`/edit/${storyworld.id}/episodes`}>
              <div>
                <StoryworldImage
                  src={
                    storyworld.imageUrl
                      ? storyworld.imageUrl
                      : "./images/default-storyworld.svg"
                  }
                  alt=""
                />
              </div>
            </Link>
          </StoryworldButton>
          <Title>{storyworld.title}</Title>
          <CreatedAt>
            Created {date.format(storyworld.createdAt.toDate(), datePattern)}
          </CreatedAt>
        </StoryworldButtonContainer>
      ))}

      <CreateStoryworldModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        setSelectedStoryworld={setSelectedStoryworld}
        storyworldBeingEdited={storyworldBeingEdited}
      />
    </>
  );
}

const CreatedAt = styled.p`
  font: unset;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--inputFieldText);
  white-space: pre-wrap;
  padding: 0px !important;
  margin: 0px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EditIconContainer = styled.div`
  width: 30px;
  height: 30px;
  top: 0;
  right: 0;
  position: absolute;
  border-radius: 0px 8px;
  background: var(--color-gray-400);
  cursor: pointer;
`;

const EditIcon = styled.img`
  width: 20px;
  height: auto;
  margin: 3px auto auto 3px;
`;

const HeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const Header = styled.h1`
  font-size: 32px;
  margin-bottom: 36px;
`;

const StoryworldButtonContainer = styled.div`
  margin: 0px 20px 50px 0px;
  width: 240px;
  display: inline-block;
`;

const StoryworldButton = styled.button`
  width: 232px;
  height: 100%;
  background: var(--color-gray-500);
  padding: 0px !important;
  overflow: hidden;
  border: 0px;
  border-radius: 8px;
  object-fit: contain;
  position: relative;
`;

const StoryworldImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 14px 0px 4px 0px;
`;
