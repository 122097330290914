import React, { useRef } from "react";
import styled from "styled-components";
import {
  APP_NAME,
  discordLink,
  instagramLink,
  tikTokLink,
  twitterLink,
} from "../helpers/constants";
import Collapse from "../components/Collapse";
import faqData from "../helpers/faq";
import { trackEvent } from "../helpers/mixpanel";

export default function About() {
  const faqRef = useRef();

  return (
    <Container>
      <ContentContainer>
        <Hero src="/images/about/hero.svg" />
        <ReferenceContainer>
          <Title>Build webcomics together.</Title>
          <ScribbleEffects
            src="/images/about/bg-scribbles.svg"
            $width={530}
            $top={-12}
          />
        </ReferenceContainer>
        <OverlayContainer>
          <Subtitle>
            Read amazing web comics.
            <br />
            Submit your ideas, and together craft the best new Webcomics, Manga,
            Manhua and Manhwa.
            <br />
            <br />
            Stories you love, created by the
            <br />
            readers who love them
          </Subtitle>

          <JoinButton
            onClick={() => {
              trackEvent("Clicked Join Discord CTA");
              window.open(discordLink);
            }}
          >
            Join our Discord
          </JoinButton>

          <div style={{ height: 152 }} />
        </OverlayContainer>
        <WhiteCover>
          <ScribbleEffects
            src="images/about/white-cover-effects.svg"
            $width={480}
            $bottom={-28}
            $right={-16}
          />
          <WhiteInnerContainer>
            <ReadImage src="/images/about/reading-rabbit.png" />
            <CoverText>
              <CoverTitle>Read webcomics</CoverTitle>
              <CoverSubtitle>by great creators</CoverSubtitle>
            </CoverText>
          </WhiteInnerContainer>
        </WhiteCover>

        <Features>
          <ScribbleEffects
            src="/images/about/feature-scribbles.svg"
            $width={718}
            $top={-64}
            $left={-84}
          />

          <FeatureContainer $gap={32}>
            <FeatureTextContainer $width={185}>
              <FeatureTitle>Quest!</FeatureTitle>
              <FeatureDescription>
                Creators ask the community for ideas on where the story should
                go
              </FeatureDescription>
            </FeatureTextContainer>
            <FeatureImage src="/images/about/feature-sword.png" $width={111} />
          </FeatureContainer>

          <FeatureContainer $gap={32} $paddingLeft={16}>
            <FeatureImage
              src="/images/about/feature-painting.png"
              $width={122}
            />
            <FeatureTextContainer $width={173}>
              <FeatureTitle>Create art</FeatureTitle>
              <FeatureDescription>
                with {APP_NAME} tools and share your ideas with webcomic
                creators
              </FeatureDescription>
            </FeatureTextContainer>
          </FeatureContainer>

          <CarrotContainer>
            <CarrotImage src="/images/about/feature-carrot.png" />
            <CarrotText>
              Anyone can create with {APP_NAME} artists - no drawing skills
              necessary!
            </CarrotText>
            <CarrotButton
              onClick={() =>
                faqRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              How?
              <img src="/images/about/arrow-how.svg" alt="arrow" />
            </CarrotButton>
          </CarrotContainer>

          <FeatureContainer $gap={16} $paddingLeft={16}>
            <FeatureTextContainer $width={173}>
              <FeatureTitle>Vote</FeatureTitle>
              <FeatureDescription>
                for your favorite ideas and drive story direction
              </FeatureDescription>
            </FeatureTextContainer>
            <FeatureImage src="/images/about/trophy.png" $width={153} />
          </FeatureContainer>
        </Features>

        <Connect>
          <ScribbleEffects
            src="/images/about/connect-scribbles.svg"
            $width={488}
            $top={-42}
            $left={-96}
          />
          <ConnectContainer>
            <FeatureContainer $gap={27}>
              <FeatureImage src="/images/about/connect-eyes.png" $width={112} />
              <FeatureTextContainer $width={191}>
                <ConnectTitle>Connect</ConnectTitle>
                <ConnectDescription>
                  with our welcoming community 24/7 and chat about comics & art
                </ConnectDescription>
              </FeatureTextContainer>
            </FeatureContainer>

            <JoinDiscordButton
              onClick={() => {
                trackEvent("Clicked Join Discord CTA");
                window.open(discordLink);
              }}
            >
              <span>Join our Discord</span>

              <img src="/images/about/arrow.svg" alt="arrow" />
            </JoinDiscordButton>
          </ConnectContainer>

          <Socials>
            <a href={instagramLink}>
              <SocialIcon src="/images/about/instagram.svg" $width={24} />
            </a>

            <a href={twitterLink}>
              <SocialIcon src="/images/about/twitter.svg" $width={24} />
            </a>

            <a href={tikTokLink}>
              <SocialIcon src="/images/about/tiktok.svg" $width={27} />
            </a>
          </Socials>
        </Connect>

        <FAQ>
          <FAQBackground>
            <FAQHeader>FAQ</FAQHeader>
          </FAQBackground>

          <FAQSecondaryBackground />

          <FAQScrollPoint ref={faqRef} />

          <div style={{ height: 50 }} />

          <CollapseContainer>
            {faqData.map((faq, index) => (
              <Collapse
                key={index}
                title={faq.title}
                description={faq.description}
              />
            ))}
          </CollapseContainer>

          <div style={{ height: 40 }} />
        </FAQ>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: var(--color-blurple);
  max-width: 500px;
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Hero = styled.img`
  width: 296px;
  height: auto;
  margin-top: 12px;
`;

const Title = styled.h1`
  color: var(--color-mint-dark);
  font-family: "Hoss Sharp";
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 0px;
`;

const Subtitle = styled.h2`
  color: white;
  font-size: 14px;
  line-height: 21.5px;
  font-weight: 600;
  width: 262px;
  margin-bottom: 54px;
  margin-top: 48px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
`;

const ReferenceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScribbleEffects = styled.img`
  position: absolute;
  ${(props) => props.$top && `top: ${props.$top}px;`}
  ${(props) => props.$bottom && `bottom: ${props.$bottom}px;`}
  ${(props) => props.$left && `left: ${props.$left}px;`}
  ${(props) => props.$right && `right: ${props.$right}px;`}
  width: ${(props) => `${props.$width}px`};
  height: auto;
  z-index: 0;
  overflow-x: hidden;
  object-fit: cover;
  pointer-events: none;
`;

const JoinButton = styled.button`
  width: 204px;
  height: 71px;
  border-radius: 12px;
  border: 2px solid var(--color-mint);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--color-blurple);
  color: var(--color-mint);
  cursor: pointer;

  font-family: "Hoss Sharp";
  font-size: 20px;
  font-weight: 700;
`;

const OverlayContainer = styled(ContentContainer)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

const WhiteCover = styled.div`
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
  background-color: var(--color-cream);
  padding-top: 14px;
  padding-bottom: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const WhiteInnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  z-index: 1;
`;

const ReadImage = styled.img`
  width: 141px;
  height: auto;
  object-fit: contain;
`;

const CoverText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const CoverTitle = styled.h1`
  font-family: "Hoss Sharp";
  font-size: 32px;
  font-weight: 800;
  color: var(--color-lavender-dark);
  line-height: 26px;
  margin-bottom: 8px;
`;

const CoverSubtitle = styled.h2`
  font-family: "Hoss Sharp";
  font-size: 18px;
  font-weight: 500;
  color: var(--color-lavender-dark);
  line-height: 21.5px;
  margin-bottom: 0px;
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
  padding-top: 57px;
  padding-bottom: 57px;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const FeatureContainer = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.$gap ?? 24}px;
  width: 100%;
  ${(props) => props.$paddingLeft && `padding-left: ${props.$paddingLeft}px;`}
`;

const FeatureImage = styled.img`
  width: ${(props) => `${props.$width}px`};
  height: auto;
  object-fit: contain;
`;

const FeatureTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  ${(props) => props.$width && `width: ${props.$width}px;`}
`;

const FeatureTitle = styled.h1`
  font-family: "Hoss Sharp";
  font-size: 32px;
  font-weight: 800;
  line-height: 21.5px;
  color: var(--color-mint-light);
  margin-bottom: 0px;
`;

const FeatureDescription = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 21.5px;
  color: var(--color-cream);
  margin-bottom: 0px;
  font-family: Poppins;
  font-style: normal;
`;

const CarrotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 1;
  margin-top: -26px;
`;

const CarrotImage = styled.img`
  width: 48px;
  object-fit: contain;
`;

const CarrotText = styled.p`
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-cream);
`;

const CarrotButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.5px;
  color: var(--color-pink-light);
  padding-bottom: 1px;
  border-bottom: 1px solid var(--color-pink-light);
  background: none;
  cursor: pointer;
`;

const Connect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--color-cream);
  overflow: hidden;
  width: 100%;
  height: 368px;
`;

const ConnectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const ConnectTitle = styled(FeatureTitle)`
  color: var(--color-lavender-dark);
`;

const ConnectDescription = styled(FeatureDescription)`
  color: var(--color-lavender-dark);
  width: 191px;
`;

const JoinDiscordButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  color: var(--color-cream);
  width: 100%;
  border-radius: 999px;
  background: var(--color-blurple);
  height: 62px;
  font-family: "Hoss Sharp";
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  margin-top: 28px;
  cursor: pointer;
`;

const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  height: 27px;
  margin-top: 32px;
`;

const SocialIcon = styled.img`
  width: ${(props) => props.$width}px;
  height: auto;
  object-fit: contain;
  cursor: pointer;
`;

const FAQ = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background: url("/images/about/faq-scribbles.svg");
  background-color: var(--color-gray-900);
  width: 100%;
`;

const FAQHeader = styled.h1`
  font-family: "Hoss Sharp";
  font-weight: bold;
  font-size: 49px;
  line-height: 43px;
  color: #01231c;
  margin-bottom: 0;
  ${(props) => props.invisible && `color: transparent;`}
`;

const FAQBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a6fbd6;
  width: 204px;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  border-radius: 12px;
  border: 2px solid #01231c;
  box-sizing: border-box;
  z-index: 2;
  position: absolute;
  top: -25px;
`;

const FAQSecondaryBackground = styled(FAQBackground)`
  top: calc(-25px + 0px);
  z-index: 1;
  margin-left: 6px;
  width: 210px;
  height: calc(78px + 4px);
  background-color: #34cb8d;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 18px;
`;

const CollapseContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const FAQScrollPoint = styled.div`
  position: absolute;
  top: -100px;
  pointer-events: none;
`;
