import styled from "styled-components";

const OrDivider = () => {
  return (
    <DividingLine>
      <div />
      <span>or</span>
      <div />
    </DividingLine>
  );
};

export default OrDivider;

const DividingLine = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;

  span {
    text-transform: uppercase;
    color: var(--color-cream);
    font-size: 0.875rem;
    font-weight: 700;
  }

  :nth-child(odd) {
    width: 100%;
    height: 1px;
    background: #35383f;
  }
`;
