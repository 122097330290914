import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";

export default function Collapse({ title, description, image = null }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CollapseContainer>
      <ToggleContainer onClick={() => setIsOpen((prev) => !prev)}>
        <Title>{title}</Title>
        <SvgContainer>
          <AnimatePresence mode="popLayout">
            {!isOpen ? (
              <Icon
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                key="plus"
                initial={{ rotate: 90, opacity: 0 }}
                animate={{ rotate: 0, opacity: 1 }}
                exit={{ rotate: 90, opacity: 0 }}
              >
                <path
                  d="M1.5 7H7.5M7.5 7H13.5M7.5 7V13M7.5 7V1"
                  stroke="#70F2BA"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Icon>
            ) : (
              <Icon
                width="15"
                height="2"
                viewBox="0 0 15 2"
                fill="none"
                key="minus"
                xmlns="http://www.w3.org/2000/svg"
                initial={{ rotate: 90, opacity: 0 }}
                animate={{ rotate: 0, opacity: 1 }}
                exit={{ rotate: 90, opacity: 0 }}
              >
                <path
                  d="M1.5 1H13.5"
                  stroke="#70F2BA"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Icon>
            )}
          </AnimatePresence>
        </SvgContainer>
      </ToggleContainer>

      <AnimatePresence>
        {isOpen && (
          <>
            <Description
              initial={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.4 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0, transition: { duration: 0.2 } }}
            >
              {description}
            </Description>
            {image && <SvgObj type="image/svg+xml" data={image} />}
          </>
        )}
      </AnimatePresence>
    </CollapseContainer>
  );
}

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10.5px;
  padding-right: 10.5px;
  gap: 14px;
  border-bottom: 1px solid black;
  padding-bottom: 14px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 14px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  display: flex;
  margin-bottom: 0px;
  flex-grow: 1;
`;

const SvgContainer = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  filter: brightness(0);
`;

const Description = styled(motion.p)`
  margin-bottom: 0;
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  white-space: pre-line;
`;

const Icon = styled(motion.svg)`
  position: absolute;
  color: blue;
`;

export const SvgObj = styled.object`
  width: 100%;
  height: 100%;
`;
