import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { getQuestById, getStoryworldById } from "../../helpers/firebase";
import episodeModel from "../../lib/firebase/episodeModel";
import submissionModel from "../../lib/firebase/submissionModel";
import { sendReport } from "../../helpers/report";
import { Col, Row, notification } from "antd";
import reportModel from "../../lib/firebase/reportModel";
import { trackEvent } from "../../helpers/mixpanel";
import { commentKeys } from "../../lib/queryKeys";
import CustomSheet from "../CustomSheet";
import userModel from "../../lib/firebase/userModel";
import { AuthContext } from "../../context/AuthProvider";

export default function ReportSheet({ isOpen, setOpen, comment }) {
  const { user } = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const [isSending, setIsSending] = useState(false);
  const queryClient = useQueryClient();

  const openNotification = () => {
    const key = `open${Date.now()}`;
    api.open({
      description: (
        <Row>
          <Col span={22}>
            We have received your report. Thank you for keeping our community
            safe!
          </Col>
          <Col span={2}></Col>
        </Row>
      ),
      key,
      duration: 10,
      className: "admin-notification",
      closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
    });
  };

  useEffect(() => {
    if (isOpen) setIsSending(false);
  }, [isOpen]);

  const startSendingReport = async () => {
    if (!user) {
      setOpen(false);
      return;
    }
    setIsSending(true);
    const reportedByUser = await userModel.getOneById(user?.uid);
    const reportedUser = await userModel.getOneById(comment.creator);
    const episodeData = await episodeModel.getOneById(comment.subjectId);
    const submissionData = await submissionModel.getOneById(comment.subjectId);
    let storyworldName = "";
    let questName = "";
    let questId = "";
    let episodeId = "";
    let episodeName = "";
    let mixpanelParams = {};
    if (episodeData) {
      const storyworldData = await getStoryworldById(episodeData.storyworldId);
      episodeName = episodeData.title;
      episodeId = episodeData.id;
      storyworldName = storyworldData.title;
      mixpanelParams = {
        episode_id: episodeData.id,
        episode_name: episodeData.title,
        storyworld_id: storyworldData.id,
        storyworld_name: storyworldData.title,
        title: `${storyworldData.title}: ${episodeData.title}`,
      };
    } else if (submissionData) {
      const questData = await getQuestById(submissionData.questId);
      const storyworldData = await getStoryworldById(questData.storyworld);
      questName = questData.title;
      questId = questData.id;
      storyworldName = storyworldData.title;
      mixpanelParams = {
        quest_id: questData.id,
        quest_name: questData.title,
        storyworld_id: storyworldData.id,
        storyworld_name: storyworldData.title,
        title: `${storyworldData.title}: ${questData.title}`,
      };
    }
    const data = {
      comment: comment.comment,
      location: {
        storyworldName,
        questName,
        questId,
        episodeName,
        episodeId,
      },
      reportedBy: {
        username: reportedByUser.username,
        email: reportedByUser.email,
        id: reportedByUser.id,
      },
      reportedUser: {
        username: reportedUser.username,
        email: reportedUser.email,
        id: reportedUser.id,
      },
    };

    const result = await sendReport(data);
    if (result.success) {
      openNotification();
      await reportModel.create({ subjectId: comment.id });
      queryClient.invalidateQueries({
        queryKey: commentKeys.subject(comment.subjectId),
      });
      setIsSending(false);
      setOpen(false);
      trackEvent("Reported Comment", {
        reported_user_id: reportedUser.id,
        reported_user_email: reportedUser.email,
        reported_user_username: reportedUser.username,
        reported_by_user_id: reportedByUser.id,
        reported_by_user_email: reportedByUser.email,
        reported_by_user_username: reportedByUser.username,
        ...mixpanelParams,
      });
    } else {
      // TODO: Show error notification
    }
  };

  return (
    <>
      {contextHolder}
      <CustomSheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[225]}
        tweenConfig={{ ease: "easeOut", duration: 0.3 }}
      >
        <InnerContainer>
          <Header>Report this comment?</Header>
          <Content>
            Submit a report to us if you see a comment that may violate{" "}
            <PinkText
              onClick={() => {
                window.open("/guidelines");
              }}
            >
              guidelines
            </PinkText>
            .
          </Content>
          {!isSending ? (
            <SendReportButton onClick={async () => await startSendingReport()}>
              Report
            </SendReportButton>
          ) : (
            <SendReportButton disabled>
              <Spinner alt="" src="/icons/loading-spheres.svg" />
            </SendReportButton>
          )}
        </InnerContainer>
      </CustomSheet>
    </>
  );
}

const Header = styled.div`
  color: var(--color-cream-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 14px;
`;

const Content = styled.div`
  color: var(--color-gray-50);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  margin-top: 12px;
`;

const InnerContainer = styled.div`
  width: 85%;
  margin: 0px auto;
`;

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const SendReportButton = styled.button`
  width: 100%;
  height: 50px;
  background: var(--blurple);
  border: 0;
  border-radius: 90px;
  margin-top: 20px;
  cursor: pointer;
  line-height: 16px;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: stretch;
  color: var(--color-cream-base);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;

const PinkText = styled.span`
  color: var(--color-pink);
`;
