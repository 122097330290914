import { useState } from "react";
import SubmissionPreview from "../submissions/preview/SubmissionPreview";
import styled from "styled-components";
import WritingContent from "../WritingContent";

const VoteTextSubmission = ({ submission, handleVote, isSelected = false }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClick = () => {
    handleVote(submission);
  };

  return (
    <>
      <SubmissionPreview
        submission={submission}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        isInVote
      />
      <Container>
        <VoteWritingContainer $isSelected={isSelected} onClick={handleClick}>
          <WritingContent
            title={submission.title}
            text={submission.text}
            isExpandable
            omitBackground
          />
        </VoteWritingContainer>
        {isSelected && (
          <WritingCheckbox onClick={handleClick}>
            <Checkmark src="/icons/checkmark-white.svg" alt="" />
          </WritingCheckbox>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  grid-column: 1 / -1;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
  position: relative;
`;

const VoteWritingContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: start;
  border-radius: 8px;
  border: ${(props) =>
    props.$isSelected
      ? `1.5px solid var(--color-blurple)`
      : `1.5px solid var(--border-light)`};
`;

const WritingCheckbox = styled.div`
  top: 0;
  right: 0;
  height: 38px;
  width: 38px;
  border-radius: 0px 12px;
  background: var(--color-blurple);
  position: absolute;
  display: flex;
`;

const Checkmark = styled.img`
  height: 25px;
  width: 25px;
  padding: 4px;
  margin: auto;
  vertical-align: text-top;
`;

export default VoteTextSubmission;
