import React from "react";
import { useEffect } from "react";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import styled from "styled-components";
import {
  vhPanelLeftPanelWidth,
  vhPanelrightPanelLeftMargin,
} from "../helpers/constants";
import { removeTags } from "../helpers/general";
import { InputSection, Section } from "../styles/CommonElements";

const dayOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

export default function QuestPreview({
  title,
  startTimestamp,
  endTimestamp,
  description,
  objectiveDescription,
  suggestedSearchTerms,
  videoUrl,
  thumbnailUrl,
  moreInfo,
  ongoing = false,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <LeftPanel>
        <Section>
          <InnerSection>
            {(videoUrl || thumbnailUrl) && (
              <FieldHeader>Quest Assets</FieldHeader>
            )}
            {thumbnailUrl && (
              <div className="poll-form-element">
                <InputHeader>Quest Thumbnail (optional)</InputHeader>
                <ThumbnailImage src={thumbnailUrl} alt="" />
              </div>
            )}
            {videoUrl && (
              <div className="poll-form-element">
                <InputHeader>Quest Video (optional)</InputHeader>
                <div className="preview-video">
                  <ReactPlayer
                    className="quest-video-player"
                    controls={true}
                    url={videoUrl}
                  />
                </div>
              </div>
            )}

            <FieldHeader>Quest Duration</FieldHeader>
            <InputSection>
              <InputHeader style={{ marginTop: 10 }}>Quest begins</InputHeader>
              <Text>
                {startTimestamp.toLocaleDateString("en-US", dayOptions)} at{" "}
                {startTimestamp.toLocaleTimeString("en-US")}
              </Text>
            </InputSection>

            <div className="poll-form-element" style={{ marginTop: 0 }}>
              <InputHeader style={{ marginTop: 10 }}>Quest ends</InputHeader>
              {ongoing && <Text>This quest is ongoing</Text>}
              {!ongoing && (
                <Text>
                  {endTimestamp.toLocaleDateString("en-US", dayOptions)} at{" "}
                  {endTimestamp.toLocaleTimeString("en-US")}
                </Text>
              )}
            </div>
          </InnerSection>
        </Section>
      </LeftPanel>

      <RightPanel>
        <div className="create-quest-content-section">
          <InnerSection>
            <FieldHeader>Quest Information</FieldHeader>
            <InputSection>
              <div className="poll-form-element">
                <QuestTitle>{title ? title : "New Quest"}</QuestTitle>
                <InputHeader>Quest description</InputHeader>
                <Text>{parse(description)}</Text>
              </div>
            </InputSection>

            {removeTags(moreInfo) !== "" && (
              <InputSection>
                <InputHeader>More info</InputHeader>
                <Text>{parse(moreInfo)}</Text>
              </InputSection>
            )}

            <InputSection>
              <InputHeader>GenAI Prompt Question</InputHeader>
              <Text>{parse(objectiveDescription)}</Text>
            </InputSection>

            {suggestedSearchTerms.length > 0 && (
              <div className="poll-form-element" style={{ marginTop: 0 }}>
                <InputHeader>
                  Starter Prompts ({suggestedSearchTerms.length})
                </InputHeader>
                {suggestedSearchTerms.map((prompt, index) => (
                  <StarterPrompt key={index}>{prompt}</StarterPrompt>
                ))}
              </div>
            )}
          </InnerSection>
        </div>
      </RightPanel>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: inline-flex;

  img {
    max-width: 100%;
  }
`;

const LeftPanel = styled.div`
  width: ${vhPanelLeftPanelWidth}px;
`;

const RightPanel = styled.div`
  width: calc(
    100% - ${vhPanelLeftPanelWidth}px - ${vhPanelrightPanelLeftMargin}px
  );
  margin-left: ${vhPanelrightPanelLeftMargin}px;
`;

const FieldHeader = styled.div`
  color: var(--darkTextColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  letter-spacing: -0.32px;
`;

const InnerSection = styled.div`
  padding: 12px 16px 20px 16px;
  text-align: left;
`;

const StarterPrompt = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--inputFieldText);
`;

const InputHeader = styled.div`
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom: 14px;
`;

const Text = styled.div`
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-gray-50);
`;

const QuestTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 26px;
`;

const ThumbnailImage = styled.img`
  height: 160px !important;
  width: 100%;
  object-fit: cover;
  border-radius: 12px !important;
`;
