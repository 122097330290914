import React from "react";
import { Col, Row } from "antd";
import { useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import {
  createCreatorPollQuillModules,
  vhPanelLeftPanelWidth,
  vhPanelrightPanelLeftMargin,
} from "../helpers/constants";
import styled from "styled-components";
import {
  InputSection,
  RightAlignedCol,
  Section,
  Spacer,
} from "../styles/CommonElements";
import { ResponseEditor } from "../components/vh-panel";
import { removeTags } from "../helpers/general";
import CharacterCounter from "../components/CharacterCounter";
import { StyledQuill } from "../components/general";

const questionLengthLimit = 90;
const rteMinHeight = 165;

export default function CreatorPollEditor({
  question,
  setQuestion,
  responseOptions,
  setResponseOptions,
  endTimestamp,
  onEndTimestampChange,
  startTimestamp,
  onStartTimestampChange,
  creatorPollLive,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleResponseOptionChange = (e, index) => {
    let ops = [...responseOptions];
    ops[index] = e.target.value;
    setResponseOptions(ops);
  };

  return (
    <Container>
      <LeftPanel>
        <Section>
          <InnerSection>
            <FieldHeader>Duration</FieldHeader>
            <InputSection>
              <Row>
                <Col span={6}>
                  <InputHeader style={{ marginTop: 10 }}>Open</InputHeader>
                </Col>
                <Col span={18}>
                  <DateTimePicker
                    onChange={onStartTimestampChange}
                    value={startTimestamp}
                    className="datetime-picker"
                    maxDate={endTimestamp}
                    clearIcon={null}
                    calendarIcon={null}
                    disableClock={true}
                    disabled={creatorPollLive}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={6}>
                  <InputHeader style={{ marginTop: 10 }}>Close</InputHeader>
                </Col>
                <Col span={18}>
                  <DateTimePicker
                    onChange={onEndTimestampChange}
                    value={endTimestamp}
                    className="datetime-picker"
                    minDate={startTimestamp}
                    clearIcon={null}
                    calendarIcon={null}
                    disableClock={true}
                  />
                </Col>
              </Row>
            </InputSection>
          </InnerSection>
        </Section>
      </LeftPanel>

      <RightPanel>
        <Section>
          <InnerSection>
            <FieldHeader>Poll information</FieldHeader>
            <Spacer $marginTop="36px" />
            <SectionOption>
              <Row>
                <Col span={20}>
                  <InputHeader>Question</InputHeader>
                </Col>
                <RightAlignedCol span={4}>
                  <CharacterCounter
                    count={removeTags(question).length}
                    max={questionLengthLimit}
                  />
                </RightAlignedCol>
              </Row>
              <StyledQuill
                placeholder="Start typing..."
                value={question}
                setValue={setQuestion}
                limit={questionLengthLimit}
                styles={{ minHeight: `${rteMinHeight}px` }}
                modules={createCreatorPollQuillModules}
              />
            </SectionOption>
            <Spacer $marginTop="54px" />
            <FieldHeader>Responses ({responseOptions.length})</FieldHeader>
            <ResponseEditor
              disableEditor={creatorPollLive}
              responseOptions={responseOptions}
              setResponseOptions={setResponseOptions}
              handleResponseOptionChange={handleResponseOptionChange}
            />
          </InnerSection>
        </Section>
      </RightPanel>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: inline-flex;

  .datetime-picker {
    width: 100% !important;
  }
`;

const LeftPanel = styled.div`
  width: ${vhPanelLeftPanelWidth}px;
`;

const RightPanel = styled.div`
  width: calc(
    100% - ${vhPanelLeftPanelWidth}px - ${vhPanelrightPanelLeftMargin}px
  );
  margin-left: ${vhPanelrightPanelLeftMargin}px;
`;

const FieldHeader = styled.div`
  color: var(--darkTextColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  letter-spacing: -0.32px;
`;

const InputHeader = styled.div`
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.14px;
  margin-bottom: 14px;
`;

const SectionOption = styled.div`
  margin: 22px 0px 30px 0px;
`;

const InnerSection = styled.div`
  padding: 12px 16px 20px 16px;
  text-align: left;
`;
