"use client";
import React from "react";
import styled from "styled-components";
import { APP_LOGO_TEXT } from "../../helpers/constants";
import { device, useBreakpoints } from "../../lib/breakpoints";

const BANNER_IMAGE_MOBILE = "/images/home/man-008.svg";
const BANNER_IMAGE_DESKTOP = "/images/home/man-008.svg";

const HomeMain = () => {
  const { isMobile } = useBreakpoints();

  return (
    <Container>
      <InfoOverlay>
        <Title>
          <Logo src={APP_LOGO_TEXT} alt="" />
        </Title>
        <Subtitle>
          Grow your business with custom development and management of AI, ML,
          AR / VR and Blockchain products
        </Subtitle>
      </InfoOverlay>
      <Cover />
      <BannerImage $isMobile={isMobile} />
    </Container>
  );
};

export default HomeMain;

const Container = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
`;

const BannerImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    props.$isMobile
      ? `url(${BANNER_IMAGE_MOBILE})`
      : `url(${BANNER_IMAGE_DESKTOP})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;

  @media ${device.mobile} {
    background-position: top center;
  }
`;

const Cover = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
`;

const Logo = styled.img`
  width: auto;
  height: 60px;

  @media ${device.mobile} {
    text-align: center;
    margin: auto;
    justify-content: center;
  }
`;

const InfoOverlay = styled.div`
  position: absolute;
  left: 10%;
  top: 30%;
  width: 600px;
  line-height: 36px;
  text-align: left;
  z-index: 2;
  color: white;
  // text-shadow: rgba(255, 255, 255, 0.5) 1px 0 4px;

  @media ${device.tablet} {
    left: 5%;
    top: 30%;
    scale: 0.8;
  }

  @media ${device.mobile} {
    top: unset;
    left: unset;
    width: 100%;
    bottom: 5%;
    scale: 0.8;
    text-align: center;
    margin: auto;
    justify-content: center;
  }
`;

const Title = styled.div`
  margin-bottom: 16px;
`;

const Subtitle = styled.div`
  font-size: 28px;
  // color: rgba(255, 255, 255, 0.8);
`;
