import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useWindowScroll } from "react-use";
import {
  ComicReaderContent,
  ComicReaderFooter,
  ComicReaderHeader,
  ComicReaderMobileHeader,
} from "../views";
import {
  addToReadingList,
  increaseEpisodeViewsByOne,
} from "../helpers/firebase";
import { motion } from "framer-motion";
import { trackEvent } from "../helpers/mixpanel";
import bookmarkModel from "../lib/firebase/bookmarkModel";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import { bookmarkKeys, commentKeys, userKeys } from "../lib/queryKeys";
import unreportedEpisodeComments from "../lib/advanced/unreportedEpisodeComments";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AlertSourceContext, { SOURCE } from "../context/AlertSourceContext";
import { CommentSheet } from "../components/social";
import { AuthContext } from "../context/AuthProvider";
import usePublishedEpisodesQuery from "../lib/usePublishedEpisodesQuery";
import Hidden from "../components/Hidden";
import { comicUrl } from "../lib/routes";
import { device, useBreakpoints } from "../lib/breakpoints";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { DIALOG_NAME } from "../helpers/useDialogSearchParam";

const transitionDefault = {
  type: "spring",
  stiffness: 260,
  damping: 20,
};

export default function ComicReader() {
  const { storyId: storyworldId, epNum: epNumParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { user } = useContext(AuthContext);
  const { selectedStoryworld } = useContext(SelectedStoryworldContext);

  const { isMobile } = useBreakpoints();

  const [epNumber, setEpNumber] = useState(parseInt(epNumParam));
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [viewedEpisodeId, setViewedEpisodeId] = useState(null);
  const [isHeaderVisibile, setHeaderVisibility] = useState(true);
  const [revealedYPos, updateRevealedYPosition] = useState(0);

  const { y } = useWindowScroll();

  const queryClient = useQueryClient();
  const { episodes } = usePublishedEpisodesQuery(storyworldId);

  const { data: comments } = useQuery({
    queryKey: commentKeys.subject(currentEpisode?.id),
    queryFn: () => {
      if (!epNumber) {
        return [];
      }

      return unreportedEpisodeComments(currentEpisode.id);
    },
    enabled: !!currentEpisode,
    placeholderData: [],
  });

  const revealHeader = useCallback(() => {
    setHeaderVisibility(true);
    updateRevealedYPosition(y);
  }, [y]);

  useEffect(() => {
    document.body.addEventListener("click", revealHeader);

    return () => {
      document.body.removeEventListener("click", revealHeader);
    };
  }, [revealHeader, y]);

  useEffect(() => {
    const commentDrawerOpen = searchParams.get(DIALOG_NAME.comments) !== null;
    const episodeDrawerOpen = searchParams.get(DIALOG_NAME.episodes) !== null;

    if (commentDrawerOpen || episodeDrawerOpen || !isHeaderVisibile) {
      return;
    }

    // Scrolled down
    if (y > revealedYPos) {
      setHeaderVisibility(false);
    }
    // Scrolled up
    else if (y < revealedYPos) {
      // Without this the user would have to scroll back down to where they revealed the header before it would disappear
      updateRevealedYPosition(y);
    }
  }, [isHeaderVisibile, searchParams, y, revealedYPos]);

  useEffect(() => {
    async function incrementViews() {
      if (currentEpisode && viewedEpisodeId !== currentEpisode.id) {
        increaseEpisodeViewsByOne({
          episodeId: currentEpisode.id,
          currentViews: currentEpisode.views,
        });
      }
    }

    if (selectedStoryworld && currentEpisode !== null) {
      incrementViews();
      setViewedEpisodeId(storyworldId);
    }
  }, [
    selectedStoryworld,
    currentEpisode,
    viewedEpisodeId,
    epNumber,
    storyworldId,
  ]);

  const handleBookmark = useCallback(
    (episode) => {
      bookmarkModel.create({
        newBookmarkEpisodeId: episode.id,
        storyworldId,
      });
      addToReadingList(episode.id, storyworldId).then(() => {
        queryClient.invalidateQueries({
          queryKey: userKeys.user(user?.uid),
        });
        queryClient.invalidateQueries({
          queryKey: bookmarkKeys.storyworld(storyworldId),
        });
      });
    },
    [queryClient, storyworldId, user?.uid]
  );

  useEffect(
    () => {
      const updatedEpNumber = parseInt(epNumParam);
      setEpNumber(updatedEpNumber);

      if (episodes !== null && episodes.length > 0) {
        const ep = episodes.find(
          (episode) => episode.number === updatedEpNumber
        );
        setCurrentEpisode(ep);
        if (ep && selectedStoryworld) {
          trackEvent("Viewed Episode", {
            episode_id: ep.id,
            episode_name: ep.title,
            episode_number: ep.number,
            storyworld_id: selectedStoryworld.id,
            storyworld_name: selectedStoryworld.title,
            title: `${selectedStoryworld.title} #${ep.number}: ${ep.title}`,
          });
          handleBookmark(ep);
        }
      }
    },
    // We want this to trigger on url update
    [
      location.pathname,
      storyworldId,
      selectedStoryworld,
      episodes,
      handleBookmark,
      epNumParam,
    ]
  );

  const loadNextEpisode = () => {
    const nextEpNum = parseInt(epNumParam) + 1;

    const nextEpisode = episodes.find((ep) => ep.number === nextEpNum);
    if (nextEpisode) {
      navigate(comicUrl(storyworldId, nextEpNum));
      window.scrollTo(0, 0);
    }
  };

  return (
    <AlertSourceContext.Provider
      value={{ sourceType: SOURCE.episode, sourceId: currentEpisode?.id }}
    >
      <div>
        <motion.span
          initial={{ opacity: 1 }}
          animate={{
            opacity: isHeaderVisibile ? 1 : 0,
            transition: {
              ...transitionDefault,
            },
          }}
          onMouseEnter={() => revealHeader()}
          style={{ position: "relative", zIndex: 10 }}
        >
          {isMobile ? (
            <ComicReaderMobileHeader
              episodes={episodes}
              currentEpisodeNumber={epNumber}
              storyworldId={storyworldId}
            />
          ) : (
            <ComicReaderHeader
              episodes={episodes}
              currentEpisodeNumber={epNumber}
              commentTotal={comments.length}
            />
          )}
        </motion.span>

        {currentEpisode && (
          <ComicReaderContent
            loadNextEpisode={loadNextEpisode}
            episode={currentEpisode}
            episodes={episodes}
            comments={comments}
            storyworld={selectedStoryworld}
          />
        )}
        <Hidden tablet desktop>
          <motion.span
            initial={{ opacity: 1 }}
            animate={{
              opacity: isHeaderVisibile ? 1 : 0,
              transition: {
                duration: isHeaderVisibile ? 0 : 0.3,
                ...transitionDefault,
              },
            }}
            style={{
              pointerEvents: isHeaderVisibile ? "auto" : "none",
            }}
          >
            <ComicReaderFooter
              episodes={episodes}
              currentEpisode={currentEpisode}
              setEpNumber={setEpNumber}
              storyworld={selectedStoryworld}
              comments={comments}
            />
          </motion.span>
        </Hidden>

        {/* Hidden doesn't work here because it wraps the sticky element in a div */}
        {!isMobile && (
          <BackToTop onClick={() => window.scrollTo(0, 0)}>
            <ChevronUp src="/icons/chevron-left.svg" alt="" />
            Top
          </BackToTop>
        )}

        {/* Dual usage of useDialogSearchParams for ?comments breaks it, so I'm conditionally rendering this sheet rather than just hiding it */}
        {isMobile && (
          <CommentSheet
            storyworld={selectedStoryworld}
            episode={currentEpisode}
            comments={comments}
          />
        )}
      </div>
    </AlertSourceContext.Provider>
  );
}

const BackToTop = styled.button`
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 8px;

  width: 68px;
  padding: 14px 18px;
  border-radius: 8px;
  background: #3b3e49;
  font-weight: 600;

  @media ${device.tablet}, ${device.desktop} {
    position: sticky;
    bottom: 20px;
    margin-left: auto;
    margin-bottom: 18px;
    margin-right: 18px;
  }
`;

const ChevronUp = styled(ReactSVG)`
  margin: auto;
  width: 24px;
  height: 12px;
  margin-bottom: 2px;

  svg {
    transform: rotate(90deg);
    transform-origin: 50% 50%;
    width: 100%;
  }
`;
