import React, { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import GalleryEmpty from "../views/GalleryEmpty";
import GalleryPopulated from "../views/GalleryPopulated";
import { getCompletedPolls } from "../helpers/firebase";
import styled from "styled-components";
import { pollKeys } from "../lib/queryKeys";
import { trackEvent } from "../helpers/mixpanel";

export default function Gallery() {
  const { data: completedPolls, isLoading } = useQuery({
    queryKey: pollKeys.completed(),
    queryFn: async () => {
      return getCompletedPolls();
    },
  });

  const sortedPolls = useMemo(() => {
    if (!completedPolls) {
      return [];
    }

    return completedPolls.sort(function (a, b) {
      return b.endTimestamp.toDate() - a.endTimestamp.toDate();
    });
  }, [completedPolls]);

  useEffect(() => {
    trackEvent("Visited Gallery");
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Spinner src="/icons/loading-spheres-lavender.svg" alt="" />
      ) : sortedPolls.length > 0 ? (
        <GalleryPopulated completedPolls={sortedPolls} />
      ) : (
        <GalleryEmpty />
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 30px;
  text-align: left;
  width: 100%;
  margin: auto;
  margin-top: 30px;

  .ant-image {
    width: 100%;
  }
`;

const Spinner = styled.img`
  width: 50px;
  height: 50px;
  animation: rotation 2s infinite linear;
  margin: 10px auto;
  display: block;
`;
