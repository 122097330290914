import styled, { css } from "styled-components";
import { device } from "../lib/breakpoints";

// Setting any breakpoint prop to true will hide it for that breakpoint
const Hidden = ({ mobile, tablet, desktop, children }) => {
  return (
    <Wrapper $mobile={mobile} $tablet={tablet} $desktop={desktop}>
      {children}
    </Wrapper>
  );
};

// There may be a more succinct way to write this
const Wrapper = styled.div`
  ${({ $mobile }) =>
    $mobile &&
    css`
      @media (${device.mobile}) {
        display: none !important;
      }
    `}

  ${({ $tablet }) =>
    $tablet &&
    css`
      @media (${device.tablet}) {
        display: none !important;
      }
    `}

    ${({ $desktop }) =>
    $desktop &&
    css`
      @media (${device.desktop}) {
        display: none !important;
      }
    `}
`;

export default Hidden;
