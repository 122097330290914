import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigationType, useSearchParams } from "react-router-dom";
import { usePrevious } from "react-use";

export const DIALOG_NAME = {
  comments: "comments",
  login: "login",
  // signup: "signup",
  episodes: "episodes",
  edit: "edit",
  settings: "settings",
};

// ! This hook struggles when used twice with the same DIALOG_NAME at once. Will need to find a solution to that eventually.
/**
 * Custom hook to observe the open/close state of a dialog based on URL search parameters, and close it.
 *
 * @param {string} dialogName - The name of the dialog to manage.
 * @returns {[boolean, function]} - An array containing the existence of the param and a function to remove it.
 *
 * This hook uses URL search parameters to determine whether a dialog should be open or closed.
 * It listens to changes in the URL search parameters and updates the dialog state accordingly.
 * The actual result of the state changing, e.g. opening or closing a modal/sheet, is handled by the consuming component.
 *
 * - If the URL contains the search parameter for the dialog, isParamSet will be true.
 * - If the URL does not contain the search parameter, isParamSet will be false.
 *
 *
 * Additionally, the hook ensures that the search parameter is removed from the URL when the dialog is closed via removeParam.
 * Note: If you want to trigger the opening of a dialog, use useOpenParamDialog instead.
 */
export const useDialogSearchParam = (dialogName) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isParamSet, setParam] = useState(false);
  const previousDialogState = usePrevious(isParamSet);
  const navigationType = useNavigationType();

  useLayoutEffect(() => {
    const query = searchParams.get(dialogName);

    if (query !== null) {
      // This is necessary only for the sheets and the library we use.
      // When immediately opening the sheet on page load, it causes some weird layout issues because it isn't aware of the body size yet
      // May be able to avoid this by setting a different mountPoint for the sheet
      if (navigationType === "POP") {
        setTimeout(() => {
          setParam(true);
        }, 500);
      } else {
        setParam(true);
      }
    }
  }, [navigationType, searchParams, dialogName]);

  // teardown
  useEffect(() => {
    const query = searchParams.get(dialogName);

    // User just closed the sheet/modal, we need to remove the query param
    if (previousDialogState === true && isParamSet === false) {
      searchParams.delete(dialogName);
      setSearchParams(searchParams);
      return;
    }

    if (query === null && isParamSet) {
      setParam(false);
    }
  }, [
    isParamSet,
    previousDialogState,
    searchParams,
    setSearchParams,
    dialogName,
  ]);

  const removeParam = () => setParam(false);

  return [isParamSet, removeParam];
};

// Lets sibling/descendent components expand the sheet/modal
export const useOpenParamDialog = (dialogName) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get(dialogName);

  // additionalParams is an optional tuple
  const openDialog = (additionalParams) => {
    if (query !== null) {
      return;
    }

    // Clear existing searchParams before setting new one
    searchParams.forEach((_, key) => {
      searchParams.delete(key);
    });

    searchParams.set(dialogName, "");

    // Allows us to provide additional info to the dialog
    if (additionalParams) {
      additionalParams.forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    }

    setSearchParams(searchParams);
  };

  return openDialog;
};
