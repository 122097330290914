import SubmissionPreviewContent from "./SubmissionPreviewContent";
import CustomModal from "../../CustomModal";
import styled from "styled-components";
import EditCommentSection from "../../EditCommentSection";
import { CommentInputField } from "../../social";
import { useContext } from "react";
import { CommentInputContext } from "../../../context/CommentInputProvider";

export default function SubmissionModal({
  submission,
  drawerOpen,
  setDrawerOpen,
  winner = false,
  isInVote = false,
  commentsEnabled,
  sortType,
  setSortType,
}) {
  const {
    isSending,
    commentState: { commentOk, editComment },
    startCommentSubmit,
    dispatch,
  } = useContext(CommentInputContext);

  return (
    <CustomModal isOpen={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <SubmissionPreviewContent
        submission={submission}
        winner={winner}
        isInVote={isInVote}
        commentsEnabled={commentsEnabled}
        sortType={sortType}
        setSortType={setSortType}
      />
      {commentsEnabled && (
        <Footer>
          {editComment && (
            <EditCommentSection
              handleCancelEdit={() => {
                dispatch({ type: "CANCEL_EDIT" });
              }}
            />
          )}
          <AddCommentContainer>
            <CommentInputField />
            {/* This will be replaced with enter functionality at a later date */}
            <PostButton
              disabled={!commentOk || isSending}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await startCommentSubmit();
                setSortType("newest");
              }}
            >
              Post
            </PostButton>
          </AddCommentContainer>
        </Footer>
      )}
    </CustomModal>
  );
}

const Footer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  border-top: 0.5px solid var(--border-light);
`;

const AddCommentContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px 10px 0px 10px;
  position: relative;
`;

const PostButton = styled.button`
  width: 70px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 0px !important;
  outline: 0px;
  border-radius: 100px;
  background: ${(props) =>
    props.disabled ? "var(--color-gray-300)" : "var(--blurple)"};
  color: ${(props) =>
    props.disabled ? "var(--disabled-button-text)" : "var(--color-cream-base)"};
  text-align: center;
  font-family: Hoss Sharp;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */

  &::focus-visible {
    outline: 0px;
  }
`;
