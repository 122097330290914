import { getAuth } from "firebase/auth";
import { suspensionsTable } from "../../helpers/tables";
import { queryOne, queryMany, count } from "./all";
import { deleteDoc, doc, getFirestore, onSnapshot } from "firebase/firestore";
import { db } from "../../helpers/firebase";
import { logoutUser } from "../../helpers/authentication";

const table = suspensionsTable;

const suspensionModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  delete: async (id) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    await deleteDoc(doc(getFirestore(), table, id));
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
  listen: async () => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const sub = onSnapshot(doc(db, table, user.uid), (doc) => {
      if (
        doc.data() &&
        doc.data().expiresAt.toDate() > new Date() &&
        window.location.pathname !== "/login"
      ) {
        logoutUser();
      }
    });

    return sub;
  },
};

export default suspensionModel;
