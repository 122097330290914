import React from "react";
import { Modal } from "antd";
import styled from "styled-components";

export default function PreviewEpisodeModal({
  title,
  comicEntries,
  isOpen,
  setOpen,
}) {
  return (
    <Modal
      open={isOpen}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      closable={false}
      className="modal create-storyworld-modal"
      style={{
        top: "10%",
        bottom: "10%",
      }}
      footer={[]}
    >
      <Header>{title}</Header>
      <ComicContainer>
        {comicEntries.map((comic, index) => (
          <ComicImage key={index} src={comic.url} alt="" />
        ))}
      </ComicContainer>
    </Modal>
  );
}

const Header = styled.h3`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 40px;
`;

const ComicImage = styled.img`
  width: 100%;
  height: 100%;
  line-height: 0;
`;

const ComicContainer = styled.div`
  margin-bottom: 46px;
`;
