import { forwardRef, useState } from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

const IdeaCarousel = forwardRef(({ images }, ref) => {
  const [pagesExpanded, setPagesExpanded] = useState(images.map(() => true));

  return (
    <Carousel ref={ref}>
      {images.map((image, index) => (
        <CarouselItem key={image} $isExpanded={pagesExpanded[index]}>
          <img src={image} />
          <ExpandImage
            src={
              pagesExpanded[index] === true
                ? "/icons/shrink.svg"
                : "/icons/expand.svg"
            }
            onClick={() => {
              setPagesExpanded((prev) => {
                const copy = [...prev];
                copy[index] = !copy[index];
                return copy;
              });
            }}
          />
        </CarouselItem>
      ))}
    </Carousel>
  );
});

const Carousel = styled.ul`
  position: relative;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 0;
  height: 100%;
`;

const CarouselItem = styled.li`
  position: relative;
  flex-shrink: 0;
  list-style: none;

  background: black;
  border-radius: 14px;
  scroll-snap-align: start;
  scroll-margin-inline: 5vw;

  margin-inline: 4px;

  width: 90%;
  max-width: 350px;

  img {
    width: 100%;
    height: 100%;

    ${({ $isExpanded }) =>
      $isExpanded
        ? css`
            object-fit: cover;
            border-radius: 14px;
          `
        : css`
            object-fit: contain;
            border-radius: 0px;
          `}
  }
`;

const ExpandImage = styled(ReactSVG)`
  color: white;
  background-color: black;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

IdeaCarousel.displayName = "IdeaCarousel";
export default IdeaCarousel;
