import React from "react";
import { Button, Col, Row } from "antd";
import date from "date-and-time";
import { isTimePassed, removeTags } from "../helpers/general";
import pluralize from "pluralize";
import { datePattern } from "../helpers/constants";
import styled from "styled-components";
import { AdminCreatorPollDisplayStatus } from "../components/vh-panel";
import { useQuery } from "@tanstack/react-query";
import creatorPollVoteModel from "../lib/firebase/creatorPollVoteModel";
import { OpenCountdown } from "../components/general";

const timePattern = date.compile("hh:mm A");
const pollIcon = "/icons/general/poll.svg";

export default function AdminCreatorPollDisplay({
  creatorPoll,
  setIsNewCreatorPoll,
  setSelectedCreatorPoll,
}) {
  const { data: responseCounts = 0 } = useQuery({
    queryKey: ["creator-polls", "vote-counts", creatorPoll.id],
    queryFn: async () => {
      return await creatorPollVoteModel.count([
        "creatorPollId",
        "==",
        creatorPoll.id,
      ]);
    },
  });

  return (
    <Container
      onClick={async () => {
        setSelectedCreatorPoll(creatorPoll);
        setIsNewCreatorPoll(false);
      }}
      key={creatorPoll.id}
    >
      <Row gutter={48}>
        <Col span={11} style={{ display: "inline-block" }}>
          <PollType>
            <PollIcon src={pollIcon} alt="" />
            Poll
          </PollType>
          {creatorPoll.question !== "" && (
            <Question>{removeTags(creatorPoll.question)}</Question>
          )}
          <AdminCreatorPollDisplayStatus creatorPoll={creatorPoll} />
        </Col>
        <Col span={3} style={{ margin: "auto" }}>
          <DisplayDate>
            {date.format(creatorPoll.startTimestamp.toDate(), datePattern)}
          </DisplayDate>
          <DisplayTime>
            {date.format(creatorPoll.startTimestamp.toDate(), timePattern)}
          </DisplayTime>
        </Col>
        <Col span={3} style={{ margin: "auto" }}>
          <DisplayDate>
            {date.format(creatorPoll.endTimestamp.toDate(), datePattern)}
          </DisplayDate>
          <DisplayTime>
            {date.format(creatorPoll.endTimestamp.toDate(), timePattern)}
          </DisplayTime>
        </Col>
        <Col span={7} style={{ paddingLeft: 0 }}>
          <Row style={{ height: "100%" }} gutter={12}>
            <Col span={12} className="poll-column">
              <ResponseText>
                {pluralize("response", responseCounts, true)}
              </ResponseText>
            </Col>
            <Col span={12} className="poll-column">
              <ColumnRightSection>
                {isTimePassed(creatorPoll.startTimestamp.toDate()) &&
                  !isTimePassed(creatorPoll.endTimestamp.toDate()) &&
                  !creatorPoll.isDraft && (
                    <OpenCountdown time={creatorPoll.endTimestamp.toDate()} />
                  )}
                <PollButton>Review poll</PollButton>
              </ColumnRightSection>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled(Button)`
  width: 100%;
  height: auto;
  word-wrap: break-word;
  text-align: left;
  border-radius: 12px;
  background: var(--color-gray-500);
  border: 0;
  margin: auto;
  padding: 28px 24px 24px 24px;
  &:hover {
    background: var(--color-gray-500);
  }
  &:focus {
    background: var(--color-gray-500);
  }
`;

const ColumnRightSection = styled.h2`
  display: contents;
`;

const DisplayDate = styled.h4`
  color: #fcfcfc;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.12px;
  line-height: 18px;
`;

const DisplayTime = styled.h4`
  color: var(--inputFieldText);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.14px;
  line-height: 24px;
`;

const PollButton = styled(Button)`
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  width: auto;
  margin: auto;
  align-items: center;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #6f6c6c;

  &:hover {
    color: var(--hoverColor) !important;
    border-color: var(--hoverColor) !important;
  }

  &:focus {
    /* color: rgba(255, 255, 255, 0.85) !important; */
    border-color: #6f6c6c !important;
  }
`;

const PollIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-bottom: 2px;
`;

const PollType = styled.h2`
  color: var(--inputFieldText);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
`;

const Question = styled.div`
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.15px;
  white-space: break-spaces;
`;

const ResponseText = styled.div`
  color: #fcfcfc;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.12px;
  margin: auto;
`;
