import { styled } from "styled-components";
import indicator from "ordinal/indicator";

const SubmissionRank = ({ rank }) => {
  if (!rank) {
    return null;
  }
  return (
    <Container>
      <RankBadge
        rank={rank}
        background={
          rank > 1 ? "/images/small-banner.svg" : "/images/trophy-banner.svg"
        }
      >
        <Rank rank={rank}>
          {rank}
          <SubmissionRankIndicator>{indicator(rank)}</SubmissionRankIndicator>
        </Rank>
      </RankBadge>
    </Container>
  );
};

const Container = styled.div`
  height: auto;
  position: absolute;
  top: 2px;
  right: 4%;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  color: #ffffff;
`;

const SubmissionRankIndicator = styled.sup`
  font-size: 11px;
  text-transform: uppercase;
`;

const RankBadge = styled.div`
  margin: auto;
  width: ${(props) => (props.rank > 1 ? `42` : `63`)}px;
  height: ${(props) => (props.rank > 1 ? `42` : `82`)}px;
  right: 0;
  flex-shrink: 0;
  font-family: Hoss Sharp;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: ${(props) =>
    props.background ? `url(${props.background})` : null};
`;

const rankStrokeWidth = 1;

const Rank = styled.span`
  margin: auto;
  position: absolute;
  top: ${(props) => (props.rank > 1 ? `2` : `37`)}px;
  right: ${(props) => (props.rank > 1 ? `7` : `20`)}px;
  color: var(--color-blurple-dark);
  font-family: Hoss Sharp;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.5px;
  text-shadow:
    -${rankStrokeWidth}px -${rankStrokeWidth}px 0 var(--color-cream),
    0 -${rankStrokeWidth}px 0 var(--color-cream),
    ${rankStrokeWidth}px -${rankStrokeWidth}px 0 var(--color-cream),
    ${rankStrokeWidth}px 0 0 var(--color-cream),
    ${rankStrokeWidth}px ${rankStrokeWidth}px 0 var(--color-cream),
    0 ${rankStrokeWidth}px 0 var(--color-cream),
    -${rankStrokeWidth}px ${rankStrokeWidth}px 0 var(--color-cream),
    -${rankStrokeWidth}px 0 0 var(--color-cream);
`;

export default SubmissionRank;
