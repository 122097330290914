import { useContext, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import storyworldModel from "../../lib/firebase/storyworldModel";
import Episodes from "../../views/Episodes";
import { Col, Row } from "antd";
import { ReadMore } from "../general";
import { bookmarkKeys, storyworldKeys } from "../../lib/queryKeys";
import { trackEvent } from "../../helpers/mixpanel";
import { getBookmarkByStoryworldId } from "../../helpers/firebase";
import usePublishedEpisodesQuery from "../../lib/usePublishedEpisodesQuery";
import { isCatalogComic } from "../../helpers/general";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { device } from "../../lib/breakpoints";
import { comicUrl } from "../../lib/routes";

export default function StoryworldQuestPageMobile() {
  const { storyId: id } = useParams();

  const { addBackLocation } = useContext(BackLocationsContext);

  const { episodes } = usePublishedEpisodesQuery(id);

  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(id),
    queryFn: () => storyworldModel.getOneById(id),
    select: (data) => ({
      ...data,
      heroImage: data.backgroundImageUrl ?? data.imageUrl,
    }),
  });

  const { data: bookmark } = useQuery({
    queryKey: bookmarkKeys.storyworld(id),
    queryFn: () => {
      return getBookmarkByStoryworldId(id);
    },
    enabled: !!id,
  });

  const ctaEpisodeNumber = useMemo(() => {
    if (!bookmark) {
      return 1;
    }

    const bookmarkedEpisode = (episodes ?? []).find(
      (episode) => episode.id === bookmark.episodeId
    );

    return bookmarkedEpisode ? bookmarkedEpisode.number : 1;
  }, [bookmark, episodes]);

  if (storyworldQuery.status !== "success" || storyworldQuery.data === null)
    return null;

  return (
    <Container>
      <InfoContainer>
        <ReadMore text={storyworldQuery.data.description} threshold={80} />
        <CtaCell>
          <Row gutter={12}>
            <Col
              span={
                !storyworldQuery.data.type ||
                !isCatalogComic(storyworldQuery.data.type)
                  ? 12
                  : 24
              }
            >
              <Link
                to={comicUrl(id, ctaEpisodeNumber.toString())}
                onClick={() => {
                  trackEvent("Clicked Read Episode CTA", {
                    storyworld_id: id,
                    storyworld_name: storyworldQuery.data.title,
                  });
                  addBackLocation(`/stories/${id}`);
                }}
              >
                <ContributeButton>
                  {bookmark
                    ? `
                      Continue Ep
                      ${ctaEpisodeNumber}`
                    : "Read Ep 1"}
                </ContributeButton>
              </Link>
            </Col>
            {!storyworldQuery.data.type ||
              (!isCatalogComic(storyworldQuery.data.type) && (
                <Col span={12}>
                  <Link
                    to={`/quests/${id}`}
                    onClick={() => {
                      trackEvent("Clicked See Quests CTA", {
                        storyworld_id: id,
                        storyworld_name: storyworldQuery.data.title,
                      });
                      addBackLocation(`/stories/${id}`);
                    }}
                  >
                    <SeeQuestsButton>
                      <UnicodeChar>🕮</UnicodeChar> See Quests
                    </SeeQuestsButton>
                  </Link>
                </Col>
              ))}
          </Row>
        </CtaCell>
      </InfoContainer>

      <Episodes
        bookmark={bookmark}
        storyworld={storyworldQuery.data}
        storyworldId={id}
        episodes={episodes}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  @media (${device.mobile}) {
    background-color: #1d232d;
    position: relative;
  }
`;

const ContributeButton = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100px;
  background-color: var(--color-blurple);
  font-family: "Hoss Sharp";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: var(--color-cream);
`;

const CtaCell = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
  z-index: 1;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;

  .read-more-content {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-bottom: 0;
    color: var(--color-gray-50);
    font-family: Poppins;
    line-height: 21.5px !important;
  }
`;

const SeeQuestsButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  font-family: "Hoss Sharp";
  font-size: 16px;
  font-weight: 600;
  color: var(--color-cream);
  border-radius: 100px;
  border: 1.5px solid #777e91;
`;

const UnicodeChar = styled.span`
  font-family: "Symbola";
  margin-bottom: -3px;
  margin-right: 4px;
`;
