import React, { useContext } from "react";
import { Button, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { SelectedStoryworldContext } from "../../context/SelectedStoryworldProvider";
import { siderWidth } from "../../views/VisionHolderPanel";
import { device } from "../../lib/breakpoints";

export default function VisionHolderPanelHeader({
  selectedQuest,
  setSelectedQuest,
  selectedPoll,
  setSelectedPoll,
  selectedEpisode,
  setSelectedEpisode,
  selectedCreatorPoll,
  setSelectedCreatorPoll,
}) {
  const location = useLocation();

  const { selectedStoryworld, setSelectedStoryworld } = useContext(
    SelectedStoryworldContext
  );

  return (
    <Container>
      {selectedStoryworld !== null &&
        selectedQuest === null &&
        selectedPoll === null &&
        selectedEpisode === null &&
        selectedCreatorPoll === null && (
          <HeaderNavPath>
            {location.pathname !== "/quests" && (
              <BackLink
                className="storyworld-path-link"
                onClick={() => {
                  setSelectedStoryworld(null);
                  setSelectedQuest(null);
                  setSelectedCreatorPoll(null);
                }}
              >
                <Link to="/edit">Storyworlds /</Link>
              </BackLink>
            )}
            <Tooltip title={selectedStoryworld.title} placement="bottomLeft">
              <StoryworldTitle>{selectedStoryworld.title}</StoryworldTitle>
            </Tooltip>
          </HeaderNavPath>
        )}

      {selectedStoryworld !== null &&
        (selectedQuest !== null ||
          selectedPoll !== null ||
          selectedEpisode !== null ||
          selectedCreatorPoll !== null) && (
          <HeaderNavPathLeft>
            <BackButton
              onClick={() => {
                setSelectedQuest(null);
                setSelectedPoll(null);
                setSelectedEpisode(null);
                setSelectedCreatorPoll(null);
              }}
            >
              <LeftOutlinedIcon />
              <Back>BACK</Back>
            </BackButton>
            <h2>{selectedStoryworld.title}</h2>
          </HeaderNavPathLeft>
        )}
    </Container>
  );
}

const Container = styled.div`
  background: transparent;
  min-width: 50%;
  width: fit-content;
  height: 80px;
  box-shadow: inset 1px 0px 0px var(--color-black);
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
  overflow: hidden;

  @media (${device.mobile}) {
    display: none;
  }
`;

const Back = styled.span`
  margin: 0px 0px 10px 2px !important;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: bold;
`;

const BackButton = styled(Button)`
  color: #999aa8;
  border: 0;
  margin-bottom: 18px !important;
  margin-right: 10px;
  background: transparent !important;

  &:hover {
    color: var(--hoverColor) !important;
  }
`;

const BackLink = styled(Button)`
  border: none;
  padding: 0px;
  margin-right: 8px !important;
  color: var(--darkTextColor);
  background: transparent !important;

  &:hover {
    color: var(--hoverColor) !important;
  }
`;

const HeaderNavPath = styled.div`
  text-align: left;
  position: absolute;
  left: ${siderWidth + 30}px;
  top: 30px;
  display: flex;
  vertical-align: bottom !important;
  color: #999aa8;
  border: 0;
  margin-right: 10px;
  background: transparent !important;

  h2 {
    margin-top: -2px;
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  &:hover {
    color: var(--hoverColor) !important;
  }
`;

const HeaderNavPathLeft = styled.div`
  text-align: left;
  position: absolute;
  left: 24px;
  top: 30px;
  display: flex;
  vertical-align: bottom !important;
  color: #999aa8;
  border: 0;
  margin-bottom: 18px !important;
  margin-right: 10px;
  background: transparent !important;

  h2 {
    margin-top: -2px;
  }

  &:hover {
    color: var(--hoverColor) !important;
  }
`;

const LeftOutlinedIcon = styled(LeftOutlined)`
  margin: 0px 0px 10px 2px !important;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 2px !important;
`;

const StoryworldTitle = styled.h2`
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    width: 200px;
    font-size: 14px;
    padding-top: 7px;
  }
`;
