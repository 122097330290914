import mixpanel from "mixpanel-browser";
import alertConfigModel from "./firebase/alertConfigModel";

const finishAccountSetup = (user, username) => {
  // Create their profile in mixpanel
  mixpanel.people.set({
    $email: user.email,
    $name: username,
  });

  // Add them to mailchimp
  fetch(`${process.env.REACT_APP_API_URL}/mail/register`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify({
      email: user?.email.toLocaleLowerCase(),
      username: username,
    }),
  });

  alertConfigModel.create(user);
};

export default finishAccountSetup;
