// TODO: find alternative to async/await
/* eslint-disable no-async-promise-executor */
import {
  bookmarksTable,
  constantsTable,
  episodesTable,
  pollTable,
  questTable,
  quotasTable,
  storyworldTable,
  submissionsTable,
  subscriptionsTable,
  userTable,
  votesTable,
  allowListTable,
  createdAccountsTable,
} from "./tables";
import {
  collection,
  deleteDoc,
  getFirestore,
  query,
  setDoc,
  addDoc,
  where,
  updateDoc,
  arrayUnion,
  orderBy,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { doc, getDoc } from "firebase/firestore";
import { getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getRandomProfilePic } from "./authentication";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { getWinningSubmissionsFromList } from "./general";
import { leonardoModels } from "./genAI";
import userModel from "../lib/firebase/userModel";
import submissionModel from "../lib/firebase/submissionModel";
import voteModel from "../lib/firebase/voteModel";
import { prodFirebaseConfig, sandboxFirebaseConfig } from "./firebaseConfigs";

let config;

switch (process.env.REACT_APP_DEPLOYMENT) {
  case "prod":
    config = prodFirebaseConfig;
    break;
  default:
    config = sandboxFirebaseConfig;
}

const app = initializeApp(config);

export const db = getFirestore(app);
// connectFirestoreEmulator(db, "localhost", 8080);

const storage = getStorage();

export function getResultsFromSnapshot(querySnapshot) {
  const results = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    data.id = doc.id;
    results.push(data);
  });
  return results;
}

export function generateRandomId() {
  return Math.floor(Math.random() * 999999999999);
}

export function timestampToDate(timestamp) {
  return new Date(timestamp.seconds * 1000);
}

export async function getUserInfoCountByEmail(emailAddress) {
  const q = query(
    collection(db, userTable),
    where("email", "==", emailAddress.toLowerCase())
  );
  const userInfoCount = await getCountFromServer(q);
  const count = userInfoCount.data().count;
  return count;
}

export async function deleteUser() {
  const currentUser = getAuth().currentUser;

  return new Promise(async (resolve, reject) => {
    if (!currentUser) {
      reject("There is no logged in user");
    } else {
      const userId = currentUser.uid;
      const docRef = doc(db, userTable, userId);
      const userData = await getDoc(docRef);

      await updateDoc(docRef, {
        email: "",
        username: "deleted",
        deleted: true,
        pfp: getRandomProfilePic(),
      });

      currentUser
        .delete()
        .then(() => {
          resolve(true);
        })
        .catch(async (error) => {
          console.error(error);

          // restore user data
          await updateDoc(docRef, userData.data());
          reject(error);
        });
    }
  });
}

export async function getCompletedPolls() {
  const q = query(
    collection(db, pollTable),
    where("endTimestamp", "<", new Date()),
    where("confirmed", "==", true),
    where("isDraft", "==", false),
    orderBy("endTimestamp", "desc")
  );
  const querySnapshot = await getDocs(q);
  const results = getResultsFromSnapshot(querySnapshot);
  return results;
}

export async function deleteUserPoll(id) {
  await deleteDoc(doc(db, pollTable, id));
}

export async function deleteUserQuest(id) {
  await deleteDoc(doc(db, questTable, id));
}

export async function createPoll({
  title,
  startTimestamp,
  endTimestamp,
  votesPerUser,
  storyworld,
  questInfo,
  isDraft,
  confirmed,
}) {
  const currentUser = getAuth().currentUser;

  if (title === "") {
    title = "Storyworld Poll";
  }
  return new Promise(async (resolve, reject) => {
    if (await doesQuestPollExist(questInfo.id)) {
      reject("Quest already has an existing Poll");
    } else {
      const docRef = await addDoc(collection(db, pollTable), {
        title,
        creator: currentUser.uid,
        startTimestamp,
        endTimestamp,
        votesPerUser,
        storyworld,
        questId: questInfo.id,
        createdAt: new Date(),
        lastUpdated: new Date(),
        isDraft,
        confirmed,
      });
      if (docRef.id !== undefined && docRef.id !== null) {
        await updateQuest({
          type: questInfo.type,
          questId: questInfo.id,
          title: questInfo.title,
          startTimestamp: questInfo.startTimestamp,
          endTimestamp: questInfo.endTimestamp,
          description: questInfo.description,
          objectiveDescription: questInfo.objectiveDescription,
          textSubmissionLimit: questInfo.textSubmissionLimit,
          isDraft: questInfo.isDraft,
          creator: questInfo.creator,
          createdAt: questInfo.createdAt,
          submissionRequirements: questInfo.submissionRequirements,
          suggestedSearchTerms: questInfo.suggestedSearchTerms,
          videoUrl: questInfo.videoUrl,
          thumbnailUrl: questInfo.thumbnailUrl ? questInfo.thumbnailUrl : "",
          storyworld: questInfo.storyworld,
          moreInfo: questInfo.moreInfo,
        });
        resolve(true);
      } else {
        reject(false);
      }
    }
  });
}

export async function setPollConfirmed({ poll, confirmedSubmissionIds }) {
  for (let i = 0; i < confirmedSubmissionIds.length; i += 1) {
    await approveSubmission({
      submissionId: confirmedSubmissionIds[i],
    });
  }
  await updateDoc(doc(db, pollTable, poll.id), {
    confirmed: true,
    lastUpdated: new Date(),
    confirmedSubmissionIds,
  });
  return true;
}

export async function approveSubmission({ submissionId }) {
  await updateDoc(doc(db, submissionsTable, submissionId), {
    rejected: false,
    lastUpdated: new Date(),
  });
  return true;
}

export async function rejectSubmission({ submission }) {
  await updateDoc(doc(db, submissionsTable, submission.id), {
    rejected: true,
    lastUpdated: new Date(),
  });
  return true;
}

export async function getUserStoryworlds() {
  const currentUser = getAuth().currentUser;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, storyworldTable),
        where("creators", "array-contains", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const results = getResultsFromSnapshot(querySnapshot);
      /*
      const finalResults = results.sort(function (a, b) {
        return b.lastUpdated.toDate() - a.lastUpdated.toDate();
      });
      console.log(finalResults);
      resolve(finalResults);
      */
      resolve(results);
    }
  });
}

export async function getStoryworldById(id) {
  if (!id || id === undefined) return;
  const docRef = doc(db, storyworldTable, id);
  const docSnap = await getDoc(docRef);
  const docData = docSnap.data();
  docData.id = id;
  return docData;
}

export async function getQuestById(id) {
  if (!id) return;
  const docRef = doc(db, questTable, id);
  const docSnap = await getDoc(docRef);
  const docData = docSnap.data();
  docData.id = id;
  return docData;
}

export async function doesQuestPollExist(id) {
  if (!id) return;
  const q = query(collection(db, pollTable), where("questId", "==", id));
  const pollCountQuery = await getCountFromServer(q);
  if (pollCountQuery.data().count > 0) return true;
  else return false;
}

export async function increaseStoryworldViewsByOne({
  storyworldId,
  currentViews,
}) {
  return new Promise(async () => {
    const docRef = doc(db, storyworldTable, storyworldId);
    if (docRef) {
      await updateDoc(docRef, {
        views: currentViews + 1,
      });
    }
  });
}

export async function increaseEpisodeViewsByOne({ episodeId, currentViews }) {
  return new Promise(async () => {
    const docRef = doc(db, episodesTable, episodeId);
    if (docRef) {
      await updateDoc(docRef, {
        views: currentViews + 1,
      });
    }
  });
}

export async function createStoryworld({
  title,
  imageUrl,
  backgroundImageUrl,
  description = "",
  modelId = leonardoModels[0].value,
}) {
  const currentUser = getAuth().currentUser;
  if (title === "") {
    title = "New Storyworld";
  }
  return new Promise(async (resolve, reject) => {
    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, storyworldTable), {
      title,
      imageUrl,
      description,
      backgroundImageUrl,
      views: 0,
      creators: [currentUser.uid],
      createdAt: new Date(),
      lastUpdated: new Date(),
      modelId,
    });
    if (docRef.id !== undefined && docRef.id !== null) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

export async function createQuest({
  type,
  title,
  startTimestamp,
  endTimestamp,
  description,
  objectiveDescription,
  textSubmissionLimit,
  isDraft,
  submissionRequirements,
  suggestedSearchTerms = [],
  videoUrl,
  thumbnailUrl,
  storyworld,
  moreInfo,
  ongoing = false,
}) {
  const currentUser = getAuth().currentUser;
  if (title === "") {
    title = "New Quest";
  }
  if (videoUrl === undefined) {
    videoUrl = "";
  }
  if (thumbnailUrl === undefined) {
    thumbnailUrl = "";
  }

  return new Promise(async (resolve, reject) => {
    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, questTable), {
      type,
      title,
      creator: currentUser.uid,
      startTimestamp,
      endTimestamp,
      description,
      objectiveDescription,
      textSubmissionLimit,
      isDraft,
      createdAt: new Date(),
      lastUpdated: new Date(),
      submissionRequirements,
      suggestedSearchTerms,
      videoUrl,
      thumbnailUrl,
      storyworld,
      moreInfo,
      ongoing,
      // revealMode,
      // endTime: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
    });
    if (docRef.id !== undefined && docRef.id !== null) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

export async function updateQuest({
  type,
  questId,
  title,
  startTimestamp,
  endTimestamp,
  description,
  objectiveDescription,
  textSubmissionLimit,
  isDraft,
  createdAt,
  submissionRequirements,
  suggestedSearchTerms = [],
  videoUrl,
  thumbnailUrl,
  storyworld,
  moreInfo,
  ongoing = false,
}) {
  const currentUser = getAuth().currentUser;
  if (title === "") {
    title = "Newly Updated Quest";
  }
  if (videoUrl === undefined) {
    videoUrl = "";
  }
  if (thumbnailUrl === undefined) {
    thumbnailUrl = "";
  }
  if (moreInfo === undefined) {
    moreInfo = "";
  }
  if (textSubmissionLimit === undefined) textSubmissionLimit = null;
  await setDoc(doc(db, questTable, questId), {
    type,
    title,
    creator: currentUser.uid,
    startTimestamp,
    endTimestamp,
    description,
    objectiveDescription,
    textSubmissionLimit,
    isDraft,
    createdAt,
    lastUpdated: new Date(),
    submissionRequirements,
    suggestedSearchTerms,
    videoUrl,
    thumbnailUrl,
    storyworld,
    moreInfo,
    ongoing,
  });
  return true;
}

export async function updatePoll({
  pollId,
  title,
  startTimestamp,
  endTimestamp,
  isDraft,
  votesPerUser,
  createdAt,
  storyworld,
  questInfo,
  confirmed,
}) {
  const currentUser = getAuth().currentUser;
  if (title === "") {
    title = "Newly Updated Poll";
  }
  await setDoc(doc(db, pollTable, pollId), {
    title,
    questId: questInfo.id,
    startTimestamp,
    endTimestamp,
    votesPerUser,
    creator: currentUser.uid,
    createdAt,
    lastUpdated: new Date(),
    storyworld,
    isDraft,
    confirmed,
  });
  await updateQuest({
    type: questInfo.type,
    questId: questInfo.id,
    title: questInfo.title,
    startTimestamp: questInfo.startTimestamp,
    endTimestamp: questInfo.endTimestamp,
    description: questInfo.description,
    objectiveDescription: questInfo.objectiveDescription,
    textSubmissionLimit: questInfo.textSubmissionLimit,
    isDraft: questInfo.isDraft,
    creator: questInfo.creator,
    createdAt: questInfo.createdAt,
    submissionRequirements: questInfo.submissionRequirements,
    suggestedSearchTerms: questInfo.suggestedSearchTerms,
    videoUrl: questInfo.videoUrl,
    thumbnailUrl: questInfo.thumbnailUrl ? questInfo.thumbnailUrl : "",
    storyworld: questInfo.storyworld,
    moreInfo: questInfo.moreInfo,
    ongoing: questInfo.ongoing ?? false,
  });
  return true;
}

export async function getStoryworldPolls(storyworldId) {
  const q = query(
    collection(db, pollTable),
    where("storyworld", "==", storyworldId)
  );
  const querySnapshot = await getDocs(q);
  const results = getResultsFromSnapshot(querySnapshot);
  return results;
}

export async function getStoryworldQuests(storyworldId) {
  const q = query(
    collection(db, questTable),
    where("storyworld", "==", storyworldId)
  );
  const querySnapshot = await getDocs(q);
  const results = getResultsFromSnapshot(querySnapshot);

  const finalResults = results.sort(function (a, b) {
    return b.lastUpdated.toDate() - a.lastUpdated.toDate();
  });
  return finalResults;
}

export async function uploadImage(folder, image) {
  return new Promise(async (resolve, reject) => {
    const storageRef = ref(
      storage,
      `${folder}/${new Date()} -- ${generateRandomId()}`
    );
    uploadBytes(storageRef, image).then((snapshot) => {
      if (snapshot) {
        const imageUrl = getDownloadURL(storageRef);
        resolve(imageUrl);
      } else reject("error uploading image");
    });
  });
}

export async function getQuestSubmissions(quest) {
  const q = query(
    collection(db, submissionsTable),
    where("questId", "==", quest.id)
  );
  const querySnapshot = await getDocs(q);
  const results = await getResultsFromSnapshot(querySnapshot);
  return results;
}

export async function getConfirmedQuestSubmissions(quest) {
  const results = [];
  const questSubmissionsPromise = await getQuestSubmissions(quest);
  for (const submission of questSubmissionsPromise) {
    if (!submission.rejected) results.push(submission);
  }
  return results;
}

export async function getWinningQuestSubmissions(quest) {
  const results = [];
  const questSubmissionsPromise = await getConfirmedQuestSubmissions(quest);
  for (const sub of questSubmissionsPromise) {
    sub.voteCount = await getVoteCountForSubmission(sub);
    results.push(sub);
  }
  return getWinningSubmissionsFromList(results);
}

export async function getVoteCountForSubmission(submission) {
  const q = query(
    collection(db, votesTable),
    where("voteIds", "array-contains", submission.id)
  );
  const querySnapshot = await getCountFromServer(q);
  return querySnapshot.data().count;
}

export async function getQuestVoters(poll) {
  const q = query(collection(db, votesTable), where("pollId", "==", poll.id));
  const querySnapshot = await getDocs(q);
  const results = await getResultsFromSnapshot(querySnapshot);
  return results;
}

export async function getBookmarkByStoryworldId(storyworldId) {
  const currentUser = getAuth().currentUser;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(
        collection(db, bookmarksTable),
        where("storyworldId", "==", storyworldId),
        where("creator", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);

      const finalResults = results.sort(function (a, b) {
        return b.createdAt.toDate() - a.createdAt.toDate();
      });
      resolve(finalResults.length > 0 ? finalResults[0] : null);
    }
  });
}

export async function getPublishedEpisodesByStoryworldId(storyworldId) {
  const q = query(
    collection(db, episodesTable),
    where("storyworldId", "==", storyworldId),
    where("publishedAt", "<=", new Date())
  );
  const querySnapshot = await getDocs(q);
  const results = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    data.id = doc.id;
    if (!data.isDraft) results.push(data);
  });
  return results;
}

export async function getEpisodesByStoryworldId(storyworldId) {
  const q = query(
    collection(db, episodesTable),
    where("storyworldId", "==", storyworldId)
  );
  const querySnapshot = await getDocs(q);
  const results = await getResultsFromSnapshot(querySnapshot);

  const finalResults = results.sort(function (a, b) {
    return a.publishedAt.toDate() - b.publishedAt.toDate();
  });
  for (let i = 0; i < finalResults.length; i++) {
    finalResults[i].number = i + 1;
  }
  return finalResults;
}

export async function getStoryworldSubscriptionForCurrentUser(storyworldId) {
  const currentUser = getAuth().currentUser;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const results = [];
      const q = query(
        collection(db, subscriptionsTable),
        where("storyworldId", "==", storyworldId),
        where("creator", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        results.push(data);
      });
      resolve(results);
    }
  });
}

export async function subscribeToStoryworld(storyworldId) {
  const currentUser = getAuth().currentUser;
  return new Promise(async (resolve, reject) => {
    await addDoc(collection(db, subscriptionsTable), {
      creator: currentUser.uid,
      storyworldId,
      createdAt: new Date(),
    }).then(function (docRef) {
      resolve(docRef.id);
    });
    reject("Error subscribing to storyworld");
  });
}

export async function unsubscribeFromStoryworld(subscriptinId) {
  await deleteDoc(doc(db, subscriptionsTable, subscriptinId));
}

export async function addToReadingList(episodeId, storyworldId) {
  const currentUser = getAuth().currentUser;
  if (!currentUser) return null;
  const userRef = doc(db, userTable, currentUser.uid);
  const userSnapshot = await getDoc(userRef);
  const userData = userSnapshot.data();

  const userStories = userData?.readStories ?? [];

  const storyIndex = userStories.findIndex((story) => story === storyworldId);
  const readStories = [...userStories];

  if (storyIndex !== -1) {
    readStories.splice(storyIndex, 1);
  }

  readStories.unshift(storyworldId);

  const readingListRef = doc(db, userTable, currentUser.uid);
  const readingListUnion = await updateDoc(readingListRef, {
    readingList: arrayUnion(episodeId),
    readStories,
  });
  if (readingListUnion) return readingListUnion;
}

export async function increaseGenerationQuota(questId) {
  const currentUser = getAuth().currentUser;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      let quotaData = await getUserGenerationQuotaByQuestId(questId);
      const genLimit = await getGeneratorLimit();
      if (
        (quotaData && quotaData.amount && quotaData.amount < genLimit) ||
        !quotaData
      ) {
        if (!quotaData) {
          const docRef = await addDoc(collection(db, quotasTable), {
            questId,
            userId: currentUser.uid,
          });
          const docSnap = await getDoc(docRef);
          quotaData = docSnap.data();
        }
        await increaseUserGenerationQuotaByOne(quotaData);
        resolve(true);
      }
    }
    resolve(false);
  });
}

export async function increaseUserGenerationQuotaByOne(quotaData) {
  const currentUser = getAuth().currentUser;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else if (!quotaData) {
      reject("There is no quota data");
    } else {
      const q = query(
        collection(db, quotasTable),
        where("questId", "==", quotaData.questId),
        where("userId", "==", currentUser.uid),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const results = getResultsFromSnapshot(querySnapshot);
      const docData = results[0];
      await updateDoc(doc(db, quotasTable, docData.id), {
        amount: docData.amount ? docData.amount + 1 : 1,
      });
      resolve(true);
    }
  });
}

export async function getUserGenerationQuotaByQuestId(questId) {
  const currentUser = getAuth().currentUser;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const userId = currentUser.uid;
      const q = query(
        collection(db, quotasTable),
        where("questId", "==", questId),
        where("userId", "==", userId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);
      if (results.length > 0) resolve(results[0]);
      else resolve(null);
    }
  });
}

async function getGeneratorLimit() {
  const currentUser = getAuth().currentUser;
  return new Promise(async (resolve, reject) => {
    if (currentUser === undefined) {
      reject("There is no logged in user");
    } else {
      const q = query(collection(db, constantsTable), limit(1));
      const querySnapshot = await getDocs(q);
      const results = await getResultsFromSnapshot(querySnapshot);
      if (results.length > 0) resolve(results[0].generatorLimit);
      else resolve(0);
    }
  });
}

export async function isEmailOnAllowList(emailAddress) {
  const q = query(
    collection(db, allowListTable),
    where("email", "==", emailAddress.toLowerCase()),
    limit(1)
  );
  const countQuery = await getCountFromServer(q);
  const count = countQuery.data().count;
  return count > 0;
}

export async function getAllowListInfoByEmail(emailAddress) {
  const q = query(
    collection(db, allowListTable),
    where("email", "==", emailAddress.toLowerCase()),
    limit(1)
  );
  const querySnapshot = await getDocs(q);
  const results = await getResultsFromSnapshot(querySnapshot);
  return results[0];
}

export async function setEnteredEmailFirstTime(userInfo) {
  const docRef = doc(db, allowListTable, userInfo.id);
  await updateDoc(docRef, {
    enteredEmailFirstTime: true,
  });
  return true;
}

export async function usernameExists(desiredUsername) {
  const q = query(
    collection(db, userTable),
    where("usernameLowercase", "==", desiredUsername.toLowerCase()),
    limit(1)
  );
  const countQuery = await getCountFromServer(q);
  const count = countQuery.data().count;
  return count > 0;
}

export async function addUsedAccountCreationEmail(email) {
  return new Promise(async (resolve, reject) => {
    const docRef = await addDoc(collection(db, createdAccountsTable), {
      email: email.toLocaleLowerCase(),
    });
    if (docRef.id !== undefined && docRef.id !== null) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

export async function getEmailsUsedForAccountCreation() {
  const q = query(collection(db, createdAccountsTable));
  const querySnapshot = await getDocs(q);
  const results = await getResultsFromSnapshot(querySnapshot);
  const finalResults = [];
  results.forEach(async (res) => {
    if (res.email) {
      const userInfo = await userModel.getMany(
        ["limit", 1],
        ["email", "==", res.email]
      );
      finalResults.push({ id: userInfo[0].id, email: res.email });
    }
  });
  return finalResults;
}

export async function getUserTrackingData() {
  const finalData = [];
  const users = await userModel.getMany();
  users.forEach(async (user) => {
    const questSubmissions = await submissionModel.getManyByUserId(user.id);
    const pollVotes = await voteModel.getManyByUserId(user.id);
    const userData = {
      id: user.id,
      email: user.email,
      username: user.username,
      questSubmissions: questSubmissions.length,
      pollVotes: pollVotes.length,
    };
    finalData.push(userData);
  });
  return finalData;
}
