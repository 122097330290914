import React, { useEffect, useState } from "react";
import styled from "styled-components";

const defaultReadMoreThreshold = 50;

const Content = ({ text, threshold, showEdited, showLess }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleExpansion = () => {
    // If collapsing is disabled and we have already expanded the text
    if (!showLess && !isReadMore) {
      return;
    }

    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    if (text && text.length < threshold) setIsReadMore(false);
  }, [text, threshold]);

  return (
    <TextContainer onClick={toggleExpansion} className="read-more-content">
      {isReadMore && text ? `${text.slice(0, threshold)} ...` : text}
      {((text && text.length < threshold) || !isReadMore) && showEdited && (
        <EditedIndicator>(edited)</EditedIndicator>
      )}
      {text && text.length > threshold && (
        <MoreText>
          {isReadMore ? " + More" : showLess ? " - Less" : ""}
        </MoreText>
      )}
    </TextContainer>
  );
};

const ReadMore = ({
  text,
  threshold = defaultReadMoreThreshold,
  showEdited = false,
  showLess = true,
}) => {
  return (
    <Container
      onScroll={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Content
        text={text}
        threshold={threshold}
        showEdited={showEdited}
        showLess={showLess}
      />
    </Container>
  );
};

export default ReadMore;

const Container = styled.div`
  overflow-y: scroll;
  max-height: fit-content;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const TextContainer = styled.p`
  color: var(--color-gray-50);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.5px; /* 189.286% */
  letter-spacing: -0.14px;
`;

const MoreText = styled.span`
  color: var(--color-pink);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.5px;
  letter-spacing: -0.14px;
  white-space: nowrap;
`;

const EditedIndicator = styled.span`
  color: var(--color-gray-200);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
`;
