import { styled } from "styled-components";
import { Link } from "react-router-dom";
import {
  ProfileSubmission,
  ProfileSubmissionWrapper,
} from "../components/profile/ProfileSubmission";
import { device } from "../lib/breakpoints";
import { Spacer } from "../styles/CommonElements";
import Hidden from "../components/Hidden";

function SelfEmptyState() {
  return (
    <EmptyWrapper>
      <Hidden mobile>
        <Spacer $marginTop="12px" />
      </Hidden>
      <Image src="/images/profile/sword-hollow-gray.svg" alt="" />
      <EmptyTitle>No ideas created yet</EmptyTitle>
      <Spacer $marginTop="0px" />
      <SelfEmptyText>
        <StyledLink to="/quests">Go on a quest</StyledLink> and make something
        awesome!
      </SelfEmptyText>
    </EmptyWrapper>
  );
}

function UserEmptyState() {
  return (
    <EmptyWrapper>
      <Carrot src="/icons/social/carrot-gray.svg" alt="" />
      <EmptyText>They haven't made anything yet</EmptyText>
    </EmptyWrapper>
  );
}

const ProfileArtTab = ({ submissions, isLoading, isSelf }) => {
  if (isLoading) {
    return Array.from({ length: 8 }).map((_, i) => (
      <ProfileSubmissionWrapper key={i} className="animate-pulse" />
    ));
  }

  return (
    <>
      <Container>
        {submissions.map((submission) => (
          <ProfileSubmission key={submission.id} submission={submission} />
        ))}

        {submissions.length === 0 && (
          <div style={{ gridColumn: "1 / -1", textAlign: "center" }}>
            <EmptyText>
              {isSelf ? <SelfEmptyState /> : <UserEmptyState />}
            </EmptyText>
          </div>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  padding-bottom: 20px;
`;

const Carrot = styled.img`
  width: auto;
  height: 69px;
`;

const EmptyText = styled.div`
  margin-top: 8px;
  color: var(--color-gray-200);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
`;

const EmptyTitle = styled.div`
  color: var(--color-cream-dark);
  text-align: center;
  /* Medium Heading */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 8px;

  @media ${device.tablet}, ${device.desktop} {
    margin-top: 12px;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;

  @media ${device.tablet}, ${device.desktop} {
    margin-top: 38px;
  }
`;

const Image = styled.img`
  width: auto;
  height: 90px;
`;

const SelfEmptyText = styled.p`
  color: var(--color-gray-50);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  max-width: 340px;
  margin: auto;
`;

const StyledLink = styled(Link)`
  color: #f5a2ff;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: #f385ff;
    text-decoration: underline;
  }
`;

export default ProfileArtTab;
