import React from "react";
import { isTimePassed } from "../../helpers/general";
import styled from "styled-components";
import { LiveCountdown } from "../../styles/CommonElements";

const checkmark = "/icons/checkmark.svg";
const draftIcon = "/icons/calendar.svg";

export default function AdminCreatorPollDisplayStatus({ creatorPoll }) {
  return (
    <>
      {isTimePassed(creatorPoll.endTimestamp.toDate()) ? (
        <StatusCompleted>
          <Icon src={checkmark} alt="" /> Completed
        </StatusCompleted>
      ) : creatorPoll.isDraft ? (
        <StatusDraft>
          <Icon src={draftIcon} alt="" /> Draft Saved
        </StatusDraft>
      ) : (
        <StatusLive
          $moreMargin={creatorPoll.startTimestamp.toDate() > new Date()}
        >
          Live
          {creatorPoll.startTimestamp.toDate() > new Date() && (
            <span>
              {" "}
              in <LiveCountdown value={creatorPoll.startTimestamp.toDate()} />
            </span>
          )}
        </StatusLive>
      )}
    </>
  );
}

const StatusCompleted = styled.h5`
  color: #71c274;
  font-size: 10px;
  line-height: 24px;
  margin-top: 4px;
`;

const Icon = styled.img`
  margin-right: 4px;
`;

const StatusDraft = styled.h5`
  color: white;
  font-size: 10px;
  line-height: 24px;
  margin-top: 4px;
  margin-left: 0;
`;

const StatusLive = styled.h5`
  color: #f5a2ff;
  font-size: 10px;
  line-height: 24px;
  margin-top: ${(props) => (props.$moreMargin ? "-4px" : "4px")};

  span {
    color: #f5a2ff !important;
    font-size: 10px !important;
  }
`;
