import { useLocation } from "react-router-dom";
import { SESSION_STORAGE } from "../helpers/constants";
import {
  DIALOG_NAME,
  useDialogSearchParam,
} from "../helpers/useDialogSearchParam";
import { useBreakpoints } from "../lib/breakpoints";
import AuthSheet from "./AuthSheet";
import LoginModal from "./login/LoginModal";

const ContentGate = () => {
  const [isParamSet, removeParam] = useDialogSearchParam(DIALOG_NAME.login);
  const { isMobile } = useBreakpoints();
  const location = useLocation();

  const saveUrlToSessionStorage = () => {
    sessionStorage.setItem(SESSION_STORAGE.redirectUrl, location.pathname);
  };

  const onClose = () => {
    removeParam();
  };

  return (
    <>
      <AuthSheet
        isOpen={isParamSet && isMobile}
        onNavigation={saveUrlToSessionStorage}
        onClose={removeParam}
      />
      <LoginModal isOpen={isParamSet && !isMobile} onClose={onClose} />
    </>
  );
};

export default ContentGate;
