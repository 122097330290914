// ----- Components Import -----
import { styled } from "styled-components";
import { Container, Spacer } from "../../styles/CommonElements";

export default function ServiceCard({ feature }) {
  return (
    <MasterContainer>
      <Container>
        <Icon src={feature.icon} alt="" />
        <Spacer $marginTop="1.5rem" />
        <Header>{feature.title}</Header>
        <Subheader>{feature.desc}</Subheader>
      </Container>
    </MasterContainer>
  );
}

// ----- Styles -----
const MasterContainer = styled.section`
  padding: 2rem 0;
  width: 100%;
  min-height: 350px;
  flex-shrink: 0;
  background: transparent;
  min-height: 400px;

  @media (min-width: 106.25rem) {
    padding: 3rem 0;
  }

  @media (max-width: 35.9375rem) {
    padding-top: 2rem;

    .service-card-container {
      max-width: 100% !important;
    }
  }

  @media (max-width: 24.375rem) {
    padding-top: 2rem;
  }
`;

const Icon = styled.img`
  margin: auto;
  height: 250px;
  width: 100%;
  object-fit: cover;
  object-position: top center;

  @media (max-width: 47.9375rem) {
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const Header = styled.div`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;

  @media (max-width: 47.9375rem) {
    justify-content: center;
  }
`;

const Subheader = styled.div`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;

  @media (max-width: 47.9375rem) {
    justify-content: center;
  }

  img {
    max-width: 5.62rem;
    display: inline-block;
    margin-right: -1.5rem;
    position: relative;
    z-index: 10;
  }

  a {
    background-color: #796ec4;
  }
`;
