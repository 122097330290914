import { useState, useEffect, useContext, useMemo, useReducer } from "react";
import styled from "styled-components";
import { Col, Row, notification, message } from "antd";
import pluralize from "pluralize";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { commentKeys, userKeys } from "../lib/queryKeys";
import userModel from "../lib/firebase/userModel";
import { commentLengthMax, commentLengthThreshold } from "./constants";
import { containsProfanity } from "./general";
import { trackEvent } from "./mixpanel";
import commentModel from "../lib/firebase/commentModel";
import AlertSourceContext from "../context/AlertSourceContext";
import { AuthContext } from "../context/AuthProvider";

const validateComment = (comment) => {
  if (!comment) {
    return false;
  }

  if (
    comment.length >= commentLengthThreshold &&
    comment.length <= commentLengthMax &&
    !containsProfanity(comment)
  ) {
    return true;
  }
  return false;
};

const commentReducer = (state, action) => {
  switch (action.type) {
    case "SET_COMMENT":
      return {
        ...state,
        comment: action.comment,
        commentOk: validateComment(action.comment),
      };
    case "SET_EDIT_COMMENT":
      return {
        ...state,
        editComment: action.editComment,
        comment: action.editComment?.comment,
        commentOk: true,
        replyTo: null,
      };
    case "SET_REPLY_TO":
      return {
        ...state,
        replyTo: action.replyTo,
      };
    case "CANCEL_EDIT":
      return {
        ...state,
        editComment: null,
        comment: "",
      };
    case "CANCEL_REPLY":
      return {
        ...state,
        replyTo: null,
      };
    case "RESET":
      return {
        comment: "",
        commentOk: false,
        editComment: null,
        replyTo: null,
      };
  }
};

// Copied over from SubmissionCommentSection without refactoring
const useComment = (trackingData = null) => {
  const [deletedComments, setDeletedComments] = useState([]);

  // These four pieces of state are all related. Rather than use a bunch of effects and state calls, we can use a reducer to manage them all together.
  const [commentState, dispatch] = useReducer(commentReducer, {
    comment: "",
    commentOk: false,
    editComment: null,
    replyTo: null,
  });

  const { comment, commentOk, editComment, replyTo } = commentState;

  const { sourceId, sourceType } = useContext(AlertSourceContext);
  const { user, requireLogin } = useContext(AuthContext);

  const [api, notificationContextHolder] = notification.useNotification();

  const queryClient = useQueryClient();

  const { data: userToReplyTo } = useQuery({
    queryKey: userKeys.user(replyTo?.creator),
    queryFn: () => {
      return userModel.getOneById(replyTo.creator);
    },
    enabled: !!replyTo?.creator,
  });

  const { mutate: createComment, isPending: isCreating } = useMutation({
    mutationFn: (variables) => {
      return commentModel.create(variables);
    },
    onSuccess: () => {
      message.success("Comment submitted. Thank you!");
      dispatch({ type: "RESET" });
      queryClient.invalidateQueries({
        queryKey: commentKeys.subject(sourceId),
      });
    },
    onError: () => {
      message.error("Comment was not submitted. Please try again.");
    },
  });

  const { mutate: editCommentMutation, isPending: isEditing } = useMutation({
    mutationFn: (variables) => {
      return commentModel.edit(variables);
    },
    onSuccess: () => {
      message.success("Edited comment successfully");
      dispatch({ type: "RESET" });
      queryClient.invalidateQueries({
        queryKey: commentKeys.subject(sourceId),
      });
    },
    onError: () => {
      message.error("Comment was not edited. Please try again.");
    },
  });

  useEffect(() => {
    if (!commentOk && comment) {
      if (containsProfanity(comment)) {
        const key = "profanity-notification";
        api.open({
          description: (
            <Row>
              <Col span={22}>
                <ErrorText>
                  Oops! Please use kind words. Let's keep our community positive
                  and respectful
                </ErrorText>
              </Col>
              <Col span={2}></Col>
            </Row>
          ),
          key,
          duration: 0,
          className: "error-notification",
          closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
        });
      } else api.destroy("profanity-notification");

      if (comment.length > commentLengthMax) {
        const key = "length-notification";
        api.open({
          description: (
            <Row>
              <Col span={22}>
                <ErrorText>
                  Must be less than {commentLengthMax} characters.
                </ErrorText>
              </Col>
              <Col span={2}></Col>
            </Row>
          ),
          key,
          duration: 0,
          className: "error-notification",
          closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
        });
      } else api.destroy("length-notification");
    } else {
      api.destroy("profanity-notification");
      api.destroy("length-notification");
    }
  }, [comment, api, commentOk]);

  useEffect(() => {
    if (deletedComments.length > 0) {
      const key = "deleted-comment-notification";
      api.open({
        description: (
          <Row>
            <Col span={22}>
              <ErrorText>
                {pluralize("comment", deletedComments.length, true)} deleted.
                Tap to undo.
              </ErrorText>
            </Col>
            <Col span={2}></Col>
          </Row>
        ),
        key,
        duration: 30,
        className: "error-notification",
        closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
        onClose: () => {
          setDeletedComments([]);
        },
        onClick: async () => {
          api.destroy("deleted-comment-notification");
          await Promise.all(
            deletedComments.map(async (c) => {
              try {
                await commentModel.set({
                  comment: c.comment,
                  commentId: c.id,
                  commentedBy: c.creator,
                  createdAt: c.createdAt,
                  creator: c.creator,
                  edited: c.edited,
                  lastUpdated: c.lastUpdated,
                  replyTo: c.replyTo ? c.replyTo : null,
                  sourceType,
                  sourceId,
                });
              } catch {
                message.error("Comment was not submitted. Please try again.");
              }
            })
          );
          setDeletedComments([]);
          queryClient.invalidateQueries({
            queryKey: commentKeys.subject(sourceId),
          });
        },
      });
    } else {
      api.destroy("deleted-comment-notification");
    }
  }, [deletedComments, api, sourceId, queryClient, sourceType]);

  const replyToUsername = useMemo(() => {
    if (replyTo) {
      return userToReplyTo?.username;
    }
    return "";
  }, [replyTo, userToReplyTo]);

  const startCommentSubmit = async () => {
    if (!user) {
      requireLogin();
      return;
    }

    if (editComment) {
      editCommentMutation({
        editedComment: editComment,
        comment,
      });
      const type = editComment.replyTo ? "reply" : "comment";

      trackEvent("Edited Comment", {
        ...trackingData,
        type,
      });
    } else {
      const type = replyTo ? "reply" : "comment";

      createComment({
        comment,
        commentedBy: user?.uid,
        replyTo,
        sourceType,
        sourceId,
      });

      trackEvent("Posted Comment", {
        ...trackingData,
        type,
      });
    }
  };

  return {
    commentState,
    dispatch,
    isSending: isCreating || isEditing,
    replyToUsername,
    startCommentSubmit,
    setDeletedComments,
    notificationContextHolder,
  };
};

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
`;

const ErrorText = styled.span`
  color: var(--color-red);
`;

export default useComment;
