import React from "react";
import { Username } from "../user";
import moment from "moment";
import {
  AlertDescription,
  AlertTimeLeft,
  AlertContainer,
  AlertTextContainer,
  AlertText,
  AlertTitle,
  AlertIcon,
  AlertWatchIcon,
} from "../../styles/CommonAlertElements";
import styled from "styled-components";

const writingQuestResultsAlertThumbnail =
  "https://firebasestorage.googleapis.com/v0/b/strider-product.appspot.com/o/alert-writing-quest-results-thumbnail.svg?alt=media&token=129a830d-4baf-4b39-a874-4aa5f29da5e8";

export default function QuestAlert({ alert }) {
  let title = "";
  switch (alert.state) {
    case "submit":
      title = `Submit ${alert.context?.questType} for "${alert.subjectTitle}"`;
      break;
    case "vote":
      title = `Vote on "${alert.subjectTitle}"`;
      break;
    case "results":
      title = alert.context.hasSubmitted
        ? `See how you did in "${alert.subjectTitle}"`
        : `Quest results out for "${alert.subjectTitle}"`;
      break;
    default:
      break;
  }

  const imageWithResultsFallback =
    alert.state === "results" && alert.context?.questType === "writing"
      ? writingQuestResultsAlertThumbnail
      : alert.alertImage;

  if (alert?.context?.isWinner) {
    return (
      <WinnerAlertContainer>
        <BackgroundWrapper>
          <Hero>
            <img src="/icons/confetti.svg" />
            <WinnerImage>
              <img src={imageWithResultsFallback} />
            </WinnerImage>
          </Hero>
          <WinnerAlertTitle>
            Congratulations, you're the Top Winner!
            <AlertText>
              {` • ${moment(alert.createdAt.toDate()).fromNow(true)}`}
            </AlertText>
          </WinnerAlertTitle>
          <WinnerAlertText>
            {`${alert.storyworldName}: "${alert.subjectTitle}"`}
          </WinnerAlertText>
        </BackgroundWrapper>
      </WinnerAlertContainer>
    );
  }

  return (
    <AlertContainer>
      <AlertIcon
        alert={{
          ...alert,
          // Instead of using all the built-in logic for AlertIcon, in this case we supply an image with that pink background
          alertImage: imageWithResultsFallback,
        }}
      />
      <AlertTextContainer>
        <AlertTitle>
          <Username userId={alert.likedBy} /> {title}
          <AlertText>
            {` • ${moment(alert.createdAt.toDate()).fromNow(true)}`}
          </AlertText>
        </AlertTitle>
        <AlertDescription>
          {alert.state !== "results" ? (
            <>
              <AlertWatchIcon src="/icons/quests/clock.svg" alt="time icon" />{" "}
              <AlertTimeLeft>
                {alert.endTime.toDate() > new Date()
                  ? moment(alert.endTime.toDate()).fromNow(true)
                  : "Closed"}
              </AlertTimeLeft>
              <AlertText>{` • ${alert.storyworldName}`}</AlertText>
            </>
          ) : (
            <AlertText>{alert.storyworldName}</AlertText>
          )}
        </AlertDescription>
      </AlertTextContainer>
    </AlertContainer>
  );
}

export const WinnerAlertContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-top: 1px solid var(--border-light);
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 16px;

  border-radius: 8px;
  background: url("/images/black-bg-pattern.svg");
  /* Should be the blurple gradient but I am struggling with that one */
  border: 1px solid var(--color-pink);
`;

export const Hero = styled.div`
  > img {
    width: 80%;

    position: absolute;
    left: 10%;
  }
`;

export const WinnerImage = styled.div`
  width: 52px;
  height: 52px;
  text-align: center;
  margin: auto;
  border-radius: 4px;
  border: 1px solid #f5a2ff;

  > img {
    height: 100%;
    width: 100%;
  }
`;

export const WinnerAlertTitle = styled.div`
  color: var(--color-pink);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 2px;
`;

export const WinnerAlertText = styled.div`
  color: #949ba4;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;
