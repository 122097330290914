import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import ProfileMenuButton from "../views/ProfileMenuButton";
import ProfileArtTab from "../views/ProfileArtTab";
import ProfileLikedTab from "../views/ProfileLikedTab";
import { TabItem } from "../components/TabItem";
import { useQuery } from "@tanstack/react-query";
import submissionModel from "../lib/firebase/submissionModel";
import userModel from "../lib/firebase/userModel";
import { userKeys } from "../lib/queryKeys";
import { trackEvent } from "../helpers/mixpanel";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import ProfileInfo from "../components/profile/ProfileInfo";
import Backlink from "../components/Backlink";
import { device } from "../lib/breakpoints";
import Hidden from "../components/Hidden";
import { Spacer } from "../styles/CommonElements";

export default function UserProfile() {
  const { id: userId } = useParams();
  const [tab, setTab] = useState("art");

  const { backLocations, goBack } = useContext(BackLocationsContext);

  const { data: userInfo, isLoading: isUserLoading } = useQuery({
    queryKey: userKeys.user(userId),
    queryFn: () => userModel.getOneById(userId),
  });

  const artQuery = useQuery({
    queryKey: userKeys.profile_submissions(userId),
    queryFn: () =>
      submissionModel.getProfileSubmisisons(userId, [
        "sort",
        "createdAt",
        "desc",
      ]),
  });

  const likedQuery = useQuery({
    queryKey: userKeys.profile_liked(userId),
    queryFn: () => submissionModel.getProfileLiked(userId),
  });

  useEffect(() => {
    trackEvent("Visited User Profile");
  }, []);

  return (
    <Container>
      <InfoContainer>
        <Hidden tablet desktop>
          <ActionsOverlay>
            {backLocations.length > 0 && <Backlink onClick={goBack} />}
          </ActionsOverlay>
        </Hidden>

        <Hidden mobile>
          <Spacer $marginTop="20px" />
        </Hidden>

        <ProfileInfo isLoading={isUserLoading} userInfo={userInfo} />

        <ProfileMenuButton profileUser={userId} />
      </InfoContainer>

      <ContentContainer>
        <TabContainer>
          <TabItem
            layoutId="profile"
            isActive={tab === "art"}
            onClick={() => setTab("art")}
          >
            Art
          </TabItem>

          <TabItem
            layoutId="profile"
            isActive={tab === "liked"}
            onClick={() => setTab("liked")}
          >
            Liked
          </TabItem>
        </TabContainer>

        <MainContainer>
          {tab === "art" && (
            <ProfileArtTab
              submissions={artQuery.data}
              isLoading={artQuery.isLoading}
            />
          )}
          {tab === "liked" && (
            <ProfileLikedTab
              submissions={likedQuery.data}
              isLoading={likedQuery.isLoading}
            />
          )}
        </MainContainer>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  @media ${device.tablet}, ${device.desktop} {
    display: inline-flex;
    margin: auto;
  }

  @media (${device.tablet}) {
    width: 598px;
    gap: 12px;
    margin-top: 32px;
  }

  @media (${device.desktop}) {
    width: 900px;
    gap: 48px;
    margin-top: 40px;
  }
`;
const ContentContainer = styled.div`
  @media ${device.tablet}, ${device.desktop} {
    padding: 0px;
  }

  @media (${device.tablet}) {
    width: 354px;
  }

  @media (${device.desktop}) {
    width: 523px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  position: relative;
  min-height: 230px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  @media ${device.tablet}, ${device.desktop} {
    border-radius: 12px;
    border: 1px solid var(--border-light);
    background: var(--color-gray-600);
    padding: 24px;
    height: min-content;
  }

  @media (${device.tablet}) {
    width: 232px;
  }

  @media (${device.desktop}) {
    width: 308px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 48px;
  padding: 0 20px;

  @media ${device.tablet}, ${device.desktop} {
    margin-top: 0;
    padding: 0;
  }

  @media ${device.tablet} {
    margin-bottom: 16px;
  }

  @media ${device.desktop} {
    margin-bottom: 24px;
  }
`;

const MainContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.tablet}, ${device.desktop} {
    padding: 0;
  }
`;

const ActionsOverlay = styled.div`
  position: absolute;
  top: 8px;
  left: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
  width: fit-content;
  margin: auto;
`;
