import styled from "styled-components";
import { isOniOS } from "../helpers/general";
import { device } from "../lib/breakpoints";

const EditCommentSection = ({ handleCancelEdit }) => {
  return (
    <Container>
      <StopEditButton
        src="/icons/social/stop-edit.svg"
        alt=""
        onClick={handleCancelEdit}
        className="stop-edit-button"
      />
      Editing comment
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  text-align: left;
  background: var(--color-gray-700);
  color: var(--color-gray-50);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  border-bottom: ${(props) =>
    props.$isFocused && isOniOS() ? "0px" : "0.5px solid var(--border-light)"};
  padding: 10px 20px;

  @media ${device.tablet}, ${device.desktop} {
    padding: 10px;
    background: none;
    border-bottom: 0;
  }
`;

const StopEditButton = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

export default EditCommentSection;
