// Employee user accounts to remove from certain calculations

export const prodEmployeeIds = [];

export const sandboxEmployeeIds = [];

export let employeeIds;

switch (process.env.REACT_APP_DEPLOYMENT) {
  case "demo":
    employeeIds = [];
    break;
  case "prod":
    employeeIds = prodEmployeeIds;
    break;
  default:
    employeeIds = sandboxEmployeeIds;
}
