import React, { useContext, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components";
import { Col, Row, notification } from "antd";
import {
  BanUserSheet,
  ReportUserSheet,
  SuspendUserSheet,
} from "../components/social";
import { adminEmails } from "../helpers/adminEmails";
import { isUserBanned, unbanUser } from "../helpers/report";
import { sleep } from "../helpers/general";
import { AuthContext } from "../context/AuthProvider";
import { device } from "../lib/breakpoints";

export default function ProfileMenuButton({ profileUser }) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const { user } = useContext(AuthContext);

  const [reportSheetOpen, setReportSheetOpen] = useState(false);
  const [suspendSheetOpen, setSuspendSheetOpen] = useState(false);
  const [banSheetOpen, setBanSheetOpen] = useState(false);
  const [isBanned, setBanned] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      const result = await isUserBanned(profileUser);
      setBanned(result);
    }

    fetchData();
  }, [profileUser]);

  const openUnbanNotification = async () => {
    const key = `open${Date.now()}`;
    api.open({
      description: (
        <Row>
          <Col span={22}>You have unbanned this user.</Col>
          <Col span={2}></Col>
        </Row>
      ),
      key,
      duration: 10,
      className: "report-notification",
      closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="" />,
    });
    await sleep(1);
    const result = await isUserBanned(profileUser);
    setBanned(result);
  };

  useEffect(() => {
    async function fetchData() {
      const result = await isUserBanned(profileUser);
      setBanned(result);
    }

    if (!banSheetOpen) fetchData();
  }, [banSheetOpen, profileUser]);

  const [api, contextHolder] = notification.useNotification();

  if (!user) return null;

  return (
    <>
      {contextHolder}
      <ButtonContainer
        ref={ref}
        className="transition-colors"
        $isActive={open}
        onClick={() => setOpen(!open)}
      >
        <DropdownIcon src="/icons/social/vertical-dots.svg" alt="" />
      </ButtonContainer>

      <AnimatePresence mode="wait">
        {open && (
          <Dropdown
            initial={{ opacity: 0, y: -5, x: 8 }}
            animate={{ opacity: 1, y: 0, x: 0, transition: { duration: 0.1 } }}
            exit={{ opacity: 0, y: -5, x: 8, transition: { duration: 0.1 } }}
          >
            <TextContainer onClick={async () => setReportSheetOpen(true)}>
              <Icon src="/icons/social/flag.svg" alt="" />
              <Text>Report</Text>
            </TextContainer>
            {user && adminEmails.includes(user.email) && (
              <>
                <TextContainer
                  style={{ marginTop: 4 }}
                  onClick={async () => setSuspendSheetOpen(true)}
                >
                  <Icon src="/icons/social/shield.svg" alt="" />
                  <Text>Suspend</Text>
                </TextContainer>
                {isBanned && (
                  <TextContainer
                    style={{ marginTop: 4 }}
                    onClick={async () => {
                      await unbanUser(profileUser);
                      openUnbanNotification();
                    }}
                  >
                    <Icon src="/icons/social/stop.svg" alt="" />
                    <Text>Unban</Text>
                  </TextContainer>
                )}
                {!isBanned && (
                  <TextContainer
                    style={{ marginTop: 4 }}
                    onClick={async () => setBanSheetOpen(true)}
                  >
                    <Icon src="/icons/social/stop.svg" alt="" />
                    <Text>Ban</Text>
                  </TextContainer>
                )}
              </>
            )}
          </Dropdown>
        )}
      </AnimatePresence>
      <ReportUserSheet
        user={profileUser}
        isOpen={reportSheetOpen}
        setOpen={setReportSheetOpen}
      />
      <SuspendUserSheet
        user={profileUser}
        isOpen={suspendSheetOpen}
        setOpen={setSuspendSheetOpen}
      />
      <BanUserSheet
        user={profileUser}
        isOpen={banSheetOpen}
        setOpen={setBanSheetOpen}
      />
    </>
  );
}

const ButtonContainer = styled.div`
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (!props.$isActive ? "#555a67" : "#F5A2FF")};
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 13px;

  @media ${device.tablet}, ${device.desktop} {
    background-color: transparent;
    filter: brightness(200%);
    top: 16px;
  }
`;

const Dropdown = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: 8px;
  padding: 10px;
  width: 110px;
  border-radius: 8px;
  background-color: #15171f;
  z-index: 1;

  @media ${device.tablet}, ${device.desktop} {
    right: -75px;
  }
`;

const DropdownIcon = styled.img`
  height: 19px;
  width: 19px;

  @media ${device.tablet}, ${device.desktop} {
    height: 26px;
    width: 26px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
`;

const Text = styled.p`
  color: #e96f68;
  font-weight: 500;
  font-size: 1em;
  line-height: 160%;
  margin-bottom: 0px;
  text-align: left;
`;

const Icon = styled.img`
  height: 19px;
  width: 19px;
`;

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
`;
