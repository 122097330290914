import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import styled, { css } from "styled-components";
import userModel from "../lib/firebase/userModel";
import { useQuery } from "@tanstack/react-query";
import { isUserBanned } from "../helpers/report";
import { getDefaultProfilePicByUserId } from "../helpers/authentication";
import { userKeys } from "../lib/queryKeys";
import { BackLocationsContext } from "../context/BackLocationsProvider";

export default function UserDisplay({
  userId,
  showName,
  size = 40,
  fontSize = "14px",
  isCurrentUser,
  isAuthor = false,
  isWinner = false,
  isLink = true,
}) {
  const { addBackLocation } = useContext(BackLocationsContext);

  const { data: userData, isSuccess: isUserQuerySuccess } = useQuery({
    queryKey: userKeys.user(userId),
    queryFn: () => {
      return userModel.getOneById(userId);
    },
    enabled: !!userId,
  });

  const { data: isBanned, isSuccess: isBannedQuerySuccess } = useQuery({
    queryKey: userKeys.user_banned(userId),
    queryFn: () => {
      return isUserBanned(userId);
    },
  });

  if (!userData || !isUserQuerySuccess) return null;
  if (!isBannedQuerySuccess) return null;

  const profileLink =
    !userData?.pfp || !userData?.username || isBanned || !isLink
      ? null
      : isCurrentUser
      ? "/profile"
      : `/profile/${userId}`;

  return (
    <Container>
      <LinkWrapper
        {...(profileLink
          ? {
              to: profileLink,
              onClick: () => {
                addBackLocation();
              },
            }
          : {
              as: "div",
            })}
      >
        <Avatar
          size={size}
          icon={
            <UserProfileImage
              src={
                isBanned ? getDefaultProfilePicByUserId(userId) : userData?.pfp
              }
            />
          }
        />
        {showName && (
          <UsernameTitleContainer>
            <UsernameTitle
              $isAuthor={isAuthor}
              $isWinner={isWinner}
              $fontSize={fontSize}
              className={`username`}
            >
              {isBanned || !userData?.username ? "deleted" : userData?.username}
            </UsernameTitle>
          </UsernameTitleContainer>
        )}
      </LinkWrapper>
    </Container>
  );
}

const Container = styled.div``;

const LinkWrapper = styled(Link)``;

const UserProfileImage = styled.img`
  object-fit: contain;
`;

const UsernameTitleContainer = styled.div`
  display: inline-flex;
`;

const UsernameTitle = styled.div`
  color: var(--color-cream-base);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 0.375em;
  font-size: ${(props) => props.$fontSize};

  ${({ $isAuthor }) =>
    $isAuthor &&
    css`
      color: var(--color-green-base);
      text-align: left;
    `}

  ${({ $isWinner }) =>
    $isWinner &&
    css`
      margin-left: 0.5em;
    `}
`;
