import React, { useContext, useMemo } from "react";
import { styled } from "styled-components";
import { useQuery } from "@tanstack/react-query";
import submissionModel from "../../lib/firebase/submissionModel";
import { getQuestAndPollStates } from "../../helpers/general";
import EmptySubmissions from "./EmptySubmissions";
import voteModel from "../../lib/firebase/voteModel";
import { submissionKeys, voteKeys } from "../../lib/queryKeys";
import { AuthContext } from "../../context/AuthProvider";
import SubmissionsDisplay from "../submissions/SubmissionsDisplay";

export default function QuestSubmissions({ quest, submissionAmount }) {
  const { user, isGuest } = useContext(AuthContext);

  const questAndPollStates = useMemo(() => {
    if (!quest) return null;

    return getQuestAndPollStates(quest);
  }, [quest]);

  const questSubmissionsQuery = useQuery({
    queryKey: submissionKeys.quest_submissions(quest.id),
    queryFn: async () => {
      if (isGuest) {
        return submissionModel.getMany(
          ["questId", "==", quest.id],
          ["rejected", "==", false]
        );
      }

      return submissionModel.getMany(
        ["questId", "==", quest.id],
        ["rejected", "==", false],
        ["creator", "!=", user?.uid]
      );
    },
    enabled: !!user?.uid || isGuest,
    placeholderData: [],
  });

  const { data: currentUserSubmission } = useQuery({
    queryKey: submissionKeys.user_submission(quest.id, user?.uid),
    queryFn: async () => {
      return submissionModel.getMany(
        ["questId", "==", quest.id],
        ["creator", "==", user.uid],
        ["limit", 1]
      );
    },
    select: (data) => (data.length > 0 ? data[0] : null),
    enabled: !!user?.uid,
    placeholderData: null,
  });

  const { data: hasVoted } = useQuery({
    queryKey: voteKeys.quest_user_vote(quest.id, user?.uid),
    queryFn: async () => {
      return voteModel.getMany(
        ["questId", "==", quest.id],
        ["creator", "==", user?.uid],
        ["limit", 1]
      );
    },
    select: (data) => data.length > 0,
    enabled: !!user?.uid,
    placeholderData: false,
  });

  if (!questAndPollStates) return null;
  if (questSubmissionsQuery.status !== "success") return null;

  return (
    <Container>
      {submissionAmount > 0 ? (
        <SubmissionsDisplay
          quest={quest}
          currentUserSubmission={currentUserSubmission}
          state={questAndPollStates}
          hasVoted={hasVoted}
          confirmedSubmissions={questSubmissionsQuery.data}
        />
      ) : (
        <EmptySubmissions />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  margin: auto;

  .info-row {
    padding: 0px 12px;
    height: 100%;
  }

  .timer-col {
    margin: auto;
    text-align: center;
    margin-left: 0px;
    margin-top: 17px;
  }

  .action-col {
    font-size: 13px !important;
    margin: auto;
    max-width: 140px;
    margin-left: 0px;
    margin-top: -8px;
  }

  .mobile-submission-cta-row {
    padding: 0px 24px;
    height: 70px;
  }

  .mobile-submission-cta-col {
    margin: auto !important;
    text-align: center;
    height: 100%;
    padding-top: 16px;
    margin-left: 0px;
  }
`;
