import React, { useState } from "react";
import { Image, Typography } from "antd";
import { Link } from "react-router-dom";
import { LikeButton } from "../components";
import styled from "styled-components";
import { EmptyCountdown } from "../styles/CommonElements";

const { Title } = Typography;

export default function GalleryEmpty() {
  // TODO: Resolve the fact that this is currently only an empty array
  const [isPollEnding] = useState(false);
  const [poll] = useState(null);

  return (
    <div>
      <div>
        <Title className="mobile-quest-display-title" level={4}>
          Welcome to the Art Gallery
        </Title>
      </div>
      {isPollEnding && (
        <div className="center" style={{ marginTop: "24px" }}>
          <CountdownSection>
            Gallery opening in{" "}
            <span>
              <Stopwatch src="/icons/stopwatch.svg" preview={false} />
              <EmptyCountdown
                value={poll.endTimestamp.toDate()}
                onFinish={() => window.location.reload()}
              />
            </span>
          </CountdownSection>
        </div>
      )}
      <EmptyGalleryImageSection>
        <Image src="images/gallery-empty.svg" preview={false} />
      </EmptyGalleryImageSection>
      <EmptyGallerySection>
        <EmptyGalleryText>
          After each{" "}
          <Link className="link" to="/quests">
            quest
          </Link>
          , everyone's art will appear on this page.
        </EmptyGalleryText>
        <EmptyGalleryText>
          <div>
            Tap <LikeButton /> on your favorite to like it!
          </div>
          <div>
            {" "}
            They will be saved on your{" "}
            <Link className="link" to="/profile">
              profile
            </Link>
            , so you can always find them again later.
          </div>
        </EmptyGalleryText>
      </EmptyGallerySection>
    </div>
  );
}

const CountdownSection = styled.span`
  background: var(--color-gray-400);
  border-radius: 12px;
  padding: 10px 16px;
  margin: auto;
  font-size: 11px;
  font-weight: 600 !important;
`;

const EmptyGallerySection = styled.div`
  width: 85%;
  margin: auto;
  text-align: center;
  margin-top: 30px;
`;

const EmptyGalleryText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 30px;
  color: var(--color-gray-50);
`;

const Stopwatch = styled(Image)`
  margin: 0px 6px 2px 4px;
  width: 10px !important;
  display: inline-flex;
`;

const EmptyGalleryImageSection = styled.div`
  margin-top: 40px;

  .ant-image {
    width: 100%;
  }
`;
