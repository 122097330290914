import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { isQuestCompleted } from "../helpers/general";
import QuestButtons from "./QuestButtons";
import QuestPreview from "./QuestPreview";
import styled from "styled-components";
import QuestEditor from "./QuestEditor";
import { SUBMISSION_TYPES } from "../helpers/constants";

export default function CreateQuest({
  setUserQuests,
  selectedQuest,
  userQuests,
  isNewQuest,
  setSelectedQuest,
}) {
  const [title, setTitle] = useState(
    selectedQuest.title === "" ? "" : selectedQuest.title
  );

  const [startTimestamp, onStartTimestampChange] = useState(
    isNewQuest
      ? new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
      : selectedQuest.startTimestamp.toDate()
  );
  const [endTimestamp, onEndTimestampChange] = useState(
    isNewQuest
      ? new Date(new Date().getTime() + 60 * 60 * 48 * 1000)
      : selectedQuest.endTimestamp.toDate()
  );
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [description, setDescription] = useState(
    selectedQuest.description ? selectedQuest.description : ""
  );
  const [objectiveDescription, setObjectiveDescription] = useState(
    selectedQuest.objectiveDescription ? selectedQuest.objectiveDescription : ""
  );
  const [moreInfo, setMoreInfo] = useState(
    selectedQuest.moreInfo ? selectedQuest.moreInfo : ""
  );

  const [textSubmissionLimit, setTextSubmissionLimit] = useState(
    selectedQuest.textSubmissionLimit ? selectedQuest.textSubmissionLimit : 350
  );
  const [submissionRequirements, setSubmissionRequirements] = useState(
    selectedQuest.submissionRequirements
      ? selectedQuest.submissionRequirements
      : [SUBMISSION_TYPES.IDEA]
  );
  const [videoUrl, setVideoUrl] = useState(
    selectedQuest.videoUrl ? selectedQuest.videoUrl : undefined
  );
  const [thumbnailUrl, setThumbnailUrl] = useState(
    selectedQuest.thumbnailUrl ? selectedQuest.thumbnailUrl : undefined
  );
  const [ongoing, setOngoing] = useState(
    selectedQuest.ongoing ? selectedQuest.ongoing : false
  );

  const [suggestedSearchTerms, setSuggestedSearchTerms] = useState(
    selectedQuest.suggestedSearchTerms ?? []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <Row>
          <Col span={12}>
            <Header>{isNewQuest ? "Create New Quest" : "Update Quest"}</Header>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <QuestButtons
              selectedQuest={selectedQuest}
              setUserQuests={setUserQuests}
              userQuests={userQuests}
              isNewQuest={isNewQuest}
              setSelectedQuest={setSelectedQuest}
              title={title}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              textSubmissionLimit={textSubmissionLimit}
              description={description}
              objectiveDescription={objectiveDescription}
              submissionRequirements={submissionRequirements}
              suggestedSearchTerms={suggestedSearchTerms}
              showDelete={false}
              isPreviewing={isPreviewing}
              setIsPreviewing={setIsPreviewing}
              videoUrl={videoUrl}
              thumbnailUrl={thumbnailUrl}
              isCompleted={isQuestCompleted(selectedQuest)}
              moreInfo={moreInfo}
              ongoing={ongoing}
            />
          </Col>
        </Row>
      </HeaderContainer>

      {!isPreviewing && !isQuestCompleted(selectedQuest) && (
        <QuestEditor
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          textSubmissionLimit={textSubmissionLimit}
          setTextSubmissionLimit={setTextSubmissionLimit}
          objectiveDescription={objectiveDescription}
          setObjectiveDescription={setObjectiveDescription}
          moreInfo={moreInfo}
          setMoreInfo={setMoreInfo}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          thumbnailUrl={thumbnailUrl}
          setThumbnailUrl={setThumbnailUrl}
          endTimestamp={endTimestamp}
          onEndTimestampChange={onEndTimestampChange}
          startTimestamp={startTimestamp}
          onStartTimestampChange={onStartTimestampChange}
          submissionRequirements={submissionRequirements}
          setSubmissionRequirements={setSubmissionRequirements}
          suggestedSearchTerms={suggestedSearchTerms}
          setSuggestedSearchTerms={setSuggestedSearchTerms}
          ongoing={ongoing}
          setOngoing={setOngoing}
        />
      )}

      {(isPreviewing || isQuestCompleted(selectedQuest)) && (
        <QuestPreview
          title={title}
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          description={description}
          suggestedSearchTerms={suggestedSearchTerms}
          objectiveDescription={objectiveDescription}
          videoUrl={videoUrl}
          thumbnailUrl={thumbnailUrl}
          moreInfo={moreInfo}
          ongoing={ongoing}
        />
      )}

      <QuestButtonDiv>
        <QuestButtons
          selectedQuest={selectedQuest}
          setUserQuests={setUserQuests}
          userQuests={userQuests}
          isNewQuest={isNewQuest}
          setSelectedQuest={setSelectedQuest}
          title={title}
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          textSubmissionLimit={textSubmissionLimit}
          description={description}
          objectiveDescription={objectiveDescription}
          submissionRequirements={submissionRequirements}
          suggestedSearchTerms={suggestedSearchTerms}
          showDelete={true}
          isPreviewing={isPreviewing}
          setIsPreviewing={setIsPreviewing}
          videoUrl={videoUrl}
          thumbnailUrl={thumbnailUrl}
          isCompleted={isQuestCompleted(selectedQuest)}
          moreInfo={moreInfo}
          ongoing={ongoing}
        />
      </QuestButtonDiv>
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  margin: auto;
  margin-top: 0px;
  display: inline-block;
  width: 100%;
  min-width: 350px;
  margin-bottom: 80px;
`;

const Header = styled.div`
  color: var(--Neutral-01, #fcfcfc);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 106.667% */
  letter-spacing: -0.6px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 40px !important;
`;

const QuestButtonDiv = styled.div`
  text-align: right;
  margin-top: 24px;
`;
