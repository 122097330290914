import styled from "styled-components";
import { device } from "../lib/breakpoints";
import Button from "../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useErrorBoundary } from "react-error-boundary";

const RouterErrorBoundary = ({ isNotFound = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // useRef isn't called on re-renders, so this equals the location of where the error appeared
  const errorLocation = useRef(location.pathname);

  const { resetBoundary } = useErrorBoundary();

  useEffect(() => {
    if (errorLocation.current !== location.pathname) {
      resetBoundary();
    }
  }, [location.pathname, resetBoundary]);

  return (
    <Container>
      {!isNotFound && (
        <ErrorContext>
          <Title>Something went wrong. Please reload the page.</Title>
        </ErrorContext>
      )}
      {isNotFound && (
        <ErrorContext>
          <Title>Page not found</Title>
          <Button variant="primary" onClick={() => navigate("/")}>
            Return to home page
          </Button>
        </ErrorContext>
      )}
    </Container>
  );
};

export default RouterErrorBoundary;

const Container = styled.div`
  inset: 0px;
  height: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorContext = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;

  @media ${device.tablet}, ${device.desktop} {
    font-size: 1.5rem;
  }
`;
