import React, { useState, useEffect } from "react";
import { Col, Input, Row, Tooltip, Image, Switch } from "antd";
import { arrayMove } from "@dnd-kit/sortable";
import {
  containsProfanity,
  getAdjustedEpisodeText,
  removeItemOnce,
} from "../helpers/general";
import DateTimePicker from "react-datetime-picker";
import EpisodeButtons from "./EpisodeButtons";
import DragComics from "./DragComics";
import { UploadComicButton, UploadDragAndDropComic } from ".";
import styled from "styled-components";
import {
  PreviewEpisodeModal,
  QuestWinners,
} from "../components/create-episode";
import { UploadDragAndDropImageQuestThumbnail } from "../components/vh-panel";
import { DeleteFilled } from "@ant-design/icons";
import {
  vhPanelLeftPanelWidth,
  vhPanelrightPanelLeftMargin,
} from "../helpers/constants";
import {
  InputSection,
  RightAlignedCol,
  Section,
} from "../styles/CommonElements";

const phoneIcon = "/icons/phone.svg";

export default function CreateEpisode({
  selectedEpisode,
  setSelectedEpisode,
  episodes,
  setEpisodes,
  isNewEpisode,
  selectedStoryworld,
  winningSubmission,
}) {
  const [title, setTitle] = useState(
    selectedEpisode.title === "" ? "" : selectedEpisode.title
  );
  const [publishTimestamp, setPublishTimestamp] = useState(
    isNewEpisode || selectedEpisode.isNewEpisode
      ? new Date()
      : selectedEpisode.publishedAt.toDate()
  );
  const [comicsEmpty, setComicsEmpty] = useState(true);
  const [comicEntries, setComicEntries] = useState(
    selectedEpisode.comicUrls ? selectedEpisode.comicUrls : []
  );
  const [winningComic, setWinningComic] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(
    selectedEpisode.thumbnailUrl ? selectedEpisode.thumbnailUrl : undefined
  );
  const [contributorSwitchChecked, setContributorSwitchChecked] = useState(
    selectedEpisode.contributors && selectedEpisode.contributors.length > 0
      ? true
      : false
  );
  const [contributors, setContributors] = useState(
    selectedEpisode.contributors ? selectedEpisode.contributors : []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (comicEntries.length > 0) {
      setComicsEmpty(false);
    } else {
      setComicsEmpty(true);
    }
    const result = comicEntries.filter(function (e) {
      return e.isWinningSubmission === true;
    });
    if (result.length > 0) setWinningComic(result[0]);
    else setWinningComic(null);
  }, [comicEntries]);

  const handleTitleChange = (event) => {
    const text = event.target.value;
    if (!containsProfanity(text)) {
      setTitle(text);
    }
  };

  const removeComicEntry = function (comicEntry) {
    const newEntries = removeItemOnce(comicEntries, comicEntry);
    const newData = newEntries.slice(0).map((entry, index) => ({
      ...entry,
      pageNumber: index + 1,
    }));
    setComicEntries(newData);
  };

  const updateComicOrdering = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setComicEntries((newItems) => {
        const oldIndex = newItems.findIndex((item) => item.id === active.id);
        const newIndex = newItems.findIndex((item) => item.id === over.id);

        const resortedArray = arrayMove(newItems, oldIndex, newIndex);

        return resortedArray.map((entry, index) => ({
          ...entry,
          pageNumber: index + 1,
        }));
      });
    }
  };

  return (
    <Container>
      {/* Create, Update, Delete Buttons */}
      <HeaderContainer>
        <Row>
          <Col span={12}>
            <HeaderTitle>
              {isNewEpisode || selectedEpisode.isNewEpisode
                ? "Create Episode"
                : "Update Episode"}
            </HeaderTitle>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <EpisodeButtons
              selectedEpisode={selectedEpisode}
              setSelectedEpisode={setSelectedEpisode}
              setEpisodes={setEpisodes}
              episodes={episodes}
              isNewEpisode={isNewEpisode || selectedEpisode.isNewEpisode}
              title={title}
              publishedAt={publishTimestamp}
              selectedStoryworld={selectedStoryworld}
              comicEntries={comicEntries}
              imageUrl={""}
              winningSubmission={winningSubmission}
              winningComic={winningComic}
              thumbnailUrl={thumbnailUrl}
              contributors={contributors}
            />
          </Col>
        </Row>
      </HeaderContainer>
      {/* END of Create, Update, Delete Buttons */}

      <InnerContainer>
        <LeftPanel>
          <div
            className="create-quest-content-section"
            style={{ width: "100%" }}
          >
            <Section>
              <FieldHeader>Episode Assets</FieldHeader>
              <InputSection>
                <Row>
                  <Col span={20}>
                    <InputHeader>Episode Thumbnail (optional)</InputHeader>
                    <ImageHintContainer>
                      <ImageHint>Recommended: 200x200</ImageHint>
                    </ImageHintContainer>
                  </Col>
                  <RightAlignedCol span={4}>
                    {thumbnailUrl !== undefined && (
                      <DeleteVideoButton
                        onClick={() => {
                          setThumbnailUrl(undefined);
                        }}
                      >
                        <DeleteFilled />
                      </DeleteVideoButton>
                    )}
                  </RightAlignedCol>
                </Row>
                {thumbnailUrl !== undefined ? (
                  <ThumbnailImage src={thumbnailUrl} alt="" />
                ) : (
                  <UploadDragAndDropImageQuestThumbnail
                    setImageUrl={setThumbnailUrl}
                    className="upload-drag-and-drop"
                    maxFileSizeInMb={100}
                    byPassRes={true}
                  />
                )}
              </InputSection>
            </Section>
          </div>

          <div
            className="create-quest-content-section"
            style={{ width: "100%", marginTop: 8 }}
          >
            <Section>
              <FieldHeader>Story Contributor(s)</FieldHeader>
              <InputSection>
                <InputHeader>
                  Do you want to recognize anyone as a story contributor at the
                  end of this episode?
                </InputHeader>
                <SwitchText side="left" checked={!contributorSwitchChecked}>
                  No
                </SwitchText>{" "}
                <Switch
                  defaultChecked={contributorSwitchChecked}
                  onChange={() =>
                    setContributorSwitchChecked(!contributorSwitchChecked)
                  }
                  size="medium"
                />{" "}
                <SwitchText side="right" checked={contributorSwitchChecked}>
                  Yes
                </SwitchText>
              </InputSection>
            </Section>
          </div>

          {contributorSwitchChecked && (
            <QuestWinners
              contributors={contributors}
              setContributors={setContributors}
            />
          )}
        </LeftPanel>

        <RightPanel>
          <div
            className="create-quest-content-section"
            style={{ width: "100%" }}
          >
            <Section>
              <FieldHeader>
                {getAdjustedEpisodeText({
                  number:
                    isNewEpisode || selectedEpisode.isNewEpisode
                      ? episodes.length + 1
                      : selectedEpisode.number,
                })}
              </FieldHeader>
              <InputSection>
                <Row>
                  <Col span={12}>
                    <InputHeader>Episode Title</InputHeader>
                  </Col>
                  <RightAlignedCol span={12}>
                    <Tooltip placement="left" title={"Max 60 characters"}>
                      <img src="/icons/info.svg" alt="" />
                    </Tooltip>
                  </RightAlignedCol>
                </Row>
                <Input
                  defaultValue={title}
                  placeholder="Untitled episode"
                  onChange={handleTitleChange}
                  className="create-quest-input-field create-quest-title-input"
                  maxLength={60}
                  style={{ paddingLeft: 26 }}
                />
              </InputSection>

              <InputSection>
                <Row>
                  <Col span={16}>
                    <InputHeader>Upload Episode Art</InputHeader>
                    {!comicsEmpty && (
                      <p className="upload-episode-art-hint left">
                        You can drag and drop pages to change their order.
                      </p>
                    )}
                  </Col>
                  <RightAlignedCol span={8}>
                    {!comicsEmpty ? (
                      <UploadComicButton
                        maxFileSizeInMb={10}
                        fileBucket="comicImages"
                        comicEntries={comicEntries}
                        setComicEntries={setComicEntries}
                        byPassRes={true}
                      />
                    ) : (
                      <Tooltip
                        placement="left"
                        title={"20MB Max Total Filesize"}
                      >
                        <img src="/icons/info.svg" alt="" />
                      </Tooltip>
                    )}
                  </RightAlignedCol>
                </Row>

                {!comicsEmpty ? (
                  <DragComics
                    comicEntries={comicEntries}
                    removeComicEntry={removeComicEntry}
                    updateComicOrdering={updateComicOrdering}
                  />
                ) : (
                  <UploadDragAndDropComic
                    className="upload-drag-and-drop-storyworld"
                    maxFileSizeInMb={10}
                    fileBucket="comicImages"
                    comicEntries={comicEntries}
                    setComicEntries={setComicEntries}
                    byPassRes={true}
                  />
                )}
              </InputSection>

              <InputSection>
                <InputHeaderSpan>Publish episode on</InputHeaderSpan>
                <DateTimePicker
                  onChange={setPublishTimestamp}
                  value={publishTimestamp}
                  className="datetime-picker"
                  clearIcon={null}
                  calendarIcon={null}
                  disableClock={true}
                />
              </InputSection>

              {!comicsEmpty && (
                <InputSection>
                  <PreviewEpisodeHeader>Preview Episode</PreviewEpisodeHeader>
                  <PreviewEpisodeButton
                    onClick={() => {
                      if (setModalOpen !== null) setModalOpen(true);
                    }}
                  >
                    <Image
                      preview={false}
                      src={phoneIcon}
                      style={{
                        marginRight: "10px !important",
                      }}
                    />
                    Mobile
                  </PreviewEpisodeButton>
                </InputSection>
              )}
            </Section>
          </div>
        </RightPanel>
      </InnerContainer>

      <PreviewEpisodeModal
        title={title}
        isOpen={modalOpen}
        setOpen={setModalOpen}
        comicEntries={comicEntries}
      />

      <EpisodeButtonContainer>
        <EpisodeButtons
          selectedEpisode={selectedEpisode}
          setSelectedEpisode={setSelectedEpisode}
          setEpisodes={setEpisodes}
          episodes={episodes}
          isNewEpisode={isNewEpisode || selectedEpisode.isNewEpisode}
          title={title}
          publishedAt={publishTimestamp}
          selectedStoryworld={selectedStoryworld}
          comicEntries={comicEntries}
          imageUrl={""}
          winningSubmission={winningSubmission}
          winningComic={winningComic}
          thumbnailUrl={thumbnailUrl}
          contributors={contributors}
        />
      </EpisodeButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  margin: auto;
  margin-top: 0px;
  display: inline-block;
  width: 100%;
  min-width: 350px;
  margin-bottom: 80px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const EpisodeButtonContainer = styled.div`
  text-align: right;
  margin-top: 24px;
`;

const HeaderTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 36px;
`;

const LeftPanel = styled.div`
  width: ${vhPanelLeftPanelWidth}px;
`;

const RightPanel = styled.div`
  width: calc(
    100% - ${vhPanelLeftPanelWidth}px - ${vhPanelrightPanelLeftMargin}px
  );
  margin-left: ${vhPanelrightPanelLeftMargin}px;
`;

const InnerContainer = styled.div`
  display: inline-flex;
  width: 100%;
`;

const FieldHeader = styled.div`
  color: var(--darkTextColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  letter-spacing: -0.32px;
`;

const InputHeader = styled.div`
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom: 14px;
`;

const InputHeaderSpan = styled.span`
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.thin ? "400" : "600")};
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom: 14px;
  margin-right: 30px;
`;

const PreviewEpisodeHeader = styled.div`
  color: #efefef;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: -0.2px;
  margin-bottom: 20px;
  margin-top: 40px;
`;

const DeleteVideoButton = styled.button`
  align-items: top;
  border: none;
  margin: auto;
  height: auto;
  padding: 0px;
  font-weight: bold;
  background: none;
  cursor: pointer;
`;

const ThumbnailImage = styled.img`
  height: 160px !important;
  width: 100%;
  object-fit: cover;
  border-radius: 12px !important;
`;

const SwitchText = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.15px;
  color: ${(props) => (props.checked ? "#F4F4F4" : "var(--darkTextColor)")};
  font-weight: ${(props) => (props.checked ? "bold" : "500")};
  margin-left: ${(props) => (props.side === "left" ? "0" : "4")}px;
  margin-right: ${(props) => (props.side === "right" ? "0" : "4")}px;
`;

const PreviewEpisodeButton = styled.button`
  color: white;
  background: var(--color-black);
  height: auto;
  padding: 14px 24px;
  gap: 8px;
  border-radius: 12px;
  border: 0;
  display: inline-flex;
  cursor: pointer;

  &:hover {
    background: var(--color-black);
    color: white !important;
  }

  &:focus {
    background: var(--color-black);
    color: white !important;
  }
`;

const ImageHintContainer = styled.div`
  margin: -10px 0px 10px 0px;
`;

const ImageHint = styled.p`
  font: unset;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--inputFieldText);
  padding: 0px !important;
  margin: 0px;
  display: block;
  text-align: left;
`;
