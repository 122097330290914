import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import UserDisplay from "../components/UserDisplay";
import storyworldModel from "../lib/firebase/storyworldModel";
import { Col, Row, notification } from "antd";
import { APP_NAME, adminUserId } from "../helpers/constants";
import { storyworldKeys } from "../lib/queryKeys";
import { trackEvent } from "../helpers/mixpanel";
import Bell from "../components/Bell";
import { NotificationCloseIcon } from "../styles/CommonElements";
import useAlertConfig from "../lib/useAlertConfig";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import { AuthContext } from "../context/AuthProvider";
import Backlink from "../components/Backlink";
import { device } from "../lib/breakpoints";
import Hidden from "../components/Hidden";
import {
  StoryworldQuestPageDesktop,
  StoryworldQuestPageMobile,
} from "../components/storyworld-quest-page";
import { Helmet } from "react-helmet";
import { storyworldDetailUrl } from "../lib/routes";

export default function StoryworldQuestPage() {
  const { storyId: id } = useParams();

  const { backLocations, addBackLocation, goBack } =
    useContext(BackLocationsContext);
  const { user, requireLogin } = useContext(AuthContext);

  const {
    toggleFollow,
    followingStatus: { questAlerts },
  } = useAlertConfig(id);

  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(id),
    queryFn: () => storyworldModel.getOneById(id),
    select: (data) => ({
      ...data,
      heroImage: data.backgroundImageUrl ?? data.imageUrl,
    }),
  });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (updatedStatus) => {
    const key = `open${Date.now()}`;
    api.open({
      description: (
        <Row>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Bell isDisabled={!updatedStatus} width="24px" height="24px" />
          </Col>
          <Col span={18}>
            {`Quest alerts are turned ${updatedStatus ? "on" : "off"} for ${
              storyworldQuery.data?.title
            }.`}
            <Link
              to="/feed/settings"
              onClick={() => {
                addBackLocation(storyworldDetailUrl(storyworldQuery.data?.id));
              }}
            >
              {" "}
              See all settings
            </Link>
          </Col>
          <Col span={2}></Col>
        </Row>
      ),
      key,
      duration: 10,
      className: "report-notification",
      closeIcon: (
        <NotificationCloseIcon src="/icons/x-icon-gray.svg" alt="close" />
      ),
    });
  };

  const toggleQuestsFollow = () => {
    if (!user) {
      requireLogin();
      return;
    }
    toggleFollow(
      {
        alertType: "quest",
      },
      {
        onSuccess: ({ questAlertStories }) => {
          const notifcationsEnabled = questAlertStories.includes(
            storyworldQuery.data?.id
          );
          openNotification(notifcationsEnabled);
        },
      }
    );
    trackEvent("Toggled Quest Alert", {
      toggled: !questAlerts ? "on" : "off",
    });
  };

  if (storyworldQuery.status !== "success" || storyworldQuery.data === null)
    return null;

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>
          {APP_NAME} - {storyworldQuery.data.title}
        </title>
      </Helmet>
      <Container>
        <Hero>
          <HeroImage src={storyworldQuery.data.heroImage} />

          <Hidden tablet desktop>
            <ActionsOverlay>
              {backLocations.length > 0 && (
                <FilteredBacklink onClick={goBack} />
              )}
              <NotificationToggle
                onClick={() => {
                  toggleQuestsFollow();
                }}
                alt={
                  questAlerts ? "disable notifications" : "enable notifications"
                }
              >
                <Bell width="16px" height="16px" isDisabled={!questAlerts} />
              </NotificationToggle>
            </ActionsOverlay>
            <HeroInfoOverlay>
              {storyworldQuery.data.genre && (
                <Genre>{storyworldQuery.data.genre}</Genre>
              )}
              <Title>{storyworldQuery.data.title}</Title>
              <Users>
                {storyworldQuery.data.creators.map(
                  (creatorId) =>
                    creatorId !== adminUserId && (
                      <UserDisplay
                        key={creatorId}
                        size={25}
                        userId={creatorId}
                        showName={true}
                        isAuthor={true}
                      />
                    )
                )}
              </Users>
            </HeroInfoOverlay>
          </Hidden>
        </Hero>

        <Hidden tablet desktop>
          <StoryworldQuestPageMobile />
        </Hidden>

        <Hidden mobile>
          <StoryworldQuestPageDesktop toggleQuestsFollow={toggleQuestsFollow} />
        </Hidden>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  @media (${device.mobile}) {
    background-color: #1d232d;
    position: relative;
  }
`;

const ActionsOverlay = styled.div`
  position: absolute;
  top: 8px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  z-index: 3;
  width: 90%;
  margin: auto;

  @media (${device.tablet}) {
    width: 597px;
  }

  @media (${device.device}) {
    width: 907px;
  }
`;

const Genre = styled.div`
  color: var(--color-cream-base);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: -0.2rem;
  color: ${(props) => props.$isPink && "var(--color-pink)"};
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-size: 12px;
  font-weight: 500;
`;

const Hero = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to bottom,
      rgba(35, 38, 47, 0.7),
      rgba(35, 38, 47, 0)
    );
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(
      to top,
      rgba(35, 38, 47, 1) 0%,
      rgba(35, 38, 47, 0.9) 20%,
      rgba(35, 38, 47, 0)
    );
  }

  @media ${device.tablet}, ${device.desktop} {
    display: none;
  }
`;

const HeroImage = styled.img`
  min-height: 200px;
  object-fit: cover;
  max-height: 220px;
  width: 100%;
  z-index: 1;
  opacity: 0.8;
  pointer-events: none;

  @media (${device.tablet}) {
    max-height: 344px;
    opacity: 0.3;
  }

  @media (${device.desktop}) {
    max-height: 344px;
    opacity: 0.3;
  }
`;

const HeroInfoOverlay = styled.div`
  position: absolute;
  bottom: 14px;
  left: 18px;
  width: calc(100% - 18px); // the 18 above
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 3;
  margin: auto;
`;

const FilteredBacklink = styled(Backlink)`
  svg {
    filter: drop-shadow(0 0 0.25rem black);
  }
`;

const NotificationToggle = styled.button`
  border-radius: 69px;
  background: #353a46;
  height: 30px;
  padding: 4px 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
`;

const Title = styled.h1`
  margin-bottom: 0px;
  font-family: var(--font-primary);
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  color: var(--color-cream-base);
  text-align: left;
`;

const Users = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 12px;
`;
