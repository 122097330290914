"use client";
// ----- Components Import -----
import { styled } from "styled-components";

// ----- Assets Import -----
import { device } from "../../lib/breakpoints";
import { APP_NAME } from "../../helpers/constants";
import { Link } from "react-router-dom";

const bannerImage = "/images/home/man-010.svg";

export default function HomeCTA() {
  return (
    <Link to="/contact">
      <Container>
        <Header>
          Contact us today to get started with <b>{APP_NAME}</b>
        </Header>
      </Container>
    </Link>
  );
}

// ----- Styles -----
const Container = styled.div`
  width: 100%;
  height: 500px;
  background: url(${bannerImage});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
`;

const Header = styled.div`
  font-size: 30px;
  font-weight: 400;
  text-transform: capitalize;
  color: white;
  text-align: center;
  height: 100%;
  align-content: center;

  @media ${device.tablet} {
    scale: 0.8;
  }

  @media ${device.mobile} {
    scale: 0.8;
  }
`;
