import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { validateEmail } from "../../helpers/authentication";
import styled from "styled-components";
import { InputHeader, Spacer, StyledInput } from "../../styles/CommonElements";
import Button from "../Button";
import { useSearchParams } from "react-router-dom";
import Backlink from "../Backlink";
import { useBreakpoints } from "../../lib/breakpoints";

export default function ForgotPassword({ email: filledEmail }) {
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState(searchParams.get("email") || filledEmail);
  const [emailSent, setEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { isMobile } = useBreakpoints();

  const auth = getAuth();

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSending(true);
    await sendPasswordResetEmail(auth, email);
    setIsSending(false);
    setEmailSent(true);
  };

  return (
    <Container>
      {isMobile && (
        <BackLinkWrapper>
          <Backlink to="/login" />
        </BackLinkWrapper>
      )}

      {!emailSent ? (
        <form onSubmit={onSubmit}>
          {isMobile && <WelcomeHeader>Reset your password</WelcomeHeader>}
          <LoginHint>
            Enter the email you use to log in and we will send you a link to
            reset your password
          </LoginHint>
          <div>
            <InputHeader>Email</InputHeader>
            <Spacer $marginTop="6px" />
            <InputContainer>
              <StyledInput
                className="transition-colors"
                placeholder="email@email.com"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {/* <Checkmark src="/icons/checkmark.svg" /> */}
            </InputContainer>
            <SubmitButtons>
              <Button
                size="large"
                disabled={!(validateEmail(email) && email !== "") || isSending}
              >
                {!isSending && "Submit"}
                {isSending && (
                  <Spinner alt="" src="/icons/loading-spheres.svg" />
                )}
              </Button>
            </SubmitButtons>
          </div>
        </form>
      ) : (
        <>
          <LoginHint>Thanks! We've sent an email to {email}.</LoginHint>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  margin: auto;
  margin-top: 30px;
  width: 90%;
`;

/*
const Checkmark = styled.img`
  right: 14px !important;
  top: 35%;
  height: 12px;
  position: absolute;
`;
*/

const InputContainer = styled.div`
  position: relative;
`;

const WelcomeHeader = styled.div`
  font-size: 28px;
  font-weight: 800;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
`;

const LoginHint = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 30px;
  color: black;
`;

const SubmitButtons = styled.h4`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  margin-bottom: 0;
  text-align: left;
  margin: auto;
  text-align: center;
  margin-top: 36px;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const BackLinkWrapper = styled.div`
  margin-bottom: 20px;
  color: var(--color-lavender);
`;
