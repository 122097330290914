import React, { useState } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

export default function PromptDisplay({
  prompt,
  setPrompt,
  maxPromptLength,
  editIcon,
  placeholder,
}) {
  const [isEditing, setIsEditing] = useState(false);

  let editorRef = React.createRef();

  return (
    <Container>
      {!isEditing ? (
        <Row
          gutter={10}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <Col span={22}>
            <OneLinePrompt className="one-line-prompt">
              {prompt !== "" ? prompt : placeholder}
            </OneLinePrompt>
          </Col>
          <Col span={2} className="right">
            <EditIcon className="edit-icon" src={editIcon} />
          </Col>
        </Row>
      ) : (
        <TextareaAutosize
          maxRows="4"
          className="autosize-textarea"
          value={prompt}
          placeholder={placeholder}
          onChange={(e) => setPrompt(e.target.value)}
          maxLength={maxPromptLength}
          ref={editorRef}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  border-radius: 8px !important;
  background: var(--color-gray-300);
  display: block;
  position: relative !important;
  width: 100%;
  height: auto;
  padding: 10px 5%;
  color: white;

  .autosize-textarea {
    text-align: left;
    font-size: 14px;
    color: white;
    width: 100%;
    border: 0;
    background: none;
    resize: none;
    display: block;
    margin-top: -1px;
    margin-left: -2px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.2px;

    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    &:focus-visible {
      outline: 0;
    }

    &::placeholder {
      opacity: 0.5;
      color: var(--color-gray-100);
    }
  }
`;

const EditIcon = styled.img`
  color: var(--color-gray-50) !important;
  display: inline-flex;
  width: auto;
  height: 18px;
  margin: auto;
  text-align: right;
  vertical-align: sub;
`;

const OneLinePrompt = styled.span`
  color: var(--color-gray-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    opacity: 0.5;
  }
`;
