import React from "react";
import { styled } from "styled-components";
import { TabItem } from "../TabItem";
import {
  getMixpanelQuestState,
  getMixpanelQuestType,
  trackEvent,
} from "../../helpers/mixpanel";
import { useQuery } from "@tanstack/react-query";
import { storyworldKeys } from "../../lib/queryKeys";
import storyworldModel from "../../lib/firebase/storyworldModel";
import { containsEmptyTags, hasImgTag } from "../../helpers/general";

export default function QuestDisplayMenuSwitch({
  quest,
  extraMenu,
  setExtraMenu,
  submissionAmount,
  showResults = false,
}) {
  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(quest.storyworld),
    queryFn: () => {
      return storyworldModel.getOneById(quest.storyworld);
    },
  });

  return (
    <QuestMenuSwitch>
      <TabContainer
        $smallerFont={
          quest.moreInfo &&
          quest.moreInfo !== "" &&
          (!containsEmptyTags(quest.moreInfo) || hasImgTag(quest.moreInfo))
        }
      >
        {quest.moreInfo &&
          quest.moreInfo !== "" &&
          (!containsEmptyTags(quest.moreInfo) || hasImgTag(quest.moreInfo)) && (
            <TabItem
              layoutId="quest"
              isActive={extraMenu === "info"}
              onClick={() => setExtraMenu("info")}
              className="tab-element"
            >
              Info
            </TabItem>
          )}

        <TabItem
          layoutId="quest"
          isActive={extraMenu === "comments"}
          onClick={() => {
            setExtraMenu("comments");
            trackEvent("Clicked Comments Subtab", {
              quest_id: quest.id,
              quest_name: quest.title,
              quest_state: getMixpanelQuestState(quest),
              quest_type: getMixpanelQuestType(quest),
              storyworld_id: quest.storyworld,
              storyworld_name: storyworldQuery.data.title,
              title: `${storyworldQuery.data.title}: ${quest.title}`,
            });
          }}
          className="tab-element"
        >
          Comments
        </TabItem>

        {showResults && (
          <TabItem
            layoutId="quest"
            isActive={extraMenu === "results"}
            onClick={() => {
              setExtraMenu("results");
            }}
            className="tab-element"
          >
            Results
          </TabItem>
        )}

        {!showResults && (
          <TabItem
            layoutId="quest"
            isActive={extraMenu === "submissions"}
            onClick={() => {
              setExtraMenu("submissions");
              trackEvent("Clicked Submissions Subtab", {
                quest_id: quest.id,
                quest_name: quest.title,
                quest_state: getMixpanelQuestState(quest),
                quest_type: getMixpanelQuestType(quest),
                storyworld_id: quest.storyworld,
                storyworld_name: storyworldQuery.data.title,
                title: `${storyworldQuery.data.title}: ${quest.title}`,
              });
            }}
            className="tab-element"
          >
            Submissions
            {submissionAmount > 0 && (
              <SubmissionBadge active={extraMenu === "submissions"}>
                {submissionAmount}
              </SubmissionBadge>
            )}
          </TabItem>
        )}

        <TabItem
          layoutId="quest"
          isActive={extraMenu === "faq"}
          onClick={() => {
            setExtraMenu("faq");
            trackEvent("Clicked FAQ Subtab", {
              quest_id: quest.id,
              quest_name: quest.title,
              quest_state: getMixpanelQuestState(quest),
              quest_type: getMixpanelQuestType(quest),
              storyworld_id: quest.storyworld,
              storyworld_name: storyworldQuery.data.title,
              title: `${storyworldQuery.data.title}: ${quest.title}`,
            });
          }}
          className="tab-element"
        >
          FAQ
        </TabItem>
      </TabContainer>
    </QuestMenuSwitch>
  );
}

const QuestMenuSwitch = styled.div`
  margin-top: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 24px;
  border-bottom: 2px solid #35383e;
  height: fit-content;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab-element {
    flex-basis: fit-content;
    padding-left: 17px !important;
    padding-right: 17px !important;
    padding-bottom: 17px;
    margin-bottom: -2px;
  }

  div {
    text-align: center;
    font-family: var(--font-primary);
    font-size: ${(props) => (props.$smallerFont ? "15px" : "16px")};
    font-style: normal;
    letter-spacing: 0.2px;
  }

  .activeUnderline {
    border-radius: 0px !important;
  }
`;

const SubmissionBadge = styled.span`
  text-align: right;
  font-family: var(--font-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 70%; /* 15.6px */
  color: var(--color-gray-500);
  display: inline-flex;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  background: ${(props) =>
    props.active ? "var(--color-pink)" : "var(--disabled-button-text)"};
  margin-left: 6px;
  height: 18px;
  min-width: 18px;
  padding-left: ${(props) => (props.active ? "4" : "5")}px;
`;
