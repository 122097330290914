import { message, Upload } from "antd";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { generateRandomId } from "../helpers/firebase";
import styled from "styled-components";

export default function UploadDragAndDropVideo({
  setVideoUrl,
  maxFileSizeInMb,
}) {
  const { Dragger } = Upload;
  const storage = getStorage();

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "video/mp4";
    if (!isJpgOrPng) {
      message.error("Please upload a MP4 file");
    }
    const isLt2M = file.size / 1024 / 1024 < maxFileSizeInMb;
    if (!isLt2M) {
      message.error(`Video must be smaller than ${maxFileSizeInMb}MB`);
    }
    return isJpgOrPng && isLt2M;
  };

  const props = {
    name: "video",
    multiple: false,
    maxCount: 1,
    // accept: ".mp4",
    beforeUpload,
    action: async (file) => {
      const storageRef = ref(
        storage,
        `questVideos/${new Date()} -- ${generateRandomId()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        () => {
          // handle progress state changes here
        },
        (error) => {
          console.error(error);
          message.error(`${file.name} file upload failed.`);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setVideoUrl(downloadURL);
            message.success(`${file.name} file uploaded successfully`);
            return downloadURL;
          } catch (error) {
            console.error(error);
            message.error(`${file.name} file upload failed.`);
          }
        }
      );
    },
  };

  return (
    <Container>
      <Dragger {...props}>
        <UploadIcon src="/icons/general/upload.svg" alt="" />
        <UploadHint>Drag & drop or click to upload</UploadHint>
      </Dragger>
    </Container>
  );
}

const Container = styled.div`
  .ant-upload.ant-upload-drag {
    height: 155px !important;
    border: 2px dashed var(--color-gray-200) !important;

    &:hover {
      border: 2px dashed var(--color-gray-50) !important;
    }
  }
`;

const UploadIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
`;

const UploadHint = styled.div`
  color: var(--inputFieldText);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.5px; /* 185% */
  letter-spacing: -0.1px;
`;
