import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import { storyworldKeys, userKeys } from "../lib/queryKeys";
import publishedStoryworlds from "../lib/advanced/publishedStoryworlds";
import { useContext, useMemo } from "react";
import userModel from "../lib/firebase/userModel";
import { AuthContext } from "../context/AuthProvider";
import StoryworldCarousel from "./StoryworldCarousel";
import StoryworldCollection from "./StoryworldCollection";
import { useBreakpoints } from "../lib/breakpoints";

export default function MySeriesCollection() {
  const { user } = useContext(AuthContext);
  const { isMobile, isTabletOrAbove } = useBreakpoints();

  const { data: stories = [] } = useQuery({
    queryKey: storyworldKeys.published(),
    queryFn: () => {
      return publishedStoryworlds();
    },
    placeholderData: [],
  });

  const { data: userInfo } = useQuery({
    queryKey: userKeys.user(user?.uid),
    queryFn: () => {
      return userModel.getOneById(user.uid);
    },
    enabled: !!user?.uid,
  });

  const mySeries = useMemo(() => {
    const readStories = userInfo?.readStories;

    if (!readStories || !stories) return [];

    return (
      stories
        // only include stories user has read
        .filter((story) =>
          readStories.some((readStoryId) => {
            return story.id === readStoryId;
          })
        )
        // sort by the order present in the readStories
        .sort((storyA, storyB) => {
          const storyAIndex = readStories.findIndex(
            (readStoryId) => readStoryId === storyA.id
          );
          const storyBIndex = readStories.findIndex(
            (readStoryId) => readStoryId === storyB.id
          );

          return storyAIndex - storyBIndex;
        })
    );
  }, [stories, userInfo?.readStories]);

  return (
    <Container>
      {mySeries.length > 0 && (
        <>
          {isMobile && (
            <StoryworldCollection title={"My Series"} stories={mySeries} />
          )}

          {isTabletOrAbove && (
            <StoryworldCarousel title={"My Series"} stories={mySeries} />
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 22px 0px;
`;
