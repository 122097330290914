import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import PostedComment from "./PostedComment";
import likeModel from "../../lib/firebase/likeModel";
import { trackEvent } from "../../helpers/mixpanel";
import CommentInputProvider from "../../context/CommentInputProvider";
import {
  DIALOG_NAME,
  useOpenParamDialog,
} from "../../helpers/useDialogSearchParam";
import EmptyComments from "./EmptyComments";
import { device, useBreakpoints } from "../../lib/breakpoints";
import Button from "../Button";
import { ReactSVG } from "react-svg";
import { Spacer } from "../../styles/CommonElements";

export default function EpisodeCommentSection({
  comments,
  currentEpisode,
  nextEpisode,
  loadNextEpisode,
  setDeletedComments,
  storyworld,
}) {
  const [topThreeComments, setTopThreeComments] = useState([]);
  const expandCommentSheet = useOpenParamDialog(DIALOG_NAME.comments);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    async function fetchData() {
      await Promise.all(
        comments.map(async (c) => {
          const likeCount = await likeModel.count(["submissionId", "==", c.id]);
          c.likeCount = likeCount;
        })
      );
      const sortedComments = comments.sort(function (a, b) {
        return b.likeCount - a.likeCount || b.createdAt - a.createdAt;
      });
      setTopThreeComments(sortedComments.slice(0, 3));
    }

    fetchData();
  }, [comments]);

  const hasContributors = currentEpisode?.contributors?.length > 0;

  const trackingData = {
    episode_id: currentEpisode.id,
    episode_name: currentEpisode.title,
    storyworld_id: storyworld.id,
    storyworld_name: storyworld.title,
    title: `${storyworld.title}: ${currentEpisode.title}`,
  };

  return (
    <Container>
      <BottomGrid $hasContributors={hasContributors}>
        {/* Empty Column */}
        <div />
        {/* Comments Section */}
        <Comments>
          <CommentsHeader>
            <Header>Top Comments</Header>

            {comments.length > 0 && isMobile && (
              <ViewAll
                onClick={() => {
                  expandCommentSheet();
                  trackEvent("Clicked View All Comments", trackingData);
                }}
              >
                View all
              </ViewAll>
            )}
            {!isMobile && (
              <Button
                width="unset"
                size="small"
                onClick={() => {
                  expandCommentSheet();
                  trackEvent("Clicked Leave a Comment", trackingData);
                }}
              >
                <ReactSVG
                  src="/icons/icon-edit.svg"
                  style={{ marginRight: "0.5rem" }}
                />{" "}
                Leave a comment
              </Button>
            )}
          </CommentsHeader>
          <CommentInputProvider>
            {comments.length > 0 ? (
              <TopCommentsContainer>
                {topThreeComments.map((comment, index) => (
                  <PostedComment
                    comment={{
                      ...comment,
                      // the existance of replyTo is being used to impact the size of the UserDisplay
                      replyTo: null,
                    }}
                    key={index}
                    showReply={false}
                    setDeletedComments={setDeletedComments}
                    mixpanelParams={trackingData}
                  />
                ))}
                <Spacer $marginTop="1rem" />
                <Button
                  variant="ghost"
                  width="unset"
                  onClick={() => {
                    expandCommentSheet();
                    trackEvent("Clicked View All Comments", trackingData);
                  }}
                >
                  See all comments ({comments.length})
                </Button>
              </TopCommentsContainer>
            ) : (
              <EmptyCommentsContainer>
                <EmptyComments
                  headerText="No comments yet"
                  subheaderText="Leave a comment"
                  onSubheaderClick={() => expandCommentSheet()}
                />
              </EmptyCommentsContainer>
            )}
          </CommentInputProvider>
        </Comments>
      </BottomGrid>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  padding: 0px 20px;

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }

  @media ${device.tablet} {
    width: 600px;
    margin: auto;
  }

  @media ${device.desktop} {
    width: 908px;
    margin: auto;
  }
`;

const TopCommentsContainer = styled.div`
  text-align: left;
  margin-top: 10px;

  button {
    margin: auto;
  }
`;

const CommentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-light);
  padding-bottom: 16px;

  @media ${device.tablet}, ${device.desktop} {
    border-bottom: none;
  }
`;

const Header = styled.div`
  color: var(--color-cream-base);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  align-self: center;

  @media ${device.desktop} {
    font-size: 24px;
  }
`;

const EmptyCommentsContainer = styled.div`
  width: 100%;
  margin-top: 34px;
  margin-bottom: 50px;
`;

const ViewAll = styled.button`
  color: var(--color-pink-normal);
  text-align: right;
  /* Small Body Text (Default) */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.5px; /* 189.286% */
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
`;

const BottomGrid = styled.div`
  border-top: 2px solid var(--border-light);
  padding-top: 2.5rem;

  @media ${device.mobile} {
    border-top: none;
    padding-top: unset;
  }

  @media ${device.desktop} {
    max-width: 540px;
    margin: auto;

    ${({ $hasContributors }) =>
      $hasContributors &&
      css`
        max-width: unset;

        display: grid;
        grid-template-columns: 7fr 1fr 4fr;

        & > *:first-child {
          order: 3;
        }

        & > *:nth-child(2) {
          order: 1;
        }
      `}
  }
`;

const Comments = styled.div`
  @media ${device.desktop} {
    /* order: -1; */
  }
`;
