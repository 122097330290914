import React from "react";
import styled from "styled-components";

export default function NumberCircle({ number, showPlus = false, size = 40 }) {
  return (
    <Circle $size={size}>
      <Number $size={size}>
        {showPlus ? "+" : null}
        {number}
      </Number>
    </Circle>
  );
}

const Circle = styled.span`
  height: 100%;
  width: auto;
  margin: auto;
  background: var(--hoverColor);
  border-radius: 40px;
  color: var(--color-gray-500);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  width: ${(props) => props.$size && `${props.$size}px`};
  height: ${(props) => props.$size && `${props.$size}px`};
`;

const Number = styled.div`
  height: 100%;
  margin: auto;
  vertical-align: middle;
  display: inline-block;
  line-height: ${(props) => props.$size && `${props.$size - 2}px`};
`;
