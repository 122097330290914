import React from "react";
import styled from "styled-components";
import Collapse from "../Collapse";
import faqData from "../../helpers/faq";

export default function FaqSection() {
  return (
    <Container>
      <CollapseContainer>
        {faqData.map((faq, index) => (
          <Collapse
            key={index}
            title={faq.title}
            description={faq.description}
          />
        ))}
      </CollapseContainer>

      <div style={{ height: 40 }} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const CollapseContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;
