import DragCard from "./DragCard";
import {
  DndContext,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import styled from "styled-components";

export default function DragComics({
  comicEntries,
  removeComicEntry,
  updateComicOrdering,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <div>
      <Row>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={updateComicOrdering}
        >
          <SortableContext items={comicEntries}>
            {comicEntries.map((item) => (
              <DragCard
                key={item.id}
                comicEntry={item}
                removeComicEntry={removeComicEntry}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Row>
    </div>
  );
}

const Row = styled.div`
  display: flex;
  flex-flow: wrap;
  width: fit-content;

  .grabbable {
    display: inline-block;
  }
`;
