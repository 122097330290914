import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import storyworldModel from "../../lib/firebase/storyworldModel";
import { storyworldKeys } from "../../lib/queryKeys";
import { trackEvent } from "../../helpers/mixpanel";
import { Spacer } from "../../styles/CommonElements";
import { device, useBreakpoints } from "../../lib/breakpoints";
import useFetchQuestCards from "../quests/useFetchQuestCards";
import CreatorPoll from "../CreatorPoll/CreatorPoll";
import QuestCard from "../QuestCard";
import EmptyQuests from "../quests/EmptyQuests";
import { MENU_OPTIONS } from "./StoryworldQuestPageDesktop";
import {
  getQuestAndPollStates,
  isPollCompleted,
  isQuestOpen,
} from "../../helpers/general";

const PAGE_SIZE_DESKTOP = 9;
const PAGE_SIZE_TABLET = 8;

const CARD_WIDTH = "292px";

export default function QuestCardContainer({ extraMenu }) {
  const { storyId: id } = useParams();
  const { isDesktop } = useBreakpoints();

  const PAGE_SIZE = isDesktop ? PAGE_SIZE_DESKTOP : PAGE_SIZE_TABLET;

  const [cardsToDisplay, setCardsToDisplay] = useState(PAGE_SIZE);
  const [displayCards, setDisplayCards] = useState([]);

  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(id),
    queryFn: () => storyworldModel.getOneById(id),
    select: (data) => ({
      ...data,
      heroImage: data.backgroundImageUrl ?? data.imageUrl,
    }),
  });

  const { cards, isLoading } = useFetchQuestCards(storyworldQuery.data);

  const onLoadMore = () => {
    setCardsToDisplay(cardsToDisplay + PAGE_SIZE);
    trackEvent("Clicked Load More", {
      storyworld_id: storyworldQuery.data?.id,
      storyworld_name: storyworldQuery.data?.title,
    });
  };

  useEffect(() => {
    const newCards = cards.slice(0, cardsToDisplay);

    const isCreatorPoll = (card) => card.question || card.question === "";

    if (MENU_OPTIONS.CREATE === extraMenu) {
      setDisplayCards(
        newCards.filter((card) => !isCreatorPoll(card) && isQuestOpen(card))
      );
    } else if (MENU_OPTIONS.VOTE === extraMenu) {
      setDisplayCards(
        newCards.filter(
          (card) =>
            (isCreatorPoll(card) && !isPollCompleted(card)) ||
            (!isCreatorPoll(card) && getQuestAndPollStates(card).poll?.isOpen)
        )
      );
    } else if (MENU_OPTIONS.RESULTS === extraMenu) {
      setDisplayCards(
        newCards.filter(
          (card) =>
            (!isCreatorPoll(card) &&
              getQuestAndPollStates(card).quest.isCompleted &&
              getQuestAndPollStates(card).poll?.isCompleted) ||
            (isCreatorPoll(card) && isPollCompleted(card))
        )
      );
    }
  }, [cards, cardsToDisplay, extraMenu]);

  if (storyworldQuery.status !== "success" || storyworldQuery.data === null)
    return null;

  return (
    <Container>
      <InnerContainer>
        {displayCards.length > 0 ? (
          displayCards.map((card, index) => {
            return card.question || card.question === "" ? (
              <CreatorPoll key={index} creatorPoll={card} width={CARD_WIDTH} />
            ) : (
              <QuestCard quest={card} key={card.id} width={CARD_WIDTH} />
            );
          })
        ) : (
          <EmptyQuests isLoading={isLoading} />
        )}
      </InnerContainer>
      {cardsToDisplay < displayCards.length && (
        <>
          <Spacer $marginTop="2rem" />
          <LoadMore onClick={onLoadMore}>Load More</LoadMore>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin: auto;
`;

const InnerContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;

  @media (${device.tablet}) {
    gap: 13px;
  }
`;

const LoadMore = styled.button`
  display: flex;
  width: 201px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 104px;
  border-radius: 20px;
  border: 2px solid #777e91;
  margin: auto;
  background: none;
  color: var(--color-cream-base);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 8px;
`;
