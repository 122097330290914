import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import dayjs from "dayjs";
import { storyworldKeys } from "../../lib/queryKeys";
import storyworldModel from "../../lib/firebase/storyworldModel";
import { Username } from "../user";
import { device } from "../../lib/breakpoints";
import { Spacer } from "../../styles/CommonElements";

// userInfo does not always equal the full useInfo object. For guests we pass in a partial set of values that match the shape.
const ProfileInfo = ({ isLoading = false, userInfo = {} }) => {
  const { data: isCreator, isLoading: isCreatorStatusLoading } = useQuery({
    queryKey: storyworldKeys.by_creator(userInfo?.id),
    queryFn: () => {
      return storyworldModel.getMany([
        "creators",
        "array-contains",
        userInfo.id,
      ]);
    },
    enabled: !!userInfo?.id,
    select: (data) => {
      return data.length > 0;
    },
    // stale time 24 hours
    staleTime: 1000 * 60 * 60 * 24,
  });

  if (isLoading || isCreatorStatusLoading) {
    return (
      <>
        <LoadingImg className="animate-pulse" />
        <LoadingTitle className="animate-pulse" />
        <LoadingTenure className="animate-pulse" />
      </>
    );
  }

  return (
    <>
      {userInfo?.pfp && (
        <ProfileImg alt="the user's profile image" src={userInfo.pfp} />
      )}
      {userInfo?.username && (
        <ProfileTitle>
          {userInfo?.id ? (
            <Username userId={userInfo.id} isCreator={isCreator} />
          ) : (
            <Guest>{userInfo.username}</Guest>
          )}
        </ProfileTitle>
      )}
      {userInfo?.bio && <ProfileBio>{userInfo.bio}</ProfileBio>}
      {userInfo?.createdAt && (
        <>
          <Spacer $marginTop="8px" />
          <ProfileTenure>
            Joined {dayjs(userInfo.createdAt).format("MMM YYYY")}
          </ProfileTenure>
        </>
      )}
    </>
  );
};

const ProfileBio = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 2px;

  @media ${device.tablet}, ${device.desktop} {
    color: var(--color-gray-50);
    text-align: center;
    font-style: normal;
    font-weight: 500;
  }

  @media (${device.tablet}) {
    font-size: 11px;
    line-height: 160%;
  }

  @media (${device.desktop}) {
    font-size: 12px;
    line-height: normal;
  }
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;

  @media (${device.tablet}) {
    width: 80px;
    height: 80px;
  }
`;

export const ProfileTitle = styled.h1`
  margin-top: 18px;
  margin-bottom: 4px;

  @media ${device.tablet}, ${device.desktop} {
    margin: 0;
  }
`;

// Matches Username.jsx styling
const Guest = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  font-family: var(--font-primary);

  @media ${device.tablet}, ${device.desktop} {
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
  }
`;

const ProfileTenure = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 160%;
  color: var(--color-gray-150);
`;

const LoadingTitle = styled.div`
  width: 20ch;
  height: 20px;
  background-color: var(--color-gray-200);
  margin-top: 16px;
  border-radius: 10px;
`;

const LoadingTenure = styled.div`
  width: 10ch;
  height: 12px;
  background-color: var(--color-gray-200);
  margin-top: 4px;
  border-radius: 6px;
`;

const LoadingImg = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: var(--color-gray-200);
`;

export default ProfileInfo;
