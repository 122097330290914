import { useContext, createRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import party from "party-js";
import styled, { css } from "styled-components";
import { trackEvent } from "../helpers/mixpanel";
import { likeKeys, userKeys } from "../lib/queryKeys";
import likeModel from "../lib/firebase/likeModel";
import { useAlertSourceContext } from "../context/AlertSourceContext";
import { AuthContext } from "../context/AuthProvider";
import { optimisticUpdate } from "../lib/client";
import { device } from "../lib/breakpoints";

const heartPink = "/icons/heart-pink.svg";
const heartGrayOutline = "/icons/heart-gray-outline.svg";
const heartWhiteOutline = "/icons/heart-white-outline.svg";

const releaseParticles = (ref) => {
  party.resolvableShapes["heart"] = `<img src="/icons/heart-pink.svg" />`;
  party.sparkles(ref.current, {
    count: party.variation.range(10, 15),
    size: 0.7,
    speed: 70,
    lifetime: 1,
    shapes: ["heart"],
  });
};

export default function LikeButton({
  onClick = null,
  mixpanelParams = null,
  itemType,
  itemId,
  ghost,
}) {
  const { sourceType, sourceId } = useAlertSourceContext();
  const { user, requireLogin } = useContext(AuthContext);

  let heartRef = createRef();
  let disabledButtonRef = createRef();

  const queryClient = useQueryClient();
  const { data: likes } = useQuery({
    queryKey: likeKeys.item(itemId),
    queryFn: () => {
      return likeModel.getMany(["submissionId", "==", itemId]);
    },
    placeholderData: [],
  });

  const { mutate: like } = useMutation({
    mutationFn: (args) => likeModel.create(args),
    onMutate: async ({ itemId }) => {
      return optimisticUpdate(queryClient, likeKeys.item(itemId), (oldData) => {
        return [...oldData, { creator: user.uid, id: itemId }];
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(likeKeys.item(itemId));
      queryClient.invalidateQueries(userKeys.profile_liked(user.uid));
    },
  });

  const { mutate: unlike } = useMutation({
    mutationFn: (args) => likeModel.delete(args),
    onMutate: ({ likeId }) => {
      return optimisticUpdate(queryClient, likeKeys.item(itemId), (oldData) => {
        return oldData.filter((like) => like.id !== likeId);
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(likeKeys.item(itemId));
      queryClient.invalidateQueries(userKeys.profile_liked(user.uid));
    },
  });

  const isGhost = ghost;
  const userLikeId = likes?.filter((like) => like.creator === user?.uid)[0]?.id;

  const likeOrUnlike = async (event) => {
    if (!user) {
      requireLogin();
      return;
    }
    onClick && onClick(event);
    if (userLikeId) {
      unlike({ likeId: userLikeId });
    } else {
      releaseParticles(heartRef);
      like({
        itemType,
        itemId,
        sourceType,
        sourceId,
      });
      if (itemId && itemType !== "comment") {
        trackEvent("Liked User Submission", {
          submission_id: itemId,
          // We may want to provide one last context element to keep a running mixpanel location
          location: sourceType,
        });
      } else if (itemId && itemType === "comment") {
        trackEvent("Liked Comment", mixpanelParams);
      }
    }
  };

  return (
    <Container $isLiked={Boolean(userLikeId)} $isGhost={isGhost}>
      {!itemId ? (
        <ClickButton
          ref={disabledButtonRef}
          onClick={() => {
            releaseParticles(disabledButtonRef);
          }}
        >
          <Heart src={heartPink} alt="" />
        </ClickButton>
      ) : (
        <ClickButton
          ref={heartRef}
          $hasLikes={likes.length > 0}
          $isGhost={isGhost}
        >
          <Heart
            src={
              userLikeId
                ? heartPink
                : isGhost
                ? heartGrayOutline
                : heartWhiteOutline
            }
            alt=""
            $isLiked={Boolean(userLikeId)}
            $isGhost={isGhost}
          />
          {likes.length > 0 && <LikeAmount>{likes.length}</LikeAmount>}
          <ClickButtonOverlay onClick={likeOrUnlike} />
        </ClickButton>
      )}
    </Container>
  );
}

const ClickButton = styled.button`
  width: auto;
  height: 30px;
  border-radius: 65px;

  padding: 0px;

  background: ${(props) =>
    !props.$isGhost ? "var(--color-gray-400)" : "none"};
  color: ${(props) =>
    props.$isGhost ? "var(--disabled-button-text)" : "var(--color-cream-base)"};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: ${(props) => (props.$isGhost ? "500" : "600")};
  border: none;
  position: relative;

  &:disabled {
    background: var(--color-gray-400);
    color: var(--hoverColor);
  }

  &:active {
    background: auto;
    color: auto;
  }

  &:hover {
    background: auto;
    color: auto;
  }

  &:focus {
    background: auto;
    color: auto;
  }

  @media ${device.mobile} {
    /* Extra space for tapping on mobile */
    padding: 0px 10px;
  }
`;

const Container = styled.span`
  ${({ $hasLikes, $isGhost }) =>
    $hasLikes
      ? css`
          color: var(--color-pink);
          height: 32px;
          font-weight: ${$isGhost ? "500" : "600"};
          border: ${$isGhost ? "2px solid var(--color-gray-400)" : "none"};
          background: ${$isGhost ? "none" : "var(--color-gray-400)"};
        `
      : css`
          height: 30px;

          &:focus {
            color: auto;
          }
        `}

  &:hover {
    color: auto;
  }
`;

const Heart = styled.img`
  margin-bottom: 1px;
  margin-right: 1px;
  height: 16px;
  width: 16px;

  @media ${device.tablet}, ${device.desktop} {
    height: 20px;
    width: 20px;
  }
`;

const LikeAmount = styled.span`
  margin-left: 5px;
`;

const overlaySizeOverage = 14;
const overlayPositionVal = -1 * (overlaySizeOverage / 2);

const ClickButtonOverlay = styled.div`
  position: absolute;
  height: calc(100% + ${overlaySizeOverage}px);
  width: calc(100% + ${overlaySizeOverage}px);
  border-radius: 65px;
  background: none;
  top: ${overlayPositionVal}px;
  right: ${overlayPositionVal}px;
`;
