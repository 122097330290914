import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FieldContainer,
  FormError,
  InputHeader,
  Spacer,
  StyledCheckbox,
  StyledInput,
} from "../../styles/CommonElements";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthProvider";
import RevealPassword from "../RevealPassword";
import { Link } from "react-router-dom";
import Button from "../Button";
import OrDivider from "../OrDivider";

const passwordLowerThreshold = 6;
const passwordUpperThreshold = 15;

const AccountCreationForm = ({ isThirdPartyUser, isSubmitting, onSubmit }) => {
  const { user } = useContext(AuthContext);
  const [passwordRevealed, setPasswordRevealed] = useState(false);
  const [passwordConfirmedRevealed, setPasswordConfirmedRevealed] =
    useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      agreedToTerms: true,
    },
  });

  useEffect(() => {
    if (isThirdPartyUser && user) {
      setValue("email", user.email, {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
  }, [isThirdPartyUser, setValue, user]);

  // Normally would inline this, but we need to access the ref that hook form uses to track the inputs for RevealPassword
  const { ref: passwordRef, ...passwordInputProps } = register("password", {
    required: isThirdPartyUser ? false : "Please enter a password",
    minLength: {
      value: passwordLowerThreshold,
      message: `Password must be at least ${passwordLowerThreshold} characters`,
    },
    maxLength: {
      value: passwordUpperThreshold,
      message: `Password must be less than ${passwordUpperThreshold} characters`,
    },
  });

  const { ref: confirmPasswordRef, ...confirmPasswordInputProps } = register(
    "passwordConfirm",
    {
      required: isThirdPartyUser ? false : "Please confirm your password",
      validate: (value) =>
        watch("password") === value || "Passwords do not match",
    }
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputHeader htmlFor="email">Email</InputHeader>
        <InputContainer>
          <StyledInput
            id="email"
            placeholder="email@email.com"
            disabled={isThirdPartyUser}
            className={isThirdPartyUser && "locked"}
            type="email"
            {...register(
              "email",
              // These inline validations can be replaced by Zod
              {
                required: "Please enter your email address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              }
            )}
          />
          {isThirdPartyUser && <Checkmark src="/icons/checkmark.svg" />}
        </InputContainer>
        {errors.email && <FormError>{errors.email.message}</FormError>}
        <Spacer $marginTop="1rem" />

        {!isThirdPartyUser && (
          <>
            <InputHeader htmlFor="password">Password</InputHeader>
            <InputContainer>
              <StyledInput
                {...passwordInputProps}
                id="password"
                className="transition-colors"
                placeholder={`Must be at least ${passwordLowerThreshold} characters`}
                maxLength={40}
                type={passwordRevealed ? "text" : "password"}
                ref={passwordRef}
              />
              <RevealPassword
                isRevealed={passwordRevealed}
                toggleRevealed={() => setPasswordRevealed(!passwordRevealed)}
                passwordRef={passwordRef}
              />
            </InputContainer>
            {errors.password && (
              <FormError>{errors.password.message}</FormError>
            )}

            <Spacer $marginTop="1rem" />

            <InputHeader htmlFor="password-confirm">
              Confirm password
            </InputHeader>
            <InputContainer>
              <StyledInput
                {...confirmPasswordInputProps}
                id="password-confirm"
                className="transition-colors"
                placeholder="Type your password again"
                maxLength={40}
                type={passwordConfirmedRevealed ? "text" : "password"}
                ref={confirmPasswordRef}
              />
              <RevealPassword
                isRevealed={passwordConfirmedRevealed}
                toggleRevealed={() =>
                  setPasswordConfirmedRevealed(!passwordConfirmedRevealed)
                }
                passwordRef={confirmPasswordRef}
              />
            </InputContainer>
            {errors.passwordConfirm && (
              <FormError>{errors.passwordConfirm.message}</FormError>
            )}

            <Spacer $marginTop="1rem" />
          </>
        )}

        <FieldContainer style={{ flexDirection: "row", gap: "0.75rem" }}>
          {/* Controller is a helper component from hook form made to deal with external library (like antd) controlled components */}
          <Controller
            name="agreedToTerms"
            control={control}
            rules={{
              required:
                "You must agree to the terms of service before registering",
            }}
            render={({ field }) => (
              <StyledCheckbox checked={field.value} {...field} />
            )}
          />

          <TermsOfService>
            I have read and agree to the{" "}
            <StyledLink to="/terms-of-use" target="_blank">
              Terms of Service
            </StyledLink>
            .
          </TermsOfService>
        </FieldContainer>
        {errors.agreedToTerms && (
          <FormError>{errors.agreedToTerms.message}</FormError>
        )}

        <Spacer $marginTop="1.25rem" />

        <Button
          type="submit"
          size="large"
          isLoading={isSubmitting}
          disabled={!isValid || isSubmitting}
        >
          Create Account
        </Button>
      </form>
      {!isThirdPartyUser && (
        <>
          <Spacer $marginTop="1rem" />
          <OrDivider />
          <Spacer $marginTop="1rem" />
          {/*
          <ThirdPartyAuth cta="Sign up" />
          */}
        </>
      )}
    </>
  );
};

const InputContainer = styled.div`
  position: relative;

  .locked {
    color: var(--seafoam);
  }
`;

const Checkmark = styled.img`
  right: 14px !important;
  top: 35%;
  height: 12px;
  position: absolute;
`;

const StyledLink = styled(Link)`
  color: var(--color-pink);
`;

const TermsOfService = styled.div`
  color: var(--color-gray-50);
`;

export default AccountCreationForm;
