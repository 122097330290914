import { Col, Row } from "antd";
import React, { useMemo } from "react";
import { styled } from "styled-components";
import Backlink from "../components/Backlink";

export default function ComicReaderMobileHeader({
  episodes,
  currentEpisodeNumber,
  storyworldId,
}) {
  const episodeTitle = useMemo(() => {
    if (currentEpisodeNumber && episodes.length > 0) {
      const currentEp = episodes.find(
        (ep) => ep.number === currentEpisodeNumber
      );
      return currentEp ? currentEp.title : "";
    }

    return "";
  }, [currentEpisodeNumber, episodes]);

  return (
    <Container>
      <Row
        style={{
          margin: "auto !important",
          height: "inherit",
          padding: "0% 2%",
        }}
      >
        <Col span={2} className="margin-auto">
          <Backlink to={`/stories/${storyworldId}`} />
        </Col>
        <Col span={20} className="margin-auto comic-reader-header-title">
          <EpisodeTitleText>{episodeTitle}</EpisodeTitleText>
        </Col>
        <Col span={2} className="margin-auto">
          {/*
            <ShareButton
              message="Episode Share"
              inCircle={true}
              title={selectedStoryworld ? selectedStoryworld.title : ""}
              location="episode"
              episode={episodes[currentEpisodeNumber - 1 + nextEpisodeIndex]}
              storyworldId={storyworldId}
              imageUrl={
                selectedStoryworld
                  ? selectedStoryworld.backgroundImageUrl
                  : null
              }
            />
            */}
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 50px;
  background: #353a46;
  position: fixed;
  top: 0;
  padding: 0px 4px;
  overflow: auto;
  overflow-y: hidden;
  padding: 0px 4px;
`;

const EpisodeTitleText = styled.span`
  width: 65%;
  font-size: 12px;
  text-align: center;
  color: white;
  text-align: center;

  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  font-weight: 600;
  padding: 0px !important;
  margin: auto 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
