import { createContext, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getStoryworldById } from "../helpers/firebase";
import { storyworldKeys } from "../lib/queryKeys";

export const SelectedStoryworldContext = createContext();

const SelectedStoryworldProvider = ({ children }) => {
  const [selectedStoryworld, setSelectedStoryworld] = useState(null);
  const [storyworldElement, setStoryworldElement] = useState(null);

  const { storyId } = useParams();

  const { data: urlStoryworld } = useQuery({
    queryKey: storyworldKeys.storyworld(storyId),
    queryFn: () => {
      return getStoryworldById(storyId);
    },
    enabled: !!storyId,
  });

  useEffect(() => {
    if (urlStoryworld) {
      setSelectedStoryworld(urlStoryworld);
    } else {
      setSelectedStoryworld(null);
      setStoryworldElement(null);
    }
  }, [urlStoryworld]);

  return (
    <SelectedStoryworldContext.Provider
      value={{
        selectedStoryworld,
        setSelectedStoryworld,
        storyworldElement,
        setStoryworldElement,
      }}
    >
      {children}
    </SelectedStoryworldContext.Provider>
  );
};

export default SelectedStoryworldProvider;
