import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { device, useBreakpoints } from "../lib/breakpoints";
import Slider from "react-slick";
import StoryCard from "./stories/StoryCard";
import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
import StoryHoverCard from "./stories/StoryHoverCard";

const carouselAnimationSpeedInSeconds = 0.5;

const TABLET_PAGE_AMOUNT = 5;
const DESKTOP_PAGE_AMOUNT = 6;

const LEFT_ARROW_ICON = "/icons/stories/carousel-left-carat.svg";
const RIGHT_ARROW_ICON = "/icons/stories/carousel-right-carat.svg";

export default function StoryworldCarousel({
  stories,
  title,
  showActiveMark = true,
}) {
  let carouselRef = React.createRef();

  const { selectedStoryworld, storyworldElement } = useContext(
    SelectedStoryworldContext
  );

  const { isTablet, isDesktop } = useBreakpoints();
  const [itemsPerPage, setItemsPerPage] = useState(TABLET_PAGE_AMOUNT);
  const [currentSlide, setCurrentSlide] = useState(0);

  const pageCount = Math.ceil(stories.length / itemsPerPage);

  const afterChange = (newSlide) => {
    setCurrentSlide(Math.ceil(newSlide / itemsPerPage));
  };

  // Hack to ensure the carousel is instantiated correctly on the first slide
  useEffect(() => {
    if (carouselRef?.current && currentSlide === 0) {
      carouselRef.current.slickGoTo(0);
    }
  }, [carouselRef, currentSlide]);

  useEffect(() => {
    if (isTablet) {
      setItemsPerPage(TABLET_PAGE_AMOUNT);
    } else if (isDesktop) {
      setItemsPerPage(DESKTOP_PAGE_AMOUNT);
    }
  }, [isDesktop, isTablet]);

  return (
    <>
      <Container $storiesAmount={stories.length}>
        {title && <Title>{title}</Title>}
        <CarouselContainer>
          <StyledCarousel
            afterChange={afterChange}
            arrows={itemsPerPage < stories.length}
            autoplay={false}
            dots={false}
            infinite={false}
            initialSlide={0}
            pauseOnHover={true}
            ref={carouselRef}
            slidesToShow={itemsPerPage}
            slidesToScroll={itemsPerPage}
            speed={carouselAnimationSpeedInSeconds * 1000}
            nextArrow={
              currentSlide + 1 < pageCount ? (
                <Arrow src={RIGHT_ARROW_ICON} alt="" to="next" />
              ) : (
                <></>
              )
            }
            prevArrow={
              currentSlide !== 0 ? (
                <Arrow src={LEFT_ARROW_ICON} alt="" to="prev" />
              ) : (
                <></>
              )
            }
          >
            {stories.map((storyworld) => (
              <StoryCard
                key={storyworld.id}
                showActiveMark={showActiveMark}
                storyworld={storyworld}
              />
            ))}
          </StyledCarousel>

          {pageCount > 1 && (
            <Pagination>
              {Array.from({
                length: pageCount,
              }).map((_, index) => (
                <Dot
                  key={index}
                  onClick={() => {
                    carouselRef.current.slickGoTo(index * itemsPerPage);
                    setCurrentSlide(index);
                  }}
                  $activeSlide={index === currentSlide}
                />
              ))}
            </Pagination>
          )}
        </CarouselContainer>
      </Container>

      <StoryHoverCard
        isVisible={selectedStoryworld !== null}
        storyworld={selectedStoryworld}
        target={storyworldElement}
      />
    </>
  );
}

const Container = styled.div`
  text-align: left;

  .slick-arrow {
    background: none;
  }

  .slick-track {
    display: flex;
    margin: 0;

    @media (${device.tablet}) {
      transform: ${(props) =>
        props.$storiesAmount <= TABLET_PAGE_AMOUNT && "unset !important"};
    }

    @media (${device.desktop}) {
      transform: ${(props) =>
        props.$storiesAmount <= DESKTOP_PAGE_AMOUNT && "unset !important"};
    }
  }
`;

const Arrow = styled.img`
  height: 32px;
  width: auto;
  margin-left: -24px;
  margin-right: -18px;
  cursor: pointer;
  color: #949ba4;

  @media (${device.desktop}) {
    height: 40px;
    margin-left: -32px;
    margin-right: -18px;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 1rem;
`;

const Dot = styled.div`
  border-radius: 100px;
  cursor: pointer;
  height: 8px;
  transition: width 150ms ease-out;
  width: ${(props) => (props.$activeSlide ? "32px" : "8px")};
  background: ${(props) =>
    props.$activeSlide ? "var(--color-pink)" : "#949BA4"};
  z-index: 0;

  @media (${device.tablet}) {
    margin-top: -0.5rem;
  }

  @media (${device.desktop}) {
    margin-top: 0.5rem;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-top: 24px;
  z-index: 1;

  @media (${device.tablet}) {
    gap: 8px;
  }

  @media (${device.desktop}) {
    gap: 8px;
  }
`;

const StyledCarousel = styled(Slider)`
  height: fit-content;
  width: 100%;
  background: transparent;
`;

const Title = styled.div`
  color: var(--color-cream);
  text-align: left;
  text-transform: capitalize;
  font-family: Arial;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 18px;
`;
