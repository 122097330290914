import "react-photo-album/columns.css";
import styled from "styled-components";
import UploadProvider from "./UploadProvider";
import { PaddedContainer, Spacer } from "../styles/CommonElements";
import { maxIdeaBlockEntries } from "../helpers/constants";
import SuggestedSearchSelector from "./SuggestedSearchSelector";

const SearchImagesEmpty = ({
  addEntry,
  entries,
  failedSearchQuery,
  selectSearchQuery,
  suggestedSearchTerms,
}) => {
  return (
    <>
      <PaddedContainer>
        <Content>Nothing found for</Content>
        <Content>"{failedSearchQuery}"</Content>
        <Spacer $marginTop="1.5rem" />

        {entries.length >= maxIdeaBlockEntries && (
          <Content>Try searching something different</Content>
        )}

        {entries.length < maxIdeaBlockEntries && (
          <>
            <Content>Try searching something different or</Content>
            <UploadProvider
              onSuccess={addEntry}
              fileBucket="submissions"
              isDisabled={entries.length >= maxIdeaBlockEntries}
            >
              <Highlight>upload your own image.</Highlight>
            </UploadProvider>
          </>
        )}
      </PaddedContainer>

      {suggestedSearchTerms.length > 0 && (
        <>
          <Spacer $marginTop="3rem" />
          <SuggestedSearchSelector
            selectSearchQuery={selectSearchQuery}
            suggestedSearchTerms={suggestedSearchTerms}
          />
        </>
      )}
    </>
  );
};

const Content = styled.div`
  color: var(--disabled-button-text);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.2px;
`;

const Highlight = styled(Content)`
  color: var(--color-pink);
`;

export default SearchImagesEmpty;
