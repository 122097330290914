import { getAuth } from "firebase/auth";
import { alertConfigsTable } from "../../helpers/tables";
import { configs } from "../../helpers/constants";
import { queryMany, queryOne } from "./all";
import { doc, setDoc, getFirestore, updateDoc } from "firebase/firestore";
import { db } from "../../helpers/firebase";

const table = alertConfigsTable;

const alertConfigModel = {
  create: async (providedUser) => {
    const user = getAuth().currentUser || providedUser;
    if (!user) throw new Error("User not logged in");

    const allSettings = Object.values(configs).reduce(
      (acc, settingId) => ({ ...acc, [settingId]: true }),
      {}
    );

    await setDoc(doc(db, table, user.uid), {
      createdAt: new Date(),
      lastUpdated: new Date(),
      userId: user.uid,
      episodeEmailStories: [],
      episodeAlertStories: [],
      questAlertStories: [],
      ...allSettings,
    });

    const queryResult = await queryOne({ table, id: user.uid });
    if (!queryResult) throw new Error("Gloabl alert config was not created");
    return queryResult;
  },
  update: async ({ id, settings }) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const tableRef = doc(getFirestore(), table, id);
    await updateDoc(tableRef, {
      lastUpdated: new Date(),
      ...settings,
    });
    return queryOne({ table, id });
  },
  // The document id is the user's id
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default alertConfigModel;
