import React from "react";
import Submission from "../components/submissions/Submission";
import { LikeButton, UserDisplay } from "../components";
import { styled } from "styled-components";
import AlertSourceContext, { SOURCE } from "../context/AlertSourceContext";
import { LikeButtonOverlay } from "../styles/CommonElements";

export default function GalleryEntry({
  entry,
  showText = false,
  hideUi = false,
  winner = false,
}) {
  return (
    <AlertSourceContext.Provider
      value={{ sourceType: SOURCE.submission, sourceId: entry.id }}
    >
      <Container>
        <Submission
          submission={entry}
          position="relative"
          showText={showText}
          isClickable={true}
          winner={winner}
          isImageQuest
        />
        {!hideUi && (
          <>
            <UserDisplayContainer>
              <UserDisplay userId={entry ? entry.creator : null} size={28} />
            </UserDisplayContainer>
            <LikeButtonOverlay>
              <LikeButton itemType="submission" itemId={entry?.id} />
            </LikeButtonOverlay>
          </>
        )}
      </Container>
    </AlertSourceContext.Provider>
  );
}

const Container = styled.div`
  text-align: left;
  width: 100%;
  margin: auto;
  margin-top: 0px;

  .ant-image {
    width: 100%;
  }
`;

const UserDisplayContainer = styled.div`
  height: auto;
  position: absolute;
  top: 2%;
  left: 0%;
  font-weight: 800;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  color: white;
`;
