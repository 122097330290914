import styled from "styled-components";
import GenreCollections from "../components/GenreCollections";
import ActiveStoryworldsCollection from "../components/ActiveStoryworldsCollection";
import MySeriesCollection from "../components/MySeriesCollection";
import { AuthContext } from "../context/AuthProvider";
import { useContext, useEffect } from "react";
import PromoCarousel from "../components/PromoCarousel";
import { Line, Spacer } from "../styles/CommonElements";
import { Link, useLocation } from "react-router-dom";
import { device } from "../lib/breakpoints";

const GENRE_PROMO_TABLET = "/images/cta/explore-by-genre-tablet.svg";
const GENRE_PROMO_DESKTOP = "/images/cta/explore-by-genre-desktop.svg";

export default function Storyworlds() {
  const location = useLocation();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (location.pathname === "/") {
      location.pathname = "/stories";
      history.pushState({ urlPath: "/stories" }, "", "/stories");
    }
  }, [location]);

  return (
    <Container>
      <DarkSection>
        <PromoCarousel />

        <CollectionContainer>
          <InnerContainer>
            {user && <MySeriesCollection />}
            <ActiveStoryworldsCollection />

            <GenreDivider>
              <Spacer $marginTop="3rem" />
              <Line />
              <Spacer $marginTop="3rem" />
              <Link to="/genres">
                <BannerImage />
              </Link>
              <Spacer $marginTop="2rem" />
            </GenreDivider>

            <LightSection>
              <GenreCollections />
            </LightSection>
          </InnerContainer>
        </CollectionContainer>
      </DarkSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BannerImage = styled.div`
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;

  @media ${device.tablet} {
    height: 110px;
    background-image: url(${GENRE_PROMO_TABLET});
  }

  @media ${device.desktop} {
    height: 127px;
    background-image: url(${GENRE_PROMO_DESKTOP});
  }
`;

const CollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    padding-top: 1rem;
  }

  @media ${device.desktop} {
    padding-top: 1rem;
  }
`;

const DarkSection = styled.div`
  width: 100%;
  background: url("/images/stories/bg-pattern.svg");
  background-repeat: repeat;
`;

const GenreDivider = styled.div`
  width: 100%;
  height: fit-content;
  display: none;

  @media ${device.tablet} {
    display: block;
  }

  @media ${device.desktop} {
    display: block;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 596px;
    margin: auto;
  }

  @media ${device.desktop} {
    width: 908px;
    margin: auto;
  }
`;

const LightSection = styled.div`
  background-color: var(--color-gray-500);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px 0px 40px 0px;
  border-radius: 20px 20px 0px 0px;
  border-top: 1px solid var(--border-light);

  @media ${device.tablet} {
    border: 0;
  }

  @media ${device.desktop} {
    border: 0;
  }
`;
