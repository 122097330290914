import React, { useContext } from "react";
import styled from "styled-components";
import CommentSection from "./CommentSection";
import { CommentInputContext } from "../../context/CommentInputProvider";

import AlertSourceContext from "../../context/AlertSourceContext";
import EmptyComments from "./EmptyComments";

export default function SubmissionCommentSection({
  comments,
  showSort,
  sortType,
  setSortType,
}) {
  const { sourceId } = useContext(AlertSourceContext);
  const { isFocused } = useContext(CommentInputContext);

  return (
    <>
      <Container>
        {comments.length > 0 ? (
          <>
            <CommentsCount>{`Comments (${comments.length})`}</CommentsCount>
            <CommentsContainer>
              <CommentSection
                comments={comments}
                showSort={showSort}
                sortType={sortType}
                setSortType={setSortType}
                mixpanelParams={{
                  location: "submission",
                  submission_id: sourceId,
                }}
              />
            </CommentsContainer>
          </>
        ) : (
          <EmptyCommentsContainer $isFocused={isFocused}>
            <EmptyComments
              headerText="No comments yet"
              subheaderText="Be the first to leave kudos!"
            />
          </EmptyCommentsContainer>
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  position: relative;

  &::-webkit-scrollbar {
    display: none !important;
    background: none !important;
    opacity: 0 !important;
  }
`;

const EmptyCommentsContainer = styled.div`
  width: 100%;
  margin-top: 34px;
  margin-bottom: 50px;
`;

const CommentsContainer = styled.div`
  text-align: left;
  margin-top: 10px;
`;

const CommentsCount = styled.div`
  font-family: var(--font-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: var(--color-cream);

  margin-bottom: 26px;
`;
