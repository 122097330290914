import React from "react";
import { styled } from "styled-components";

export default function EmptyComments({
  headerText,
  subheaderText,
  onSubheaderClick,
}) {
  const subheaderClickable = Boolean(onSubheaderClick);
  return (
    <>
      <CarrotIconSection>
        <CarrotIcon src="/icons/social/carrot-gray.svg" alt="" />
      </CarrotIconSection>
      {headerText && <EmptyHeader>{headerText}</EmptyHeader>}
      {subheaderText && (
        <EmptySubheader
          as={subheaderClickable ? "button" : "div"}
          onClick={onSubheaderClick}
          $isClickable={subheaderClickable}
        >
          {subheaderText}
        </EmptySubheader>
      )}
    </>
  );
}

const EmptyHeader = styled.div`
  color: var(--color-cream-base);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
`;

const EmptySubheader = styled.div`
  color: ${(props) =>
    props.$isClickable ? "var(--color-pink)" : "var(--color-gray)"};
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-top: 6px;

  /* Button reset */
  padding: 0;
  border: none;
  outline: none;
  background: none;
`;

const CarrotIcon = styled.img`
  color: var(--color-gray);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: auto;
  text-align: center;
`;

const CarrotIconSection = styled.div`
  margin: auto;
  text-align: center;
`;
