import { useContext, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { styled } from "styled-components";
import UserDisplay from "../UserDisplay";
import storyworldModel from "../../lib/firebase/storyworldModel";
import Episodes from "../../views/Episodes";
import { adminUserId } from "../../helpers/constants";
import { bookmarkKeys, storyworldKeys } from "../../lib/queryKeys";
import { trackEvent } from "../../helpers/mixpanel";
import { getBookmarkByStoryworldId } from "../../helpers/firebase";
import { Spacer } from "../../styles/CommonElements";
import usePublishedEpisodesQuery from "../../lib/usePublishedEpisodesQuery";
import { isCatalogComic } from "../../helpers/general";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { device } from "../../lib/breakpoints";
import Button from "../Button";
import useFetchQuestCards from "../quests/useFetchQuestCards";
import QuestCard from "../QuestCard";
import CreatorPoll from "../CreatorPoll/CreatorPoll";
import { ShareModal } from "../general";
import { comicUrl, storyworldQuestsUrl } from "../../lib/routes";

const SHARE_ICON = "/icons/share-hollow.svg";

export default function StoryworldDetailDesktop({ toggleStoryworldFollow }) {
  const { storyId: id } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const { addBackLocation } = useContext(BackLocationsContext);

  const { episodes } = usePublishedEpisodesQuery(id);

  const storyworldQuery = useQuery({
    queryKey: storyworldKeys.storyworld(id),
    queryFn: () => storyworldModel.getOneById(id),
    select: (data) => ({
      ...data,
      heroImage: data.backgroundImageUrl ?? data.imageUrl,
    }),
  });

  const { cards } = useFetchQuestCards(storyworldQuery.data);

  const { data: bookmark } = useQuery({
    queryKey: bookmarkKeys.storyworld(id),
    queryFn: () => {
      return getBookmarkByStoryworldId(id);
    },
    enabled: !!id,
  });

  // Get the first quest to feature on the page, and if that fails get a creatorPoll if available
  const questCard =
    storyworldQuery.data?.type !== "storyworld"
      ? null
      : cards.find((e) => e.question === null || e.question === "") ??
        cards?.[0];

  const ctaEpisodeNumber = useMemo(() => {
    if (!bookmark) {
      return 1;
    }

    const bookmarkedEpisode = (episodes ?? []).find(
      (episode) => episode.id === bookmark.episodeId
    );

    return bookmarkedEpisode ? bookmarkedEpisode.number : 1;
  }, [bookmark, episodes]);

  if (storyworldQuery.status !== "success" || storyworldQuery.data === null)
    return null;

  return (
    <>
      <Container>
        <Spacer $marginTop="1.25rem" />

        <DesktopContainer>
          <DesktopLeftContainer>
            <Image
              src={
                storyworldQuery.data.backgroundImageUrl ??
                storyworldQuery.data.imageUrl
              }
            />
            <Spacer $marginTop="1rem" />
            <Section $noRightPadding={true}>
              <Spacer $marginTop="-0.5rem" />
              <Episodes
                bookmark={bookmark}
                storyworld={storyworldQuery.data}
                storyworldId={id}
                episodes={episodes}
                toggleStoryworldFollow={toggleStoryworldFollow}
              />
            </Section>
          </DesktopLeftContainer>

          <DesktopRightContainer>
            <Section>
              <Genre $isPink={true}>{storyworldQuery.data?.genre}</Genre>
              <Spacer $marginTop="0.25rem" />
              <Title>{storyworldQuery.data?.title}</Title>
              <Spacer $marginTop="1.5rem" />
              <Description>{storyworldQuery.data?.description}</Description>
              <Spacer $marginTop="1.5rem" />
              <CreatedBy>Created by</CreatedBy>
              <Spacer $marginTop="0.3rem" />
              <Users>
                {storyworldQuery.data.creators.map(
                  (creatorId) =>
                    creatorId !== adminUserId && (
                      <UserDisplay
                        key={creatorId}
                        size={25}
                        userId={creatorId}
                        showName={true}
                        isAuthor={true}
                      />
                    )
                )}
              </Users>
              <Spacer $marginTop="1.5rem" />

              <Link
                to={comicUrl(id, ctaEpisodeNumber.toString())}
                onClick={() => {
                  trackEvent("Clicked Read Episode CTA", {
                    storyworld_id: id,
                    storyworld_name: storyworldQuery.data.title,
                  });
                  addBackLocation(`/stories/${id}`);
                }}
              >
                <Button size="medium" rounded="slight">
                  {bookmark
                    ? `Continue Episode ${ctaEpisodeNumber}`
                    : "Read Episode 1"}
                </Button>
              </Link>
              {!storyworldQuery.data.type ||
                (!isCatalogComic(storyworldQuery.data.type) && (
                  <>
                    <Spacer $marginTop="0.5rem" />
                    <Link
                      to={storyworldQuestsUrl(id)}
                      onClick={() => {
                        trackEvent("Clicked See Quests CTA", {
                          storyworld_id: id,
                          storyworld_name: storyworldQuery.data.title,
                        });
                        addBackLocation(`/stories/${id}`);
                      }}
                    >
                      <Button size="medium" variant="ghost" rounded="slight">
                        <UnicodeChar>🕮</UnicodeChar> See Quests
                      </Button>
                    </Link>
                  </>
                ))}
              <Spacer $marginTop="0.25rem" />
              <ShareButton
                size="medium"
                variant="text"
                rounded="slight"
                onClick={() => setIsOpen(true)}
              >
                <ShareIcon src={SHARE_ICON} alt="" /> Share
              </ShareButton>
              <Spacer $marginTop="-10px" />
            </Section>

            {questCard && (
              <>
                <Spacer $marginTop="0.75rem" />
                <Section $noPadding={true}>
                  {questCard?.question || questCard?.question === "" ? (
                    <CreatorPoll creatorPoll={questCard} />
                  ) : (
                    <>
                      <Spacer $marginTop="1.25rem" />
                      <CardHeader>
                        <JoinIn>Join in on</JoinIn>
                        <LatestQuest>The latest quest</LatestQuest>
                      </CardHeader>
                      <Spacer $marginTop="1rem" />
                      <QuestCard quest={questCard} noPadding={true} />
                    </>
                  )}
                </Section>
              </>
            )}
          </DesktopRightContainer>
        </DesktopContainer>

        <Spacer $marginTop="5rem" />
      </Container>

      <ShareModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        storyworld={storyworldQuery?.data}
      />
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  @media (${device.mobile}) {
    background-color: #1d232d;
    position: relative;
  }
`;

const DesktopContainer = styled.div`
  display: inline-flex;
  gap: 13px;
  position: relative;
  text-align: left;

  @media (${device.tablet}) {
    width: 720px;
  }

  @media (${device.desktop}) {
    width: 908px;
  }
`;

const DesktopLeftContainer = styled.div`
  @media (${device.tablet}) {
    width: 414px;
  }

  @media (${device.desktop}) {
    width: 523px;
  }
`;

const DesktopRightContainer = styled.div`
  @media (${device.tablet}) {
    width: 293px;
  }

  @media (${device.desktop}) {
    width: 369px;
  }
`;

const CardHeader = styled.div`
  font-family: "Hoss Sharp";
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
`;

const CreatedBy = styled.div`
  color: var(--color-gray-50);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const Description = styled.div`
  color: var(--color-gray-50);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Genre = styled.div`
  color: var(--color-cream-base);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: -0.2rem;
  color: ${(props) => props.$isPink && "var(--color-pink)"};
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Image = styled.img`
  object-fit: cover;
  border-radius: 12px;
  width: 100%;

  @media (${device.tablet}) {
    height: 232px;
  }

  @media (${device.desktop}) {
    height: 294px;
  }
`;

const JoinIn = styled.div`
  color: var(--color-green-base);
  font-size: 14px;
  font-weight: 600;
`;

const LatestQuest = styled.div`
  color: var(--color-cream);
  font-size: 18px;
  font-weight: 700;
`;

const Section = styled.div`
  border-radius: 12px;
  border: 1px solid var(--border-light);
  background: var(--color-gray-600);
  padding: ${(props) => (props.$noPadding ? "0" : "20")}px;
  padding-right: ${(props) => props.$noRightPadding && "0px"};
  overflow: hidden;
`;

const ShareButton = styled(Button)`
  color: var(--color-cream-base);
`;

const ShareIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const Title = styled.h1`
  margin-bottom: 0px;
  font-family: "Hoss Sharp";
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  color: var(--color-cream-base);
  text-align: left;
`;

const UnicodeChar = styled.span`
  font-family: "Symbola";
  margin-bottom: -3px;
  margin-right: 4px;
`;

const Users = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
