import { useContext } from "react";
import styled from "styled-components";
import { deleteUser } from "../../helpers/firebase";
import { message } from "antd";
import { UserDisplay } from "..";
import { AuthContext } from "../../context/AuthProvider";
import { useMutation } from "@tanstack/react-query";
import { device } from "../../lib/breakpoints";
import { useNavigate } from "react-router-dom";

export default function DeleteUserModalContent({ onDelete, onCancel }) {
  const { user, userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      return deleteUser();
    },
    onSuccess: () => {
      message.success("Account deleted successfully");
      onDelete?.();
      navigate("/");
    },
    onError: () => {
      message.error(
        "Error deleting account, you may need to log in again first."
      );
    },
  });

  return (
    <Container>
      <UserDisplay userId={user?.uid} size={45} />
      <Username>{userInfo?.username}</Username>
      <DeleteText>Are you sure you want to delete your account?</DeleteText>
      <ButtonContainer>
        {isPending ? (
          <DeleteUserButtonDeleting>
            <Spinner alt="" src="/icons/loading-spheres.svg" />
          </DeleteUserButtonDeleting>
        ) : (
          <DeleteUserButton
            onClick={() => {
              mutate();
            }}
          >
            <DeleteIcon src="/icons/trash.svg" alt="" />
            Delete account
          </DeleteUserButton>
        )}
      </ButtonContainer>

      <ButtonContainer>
        <CancelDeleteUserButton onClick={onCancel}>
          Cancel
        </CancelDeleteUserButton>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const DeleteIcon = styled.img`
  width: 12px;
  height: auto;
  margin: 0px 3px 4px 0px;
`;

const DeleteUserButton = styled.button`
  color: var(--lightText);
  background: var(--color-red-error);
  border: 0;
  height: 45px;
  border-radius: 24px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 0px;
  font-family: var(--font-primary);
`;

const DeleteUserButtonDeleting = styled.button`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  background: var(--color-gray-300);
  border: 0;
  height: 45px;
  border-radius: 24px;
  cursor: pointer;
  width: 100%;
`;

const CancelDeleteUserButton = styled.button`
  color: white;
  width: 100%;
  border: 0;
  background: none;
  height: 45px;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-family: var(--font-primary);
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 10px 0px 0px 0px;
`;

const DeleteText = styled.p`
  margin: 30px auto 50px auto;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--color-gray-50);

  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 280px;

  @media ${device.tablet}, ${device.desktop} {
    max-width: 80%;
    margin: auto;
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }
`;

const Username = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--profileUsername);
  margin-top: 8px;
  font-family: var(--font-primary);
`;
