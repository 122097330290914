import React from "react";
import { Checkbox, Col, Row, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { containsProfanity, removeTags } from "../helpers/general";
import { DeleteFilled } from "@ant-design/icons";
import DateTimePicker from "react-datetime-picker";
import UploadDragAndDropVideo from "./UploadDragAndDropVideo";
import ReactPlayer from "react-player";
import {
  SUBMISSION_TYPES,
  vhPanelLeftPanelWidth,
  vhPanelrightPanelLeftMargin,
} from "../helpers/constants";
import styled from "styled-components";
import { UploadDragAndDropImageQuestThumbnail } from "../components/vh-panel";
import {
  InputSection,
  RightAlignedCol,
  Section,
  StyledInput,
} from "../styles/CommonElements";
import { StyledQuill } from "../components/general";
import CharacterCounter from "../components/CharacterCounter";
import SuggestedSearchEditor from "./SuggestedSearchEditor";

const questDescriptionLimit = 400;
const promptLimit = 200;
const rteMinHeight = 165;

export default function QuestEditor({
  title,
  setTitle,
  description,
  setDescription,
  objectiveDescription,
  setObjectiveDescription,
  textSubmissionLimit,
  setTextSubmissionLimit,
  moreInfo,
  setMoreInfo,
  videoUrl,
  setVideoUrl,
  thumbnailUrl,
  setThumbnailUrl,
  endTimestamp,
  onEndTimestampChange,
  startTimestamp,
  onStartTimestampChange,
  submissionRequirements,
  setSubmissionRequirements,
  suggestedSearchTerms,
  setSuggestedSearchTerms,
  ongoing,
  setOngoing,
}) {
  const [moreQuestInfoOpen, setMoreQuestInfoOpen] = useState(
    moreInfo === "" ? false : true
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <LeftPanel>
        <Section>
          <InnerSection>
            <FieldHeader>Quest Assets</FieldHeader>
            <InputSection>
              <Row>
                <Col span={20}>
                  <InputHeader>Quest Thumbnail (optional)</InputHeader>
                  <ImageHintContainer>
                    <ImageHint>Recommended: 1920x1080</ImageHint>
                  </ImageHintContainer>
                </Col>
                <RightAlignedCol span={4}>
                  {thumbnailUrl !== undefined && (
                    <DeleteVideoButton
                      onClick={() => {
                        setThumbnailUrl(undefined);
                      }}
                    >
                      <DeleteFilled />
                    </DeleteVideoButton>
                  )}
                </RightAlignedCol>
              </Row>
              {thumbnailUrl !== undefined ? (
                <ThumbnailImage src={thumbnailUrl} alt="" />
              ) : (
                <UploadDragAndDropImageQuestThumbnail
                  setImageUrl={setThumbnailUrl}
                  className="upload-drag-and-drop"
                  maxFileSizeInMb={100}
                  byPassRes={true}
                />
              )}
            </InputSection>

            <InputSection>
              <Row>
                <Col span={20}>
                  <InputHeader>Quest Intro Video (optional)</InputHeader>
                  <ImageHintContainer>
                    <ImageHint>Recommended: 1920x1080</ImageHint>
                  </ImageHintContainer>
                </Col>
                <RightAlignedCol span={4}>
                  {videoUrl !== undefined && (
                    <DeleteVideoButton
                      onClick={async () => {
                        setVideoUrl(undefined);
                      }}
                    >
                      <DeleteFilled />
                    </DeleteVideoButton>
                  )}
                </RightAlignedCol>
              </Row>

              {videoUrl !== undefined ? (
                <ReactPlayer
                  className="quest-video-player"
                  controls={true}
                  url={videoUrl}
                />
              ) : (
                <UploadDragAndDropVideo
                  setVideoUrl={setVideoUrl}
                  className="upload-drag-and-drop"
                  maxFileSizeInMb={100}
                />
              )}
            </InputSection>

            <FieldHeader>Quest Duration</FieldHeader>

            <SectionOption>
              <InputHeaderSpan>Submission style:</InputHeaderSpan>
              <ButtonGroup>
                <Checkbox
                  onClick={(e) => setOngoing(e.target.checked)}
                  checked={ongoing}
                >
                  Ongoing
                </Checkbox>
              </ButtonGroup>
            </SectionOption>

            <InputSection>
              <Row>
                <Col span={6}>
                  <InputHeader style={{ marginTop: 10 }}>Start</InputHeader>
                </Col>
                <Col span={18}>
                  <DateTimePicker
                    onChange={onStartTimestampChange}
                    value={startTimestamp}
                    className="datetime-picker"
                    maxDate={endTimestamp}
                    clearIcon={null}
                    calendarIcon={null}
                    disableClock={true}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={6}>
                  <InputHeader style={{ marginTop: 10 }}>End</InputHeader>
                </Col>
                <Col span={18}>
                  <DateTimePicker
                    onChange={onEndTimestampChange}
                    value={endTimestamp}
                    className="datetime-picker"
                    minDate={startTimestamp}
                    clearIcon={null}
                    calendarIcon={null}
                    disableClock={true}
                    disabled={ongoing}
                  />
                </Col>
              </Row>
            </InputSection>
          </InnerSection>
        </Section>
      </LeftPanel>

      <RightPanel>
        <Section>
          <InnerSection>
            <FieldHeader>Quest Information</FieldHeader>
            <SectionOption>
              <Row>
                <Col span={12}>
                  <InputHeader>Title</InputHeader>
                </Col>
                <RightAlignedCol span={12}>
                  <Tooltip placement="left" title={"Max 150 characters"}>
                    <img src="/icons/info.svg" alt="" />
                  </Tooltip>
                </RightAlignedCol>
              </Row>
              <StyledStyledInput
                defaultValue={title}
                placeholder="Untitled quest"
                onChange={(e) => {
                  const text = e.target.value;
                  containsProfanity(text);
                  setTitle(text);
                }}
                maxLength={150}
              />
            </SectionOption>

            <SectionOption>
              <Row>
                <Col span={20}>
                  <InputHeader>Quest Description</InputHeader>
                </Col>
                <RightAlignedCol span={4}>
                  <CharacterCounter
                    count={removeTags(description).length}
                    max={questDescriptionLimit}
                  />
                </RightAlignedCol>
              </Row>

              <StyledQuill
                placeholder={`Write your quest story here. Max ${questDescriptionLimit} characters.`}
                value={description}
                setValue={setDescription}
                limit={questDescriptionLimit}
                styles={{ minHeight: `${rteMinHeight}px` }}
              />
            </SectionOption>

            <FieldHeader>Optional Quest Information</FieldHeader>
            {!moreQuestInfoOpen ? (
              <SectionOption>
                <AddMoreButton onClick={() => setMoreQuestInfoOpen(true)}>
                  + Add More Quest Info
                </AddMoreButton>
              </SectionOption>
            ) : (
              <SectionOption>
                <InputHeader>More info</InputHeader>
                <StyledQuill
                  placeholder={"What other information do you want to include?"}
                  value={moreInfo}
                  setValue={setMoreInfo}
                  limit={2000}
                  styles={{ minHeight: "225px" }}
                />
              </SectionOption>
            )}

            <FieldHeader>Creative Prompt</FieldHeader>
            <SectionOption>
              <Row>
                <Col span={20}>
                  <InputHeader>
                    Write the question that will be shown when users create
                    submissions.
                  </InputHeader>
                </Col>
                <Col span={4}>
                  <CharacterCounter
                    count={removeTags(objectiveDescription).length}
                    max={promptLimit}
                  />
                </Col>
              </Row>

              <StyledQuill
                placeholder={`What do you want the community to create? Max ${promptLimit} characters.`}
                value={objectiveDescription}
                setValue={setObjectiveDescription}
                limit={promptLimit}
                styles={{ minHeight: `${rteMinHeight}px` }}
              />
            </SectionOption>

            <FieldHeader style={{ marginTop: 60 }}>Quest Settings</FieldHeader>
            <SectionOption>
              <InputHeaderSpan>Quest type:</InputHeaderSpan>
              <ButtonGroup>
                <RadioButton
                  onClick={() =>
                    setSubmissionRequirements([SUBMISSION_TYPES.IDEA])
                  }
                  activated={
                    !submissionRequirements.includes(SUBMISSION_TYPES.TEXT) &&
                    submissionRequirements.includes(SUBMISSION_TYPES.IDEA)
                  }
                >
                  Visual
                </RadioButton>
                <RadioButton
                  onClick={() =>
                    setSubmissionRequirements(SUBMISSION_TYPES.TEXT)
                  }
                  activated={
                    submissionRequirements.includes(SUBMISSION_TYPES.TEXT) &&
                    !submissionRequirements.includes(SUBMISSION_TYPES.IDEA)
                  }
                >
                  Writing
                </RadioButton>
                <RadioButton
                  onClick={() =>
                    setSubmissionRequirements([
                      SUBMISSION_TYPES.IDEA,
                      SUBMISSION_TYPES.TEXT,
                    ])
                  }
                  activated={
                    submissionRequirements.includes(SUBMISSION_TYPES.TEXT) &&
                    submissionRequirements.includes(SUBMISSION_TYPES.IDEA)
                  }
                >
                  Visual & Writing
                </RadioButton>
              </ButtonGroup>
              {submissionRequirements.includes(SUBMISSION_TYPES.TEXT) && (
                <>
                  <InputHeaderSpan thin={true}>Limit:</InputHeaderSpan>
                  <SubmissionLimitInput
                    defaultValue={textSubmissionLimit}
                    onChange={(e) => setTextSubmissionLimit(e.target.value)}
                    type="number"
                  />
                </>
              )}
            </SectionOption>
            <SectionOption>
              <SuggestedSearchEditor
                suggestedSearchTerms={suggestedSearchTerms}
                setSuggestedSearchTerms={setSuggestedSearchTerms}
              />
            </SectionOption>
          </InnerSection>
        </Section>
      </RightPanel>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: inline-flex;

  .datetime-picker {
    width: 100% !important;
  }
`;

const LeftPanel = styled.div`
  width: ${vhPanelLeftPanelWidth}px;
`;

const RightPanel = styled.div`
  width: calc(
    100% - ${vhPanelLeftPanelWidth}px - ${vhPanelrightPanelLeftMargin}px
  );
  margin-left: ${vhPanelrightPanelLeftMargin}px;
`;

const FieldHeader = styled.div`
  color: var(--darkTextColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  letter-spacing: -0.32px;
`;

const InputHeader = styled.div`
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom: 14px;
`;

const InputHeaderSpan = styled.span`
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.thin ? "400" : "600")};
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom: 14px;
`;

const DeleteVideoButton = styled.button`
  align-items: top;
  border: none;
  margin: auto;
  height: auto;
  padding: 0px;
  font-weight: bold;
  background: none;
  cursor: pointer;
`;

const SectionOption = styled.div`
  margin: 22px 0px 30px 0px;

  .includes-characters-checkbox {
    border-color: #353945 !important;
    margin-top: 6px;
    margin-left: 18px;

    .ant-checkbox-disabled {
      border-color: #353945 !important;
      border-width: 2px;
    }

    .ant-checkbox:hover {
      height: 24px;
      width: 24px;
      border-radius: 8px !important;
      border-color: #353945 !important;
      border-width: 2px;
    }

    .ant-checkbox-inner {
      height: 24px;
      width: 24px;
      border-radius: 4px !important;
      border-color: #353945 !important;
      border-width: 2px;
      background: transparent !important;
    }

    .ant-checkbox-checked {
      background: transparent !important;
      border-color: #353945 !important;
      border-radius: 4px !important;

      .ant-checkbox-inner {
        background: transparent;
        border-color: #353945 !important;
        border-width: 2px;
      }
    }

    .ant-checkbox-inner {
      background: transparent;
    }

    .ant-checkbox::after {
      background: transparent !important;
      border-color: #353945 !important;
      border-radius: 4px !important;
      border-width: 2px;
    }

    .ant-checkbox-inner::after {
      z-index: 9;
      width: 6px;
      height: 12px;
      margin-left: 1.5px;
      margin-top: -1px;
    }
  }

  .includes-characters-checkbox:hover {
    border-color: #353945 !important;
    background: transparent !important;
    border-width: 2px;
  }

  .ant-checkbox-wrapper:hover {
    border-color: #353945 !important;
    border-width: 2px;
  }
`;

const InnerSection = styled.div`
  padding: 12px 16px 20px 16px;
  text-align: left;
`;

const SubmissionLimitInput = styled.input`
  height: 26px;
  color: black;
  vertical-align: middle;
  width: 60px;
  margin-left: 10px;
  border: 0;
`;

const ButtonGroup = styled.div`
  display: inline-flex;
  margin-left: 18px;
`;

const RadioButton = styled.button`
  margin-right: 18px;
  color: #efefef;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  line-height: 24px;
  border-radius: 4px;
  padding: 0px 8px;
  font-weight: ${(props) => (props.activated ? "700" : "500")};
  border: 2px solid
    ${(props) => (props.activated ? "var(--nextPreviewButton)" : "#353945")};
  background: ${(props) =>
    props.activated ? "var(--nextPreviewButton)" : "var(--color-gray-500)"};
`;

const AddMoreButton = styled.button`
  border-radius: 8px;
  border: 2px solid #353945;
  background: var(--color-gray-500);
  margin-top: -4px;
  display: inline-flex;
  padding: 7px 17px 5px 15px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: -0.12px;
  cursor: pointer;
`;

const ThumbnailImage = styled.img`
  height: 160px !important;
  width: 100%;
  object-fit: cover;
  border-radius: 12px !important;
`;

const ImageHintContainer = styled.div`
  margin: -10px 0px 10px 0px;
`;

const ImageHint = styled.p`
  font: unset;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--inputFieldText);
  padding: 0px !important;
  margin: 0px;
  display: block;
  text-align: left;
`;

const StyledStyledInput = styled(StyledInput)`
  padding-left: 26px;
`;
