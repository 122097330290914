import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { approveSubmission, rejectSubmission } from "../helpers/firebase";
import { css, styled } from "styled-components";
import userModel from "../lib/firebase/userModel";
import SubmissionFilter, {
  getSubmissionType,
} from "../components/submissions/SubmissionFilter";
import IdeaRow from "../components/IdeaRow";
import Button from "../components/Button";
import { Modal } from "antd";
import IdeaCarousel from "../components/IdeaCarousel";
import { ReactSVG } from "react-svg";

export default function AdminSubmissionPreview({
  submission,
  isPollOpen,
  isRejected,
  isPollConfirmed,
  isPollCompleted = false,
  isQuestOngoing = false,
}) {
  const [userName, setUserName] = useState(0);
  const [voteAmount, setVoteAmount] = useState(0);
  const [rejected, setRejected] = useState(submission.rejected);
  const [ideaBlockForModal, setIdeaBlockForModal] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const userInfo = await userModel.getOneById(submission.creator);
      setUserName(
        userInfo && userInfo.username ? userInfo.username : "MissingNo"
      );
    }
    if ((isPollOpen || isPollCompleted) && !rejected && isPollConfirmed)
      setVoteAmount(submission.voteCount);
    else setVoteAmount(0);
    fetchData();
  }, [
    submission,
    isPollOpen,
    isRejected,
    rejected,
    isPollConfirmed,
    isPollCompleted,
  ]);

  return (
    <>
      <Container>
        <div>
          {!isQuestOngoing && <VoteOverlay>{voteAmount}</VoteOverlay>}
          <SubmissionContainer
            onClick={() => {
              if (isQuestOngoing) {
                setIdeaBlockForModal(submission);
              }
            }}
          >
            {!isPollConfirmed && !isQuestOngoing && (
              <RejectOverlay>
                <OverlayButton
                  onClick={async () => {
                    if (!rejected) {
                      setRejected(true);
                      await rejectSubmission({ submission });
                    } else {
                      setRejected(false);
                      await approveSubmission({
                        submissionId: submission.id,
                      });
                    }
                  }}
                >
                  <OverlayIcon
                    src={!rejected ? "/icons/x.svg" : "/icons/check.svg"}
                    $scale={!rejected ? "1.5" : "1"}
                  />
                </OverlayButton>
              </RejectOverlay>
            )}
            <SubmissionFilter
              submission={submission}
              genAiArt={
                <SubmissionImage
                  src={submission.imageUrl}
                  alt=""
                  $rejected={rejected}
                />
              }
              text={
                <WritingContainer $rejected={rejected}>
                  <WritingTitle>{submission.title}</WritingTitle>
                  <WritingText>{submission.text}</WritingText>
                </WritingContainer>
              }
              idea={
                <SubmissionIdea
                  $rejected={rejected}
                  $isPollConfirmed={isPollConfirmed}
                >
                  <IdeaRow idea={submission} />
                </SubmissionIdea>
              }
            />
          </SubmissionContainer>
        </div>
        <SubmissionDetails
          style={{
            color: rejected
              ? "var(--color-red-rejected)"
              : "var(--color-cream-base)",
          }}
        >
          <div>
            <Title>{submission?.title}</Title>
            <Username>@{userName}</Username>
            {rejected && <div>Rejected</div>}
            {getSubmissionType(submission) !== "text" && (
              <SubmissionText>{submission.text}</SubmissionText>
            )}
          </div>
          {getSubmissionType(submission) === "idea" && !isQuestOngoing && (
            <ButtonContainer>
              <Button
                variant="ghost"
                size="small"
                rounded="slight"
                onClick={() => {
                  setIdeaBlockForModal(submission);
                }}
              >
                Enlarge Images
              </Button>
            </ButtonContainer>
          )}
          {getSubmissionType(submission) === "idea" && isQuestOngoing && (
            <ButtonContainer>
              <StyledButton
                onClick={async () => {
                  setRejected(false);
                  await approveSubmission({
                    submissionId: submission.id,
                  });
                }}
                disabled={!rejected}
              >
                <OverlayIcon src="/icons/check.svg" />
              </StyledButton>
              <StyledButton
                onClick={async () => {
                  setRejected(true);
                  await rejectSubmission({ submission });
                }}
                disabled={rejected}
              >
                <OverlayIcon src="/icons/x.svg" $scale="1.5" />
              </StyledButton>
            </ButtonContainer>
          )}
        </SubmissionDetails>
      </Container>
      <Modal
        open={Boolean(ideaBlockForModal)}
        onCancel={() => {
          setIdeaBlockForModal(null);
        }}
        footer={null}
        width="70vw"
      >
        <div style={{ padding: "2rem 0.5rem" }}>
          <IdeaCarousel images={ideaBlockForModal?.images} />
        </div>
      </Modal>
    </>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  max-width: 200px;
  display: inline-flex;
  margin-right: auto;
  gap: 24px;
`;

const OverlayButton = styled.button`
  width: 72px;
  height: 72px;
  background: #23262f;
  border-radius: 70px;
  border: 0px;
  box-shadow: 0px 10px 16px 0px rgba(22, 24, 31, 0.71);
`;

const OverlayIcon = styled(ReactSVG)`
  color: #f17878;
  transform: ${(props) => props.$scale && `scale(${props.$scale})`};

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SubmissionContainer = styled.div`
  position: relative;
  width: 700px;
`;

const StyledButton = styled(Button)`
  background: transparent;

  &:disabled,
  [disabled] {
    opacity: 0.3;
  }
`;

const RejectOverlay = styled.div`
  z-index: 3;

  ${SubmissionContainer}:hover & {
    opacity: 1;
  }

  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  vertical-align: middle;
  width: auto;
`;

const SubmissionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 2;
  min-width: 250px;
  max-width: 600px;
  word-wrap: break-word;
  color: inherit;
`;

const SubmissionIdea = styled.div`
  width: 640px;
  height: 285px;
  border-radius: 27px;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border: ${({ $rejected }) =>
    $rejected && "4.5px solid var(--color-red-rejected)"};

  ${({ $isPollConfirmed }) =>
    !$isPollConfirmed &&
    css`
      &:hover {
        opacity: 0.5;
      }
    `}
`;

const SubmissionImage = styled.img`
  width: 100%;
  max-height: 400px !important;
  max-width: 400px !important;
  margin: 12px 0px;
  border-radius: 9px;
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border: ${({ $rejected }) =>
    $rejected && "4.5px solid var(--color-red-rejected)"};
`;

const SubmissionText = styled.p`
  color: var(--darkTextColor);
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 4px;
`;

const Title = styled.div`
  color: var(--color-cream-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.744px;
`;

const Username = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2rem;
`;

const VoteOverlay = styled.div`
  position: absolute;
  display: block;
  z-index: 2;
  color: var(--hoverColor);
  font-weight: bold;
  height: 30px;
  width: 50px;
  background: var(--color-black);
  border: 1px solid var(--hoverColor);
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  top: 10px;
  left: 10px;
`;

const WritingContainer = styled.div`
  width: 100%;
  min-height: 150px;
  margin: 12px 0px;
  margin-top: 4rem;
  border-radius: 9px;
  padding: 14px;
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  text-align: left;
  border-radius: 12px;
  border: ${({ $rejected }) =>
    $rejected
      ? "2.5px solid var(--color-red-rejected)"
      : "1.5px solid #35383f"};
  background: var(--color-gray-700);
`;

const WritingText = styled.div`
  color: #c2c9dd;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const WritingTitle = styled.div`
  color: var(--cream-base, #fff6f2);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 6px;
`;
