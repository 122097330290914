import { createContext, useState } from "react";
import { useInView } from "react-intersection-observer";

export const ScrollOffscreenContext = createContext();

function ScrollOffscreenProvider({ children }) {
  // Intersection observer isn't able to detect if an element is sitting on top of the observer,
  // and since we set disableScrollLocking to true, it is possible to scroll down with a sheet open.
  const [sheetsOpen, setSheetsOpen] = useState(0);
  const { ref, inView } = useInView({
    threshold: 1,
    // Seems like it needs to clear some space reserved for Android browsers
    rootMargin: "0px 0px 60px 0px",
  });

  const addOpenSheet = () => {
    setSheetsOpen(sheetsOpen + 1);
  };

  const removeOpenSheet = () => {
    setSheetsOpen(sheetsOpen - 1);
  };

  return (
    <ScrollOffscreenContext.Provider
      value={{
        ref,
        inView: inView && sheetsOpen === 0,
        addOpenSheet,
        removeOpenSheet,
      }}
    >
      {children}
    </ScrollOffscreenContext.Provider>
  );
}

export default ScrollOffscreenProvider;
