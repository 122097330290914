import React, { createRef, useContext, useEffect } from "react";
import { MainHeader, Spacer } from "../styles/CommonElements";
import predictionModel from "../lib/firebase/predictionModel";
import { APP_NAME } from "../helpers/constants";
import { useQuery } from "@tanstack/react-query";
import { predictionKeys } from "../lib/queryKeys";
import { AuthContext } from "../context/AuthProvider";
import Carousel from "better-react-carousel";
import styled from "styled-components";
import { Link } from "react-router-dom";

const predictionPageAmount = 24;

export default function RecentPredictions() {
  const { userInfo } = useContext(AuthContext);
  let carouselRef = createRef();

  const { data: recentPredictions } = useQuery({
    queryKey: predictionKeys.user(userInfo.id),
    queryFn: () => {
      return predictionModel.getMany(
        ["creator", "==", userInfo.id],
        ["sort", "createdAt", "desc"],
        ["limit", predictionPageAmount]
      );
    },
    placeholderData: [],
    /*
    onSuccess: () => {
      carouselRef.current?.goToSlide(0);
    },
    */
  });

  return (
    recentPredictions.length > 0 && (
      <div>
        <HeaderContainer>
          <StyledMainHeader>Recent {APP_NAME}s</StyledMainHeader>
          <Subheader>
            See past {APP_NAME}s you've created. Click on one to download it.
          </Subheader>
        </HeaderContainer>
        <Spacer $marginTop="2rem" />
        <StyledCarousel ref={carouselRef} cols={3} rows={1} gap={8}>
          {recentPredictions.map((prediction) => (
            <Carousel.Item key={prediction.id}>
              <Link
                to={`${prediction.output}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PredictionImage src={prediction.output} alt="" />
              </Link>
            </Carousel.Item>
          ))}
        </StyledCarousel>
      </div>
    )
  );
}

const HeaderContainer = styled.div`
  margin: auto;
  margin-left: 10%;
  text-align: left;
  width: 90%;
`;

const PredictionImage = styled.img`
  object-fit: cover;
  height: 100%;
`;

const StyledCarousel = styled(Carousel)`
  max-height: 350px;
`;

const StyledMainHeader = styled(MainHeader)`
  text-align: left;
`;

const Subheader = styled.div`
  font-size: 16px;
  margin: auto;
  text-align: left;
`;
