import "react-photo-album/columns.css";
import styled from "styled-components";
import { PaddedContainer, Spacer } from "../styles/CommonElements";

const SuggestedSearchSelector = ({
  selectSearchQuery,
  suggestedSearchTerms,
}) => {
  return (
    <>
      <PaddedContainer>
        <Header>Suggested Searches</Header>
      </PaddedContainer>
      <Spacer $marginTop="1rem" />
      <Container>
        <SearchTermContainer>
          {suggestedSearchTerms.map((term) => (
            <>
              <SearchTerm
                key={term}
                onClick={() => selectSearchQuery(term)}
                variant="ghost"
              >
                {term}
              </SearchTerm>
              <RightSpacer />
            </>
          ))}
        </SearchTermContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Header = styled.div`
  color: var(--color-cream);
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const RightSpacer = styled.span`
  margin-right: 8px;
`;

const SearchTerm = styled.div`
  display: flex;
  border: 1px solid white;
  justify-content: center;
  width: fit-content;
  height: 32px;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  border: 1px solid #949ba4;
`;

const SearchTermContainer = styled.span`
  width: 100%;
  display: -webkit-inline-box;
  overflow-x: scroll;
  z-index: 1;
  right: 0;
  padding-left: 14px;

  &::-webkit-scrollbar {
    display: none !important;
  }
`;

export default SuggestedSearchSelector;
