import { useState, useEffect, createContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "../helpers/authentication";
import {
  DIALOG_NAME,
  useOpenParamDialog,
} from "../helpers/useDialogSearchParam";
import userModel from "../lib/firebase/userModel";
import { userKeys } from "../lib/queryKeys";
import mixpanel from "mixpanel-browser";
import banModel from "../lib/firebase/banModel";
import suspensionModel from "../lib/firebase/suspensionModel";
import hasAlertModel from "../lib/firebase/hasAlertModel";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const { data: userInfo, isLoading: isUserInfoLoading } = useQuery({
    queryKey: userKeys.user(user?.uid),
    queryFn: () => {
      return userModel.getOneById(user.uid);
    },
    enabled: !!user,
    staleTime: Infinity,
  });

  const requireLogin = useOpenParamDialog(DIALOG_NAME.login);

  const isGuest = !user && !loading;
  const isThirdPartyAuthUser =
    user && user?.providerData[0]?.providerId !== "password";

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (callbackUser) => {
      if (callbackUser) {
        setUser(callbackUser);

        mixpanel.identify(callbackUser.uid);
        banModel.listen();
        suspensionModel.listen();
        hasAlertModel.listen();
      } else {
        setUser(null);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        userInfo,
        isUserInfoLoading,
        isGuest,
        isThirdPartyAuthUser,
        requireLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
