import mixpanel from "mixpanel-browser";
import { getAuth } from "firebase/auth";
import { employeeIds } from "./employees";

import { getQuestAndPollStates } from "./general";

export async function trackEvent(eventName, props = {}, bypassCheck = false) {
  const currentUser = getAuth().currentUser;
  if (!bypassCheck) {
    if (currentUser && !employeeIds.includes(currentUser.uid))
      mixpanel.track(eventName, props);
  } else {
    mixpanel.track(eventName, props);
  }
}

export function getMixpanelQuestType(quest) {
  if (
    quest.submissionRequirements.includes("image") &&
    !quest.submissionRequirements.includes("text")
  )
    return "art only";
  else if (
    !quest.submissionRequirements.includes("image") &&
    quest.submissionRequirements.includes("text")
  )
    return "write only";
  else if (
    quest.submissionRequirements.includes("image") &&
    quest.submissionRequirements.includes("text")
  )
    return "art + write";

  return "error";
}

export function getMixpanelQuestState(quest) {
  const { quest: questState, poll: pollState } = getQuestAndPollStates(quest);

  if (questState.isOpen) return "submission open";
  else if (pollState?.isOpen) return "vote open";
  else if (pollState?.isCompleted) return "vote closed";
  else if (questState.isCompleted && pollState === null)
    return "submission closed / no poll";
  else if (questState.isCompleted && !pollState?.isOpen)
    return "submission closed / vote not open";

  return "error";
}
